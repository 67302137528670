import {
  EditEntityView,
  Entity,
  Field,
  RelationList,
  useEntityService,
} from "@stockopedia/cms-ef";
import {
  Loading,
  PlusIcon,
  ReadonlyTextInput,
  TextInput,
} from "@stockopedia/cms-ui";
import { Article } from "modules/cybertorial/articles/services/model";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { RteEditor } from "shared/components/inputs/rte-editor";
import { urls } from "urls";
import * as Yup from "yup";

import { Theme } from "../services/model";
import { ThemeService } from "../services/themes.service";

const schema = Yup.object().shape({
  title: Yup.string().required(),
  callToAction: Yup.string().required(),
});

interface Props {
  state: Theme;
  context: "create" | "edit";
}

const View: React.FC<Props> = ({ state, context }) => {
  const service = useEntityService<ThemeService>();
  const history = useHistory();

  return (
    <Entity
      context={context}
      validationSchema={schema}
      actions={[]}
      state={state}
      onSubmit={async (values) => {
        await service.save(values);
        history.goBack();
      }}
      name="theme"
      fields={[
        <Field
          required
          key="title"
          name="title"
          component={TextInput}
          width="full"
        />,
        <Field
          required
          key="callToAction"
          name="callToAction"
          component={RteEditor}
          width="full"
        />,
        <Field
          key="updatedAt"
          name="updatedAt"
          component={ReadonlyTextInput}
          width="third"
        />,
      ]}
      relations={[
        <RelationList
          name="blueprints"
          actions={[
            {
              label: "Create",
              color: "cancel",
              onClick: () => {
                history.push({
                  pathname: urls.cybertorial.blueprints.create.url,
                  state: {
                    theme: state,
                  },
                });
              },
              scopes: ["list"],
              icon: <PlusIcon color="#9ea7b8" />,
            },
          ]}
          link={(item) => `${urls.cybertorial.blueprints.root.url}/${item.id}`}
          columns={[
            {
              title: "Name",
              projection: (values) => values.name,
              key: "name",
            },
          ]}
          rows={state.blueprints!}
        />,
      ]}
      sections={[
        <RelationList
          key="articles"
          name="Recent Articles"
          link={(item) => `${urls.cybertorial.articles.root.url}/${item.id}`}
          columns={[
            {
              title: "Title",
              projection: (values) =>
                values.blocks.find(({ label }) => label === "title")?.content,
              key: "title",
            },
            {
              title: "Updated At",
              projection: (values) => values.updatedAt,
              key: "updatedAt",
            },
            {
              title: "Author",
              projection: (values: Article) => (
                <Link
                  to={`${urls.cybertorial.authors.root.url}/${
                    values.author!.id
                  }`}
                >
                  {values.author!.firstName} {values.author!.lastName}
                </Link>
              ),
              key: "author",
            },
          ]}
          count={state.numArticles}
          rows={state.recentArticles!}
        />,
      ]}
    />
  );
};

export const CreateView = () => (
  <View context={"create"} state={Theme.empty()} />
);

export const EditView = () => (
  <EditEntityView
    onError={(e) => {}}
    loadingView={() => <Loading />}
    render={(state: Theme) => <View context={"edit"} state={state} />}
  />
);
