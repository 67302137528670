import { Asset } from "../models";

export const compareAssets = (a: Asset | string, b: Asset | string) => {
  if (typeof a === "string" && typeof b === "string") {
    return a === b;
  }

  if (typeof a === "string" && typeof b !== "string") {
    return a === b.id || a === b.url;
  }

  if (typeof a !== "string" && typeof b === "string") {
    return a.id === b || a.url === b;
  }

  if (typeof a !== "string" && typeof b !== "string") {
    return a.id === b.id;
  }

  return false;
};
