import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";

import { env } from "../env";
import { Container } from "../framework/container";
import { UserService } from "../modules/auth/services/user/service";
import { StockopediaClient } from "./stockopedia";
import { StockopediaRestClient } from "./stockopedia-rest";

declare module "../framework/container" {
  interface Container {
    registerStockopediaClient(): Container;
  }
}

const defaultOptions = {
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const httpLink = new HttpLink({
  uri: env.api_url,
  fetchOptions: {
    mode: "cors",
  },
});

Container.prototype.registerStockopediaClient = function () {
  this.registerFactorySingleton(StockopediaClient, (c) => {
    const client = new ApolloClient({
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions as any,
      link: from([httpLink]),
    });

    return new StockopediaClient(c.resolve(UserService), client);
  });

  this.registerFactorySingleton(
    StockopediaRestClient,
    (c) => new StockopediaRestClient(c.resolve(UserService), env.assets_host),
  );

  return this;
};
