import styled from "styled-components";

interface Props {
  clickable?: boolean;
}

export const Paper = styled.div<Props>`
  width: 100%;
  padding: var(--padding-standard);
  position: relative;
  border-radius: 3px;
  box-shadow: 0 2px 4px #ced4de;
  background-color: white;

  cursor: ${({ clickable = false }) => (clickable ? "pointer" : "default")};
`;
