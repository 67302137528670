export const generateSearchDecorator = (regex: RegExp, component: Function) => {
  return {
    strategy: (contentBlock, callback) => {
      if (regex) {
        findWithRegex(regex, contentBlock, callback)
      }
    },
    component
  }
}

const findWithRegex = (regex: RegExp, contentBlock, callback) => {
  const text = contentBlock.getText()
  let matchArr, start, end
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index
    end = start + matchArr[0].length
    callback(start, end)
  }
}
