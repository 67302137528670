import { exporter } from "@stockopedia/rte";
import { IMapper } from "@stockopedia/typed-mapper";
import { StockopediaClient } from "clients/stockopedia";
import {
  DataResponse,
  IEntityController,
  MutationResult,
} from "framework/types";
import { Block } from "modules/cybertorial/articles";
import {
  ListParams as AuthorsListParams,
  AuthorsService,
} from "modules/cybertorial/authors/services/authors.service";
import { Author } from "modules/cybertorial/authors/services/model";
import { Blueprint, BlueprintService } from "modules/cybertorial/blueprints";
import { Direction } from "shared/types";
import { makeSortParams } from "shared/utils";

import { Template, TemplateStatus } from "./model";
import { TemplatePreview } from "./preview.model";

const TEMPLATES_QUERY = `
query GetTemplates(
  $paging: PagingArgs, $filters: TemplateFilters!, $sort: SortArgs) {
   templates(paging: $paging, filters: $filters, sort: $sort) 
       {
          pageInfo {
            totalPages
            totalCount
            currentPage
          }
           nodes {
              id
              title
              status
              createdAt
              updatedAt
              blueprint {
                id
                name
                theme {
                  callToAction
                }
              }
              articles {
                pageInfo {
                  totalCount
                }
              }
           }
   }
}
`;

const PREVIEW_QUERY = `
query GetTemplatePreview($id: ID!) {
  template(
    id: $id,
  )
  {
    title
    preview {
      id
      title
      content
      status
      createdAt
      updatedAt
      blueprint {
        id
        name
        theme {
          callToAction
          title
          id
        }
      }
      author {
        firstName
        lastName
        id
      }
    }
  }
}
`;

const TEMPLATE_QUERY = `
query GetTemplate($id: ID!) {
  template(
    id: $id,
  ) 
  {
    id
    title
    content
    status
    createdAt
    updatedAt
    blueprint {
      id
      name
      theme {
        callToAction
        title
        id
      }
    }
    author {
      firstName
      lastName
      id
    }
    articles {
      nodes {
        blocks {
          label
          content
        }
        id
        updatedAt
        author {
          id
          firstName
          lastName
        }
      }
      pageInfo {
        totalCount
      }
    }
  }
}
`;

const SAVE_TEMPLATE_QUERY = `
mutation UploadTemplate(
  $blueprintId: String
  $content: String!
  $id: ID
  $title: String!
  $authorId: ID,
  $status: TemplateStatus) {
  upsertTemplate(
    blueprintId: $blueprintId
    content: $content
    id: $id
    title: $title
    authorId: $authorId,
    status: $status
  ) 
  {
    id
  }
}
`;

const ARCHIVE_TEMPLATE_QUERY = `
mutation ArchiveTemplate(
  $blueprintId: String
  $content: String!
  $id: ID
  $title: String!
  $authorId: ID,
  $status: TemplateStatus) {
  upsertTemplate(
    blueprintId: $blueprintId
    content: $content
    id: $id
    title: $title
    authorId: $authorId,
    status: $status
  )
  {
    id
  }
}
`;

interface ListParams {
  page: number;
  take: number;
  filters: {
    authorFirstName?: string;
    authorLastName?: string;
    title?: string;
    status?: TemplateStatus;
  };
  sort: {
    field: string;
    direction: Direction;
  };
}

interface TemplateResult {
  template: TemplateResponseModel;
}

interface PreviewResult {
  template: {
    preview: TemplateResponseModel;
  };
}

export interface TemplateResponseModel {
  id: string;
  image: string;
  title: string;
  content: string;
  status: TemplateStatus;
  articles: {
    nodes: {
      id: string;
      blocks: Block[];
    }[];
    pageInfo: {
      totalCount: number;
    };
  };
  author: {
    id: string;
    firstName: string;
    lastName: string;
  };
  blueprint: {
    id: string;
    name: string;
    theme: {
      callToAction: string;
      title: string;
      id: string;
    };
  };
  updatedAt: Date;
}

interface UpsertTemplateResult {
  upsertTemplate: Template;
}

interface ListResult {
  templates: {
    pageInfo: {
      currentPage: number;
      totalPages: number;
      totalCount: number;
    };
    nodes: TemplateResponseModel[];
  };
}

export class TemplateService implements IEntityController<Template> {
  constructor(
    private readonly client: StockopediaClient,
    private readonly authorsService: AuthorsService,
    private readonly blueprintService: BlueprintService,
    private readonly mapper: IMapper<TemplateResponseModel, Template>,
    private readonly previewMapper: IMapper<
      TemplateResponseModel,
      TemplatePreview
    >,
  ) {}

  async archive(params: Template): Promise<DataResponse<MutationResult>> {
    try {
      const result = await this.client.query<UpsertTemplateResult>({
        query: ARCHIVE_TEMPLATE_QUERY,
        variables: {
          id: params.id,
          blueprintId: params.blueprint?.id,
          content: params.content,
          title: params.title,
          authorId: params.author?.id,
          status: TemplateStatus.ARCHIVED,
        },
      });

      return {
        data: result.data.upsertTemplate,
      };
    } catch (e) {
      return {
        errors: (e as any)?.errors ?? [e],
      };
    }
  }

  async getPreview(id: string): Promise<DataResponse<TemplatePreview>> {
    try {
      const result = await this.client.query<PreviewResult>({
        query: PREVIEW_QUERY,
        variables: {
          id,
        },
      });

      const {
        data: {
          template: { preview },
        },
      } = result;

      return {
        data: this.previewMapper.map({ ...preview }),
      };
    } catch (e) {
      return {
        errors: (e as any)?.errors ?? [e],
      };
    }
  }

  async get(id: string): Promise<DataResponse<Template>> {
    try {
      const result = await this.client.query<TemplateResult>({
        query: TEMPLATE_QUERY,
        variables: {
          id,
        },
      });

      const {
        data: { template },
      } = result;

      return {
        data: this.mapper.map({ ...template }),
      };
    } catch (e) {
      return {
        errors: (e as any)?.errors ?? [e],
      };
    }
  }

  async list(params: ListParams): Promise<DataResponse<Template[]>> {
    try {
      const result = await this.client.query<ListResult>({
        query: TEMPLATES_QUERY,
        variables: {
          paging: {
            page: params.page,
            take: params.take,
          },
          sort: makeSortParams(params.sort),
          filters: {
            title: params.filters.title,
            author: {
              lastName: params.filters?.authorLastName,
              firstName: params.filters?.authorFirstName,
            },
            status: params.filters?.status ? params.filters?.status : undefined,
          },
        },
      });

      return {
        data: result.data.templates.nodes.map(
          this.mapper.map.bind(this.mapper),
        ),
        paging: result.data.templates.pageInfo,
      };
    } catch (e) {
      return {
        errors: (e as any)?.errors ?? [e],
      };
    }
  }

  async listAuthors(
    params: AuthorsListParams,
  ): Promise<DataResponse<Author[]>> {
    return this.authorsService.list(params);
  }

  async listBlueprints(params): Promise<DataResponse<Blueprint[]>> {
    return this.blueprintService.list(params);
  }

  async save(params: Template): Promise<DataResponse<MutationResult>> {
    try {
      const result = await this.client.query<UpsertTemplateResult>({
        query: SAVE_TEMPLATE_QUERY,
        variables: {
          id: params.id,
          blueprintId: params.blueprint?.id,
          content: exporter(params.content),
          title: params.title,
          authorId: params.author?.id,
          status: params.status,
        },
      });

      return {
        data: result.data.upsertTemplate,
      };
    } catch (e) {
      return {
        errors: (e as any)?.errors ?? [e],
      };
    }
  }
}
