import { Select } from '@buffetjs/core'
import React, { useState } from 'react'
import styled from 'styled-components'

import { H5 } from '../../heading'
import { Padding } from '../../padding/index'
import { Row } from '../../row'
import { TextInput } from '../text'
import { NumberInput } from '../number'
import { Filter, useFilterContext } from './context'
import { FilterIcon } from './icon'

const Wrapper = styled.div`
  width: 300px;
  & > input {
    width: 100%;
  }
`

const Renderer = ({ type, field, value, options }: Filter) => {
  const { onInput } = useFilterContext()

  switch (type) {
    case 'search':
    case 'text':
      return (
        <Wrapper>
          <TextInput
            name='search'
            onChange={(value) => {
              onInput(field, value)
            }}
            type='search'
            value={value?.toString()}
          />
        </Wrapper>
      )
    case 'select':
      return (
        <Wrapper>
          <Select
            name='select'
            onChange={({ target: { value } }) => {
              onInput(field, value)
            }}
            options={['', ...(options ?? [])]}
            value={value ?? ''}
          />
        </Wrapper>
      )
    case 'number':
      return (
        <Wrapper>
          <NumberInput
            name='number'
            onChange={(value) => {
              onInput(field, value)
            }}
            value={Number(value)}
          />
        </Wrapper>
      )
  }
}

const Operator = styled.span`
  margin-right: var(--padding-sm);
  padding-top: var(--padding-sm);
  padding-bottom: var(--padding-sm);
  padding-left: var(--padding-standard);
  padding-right: var(--padding-standard);
  border: 1px solid #e3e9f3;
  display: flex;
  align-items: center;
  height: 3.4rem;
  background: white;
`

const NameContainer = styled.div`
  padding-top: var(--padding-sm);
  padding-bottom: var(--padding-sm);
  padding-left: var(--padding-standard);
  padding-right: var(--padding-standard);
  border: 1px solid #e3e9f3;
  display: flex;
  align-items: center;
  height: 3.4rem;
  min-width: 70px;
  margin-right: var(--padding-sm);
  background: white;
`

const StyledFilterButton = styled.button<ButtonProps>`
  width: 150px;
  color: var(--color-light-text);
  background: var(--color-secondary);
  align-items: center;
  border-radius: 2px;
  padding-top: 6px;
  padding-bottom: 6px;
  justify-content: center;
  border: 0;
  display: flex;
  flex-direction: row;

  & > svg {
    fill: var(--color-light-text);
    margin-right: var(--padding-sm);
  }
`

const FilterContainer = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  max-height: 0px;
  transition: all 0.2s linear;
  overflow: hidden;

  &.show {
    margin-top: var(--padding-standard);
    max-height: 500px;
  }
`

interface ButtonProps {
  onClick: () => void
}

export const FilterButton = (props: ButtonProps) => (
  <StyledFilterButton {...props}>
    <FilterIcon />
    Filter
  </StyledFilterButton>
)

export const Filters = () => {
  const [show, setShow] = useState(false)
  const { filters } = useFilterContext()

  if (!filters || !filters.length) {
    return null
  }

  return (
    <div>
      <FilterButton onClick={() => setShow(!show)} />
      <FilterContainer className={!show ? 'hide' : 'show'}>
        {filters.map((filter, index) => {
          return (
            <Padding key={index} bottom>
              <Row className='items-center'>
                <NameContainer>
                  <H5>{filter.field}</H5>
                </NameContainer>
                <Operator>{filter.operator ?? 'contains'}</Operator>
                <Renderer {...filter} />
              </Row>
            </Padding>
          )
        })}
      </FilterContainer>
    </div>
  )
}
