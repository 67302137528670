import { useEffect } from "react";

import { useAuth } from "../hooks/use-auth";

export const Login = () => {
  const signinRedirect = useAuth((s) => s.signinRedirect);

  useEffect(() => {
    signinRedirect();
  }, [signinRedirect]);
  return <></>;
};
