import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { INITIAL_URL_STORAGE_KEY } from "../contants";
import { useAuth } from "../hooks/use-auth";

export const SigninOidc = () => {
  const history = useHistory();
  const signinAsync = useAuth((s) => s.signinAsync);

  useEffect(() => {
    signinAsync().then(() => {
      history.push(sessionStorage.getItem(INITIAL_URL_STORAGE_KEY) ?? "/");
    });
  }, [signinAsync, history]);

  return <div>Redirecting...</div>;
};
