import { Button } from '@buffetjs/core'
import React from 'react'
import styled from 'styled-components'

import { Column } from '../column'
import { Margin } from '../padding/index'

type Color = 'light' | 'dark'
const colorMap = {
  light: 'var(--color-light-text)',
  dark: 'var(--color-dark-text)'
}

const getColor = (color: Color = 'dark') => colorMap[color]

export interface Props {
  color?: Color
}

export const H3 = styled.h3<Props>`
  text-transform: Capitalize;
  font-size: 2rem;
  color: ${(props) => getColor(props.color)};
`

export const H4 = styled.h4<Props>`
  text-transform: Capitalize;
  font-size: 1.6rem;
  color: ${(props) => getColor(props.color)};
`

export const H5 = styled.h5<Props>`
  text-transform: Capitalize;
  font-size: 1.2rem;
  color: ${(props) => getColor(props.color)};
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: normal;
`

export const H2 = styled.h2<Props>`
  text-transform: Capitalize;
  font-size: 2.2rem;
  color: ${(props) => getColor(props.color)};
`

export const H1 = styled.h1<Props>`
  text-transform: Capitalize;
  font-size: 2.5rem;
  color: ${(props) => getColor(props.color)};
`

const HeadingH1 = styled(H1)`
  margin-bottom: 5px;
`

interface Action {
  disabled?: boolean
  label: string
  color: string
  onClick: () => void
  icon?: React.ReactElement
  href?: string
  title?: string
}

interface HeaderProps {
  actions: Action[]
  title: string
  description?: string
  component?: (props) => JSX.Element
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const HeadingP = styled.p`
  color: grey;
  font-size: 1.2em;
`

export const Header = ({
  actions,
  title,
  description,
  component: Component = (props) => <HeadingH1 {...props} />
}: HeaderProps) => {
  return (
    <Margin bottom>
      <Container>
        <Column>
          <Component>{title}</Component>
          <HeadingP>{description}</HeadingP>
        </Column>
        <ButtonContainer>
          {actions.map((a) => {
            const button = (
              <Button
                disabled={a.disabled}
                color={a.color}
                icon={a.icon}
                label={a.label}
                onClick={a.onClick}
                title={a.title}
              />
            )

            return (
              <Margin key={a.label} left>
                {a.href ? (
                  <a href={a.href} target='_blank'>
                    {button}
                  </a>
                ) : (
                  button
                )}
              </Margin>
            )
          })}
        </ButtonContainer>
      </Container>
    </Margin>
  )
}
