export const INDICES = [
  { value: ".FTASX8630", display: "Ftas Real Estate Investment Idx" },
  { value: ".SPCY", display: "S&P 600 Small Cap Index" },
  { value: ".FTNMX2720", display: "FTSE 350 General Industrials Index" },
  { value: ".SPX", display: "S&P 500 Index" },
  { value: ".IXIC", display: "Nasdaq Composite Index" },
  { value: ".NDX", display: "Nasdaq 100 Index" },
  { value: ".FTNMX7570", display: "FTSE 350 Gas Water & Multiutilities Index" },
  {
    value: ".FTASX7570",
    display:
      "FTSE All-share - Gas Water & Multiutilities Price Return Index Eur End Of Day",
  },
  {
    value: ".FTE3X7570ECR",
    display: "FTSEurofirst 300 Gas Water & Multiutilities Index",
  },
  { value: ".FTAS", display: "FTSE All Share Index" },
  { value: ".FTSE", display: "FTSE 100 Index" },
  { value: ".FTNMX8350", display: "FTSE 350 Banks Index" },
  {
    value: ".FTNMX2350",
    display: "FTSE 350 Construction & Building Materials Index",
  },
  { value: ".FTNMX1350", display: "FTSE 350 Chemicals Index" },
  { value: ".FTNMX3530", display: "FTSE 350 Beverages Index" },
  {
    value: ".FTNMX2730",
    display: "FTSE 350 Electronic & Electrical Equipment Index",
  },
  { value: ".FTNMX1770", display: "FTSE 350 Mining Index" },
  { value: ".FTMC", display: "FTSE 250 Index" },
  { value: ".FTSC", display: "FTSE Smallcap Index" },
  { value: ".FTNMX2750", display: "FTSE 350 Industrial Engineering Index" },
  { value: ".FTNMX3350", display: "FTSE 350 Automobiles & Parts Index" },
  { value: ".FTNMX7530", display: "FTSE 350 Electricity Index" },
  { value: ".FTNMX3570", display: "FTSE 350 Food Producers Index" },
  { value: ".FTNMX5330", display: "FTSE 350 Food & Drug Retailers Index" },
  { value: ".FTNMX3740^H13", display: "Ft350 Leis Goods Idx" },
  { value: ".FTNMX5750", display: "FTSE 350 Travel & Leisure Index" },
  { value: ".FTNMX8530", display: "FTSE 350 Nonlife Insurance Index" },
  { value: ".FTNMX8570", display: "FTSE 350 Life Insurance Index" },
  {
    value: ".FTNMX4530",
    display: "FTSE 350 Health Care Equipment & Services Index",
  },
  { value: ".FTNMX5550", display: "FTSE 350 Media Index" },
  { value: ".FTNMX8770", display: "FTSE 350 General Financial Index" },
  { value: ".FTNMX0530", display: "FTSE 350 Oil & Gas Index" },
  {
    value: ".FTNMX4570",
    display: "FTSE 350 Pharmaceuticals & Biotechnology Index",
  },
  { value: ".FTUB8600", display: "Ft350 Real Estat Idx" },
  { value: ".FTNMX5370", display: "FTSE 350 General Retailers Index" },
  { value: ".FTNMX2790", display: "FTSE 350 Support Services Index" },
  { value: ".FTNMX3780", display: "FTSE 350 Tobacco Index" },
  {
    value: ".FTNMX6530",
    display: "FTSE 350 Fixed Line Telecommuniations Index",
  },
  { value: ".FTNMX2770", display: "FTSE 350 Industrial Transportation Index" },
  {
    value: ".FTNMX8980",
    display: "FTSE 350 Equity Investment Instruments Index",
  },
  { value: ".FTNMX1750", display: "FTSE 350 Industrial Metals Index" },
  { value: ".FTNMX2710", display: "FTSE 350 Aerospace & Defense Index" },
  { value: ".FTNMX3760", display: "FTSE 350 Personal Goods Index" },
  { value: ".FTNMX9570^L16", display: "Ft350 Tch Hwr Eq Idx" },
  {
    value: ".FTNMX9530",
    display: "FTSE 350 Software & Computer Services Index",
  },
  { value: ".FTNMX1730", display: "FTSE 350 Forestry & Paper Index" },
  { value: ".FTEU3", display: "FTSEurofirst 300 Index" },
  { value: ".FTEM", display: "FTSE Euromid Index" },
  { value: ".FTEUEC", display: "FTSEurofirst 300 Eurozone Index" },
  { value: ".FTEUXEC", display: "FTSEurofirst 300 Ex Eurozone" },
  {
    value: ".FTE3X2350ECR",
    display: "FTSEurofirst 300 Construction & Materials Index",
  },
  {
    value: ".FTE3X2750ECR",
    display: "FTSEurofirst 300 Industrial Engineering Euro1 Index",
  },
  {
    value: ".FTE3X4530ECR",
    display: "FTSEurofirst 300 Health Care Equipment & Services Index",
  },
  {
    value: ".FTE3X8980ECR",
    display: "FTSEurofirst 300 Equity Investment Instruments Index",
  },
  { value: ".FTE3X1350ECR", display: "FTSEurofirst 300 Chemicals Index" },
  {
    value: ".FTE3X2710ECR",
    display: "FTSEurofirst 300 Aerospace & Defense Index",
  },
  {
    value: ".FTE3X3350ECR",
    display: "FTSEurofirst 300 Automobiles & Parts Index",
  },
  { value: ".FTE3X3740ECR^H13", display: "E300 Leis Goods Idx" },
  {
    value: ".FTE3X5750ECR",
    display: "FTSEurofirst 300 Travel & Leisure Index",
  },
  {
    value: ".FTE3X9570ECR",
    display: "FTSEurofirst 300 Technology Hardware & Equipment Index",
  },
  {
    value: ".FTE3X2730ECR",
    display: "FTSEurofirst 300 Electronic Equipment Index",
  },
  {
    value: ".FTE3X4570ECR",
    display: "FTSEurofirst 300 Pharmaceuticals & Biotechnology Index",
  },
  { value: ".FTE3X5550ECR", display: "FTSEurofirst 300 Media Index" },
  {
    value: ".FTE3X5370ECR",
    display: "FTSEurofirst 300 General Retailers Index",
  },
  {
    value: ".FTE3X6530ECR",
    display: "FTSEurofirst 300 Fixed Line Telecommuniations Index",
  },
  {
    value: ".FTE3X8530ECR",
    display: "FTSEurofirst 300 Nonlife Insurance Index",
  },
  { value: ".FTE3X8570ECR", display: "FTSEurofirst 300 Life Insurance Index" },
  {
    value: ".FTE3X9530ECR",
    display: "FTSEurofirst 300 Software & Computer Services Index",
  },
  {
    value: ".FTE3X0530ECR",
    display:
      "FTSEurofirst Sectors - Oil & Gas Producers Price Return Index Eur End Of Day",
  },
  { value: ".FTE3X1730ECR^H13", display: "E300 Forest&pap Idx" },
  {
    value: ".FTE3X1750ECR",
    display: "FTSEurofirst 300 Industrial Metals Index",
  },
  {
    value: ".FTE3X2720ECR",
    display: "FTSEurofirst 300 General Industrials Index",
  },
  { value: ".FTE3X3530ECR", display: "FTSEurofirst 300 Beverages Index" },
  { value: ".FTE3X3570ECR", display: "FTSEurofirst 300 Food Producers Index" },
  { value: ".FTE3X3760ECR", display: "FTSEurofirst 300 Personal Goods Index" },
  { value: ".FTE3X3780ECR", display: "FTSEurofirst 300 Tobacco Index" },
  {
    value: ".FTE3X2790ECR",
    display: "FTSEurofirst 300 Support Services Index",
  },
  {
    value: ".FTE3X2770ECR",
    display: "FTSEurofirst 300 Industrial Transportation Index",
  },
  { value: ".FTE3X7530ECR", display: "FTSEurofirst 300 Electricity Index" },
  {
    value: ".FTE3X8770ECR",
    display: "FTSEurofirst 300 General Financial Index",
  },
  { value: ".FTE3X1770ECR", display: "FTSEurofirst 300 Mining Index" },
  {
    value: ".FTE3X5330ECR",
    display: "FTSEurofirst 300 Food & Drug Retailers Index",
  },
  { value: ".FTE3X8350ECR", display: "FTSEurofirst 300 Banks Index" },
  { value: ".FTT1X", display: "FTSE Techmark Focus Index" },
  { value: ".FTTASX", display: "FTSE Techmark All Share Index" },
  { value: ".FTAI", display: "FTSE AIM All Share Index" },
  {
    value: ".FTASX0530",
    display:
      "FTSE All-share - Oil & Gas Producers Price Return Index Gbp End Of Day",
  },
  { value: ".FTMX", display: "FTSE 250 Ex-investment Trusts Index" },
  { value: ".FTSX", display: "FTSE Smallcap Ex Its Index" },
  { value: ".FTFL", display: "FTSE Fledgling Index" },
  { value: ".FTFX", display: "FTSE Fledgling Ex Investment Trusts Index" },
  {
    value: ".FTASX8350",
    display: "FTSE All-share - Banks Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX2350",
    display:
      "FTSE All-share - Construction & Materials Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX1350",
    display: "FTSE All-share - Chemicals Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX2720",
    display:
      "FTSE All-share - General Industrials Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX3530",
    display: "FTSE All-share - Beverages Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX2730",
    display:
      "FTSE All-share - Electronic & Electrical Equipment Price Return Index Gbp End Of Day",
  },
  { value: ".FTASX1770", display: "FTSE All Share Mining Index" },
  {
    value: ".FTASX2750",
    display:
      "FTSE All-share - Industrial Engineering Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX3350",
    display:
      "FTSE All-share - Automobiles & Parts Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX7530",
    display: "FTSE All-share - Electricity Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX3570",
    display:
      "FTSE All-share - Food Producers Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX5330",
    display:
      "FTSE All-share - Food & Drug Retailers Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX3740",
    display: "FTSE All-share - Leisure Goods Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX5750",
    display:
      "FTSE All-share - Travel & Leisure Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX8530",
    display:
      "FTSE All-share - Nonlife Insurance Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX8570",
    display:
      "FTSE All-share - Life Insurance/assurance Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX4530",
    display:
      "FTSE All-share - Health Care Equipment & Services Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX5550",
    display: "FTSE All-share - Media Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX8770",
    display:
      "FTSE All-share - General Financial Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX4570",
    display:
      "FTSE All-share - Pharmaceuticals & Biotechnology Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX5370",
    display:
      "FTSE All-share - General Retailers Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX2790",
    display:
      "FTSE All-share - Support Services Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX3780",
    display: "FTSE All-share - Tobacco Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX6530",
    display:
      "FTSE All-share - Fixed Line Telecommunications Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX2770",
    display:
      "FTSE All-share - Industrial Transportation Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX8980",
    display:
      "FTSE All-share - Equity Investment Instruments Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX3000",
    display:
      "FTSE All-share - Consumer Goods Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX8000",
    display: "FTSE All-share - Financials Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX0001",
    display: "FTSE All-share - Oil & Gas Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX5000",
    display:
      "FTSE All-share - Consumer Services Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX7000",
    display: "FTSE All-share - Utilities Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX2000",
    display: "FTSE All-share - Industrials Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX9000",
    display: "FTSE All-share - Technology Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX1750",
    display:
      "FTSE All-share - Industrial Metals & Mining Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX4000",
    display: "FTSE All-share - Health Care Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX9570",
    display:
      "FTSE All-share - Technology Hardware & Equipment Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX9530",
    display:
      "FTSE All-share - Software & Computer Services Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX1000",
    display:
      "FTSE All-share - Basic Materials Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX3760",
    display:
      "FTSE All-share - Personal Goods Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX2710",
    display:
      "FTSE All-share - Aerospace & Defense Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX6000",
    display:
      "FTSE All-share - Telecommunications Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX1730",
    display:
      "FTSE All-share - Forestry & Paper Price Return Index Gbp End Of Day",
  },
  { value: ".SPLGMID", display: "S&p 900 Index" },
  { value: ".FTGEN^A19", display: "Global Energy" },
  { value: ".FT4GDUK50", display: "Ftse4gd Uk  50" },
  { value: ".IXMCC^F18", display: "S&p 500 Fincil Slct Secstk Covcall Idx" },
  { value: ".FTUB3500", display: "Ft350 Food & Bev" },
  { value: ".FTUB8300", display: "Ft350 Banks" },
  { value: ".FTUB4500", display: "Ft350 Hlth Care" },
  { value: ".FTUB8700", display: "Ft350 Fin Serv" },
  { value: ".FTUB5300", display: "Ft350 Retail" },
  { value: ".FTUB5700", display: "Ft350 Trvl & Lei" },
  { value: ".FTUB0500", display: "Ft350 Oil & Gas" },
  { value: ".FTUB3700", display: "Ft350 Pers&hsgds" },
  { value: ".FTUB2700", display: "Ft350 Ind Gd&ser" },
  { value: ".FTUB3300", display: "Ft350 Auto&parts" },
  { value: ".FTUB1300", display: "Ft350 Chemical" },
  { value: ".FTUB2300", display: "Ft350 Con & Mat" },
  { value: ".FTUB5500", display: "Ft350 Media" },
  { value: ".FTUB1700", display: "Ft350 Basic Res" },
  { value: ".FTUB8500", display: "Ft350 Insurance" },
  { value: ".FTUB9500", display: "Ft350 Technology Idx" },
  { value: ".FTUB6500", display: "Ft350 Telecomm" },
  { value: ".FTUB7500", display: "Ft350 Utilities" },
  { value: ".SPSUP", display: "S&P Composite 1500 Index" },
  { value: ".FTAIM1", display: "FTSE AIM 100 Index" },
  { value: ".FTAIM5", display: "FTSE Intl-AIM Uk 50 Index" },
  { value: ".FTNSIXS", display: "FTSE Fledgling Ex Inv Tst Index" },
  {
    value: ".FTASX0570",
    display:
      "FTSE All-share - Oil Equipment Services & Distribution Price Return Index Eur End Of Day",
  },
  {
    value: ".FTASX3720",
    display:
      "FTSE All-share - Household Goods & Home Construction Price Return Index Gbp End Of Day",
  },
  {
    value: ".FTASX6570",
    display:
      "FTSE All-share - Mobile Telecommunications Price Return Index Gbp End Of Day",
  },
  { value: ".FTNMX0570", display: "FTSE 350 Oil Equipment & Services Index" },
  { value: ".FTNMX3720", display: "FTSE 350 Household Goods Index" },
  {
    value: ".FTNMX6570",
    display: "FTSE 350 Mobile Telecommunications Gbp Index",
  },
  {
    value: ".FTNMX8990",
    display: "FTSE 350 Nonequity Investment Instruments Index",
  },
  { value: ".FTE3X0570ECR^B17", display: "E300 Oil Eq S&d Idx" },
  { value: ".FTE3X3720ECR", display: "FTSEurofirst 300 Household Goods Index" },
  {
    value: ".FTE3X6570ECR",
    display: "FTSEurofirst 300 Mobile Telecommunications Index",
  },
  {
    value: ".FTE3X8990ECR",
    display: "FTSEurofirst 300 Nonequity Investment Instruments Index",
  },
  { value: ".SPMIDSM", display: "S&p 1000 Index" },
  { value: ".FTAXX0500S", display: "Aim Oil Gas Idx" },
  { value: ".FTAXX1300S", display: "Aim Chemical Idx" },
  { value: ".FTAXX1700S", display: "Aim Basic Res Idx" },
  { value: ".FTAXX2300S", display: "Aim Con Mat Idx" },
  { value: ".FTAXX2700S", display: "Aim Ind Gdser Idx" },
  { value: ".FTAXX3300S", display: "Aim Autoparts Idx" },
  { value: ".FTAXX3500S", display: "Aim Food Bev Idx" },
  { value: ".FTAXX3700S", display: "Aim Pers Hsgds Idx" },
  { value: ".FTAXX4500S", display: "Aim Hlth Care Idx" },
  { value: ".FTAXX5300S", display: "Aim Retail Idx" },
  { value: ".FTAXX5500S", display: "Aim Media Idx" },
  { value: ".FTAXX5700S", display: "Aim Trvl Lei Idx" },
  { value: ".FTAXX6500S", display: "Aim Telecomm Idx" },
  { value: ".FTAXX7500S", display: "Aim Utilities Idx" },
  { value: ".FTAXX8300S", display: "Aim Banks Idx" },
  { value: ".FTAXX8500S", display: "Aim Insurance Idx" },
  { value: ".FTAXX8700S", display: "Aim Fin Serv Idx" },
  { value: ".FTAXX9500S", display: "Aim Technology Idx" },
  { value: ".NQGS", display: "Nsd Glo Sl Mk Cp" },
  { value: ".NQGM", display: "Nsd Glo Mkt Comp" },
  { value: ".RCMP", display: "Nsd Cap Mkt Copm" },
  { value: ".FTASX0570E", display: "Ftas Oil Eq S&d Idx" },
  { value: ".FTASX3720E", display: "Ftas Hsehld Gds Idx" },
  { value: ".FTASX8570E", display: "Ftas Life Ins Idx" },
  { value: ".FTASX8980E", display: "Ftas Eq Inv Ist Idx" },
  { value: ".FTASX8770E", display: "Ftas Gen Fin Idx" },
  { value: ".FTASX9570E", display: "Ftas Tch Hwr Eq Idx" },
  { value: ".FTASX9530E", display: "Ftas Sft Cp Srv Idx" },
  { value: ".FTASX8000E", display: "Ftas Financials Idx" },
  { value: ".FTASX9000E", display: "Ftas Technology Idx" },
  { value: ".FTASX1770E", display: "Ftas Mining Idx" },
  { value: ".FTASX0530E", display: "Ftas Oil&gas Pd Idx" },
  { value: ".FTASX1350E", display: "Ftas Chemicals Idx" },
  { value: ".FTASX2350E", display: "Ftas Cnstr&mat Idx" },
  { value: ".FTASX1730E", display: "Ftas Forest&pap Idx" },
  { value: ".FTASX1750E", display: "Ftas Ind Metals Idx" },
  { value: ".FTASX2710E", display: "Ftas Aero & Def Idx" },
  { value: ".FTASX2720E", display: "Ftas Genl Indus Idx" },
  { value: ".FTASX7000E", display: "Ftas Utilities Idx" },
  { value: ".FTASX2730E", display: "Ftas Elec&eqpt Idx" },
  { value: ".FTASX2750E", display: "Ftas Ind Eng Idx" },
  { value: ".FTASX3740E", display: "Ftas Leis Goods Idx" },
  { value: ".FTASX3350E", display: "Ftas Auto&parts Idx" },
  { value: ".FTASX3530E", display: "Ftas Beverages Idx" },
  { value: ".FTASX3570E", display: "Ftas Food Prodr Idx" },
  { value: ".FTASX4530E", display: "Ftas Hlth Eq&sr Idx" },
  { value: ".FTASX3760E", display: "Ftas Pers Goods Idx" },
  { value: ".FTASX4570E", display: "Ftas Phar & Bio Idx" },
  { value: ".FTASX3780E", display: "Ftas Tobacco Idx" },
  { value: ".FTASX5370E", display: "Ftas Generl Ret Idx" },
  { value: ".FTASX5750E", display: "Ftas Trav&leis Idx" },
  { value: ".FTASX5550E", display: "Ftas Media Idx" },
  { value: ".FTASX2790E", display: "Ftas Suprt Serv Idx" },
  { value: ".FTASX2770E", display: "Ftas Ind Trans Idx" },
  { value: ".FTASX5330E", display: "Ftas Fd&drg Ret Idx" },
  { value: ".FTASX6530E", display: "Ftas Fxd Ln Tel Idx" },
  { value: ".FTASX7530E", display: "Ftas Electricty Idx" },
  { value: ".FTASX7570E", display: "Ftas Gas Wt&ult Idx" },
  { value: ".FTASX8350E", display: "Ftas Banks Idx" },
  { value: ".FTASX8530E", display: "Ftas Nonlif Ins Idx" },
  { value: ".FTASX0001E", display: "Ftas Oil & Gas Idx" },
  { value: ".FTASX1000E", display: "Ftas Basic Mat Idx" },
  { value: ".FTASX2000E", display: "Ftas Industrials Idx" },
  { value: ".FTASX3000E", display: "Ftas Consumer Gd Idx" },
  { value: ".FTASX4000E", display: "Ftas Health Care Idx" },
  { value: ".FTASX5000E", display: "Ftas Consum Srvs Idx" },
  { value: ".FTASX6000E", display: "Ftas Telecoms Idx" },
  { value: ".FTEUECE", display: "FTSEurofirst 300 Eurozone Index" },
  {
    value: ".FT3RATE",
    display: "FTSEurofirst 300 Austria Price Return Index Eur End Of Day",
  },
  {
    value: ".FT3RBEE",
    display: "FTSEurofirst 300 BELgium Price Return Index Eur End Of Day",
  },
  {
    value: ".FT3RCHE",
    display: "FTSEurofirst 300 Switzerland Price Return Index Eur End Of Day",
  },
  {
    value: ".FT3RDEE",
    display: "FTSEurofirst 300 Germany Price Return Index Eur End Of Day",
  },
  {
    value: ".FT3RDKE",
    display: "FTSEurofirst 300 Denmark Price Return Index Eur End Of Day",
  },
  {
    value: ".FT3RESE",
    display: "FTSEurofirst 300 Spain Price Return Index Eur End Of Day",
  },
  {
    value: ".FT3RFIE",
    display: "FTSEurofirst 300 Finland Price Return Index Eur End Of Day",
  },
  { value: ".FT3RGRE", display: "FTSEurofirst 300 Greece Index" },
  {
    value: ".FT3RIEE",
    display: "FTSEurofirst 300 Ireland Price Return Index Eur End Of Day",
  },
  {
    value: ".FT3RITE",
    display: "FTSEurofirst 300 Italy Price Return Index Eur End Of Day",
  },
  {
    value: ".FT3RNLE",
    display: "FTSEurofirst 300 Netherlands Price Return Index Eur End Of Day",
  },
  {
    value: ".FT3RNOE",
    display: "FTSEurofirst 300 Norway Price Return Index Eur End Of Day",
  },
  {
    value: ".FT3RPTE",
    display: "FTSEurofirst 300 Portugal Price Return Index Eur End Of Day",
  },
  {
    value: ".FT3RSEE",
    display: "FTSEurofirst 300 Sweden Price Return Index Eur End Of Day",
  },
  {
    value: ".FTSCDEURSE",
    display:
      "FTSE Developed Europe Small Cap Price Return Index Eur End Of Day",
  },
  { value: ".FTXIN30530H", display: "Ftse China 200 O&g Prodcr Hkd Index" },
  {
    value: ".FTXIN33720U",
    display: "Ftse China 200 Household Goods Usd Index",
  },
  { value: ".FTXIN33740U", display: "Ftse China 200 Leisure Goods Usd Index" },
  { value: ".FTXIN33760U", display: "Ftse China 200 Personal Goods Usd Index" },
  { value: ".FTXIN38770U", display: "Ftse China 200 General Financ Usd Index" },
  { value: ".FTXIN39530U", display: "Ftse China 200 S/w & Cmp Srv Usd Index" },
  { value: ".FTXIN39570U", display: "Ftse China 200 Tech H & Equip Usd Index" },
  {
    value: ".CWK-FTLC",
    display: "Ftse International Cranswick Index Constituent",
  },
  { value: ".FTMN1XR", display: "Ftse Global 100" },
  { value: ".SPLRCD", display: "S&p 500 Consumer Discret(sector)" },
  { value: ".SPSY", display: "S&p 500 Financials Sector" },
  { value: ".SPLRCI", display: "S&p 500 Industrials (sector)" },
  { value: ".SPLRCL", display: "S&p 500 Communication Services (sector)" },
  { value: ".SPLRCM", display: "S&p 500 Materials (sector)" },
  { value: ".SPLRCS", display: "S&p 500 Consumer Staples (sector)" },
  { value: ".SPLRCT", display: "S&p 500 Information Tech(sector)" },
  { value: ".SPLRCU", display: "S&p 500 Utilities (sector)" },
  { value: ".FTNMX8670", display: "FTSE 350 Real Estate  Investment Trusts" },
  {
    value: ".FTNMX8630",
    display: "FTSE 350 Real Estate Investment & Services Index",
  },
  { value: ".SPXHC", display: "S&p 500 Health Care Sector" },
  { value: ".IGX", display: "S&p 500 Growth Index" },
  { value: ".IVX", display: "S&p 500 Value Index" },
  { value: ".IDX", display: "S&P 400 Mid Cap Index" },
  { value: ".DJA", display: "Dow Jones Composite Index" },
  { value: ".DJI", display: "Dow Jones Industrial Average Index" },
  { value: ".FTLC", display: "FTSE 350 Index" },
  { value: ".FTEUXUK", display: "FTSEurofirst 300 Ex Uk" },
  { value: ".FTLY", display: "FTSE 350 Lower Yield Index" },
  { value: ".FTHY", display: "FTSE 350 Higher Yield Index" },
  { value: ".FTEFC1R", display: "FTSEurofirst 100 Index" },
  {
    value: ".FT3RGBE",
    display:
      "FTSEurofirst 300 United Kingdom Price Return Index Eur End Of Day",
  },
  { value: ".GDAXI", display: "Xetra DAX Index" },
  { value: ".CLALL", display: "Xetra Classic All Share Index" },
  { value: ".PRIME", display: "Xetra Prime All Share Index" },
  { value: ".FCHI", display: "CAC 40 Index" },
  { value: ".BFX", display: "BEL 20 Index" },
  { value: ".AEX", display: "AEX Amsterdam Index" },
  { value: ".IBEX", display: "IBEX 35 Index" },
  { value: ".SPTSECP3", display: "S&P - Tse 300 Capped Index" },
  { value: ".AXJO", display: "S&P/ASX 200 Index" },
  { value: ".ATOI", display: "S&P/ASX 100 Index" },
  { value: ".AXSO", display: "ASX Small Ordinaries Index" },
  { value: ".ATLI", display: "S&P/ASX 20 Index" },
  { value: ".AORD", display: "S&P/ASX All Ordinaries Index" },
  { value: ".AXMD", display: "S&P/ASX Midcap 50 Index" },
  { value: ".AXKO", display: "S&P/ASX 300 Index" },
  { value: ".AFLI", display: "S&P/ASX 50 Index" },
  { value: ".NSEI", display: "Nifty 50 Index" },
  { value: ".TWII", display: "Taiwan Se Weighted Index" },
  { value: ".BSESN", display: "S&P BSE Sensex Index" },
  { value: ".SSEC", display: "Sse Composite Index" },
  { value: ".FTGEISAC", display: "FTSE Global All Cap Index" },
  { value: ".FTAWDPAC", display: "FTSE Developed Asia Pacific Index" },
  { value: ".BSE100", display: "S&P BSE 100 Index" },
  { value: ".GSPTSE", display: "S&P/tsx Composite Index" },
  {
    value: ".FTAWDPACXJ",
    display: "FTSE Developed Asia Pacific Ex Japan Index",
  },
  { value: ".HSCI", display: "Hang Seng Composite Index" },
  { value: ".HSI", display: "Hang Seng Index" },
  { value: ".HSLMI", display: "Hang Seng Composite Largecap & Midcap Index" },
  { value: ".HSM100", display: "Hang Seng Mainland 100 Index" },
  { value: ".HSMI", display: "Hang Seng Composite Midcap Index" },
  { value: ".HSMSI", display: "Hang Seng Composite Mid & Smallcap Index" },
  { value: ".HSSI", display: "Hang Seng Composite Smallcap Index" },
  { value: ".KS11", display: "KOSPI Composite Index" },
  { value: ".KS200", display: "KOSPI 200 Index" },
  { value: ".N225", display: "Nikkei 225 Index" },
  { value: ".N300", display: "Nikkei 300 Index" },
  { value: ".N500", display: "Nikkei 500 Index" },
  { value: ".CACMS", display: "CAC Mid & Small  Index" },
  { value: ".ENPME", display: "Enternext Pea-pme 150 Index" },
  { value: ".CACT", display: "CAC All-tradable Index" },
  { value: ".CACS", display: "CAC Small Index" },
  { value: ".SBF120", display: "Sbf 120 Eclaireur Index" },
  {
    value: ".FTE3X0570ECR",
    display:
      "FTSEurofirst 300 Oil Equipment Services & Distribution Price Index",
  },
  {
    value: ".FTNMX9570",
    display:
      "FTSE 350 Index - Technology Hardware & Equipment Price Return Index",
  },
  { value: ".FTNMX3740", display: "FTSE 350 Leisure Goods Index" },
];
