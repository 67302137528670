import axios, { AxiosInstance } from "axios";

interface IUserService {
  getUser(): Promise<{ token: string } | undefined>;
}

export class StockopediaRestClient {
  private readonly httpClient: AxiosInstance;
  constructor(userService: IUserService, baseUrl: string) {
    this.httpClient = axios.create({
      baseURL: baseUrl,
      headers: {
        "Content-Type": "application/json",
      },
    });
    this.httpClient.interceptors.request.use(async (config) => {
      const user = await userService.getUser();
      config.headers.Authorization = `Bearer ${user?.token}`;

      return config;
    });
  }

  get get() {
    return this.httpClient.get;
  }

  get post() {
    return this.httpClient.post;
  }
}
