import React from "react";
import styled from "styled-components";
import { Text, Separator } from "@buffetjs/core";
import { useAssetManager } from "../hooks";
import { FileView } from "./file-view";
import { SearchTags } from "./search-tags";
import { UploadManager } from "./upload-manager";
import { Spacer } from "./lib/spacer";
import { SelectedAssetsView } from "./selected-assets-view";
import { AssetTagsManager } from "./asset-tags-manager";
import { AssetEditMode } from "..";

interface Props {
  title?: string;
  onSelectAssets: (assets: string[]) => void;
}

const SearchWrapper = styled.div`
  flex-grow: 1;
`;

export const AssetBrowser: React.FC<Props> = ({ title, onSelectAssets }) => {
  const editMode = useAssetManager((s) => s.editMode);
  const selectedFileForUpload = useAssetManager((s) => s.selectedFileForUpload);
  const showingSelected = useAssetManager((s) => s.showingSelected);
  const selectedAssets = useAssetManager((s) => s.selectedAssets);
  const edittingAsset = useAssetManager((s) => s.edittingAsset);

  return (
    <React.Fragment>
      {!!selectedFileForUpload && <UploadManager />}

      {!selectedFileForUpload && editMode === AssetEditMode.Editing && (
        <React.Fragment>
          {!!title && (
            <React.Fragment>
              <Text fontSize="lg" fontWeight="bold">
                {title}
              </Text>
              <Spacer />
            </React.Fragment>
          )}

          <SearchWrapper>
            <SearchTags />
          </SearchWrapper>

          <FileView onSelectAssets={onSelectAssets} />
        </React.Fragment>
      )}

      {showingSelected && selectedAssets.length > 0 && (
        <React.Fragment>
          {editMode === AssetEditMode.Editing && (
            <React.Fragment>
              <Spacer />
              <Separator />
              <Spacer />
            </React.Fragment>
          )}
          <SelectedAssetsView />
        </React.Fragment>
      )}

      {!!edittingAsset && <AssetTagsManager />}
    </React.Fragment>
  );
};
