import {
  NumberInput,
  Padding,
  Row,
  SelectInput,
  TextInput,
} from "@stockopedia/cms-ui";
import { Grouping } from "@stockopedia/screener-query-sdk";
import { useRatioPickerContext } from "shared/components/ratio-picker/context";
import styled from "styled-components";

import { useScreenBuilderContext } from "../builder/context";
import { Button } from "../shared/button";
import { AdvancedType, Subject } from "../types";
import { advancedButtonOptions } from "./advanced-type-picker";
import { useScreenRuleContext } from "./context";

const InputWrapper = styled.div`
  width: 200px;
  padding-right: var(--padding-xs);
`;

const NumberInputWrapper = styled(InputWrapper)`
  width: 150px;
`;

const Span = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  padding: var(--padding-sm);
`;

export const AdvancedRuleBuilder = () => {
  const {
    actions: ruleActions,
    advancedType,
    advancedComparisonRatio,
    advancedComparisonType,
    comparisonNumber,
  } = useScreenRuleContext();
  const { actions: pickerActions } = useRatioPickerContext();
  const { actions } = useScreenBuilderContext();

  const handleTextComparisonClick = () => {
    actions.setRatioSelectAction((ratio) => {
      ruleActions.setAdvancedComparisonRatio(new Subject(ratio));
      pickerActions.showModal(false);
    });
    pickerActions.showModal(true);
  };

  return (
    <Row className="items-center">
      <NumberInputWrapper>
        <NumberInput
          name="multiplier"
          onChange={ruleActions.setComparisonNumber}
          value={comparisonNumber ?? 0}
        />
      </NumberInputWrapper>
      <Span>{advancedType === AdvancedType.Rank ? "in" : "x"}</Span>
      <InputWrapper>
        {isArithmeticType(advancedType!) ? (
          <SelectInput
            name="comparison_options"
            value={mapGrouping(advancedComparisonType)!.display}
            options={advancedSelectOptions.map((x) => x.display)}
            onChange={(v) =>
              ruleActions.setAdvancedComparisonType(mapGroupingFromDisplay(v))
            }
          />
        ) : (
          <TextInput
            name="comparison_ratio"
            value={advancedComparisonRatio?.name}
            onClick={handleTextComparisonClick}
            onChange={() => {}}
          />
        )}
      </InputWrapper>
      <Padding right left size="sm" className="auto-width">
        <Button
          type="button"
          active={true}
          onClick={() => ruleActions.setAdvancedType(undefined)}
        >
          {advancedButtonOptions.find((x) => x.value === advancedType)?.display}
        </Button>
      </Padding>
    </Row>
  );
};

const isArithmeticType = (type: AdvancedType) => {
  return type !== AdvancedType.Comparison;
};

const mapGrouping = (value: Grouping = Grouping.Market) =>
  advancedSelectOptions.find((x) => x.value?.equals(value));
const mapGroupingFromDisplay = (value: string) =>
  advancedSelectOptions.find((x) => x.display === value)!.value;

const advancedSelectOptions = [
  {
    display: "Market",
    value: Grouping.Market,
  },
  {
    display: "Sector",
    value: Grouping.Sector,
  },
  {
    display: "Industry",
    value: Grouping.Industry,
  },
];
