import styled from 'styled-components'

export const Divider = styled.hr`
  background: var(--color-background);
  height: 2px;
  border: none;
  border-radius: 50%;
  margin-top: var(--padding-sm);
  margin-bottom: var(--padding-standard);
`
