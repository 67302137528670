import RemoveIcon from '@material-ui/icons/Remove'
import { AtomicBlockUtils, EditorState } from 'draft-js'
import { Editor as BaseEditor } from 'medium-draft'
import React from 'react'

export const SeparatorSideButton = (props: BaseEditor) => {
  const onClick = () => {
    let editorState = props.getEditorState()
    const content = editorState.getCurrentContent()
    const contentWithEntity = content.createEntity('separator', 'IMMUTABLE', {})
    const entityKey = contentWithEntity.getLastCreatedEntityKey()
    editorState = EditorState.push(
      editorState,
      contentWithEntity,
      'create-entity' as any
    )
    props.setEditorState(
      AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, '-')
    )
    props.close()
  }

  return (
    <button
      className='md-sb-button md-sb-img-button'
      type='button'
      title='Add a separator'
      onClick={onClick}
    >
      <RemoveIcon width='15px' />
    </button>
  )
}
