export const COUNTRIES = [
  { value: "gb", display: "United Kingdom" },
  { value: "us", display: "United States" },
  { value: "au", display: "Australia" },
  { value: "af", display: "Afghanistan" },
  { value: "ax", display: "Aland Islands" },
  { value: "al", display: "Albania" },
  { value: "dz", display: "Algeria" },
  { value: "as", display: "American Samoa" },
  { value: "ad", display: "Andorra" },
  { value: "ao", display: "Angola" },
  { value: "ai", display: "Anguilla" },
  { value: "aq", display: "Antarctica" },
  { value: "ag", display: "Antigua and Barbuda" },
  { value: "ar", display: "Argentina" },
  { value: "am", display: "Armenia" },
  { value: "aw", display: "Aruba" },
  { value: "at", display: "Austria" },
  { value: "az", display: "Azerbaijan" },
  { value: "bs", display: "Bahamas" },
  { value: "bh", display: "Bahrain" },
  { value: "bd", display: "Bangladesh" },
  { value: "bb", display: "Barbados" },
  { value: "by", display: "Belarus" },
  { value: "be", display: "Belgium" },
  { value: "bz", display: "Belize" },
  { value: "bj", display: "Benin" },
  { value: "bm", display: "Bermuda" },
  { value: "bt", display: "Bolivia" },
  { value: "bo", display: "Bolivia (Plurinational State of)" },
  { value: "bq", display: "Bonaire, Saint Eustatius and Saba" },
  { value: "ba", display: "Bosnia Herzegovina" },
  { value: "bw", display: "Botswana" },
  { value: "bv", display: "Bouvet Island" },
  { value: "br", display: "Brazil" },
  { value: "io", display: "British Indian Ocean Territory" },
  { value: "vg", display: "British West Indies" },
  { value: "bn", display: "Brunei Darussalam" },
  { value: "bg", display: "Bulgaria" },
  { value: "bf", display: "Burkina Faso" },
  { value: "bi", display: "Burundi" },
  { value: "kh", display: "Cambodia" },
  { value: "cm", display: "Cameroon" },
  { value: "ca", display: "Canada" },
  { value: "cv", display: "Cape Verde" },
  { value: "ky", display: "Cayman Islands" },
  { value: "cf", display: "Central African Republic" },
  { value: "td", display: "Chad" },
  { value: "qh", display: "Channel Islands" },
  { value: "cl", display: "Chile" },
  { value: "cn", display: "China" },
  { value: "mo", display: "China, Macao Special Administrative Region" },
  { value: "cx", display: "Christmas Island" },
  { value: "cc", display: "Cocos (keeling) Islands" },
  { value: "co", display: "Columbia" },
  { value: "km", display: "Comoros" },
  { value: "cg", display: "Congo" },
  { value: "cd", display: "Congo, Democratic Republic of the" },
  { value: "cr", display: "Costa Rica" },
  { value: "ci", display: "Cote D'Ivoire" },
  { value: "hr", display: "Croatia" },
  { value: "cu", display: "Cuba" },
  { value: "cw", display: "Curacao" },
  { value: "cy", display: "Cyprus" },
  { value: "cz", display: "Czech Republic" },
  { value: "dk", display: "Denmark" },
  { value: "dj", display: "Djibouti" },
  { value: "dm", display: "Dominica" },
  { value: "do", display: "Dominican Republic" },
  { value: "ec", display: "Ecuador" },
  { value: "eg", display: "Egypt" },
  { value: "sv", display: "El Salvador" },
  { value: "gq", display: "Equatorial Guinea" },
  { value: "er", display: "Eritrea" },
  { value: "ee", display: "Estonia" },
  { value: "et", display: "Ethiopia" },
  { value: "fo", display: "Faeroe Islands" },
  { value: "fk", display: "Falkland Islands (Malvinas)" },
  { value: "fj", display: "Fiji" },
  { value: "fi", display: "Finland" },
  { value: "fr", display: "France" },
  { value: "gf", display: "French Guiana" },
  { value: "pf", display: "French Polynesia" },
  { value: "tf", display: "French Southern Territories" },
  { value: "ga", display: "Gabon" },
  { value: "gm", display: "Gambia" },
  { value: "ge", display: "Georgia" },
  { value: "de", display: "Germany" },
  { value: "gh", display: "Ghana" },
  { value: "gi", display: "Gibraltar" },
  { value: "gr", display: "Greece" },
  { value: "gl", display: "Greenland" },
  { value: "gd", display: "Grenada" },
  { value: "gp", display: "Guadeloupe" },
  { value: "gu", display: "Guam" },
  { value: "gt", display: "Guatemala" },
  { value: "gg", display: "Guernsey" },
  { value: "gn", display: "Guinea" },
  { value: "gw", display: "Guinea-Bissau" },
  { value: "gy", display: "Guyana" },
  { value: "ht", display: "Haiti" },
  { value: "hm", display: "Heard Island and McDonald Islands" },
  { value: "va", display: "Holy See (Vatican City State)" },
  { value: "hn", display: "Hong Kong" },
  { value: "hk", display: "Hong Kong" },
  { value: "hu", display: "Hungary" },
  { value: "is", display: "Iceland" },
  { value: "in", display: "India" },
  { value: "id", display: "Indonesia" },
  { value: "ir", display: "Iran" },
  { value: "iq", display: "Iraq" },
  { value: "ie", display: "Ireland" },
  { value: "il", display: "Israel" },
  { value: "it", display: "Italy" },
  { value: "jm", display: "Jamaica" },
  { value: "jp", display: "Japan" },
  { value: "je", display: "Jersey" },
  { value: "jo", display: "Jordan" },
  { value: "kz", display: "Kazakhstan" },
  { value: "ke", display: "Kenya" },
  { value: "ki", display: "Kiribati" },
  { value: "kp", display: "Korea, Democratic People's Republic of" },
  { value: "kr", display: "Korea, Republic of" },
  { value: "xk", display: "Kosovo" },
  { value: "kw", display: "Kuwait" },
  { value: "kg", display: "Kyrgyzstan" },
  { value: "la", display: "Lao People's Democratic Republic" },
  { value: "lv", display: "Latvia" },
  { value: "lb", display: "Lebanon" },
  { value: "ls", display: "Lesotho" },
  { value: "lr", display: "Liberia" },
  { value: "ly", display: "Libya" },
  { value: "li", display: "Liechtenstein" },
  { value: "lt", display: "Lithuania" },
  { value: "lu", display: "Luxembourg" },
  { value: "mk", display: "Macedonia" },
  { value: "mg", display: "Madagascar" },
  { value: "mw", display: "Malawi" },
  { value: "my", display: "Malaysia" },
  { value: "mv", display: "Maldives" },
  { value: "ml", display: "Mali" },
  { value: "mt", display: "Malta" },
  { value: "im", display: "Man, Isle of" },
  { value: "mh", display: "Marshall Islands" },
  { value: "mq", display: "Martinique" },
  { value: "mr", display: "Mauritania" },
  { value: "mu", display: "Mauritius" },
  { value: "yt", display: "Mayotte" },
  { value: "mx", display: "Mexico" },
  { value: "fm", display: "Moldova" },
  { value: "md", display: "Moldova, Republic of" },
  { value: "mc", display: "Monaco" },
  { value: "mn", display: "Mongolia" },
  { value: "me", display: "Montenegro" },
  { value: "ms", display: "Montserrat" },
  { value: "ma", display: "Morocco" },
  { value: "mz", display: "Mozambique" },
  { value: "mm", display: "Myanmar" },
  { value: "na", display: "Namibia" },
  { value: "nr", display: "Nauru" },
  { value: "np", display: "Nepal" },
  { value: "nl", display: "Netherlands" },
  { value: "an", display: "Netherlands Antilles" },
  { value: "nc", display: "New Caledonia" },
  { value: "nz", display: "New Zealand" },
  { value: "ni", display: "Nicaragua" },
  { value: "ne", display: "Niger" },
  { value: "ng", display: "Nigeria" },
  { value: "nu", display: "Niue" },
  { value: "nf", display: "Norfolk Island" },
  { value: "mp", display: "Northern Mariana Islands" },
  { value: "no", display: "Norway" },
  { value: "om", display: "Oman" },
  { value: "qf", display: "Other African Countries" },
  { value: "qs", display: "Other Asian Countries" },
  { value: "qo", display: "Other Central American Countries" },
  { value: "qe", display: "Other European Countries" },
  { value: "qm", display: "Other Middle Eastern Countries" },
  { value: "ym", display: "Other North American Countries" },
  { value: "qp", display: "Other Pacific-Oceanic Countries" },
  { value: "qc", display: "Other South American Countries" },
  { value: "pk", display: "Pakistan" },
  { value: "pw", display: "Palau" },
  { value: "ps", display: "Palestinian, State of" },
  { value: "pa", display: "Panama" },
  { value: "pg", display: "Papua New-Guinea" },
  { value: "py", display: "Paraguay" },
  { value: "pe", display: "Peru" },
  { value: "ph", display: "Philippines" },
  { value: "pn", display: "Pitcairn" },
  { value: "pl", display: "Poland" },
  { value: "pt", display: "Portugal" },
  { value: "pr", display: "Puerto Rico" },
  { value: "qa", display: "Qatar" },
  { value: "re", display: "Reunion" },
  { value: "ro", display: "Romania" },
  { value: "ru", display: "Russia" },
  { value: "rw", display: "Rwanda" },
  { value: "sh", display: "Saint Helena, Ascension and Tristan da Cunha" },
  { value: "kn", display: "Saint Kitts and Nevis" },
  { value: "lc", display: "Saint Lucia" },
  { value: "mf", display: "Saint Martin" },
  { value: "pm", display: "Saint Pierre and Miquelon" },
  { value: "vc", display: "Saint Vincent and the Grenadines" },
  { value: "bl", display: "Saint-Barthelemy" },
  { value: "ws", display: "Samoa" },
  { value: "sm", display: "San Marino" },
  { value: "st", display: "Sao Tome and Principe" },
  { value: "sa", display: "Saudi Arabia" },
  { value: "sn", display: "Senegal" },
  { value: "rs", display: "Serbia Montenegro" },
  { value: "sc", display: "Seychelles" },
  { value: "sl", display: "Sierra Leone" },
  { value: "sg", display: "Singapore" },
  { value: "sx", display: "Sint Maarten" },
  { value: "sk", display: "Slovakia" },
  { value: "si", display: "Slovenia" },
  { value: "sb", display: "Solomon Islands" },
  { value: "so", display: "Somalia" },
  { value: "za", display: "South Africa" },
  { value: "gs", display: "South Georgia and the South Sandwich Islands" },
  { value: "ss", display: "South Sudan" },
  { value: "es", display: "Spain" },
  { value: "lk", display: "Sri Lanka" },
  { value: "sd", display: "Sudan" },
  { value: "sr", display: "Suriname" },
  { value: "sj", display: "Svalbard and Jan Mayen Islands" },
  { value: "sz", display: "Swaziland" },
  { value: "se", display: "Sweden" },
  { value: "ch", display: "Switzerland" },
  { value: "sy", display: "Syrian Arab Republic" },
  { value: "tw", display: "Taiwan" },
  { value: "tj", display: "Tajikistan" },
  { value: "tz", display: "Tanzania, United Republic of" },
  { value: "th", display: "Thailand" },
  { value: "tl", display: "Timor-Leste" },
  { value: "tg", display: "Togo" },
  { value: "tk", display: "Tokelau" },
  { value: "to", display: "Tonga" },
  { value: "tt", display: "Trinidad and Tobago" },
  { value: "tn", display: "Tunisia" },
  { value: "tr", display: "Turkey" },
  { value: "tm", display: "Turkmenistan" },
  { value: "tc", display: "Turks and Caicos Islands" },
  { value: "tv", display: "Tuvalu" },
  { value: "ug", display: "Uganda" },
  { value: "ua", display: "Ukraine" },
  { value: "ae", display: "United Arab Emirates" },
  { value: "um", display: "United States Minor Outlying Islands" },
  { value: "vi", display: "United States Virgin Islands" },
  { value: "uy", display: "Uruguay" },
  { value: "uz", display: "Uzbekistan" },
  { value: "vu", display: "Venezuela" },
  { value: "ve", display: "Venezuela (Bolivarian Republic of)" },
  { value: "vn", display: "Vietnam" },
  { value: "wf", display: "Wallis and Futuna Islands" },
  { value: "eh", display: "Western Sahara" },
  { value: "ye", display: "Yemen" },
  { value: "zm", display: "Zambia" },
  { value: "zw", display: "Zimbabwe" },
];
