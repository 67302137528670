import { RelationItem } from "@stockopedia/cms-ef";
import { Card, Column, Header, Padding, Row } from "@stockopedia/cms-ui";
import { useEffect } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import { urls } from "urls";

import { useTemplates } from "../hooks/use-templates";
import { PreviewAuthor } from "./components/author";
import { PreviewBody } from "./components/body";
import { PreviewHeader } from "./components/header";
import { PreviewTitle } from "./components/title";

const Preview = styled.div`
  white-space: pre-line;

  * + * {
    margin-top: 20px;
  }
`;

export const PreviewTemplateView = () => {
  const { id } = useParams<{ id: string }>();

  const loadingState = useTemplates((s) => s.previewLoadingState);
  const preview = useTemplates((s) => s.preview);

  const loadPreview = useTemplates((s) => s.loadPreview);

  useEffect(() => {
    if (loadingState === null) {
      loadPreview(id);
    }
  }, [id, loadingState, loadPreview]);

  if (!preview) {
    return null;
  }

  return (
    <>
      <Header title={"Preview template"} actions={[]}></Header>
      <Row>
        <Column className="w-2thirds">
          <Padding right>
            <Card>
              <Preview>
                <PreviewTitle>{preview.title}</PreviewTitle>
                <PreviewAuthor author={preview.author!} />
                <PreviewHeader template={preview!} />
                <PreviewBody template={preview!} />
              </Preview>
            </Card>
          </Padding>
        </Column>
        <Column className="w-third">
          <Padding bottom>
            <RelationItem
              name="blueprint"
              columns={[
                {
                  title: "Name",
                  projection: (values) => values?.name,
                  key: "name",
                },
              ]}
              link={(item) =>
                `${urls.cybertorial.blueprints.root.url}/${item.id}`
              }
              item={preview.blueprint!}
            />
          </Padding>
          <RelationItem
            name="template"
            columns={[
              {
                title: "Name",
                projection: (values) => values?.title,
                key: "name",
              },
            ]}
            link={(item) => `${urls.cybertorial.templates.root.url}/${item.id}`}
            item={preview!}
          />
        </Column>
      </Row>
    </>
  );
};
