import { User } from "shared/models/user";

import { IUserService } from "./service.interface";

export class NoopUserService implements IUserService {
  private readonly user = ({
    roles: ["admin", "editor"],
    username: "testusername@stockopedia.com",
    token: "random_access_token",
  } as any) as User;

  async silentSigninRedirect(): Promise<User | undefined> {
    return this.user;
  }

  async getUser(): Promise<User | undefined> {
    return this.user;
  }

  async signinRedirect(): Promise<void> {
    window.location.href = "/";
  }

  async signinRedirectCallback(): Promise<User> {
    return this.user;
  }

  async signoutRedirect(): Promise<void> {
    window.location.href = "/";
  }
  signoutRedirectCallback(): Promise<{
    error?: string | undefined;
    error_description?: string | undefined;
    error_uri?: string | undefined;
    state?: any;
  }> {
    throw new Error("Method not implemented.");
  }
}
