export const REGIONS = [
  {
    display: "North America",
    value: "xa",
  },
  { display: "Central America", value: "xb" },
  { display: "South America", value: "xc" },
  { display: "Europe", value: "eu" },
  { display: "Africa", value: "xf" },
  { display: "Middle East", value: "xm" },
  { display: "Asia", value: "xs" },
  { display: "Australasia", value: "xp" },
];
