import styled from 'styled-components'

export const Column = styled.div`
  display: flex;
  flex-direction: column;

  &.w-2thirds {
    width: 66%;
  }

  &.w-full {
    width: 100%;
  }

  &.w-quater {
    width: 25%;
  }

  &.w-half {
    width: 50%;
  }

  &.w-third {
    width: 34%;
  }
`
