import React from "react";
import { Text, Separator } from "@buffetjs/core";
import styled from "styled-components";
import { Spacer } from "./spacer";
import { TagValue } from "../..";

interface TagsInGroups {
  [group: string]: TagValue[];
}

interface Props {
  title?: string;
  tags: TagValue[];
  onClearAll?: () => void;
  onRemove: (tag: TagValue) => void;
}

const maxWidth = "200px";

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ClearAll = styled.span`
  color: #327ba8;
  cursor: pointer;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 3px;
  max-width: ${maxWidth};
`;

const TextWrapper = styled.div`
  max-width: ${maxWidth};
  white-space: nowrap;
  overflow-x: auto;
`;

const RemoveButton = styled.a`
  padding-right: 3px;
`;

const TagWrapper: React.FC<{ tag: TagValue; onRemove: () => void }> = ({
  tag,
  onRemove,
}) => (
  <TextWrapper>
    <Text>
      <RemoveButton onClick={onRemove}>×</RemoveButton>
      {tag.value}
    </Text>
  </TextWrapper>
);

export const UsedTagsView: React.FC<Props> = ({
  title = "Selected Tags",
  tags,
  onClearAll,
  onRemove,
}) => {
  if (!tags) {
    return null;
  }

  const tagsInGroups: TagsInGroups = tags.reduce(
    (acc, tagValue) => ({
      ...acc,
      [tagValue.group]:
        tagValue.group in acc ? [...acc[tagValue.group], tagValue] : [tagValue],
    }),
    {} as TagsInGroups,
  );

  return (
    <React.Fragment>
      <TitleWrapper>
        <Text fontWeight="bold" fontSize="md">
          {title}
        </Text>
        <Spacer />
        {!!onClearAll && <ClearAll onClick={onClearAll}>Clear All</ClearAll>}
      </TitleWrapper>
      <Separator />
      <ColumnsWrapper>
        {Object.keys(tagsInGroups).map((groupName) => (
          <Column key={groupName}>
            <Text fontWeight="bold">{groupName}</Text>

            {tagsInGroups[groupName].map((tag) => (
              <TagWrapper
                key={tag.group + tag.value}
                tag={tag}
                onRemove={() => onRemove(tag)}
              />
            ))}
          </Column>
        ))}
      </ColumnsWrapper>
    </React.Fragment>
  );
};
