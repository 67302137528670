import {
  EditEntityView,
  Entity,
  Field,
  RelationList,
  useEntityService,
} from "@stockopedia/cms-ef";
import { Loading, PlusIcon, TextArea, TextInput } from "@stockopedia/cms-ui";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { ImagePicker } from "shared/components/image-picker/image-picker";
import { urls } from "urls";
import * as Yup from "yup";

import { AuthorsService } from "../services/authors.service";
import { Author } from "../services/model";

const authorSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  image: Yup.string().required(),
  biography: Yup.string().required(),
});

interface Props {
  state: Author;
  context: "create" | "edit";
}

const AuthorView: React.FC<Props> = ({ state, context }) => {
  const service = useEntityService<AuthorsService>();
  const history = useHistory();

  return (
    <Entity
      context={context}
      validationSchema={authorSchema}
      actions={[
        {
          scopes: ["edit", "create"],
          label: "Archive",
          color: "delete",
          onClick: () => service.delete(state.id),
        },
      ]}
      state={state}
      onSubmit={async (values) => {
        await service.save(values);
        history.goBack();
      }}
      name="author"
      fields={[
        <Field required name="image" component={ImagePicker} width="full" />,
        <Field required name="firstName" component={TextInput} width="half" />,
        <Field required name="lastName" component={TextInput} width="half" />,
        <Field required name="biography" component={TextArea} width="full" />,
      ]}
      relations={[
        <RelationList
          actions={[
            {
              label: "Create",
              color: "cancel",
              onClick: () => {
                history.push({
                  pathname: urls.cybertorial.templates.create.url,
                  state: {
                    author: state,
                  },
                });
              },
              scopes: ["list"],
              icon: <PlusIcon color="#9ea7b8" />,
            },
          ]}
          name="templates"
          link={(item) => `${urls.cybertorial.authors.root.url}/${item.id}`}
          columns={[
            {
              title: "Title",
              projection: (values) => values.title,
              key: "title",
            },
          ]}
          count={state.totalTemplates}
          rows={state.templates!}
        />,
      ]}
      sections={[
        <RelationList
          actions={[
            {
              label: "Create",
              color: "cancel",
              onClick: () => {
                history.push({
                  pathname: urls.cybertorial.articles.create.url,
                  state: {
                    author: state,
                  },
                });
              },
              scopes: ["list"],
              icon: <PlusIcon color="#9ea7b8" />,
            },
          ]}
          count={state.totalArticles}
          name="articles"
          link={(item) => `${urls.cybertorial.authors.root.url}/${item.id}`}
          columns={[
            {
              title: "Title",
              projection: (values) =>
                values.blocks.find(({ label }) => label === "title")?.content,
              key: "title",
            },
          ]}
          rows={state.articles!}
        />,
      ]}
    />
  );
};

export const CreateAuthorView = () => (
  <AuthorView context={"create"} state={Author.empty()} />
);

export const EditAuthorView = () => {
  const onError = useCallback((e) => {}, []);

  return (
    <EditEntityView
      onError={onError}
      loadingView={() => <Loading />}
      render={(state: Author) => <AuthorView context={"edit"} state={state} />}
    />
  );
};
