import { autoMapper } from "@stockopedia/typed-mapper";
import { formatDate } from "shared/utils";

import { Article, ArticleResponse } from "../articles";
import { Author, AuthorResponse } from "../authors";
import { Blueprint, BlueprintResponseModel } from "../blueprints";
import { Template, TemplateResponseModel } from "../templates";
import { TemplatePreview } from "../templates/services/preview.model";
import { Theme, ThemeModel } from "../themes";

export const ArticleResponseMapperSymbol = "ArticleResponseMapperSymbol";
export const AuthorResponseMapperSymbol = "AuthorResponseMapperSymbol";
export const BlueprintResponseMapperSymbol = "BlueprintResponseMapperSymbol";
export const TemplateResponseMapperSymbol = "TemplateResponseMapperSymbol";
export const ThemeResponseMapperSymbol = "ThemeResponseMapperSymbol";

export const articleMapper = autoMapper
  .createMap<ArticleResponse, Article>(Article, ArticleResponseMapperSymbol)
  .forMember("template", (opt) =>
    opt.using(TemplateResponseMapperSymbol, (x) => x.template),
  )
  .forMember("author", (opt) =>
    opt.using(AuthorResponseMapperSymbol, (x) => x.author),
  )
  .forMember("updatedAt", (opt) => opt.mapFrom((x) => formatDate(x.updatedAt)));

export const authorMapper = autoMapper
  .createMap<AuthorResponse, Author>(Author, AuthorResponseMapperSymbol)
  .forMember("templates", (opt) =>
    opt.using(TemplateResponseMapperSymbol, (x) => x.templates?.nodes),
  )
  .forMember("totalTemplates", (opt) =>
    opt.mapFrom((x) => x.templates?.pageInfo.totalCount),
  )
  .forMember("totalArticles", (opt) =>
    opt.mapFrom((x) => x.articles?.pageInfo.totalCount),
  )
  .forMember("articles", (opt) =>
    opt.using(ArticleResponseMapperSymbol, (x) => x.articles?.nodes),
  );

export const blueprintMapper = autoMapper
  .createMap<BlueprintResponseModel, Blueprint>(
    Blueprint,
    BlueprintResponseMapperSymbol,
  )
  .forMember("templates", (opt) =>
    opt.using(TemplateResponseMapperSymbol, (x) => x.templates?.nodes),
  )
  .forMember("theme", (opt) =>
    opt.using(ThemeResponseMapperSymbol, (x) => x.theme),
  )
  .forMember("articles", (opt) =>
    opt.using(ArticleResponseMapperSymbol, (x) => x.articles?.nodes),
  )
  .forMember("numTemplates", (opt) =>
    opt.mapFrom((x) => x.templates?.pageInfo?.totalCount),
  )
  .forMember("numArticles", (opt) =>
    opt.mapFrom((x) => x.articles?.pageInfo?.totalCount),
  )
  .forMember("dataSourceType", (opt) =>
    opt.mapFrom((x) => {
      if (x.dataSources?.length) {
        return x.dataSources[0]?.type;
      }
      return;
    }),
  )
  .forMember("dataSourceQuery", (opt) =>
    opt.mapFrom((x) => {
      if (x.dataSources?.length) {
        return x.dataSources[0]?.criteria;
      }
      return;
    }),
  )
  .forMember("updatedAt", (opt) => opt.mapFrom((x) => formatDate(x.updatedAt)))
  .forMember("publishLimitSecurity", (opt) =>
    opt.mapFrom((x) => x.publishingLimits?.security),
  );

export const templateMapper = autoMapper
  .createMap<TemplateResponseModel, Template>(
    Template,
    TemplateResponseMapperSymbol,
  )
  .forMember("author", (opt) =>
    opt.using(AuthorResponseMapperSymbol, (x) => x.author),
  )
  .forMember("blueprint", (opt) =>
    opt.using(BlueprintResponseMapperSymbol, (x) => x.blueprint),
  )
  .forMember("callToAction", (opt) =>
    opt.mapFrom((x) => x.blueprint?.theme?.callToAction),
  )
  .forMember("numArticles", (opt) =>
    opt.mapFrom((x) => x.articles?.pageInfo?.totalCount),
  )
  .forMember("articles", (opt) =>
    opt.using(ArticleResponseMapperSymbol, (x) => x.articles?.nodes),
  )
  .forMember("updatedAt", (opt) => opt.mapFrom((x) => formatDate(x.updatedAt)));

export const templatePreviewMapper = autoMapper
  .createMap<TemplateResponseModel, TemplatePreview>(
    TemplatePreview,
    TemplateResponseMapperSymbol,
  )
  .forMember("author", (opt) =>
    opt.using(AuthorResponseMapperSymbol, (x) => x.author),
  )
  .forMember("callToAction", (opt) =>
    opt.mapFrom((x) => x.blueprint?.theme?.callToAction),
  )
  .forMember("updatedAt", (opt) => opt.mapFrom((x) => formatDate(x.updatedAt)));

export const themeMapper = autoMapper
  .createMap<ThemeModel, Theme>(Theme, ThemeResponseMapperSymbol)
  .forMember("blueprints", (opt) =>
    opt.using(BlueprintResponseMapperSymbol, (x) => x.blueprints?.nodes),
  )
  .forMember("numArticles", (opt) =>
    opt.mapFrom((x) => x.recentArticles?.pageInfo?.totalCount),
  )
  .forMember("recentArticles", (opt) =>
    opt.using(ArticleResponseMapperSymbol, (x) => x.recentArticles?.nodes),
  )
  .forMember("updatedAt", (opt) => opt.mapFrom((x) => formatDate(x.updatedAt)))
  .forMember("callToAction", (opt) => opt.mapFrom((x) => x.callToAction));
