import React, { createContext, useContext } from 'react'

export type FilterType = 'search' | 'select' | 'text' | 'number'

export interface Filter {
  type: FilterType
  options?: string[]
  field: string
  value: string | boolean | number
  operator?: string
}

interface State {
  filters: Filter[]
  onInput: (field: string, value: string | boolean | number) => void
}
const FiltersContext = createContext<State>({} as any)

export const useFilterContext = () => useContext(FiltersContext)

export const FilterProvider = ({
  value,
  children
}: {
  value: State
  children: React.ReactNode
}) => {
  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  )
}
