import { exporter } from "@stockopedia/rte";
import { IMapper } from "@stockopedia/typed-mapper";
import { StockopediaClient } from "clients/stockopedia";
import {
  DataResponse,
  IEntityController,
  MutationResult,
} from "framework/types";
import { Block } from "modules/cybertorial/articles";
import { TemplateStatus } from "modules/cybertorial/templates";
import { Direction } from "shared/types";
import { makeSortParams } from "shared/utils";
import { gqlResult } from "shared/utils";

import { Theme } from "..";

const THEMES_QUERY = `
query GetThemes(
  $paging: PagingArgs, $filters: ThemeFilters!, $sort: SortArgs) {
   themes(paging: $paging, filters: $filters, sort: $sort) 
       {
          pageInfo {
            totalPages
            totalCount
            currentPage
          }
           nodes {
              id
              title
              updatedAt
           }
   }
}
`;

const THEME_QUERY = `
query GetTheme($id: ID!) {
  theme(
    id: $id,
  ) 
  {
    id
    title
    callToAction
    updatedAt
    blueprints {
      nodes {
        name
        id
      }
    }
    recentArticles(paging: {
      take: 10
    }) {
      nodes {
        blocks {
          label
          content
        }
        id
        updatedAt
        author {
          id
          firstName
          lastName
        }
      }
      pageInfo {
        totalCount
      }
    }
    blueprints {
      nodes {
        name
        id
      }
    }
  }
}
`;

const THEME_MUTATION = `
mutation AddTheme($title: String!, $callToAction: String!, $id: ID) {
  upsertTheme(title: $title, callToAction: $callToAction, id: $id) {
      createdAt,
      updatedAt,
      title,
      callToAction,
      id
  }
}
`;

export interface ListParams {
  page: number;
  take: number;
  filters: {
    title?: string;
    status?: TemplateStatus;
  };
  sort: {
    field: string;
    direction: Direction;
  };
}

export interface ThemeModel {
  id: string;
  title: string;
  callToAction: string;
  updatedAt: Date;
  blueprints: {
    nodes: {
      name: string;
      id: string;
    }[];
  };
  recentArticles: {
    nodes: {
      blocks: Block[];
      id: string;
    }[];
    pageInfo: {
      totalCount: number;
    };
  };
}

interface ThemeResult {
  theme: ThemeModel;
}

interface ThemeMutationResult {
  upsertTheme: ThemeModel;
}

interface ListResult {
  themes: {
    pageInfo: {
      currentPage: number;
      totalPages: number;
      totalCount: number;
    };
    nodes: ThemeModel[];
  };
}

export class ThemeService implements IEntityController<Theme> {
  constructor(
    private readonly client: StockopediaClient,
    private readonly mapper: IMapper<ThemeModel, Theme>,
  ) {}

  delete(props: any): Promise<DataResponse<MutationResult>> {
    throw new Error("Method not implemented.");
  }

  async get(id: string): Promise<DataResponse<Theme>> {
    const { data, errors } = await gqlResult(() =>
      this.client.query<ThemeResult>({
        query: THEME_QUERY,
        variables: {
          id,
        },
      }),
    );

    if (!data) {
      return {
        errors,
      };
    }

    return {
      errors,
      data: this.mapper.map(data.theme),
    };
  }

  async list(params: ListParams): Promise<DataResponse<Theme[]>> {
    const { data, errors } = await gqlResult(() =>
      this.client.query<ListResult>({
        query: THEMES_QUERY,
        variables: {
          paging: {
            page: params.page,
            take: params.take,
          },
          filters: params.filters,
          sort: makeSortParams(params.sort),
        },
      }),
    );

    return {
      data: data
        ? data.themes.nodes.map(this.mapper.map.bind(this.mapper))
        : undefined,
      paging: data?.themes.pageInfo,
      errors,
    };
  }

  async save(params: Theme): Promise<DataResponse<MutationResult>> {
    const { data, errors } = await gqlResult(() =>
      this.client.query<ThemeMutationResult>({
        query: THEME_MUTATION,
        variables: {
          title: params.title,
          callToAction: exporter(params.callToAction!),
          id: params.id,
        },
      }),
    );

    return {
      errors,
      data: data?.upsertTheme,
    };
  }
}
