import FullscreenIcon from '@material-ui/icons/Fullscreen'
import React from 'react'

import { useToolbarContext } from './toolbar'

export const FullscreenButton = () => {
  const { actions } = useToolbarContext()

  return <FullscreenIcon onClick={() => actions.toggleFullscreen()} />
}
