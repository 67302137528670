import {
  Card,
  Column,
  H1,
  H3,
  Loading,
  Padding,
  Row,
} from "@stockopedia/cms-ui";
import { useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { LoadingState } from "shared/types";

import { AdminSettings } from "./components/settings";
import { Throughput } from "./components/throughput";
import { useControlPlane } from "./hooks/use-control-plane";
import { BatchRegion } from "./state/types";

export const ControlPlane = () => {
  const loading = useControlPlane((s) => s.loadingState);
  const error = useControlPlane((s) => s.error);
  const throughputs = useControlPlane((s) => s.throughputs);
  const settings = useControlPlane((s) => s.adminSettings);
  const actionResult = useControlPlane((s) => s.saveActionResult);
  const { addToast } = useToasts();

  useEffect(() => {
    if (actionResult !== undefined) {
      addToast(actionResult ? "Updated" : "Failed to update", {
        appearance: actionResult ? "success" : "error",
        autoDismiss: true,
      });
    }
  }, [actionResult, addToast]);

  if (loading === LoadingState.waiting) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <Padding bottom size="standard">
        <H1>Cybertorial dashboard</H1>
      </Padding>
      <Row>
        <H3>Global Settings</H3>
        <Card>
          <AdminSettings {...settings!} />
        </Card>
      </Row>
      <Row>
        <H3>Regional Throughputs</H3>
      </Row>
      <Row>
        {throughputs?.map((x) => {
          return (
            <Column key={x.region} className="w-quater">
              <Padding right bottom>
                <Card title={`${BatchRegion[x.region]}`}>
                  <Throughput {...x} />
                </Card>
              </Padding>
            </Column>
          );
        })}
      </Row>
    </div>
  );
};
