import { useEffect } from "react";

import { useAuth } from "../hooks/use-auth";

export const SignoutOidc = () => {
  const signoutRedirectCallback = useAuth((s) => s.signoutRedirectCallback);

  useEffect(() => {
    signoutRedirectCallback();
  }, [signoutRedirectCallback]);

  return <></>;
};
