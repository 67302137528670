import React, { useState } from "react";
import { InputText, Text } from "@buffetjs/core";
import { useDetectClickOutside } from "react-detect-click-outside";
import styled from "styled-components";

const ResultsContainer = styled.div`
  top: 55px;

  width: 100%;
  max-height: 200px;
  overflow-y: scroll;

  padding: 3px;

  z-index: 99999;

  box-shadow: 0 2px 4px #ced4de;
  background-color: white;
`;

const SelectHighlight = styled.div`
  background-color: #ced4de;
`;

const HoverHighlight = styled.div`
  background-color: #d8dee8;
`;

interface ItemResultProps {
  item: SearchResultValue;
  highlighted: boolean;
  selected: boolean;
  onClick: () => void;
}

function ItemResult({ item, highlighted, selected, onClick }: ItemResultProps) {
  const [hover, setHover] = useState(false);

  const Wrapper = hover
    ? HoverHighlight
    : highlighted
    ? SelectHighlight
    : ({ children, ...props }) => <div {...props}>{children}</div>;

  return (
    <Wrapper
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{ cursor: hover ? "pointer" : "default" }}
    >
      <Text>
        {selected && "✓"} {item.item.value}
      </Text>
    </Wrapper>
  );
}

export interface SearchResultItem {
  value: string;
  group: string;
}

export interface SearchResultValue {
  item: SearchResultItem;
  index: number;
}

interface SearchResultValues {
  [group: string]: SearchResultValue[];
}

interface SearchResults {
  length: number;
  results: SearchResultValues;
}

interface Props {
  value?: string;
  placeholder: string;
  closeOnSelect?: boolean;
  onInput: (value: string) => void;
  onSearch: (term: string) => Promise<SearchResultValue[]>;
  isSelected: (item: SearchResultItem) => boolean;
  onSelectItem?: (item: SearchResultItem) => void;
  onDeselectItem?: (item: SearchResultItem) => void;
  onEnterClick?: () => void;
}

export const AutoCompleteSearch: React.FC<Props> = ({
  value,
  placeholder,
  closeOnSelect,
  onInput,
  onSearch,
  isSelected,
  onSelectItem,
  onDeselectItem,
  onEnterClick,
}: Props) => {
  const [resultArray, setResultArray] = useState<SearchResultValue[] | null>(
    null,
  );
  const [results, setResults] = useState<SearchResults | null>(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const clickOutsideRef = useDetectClickOutside({
    onTriggered: () => setResults(null),
  });

  const handleSearch = async (term: string) => {
    onInput(term);

    if (term.trim().length === 0) {
      return setResults(null);
    }

    const matching = await onSearch(term);

    if (matching.length === 0) {
      setResultArray(null);
      return setResults(null);
    }

    setResultArray(matching);

    setResults({
      length: matching?.length!,
      results: matching?.reduce(
        (acc, value) => ({
          ...acc,
          [value.item.group]: [
            ...(acc[value.item.group] || []),
            { item: value.item, index: value.index },
          ],
        }),
        {} as SearchResultValues,
      )!,
    });
  };

  const handleKeyUp = (code: string) => {
    if (code === "ArrowDown") {
      if (currentIndex >= results?.length! - 1) {
        setCurrentIndex(-1);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    } else if (code === "ArrowUp") {
      if (currentIndex === -1) {
        setCurrentIndex(results?.length! - 1);
      } else {
        setCurrentIndex(currentIndex - 1);
      }
    } else if (code === "Enter") {
      const currentItem = resultArray?.find(
        (match) => match.index === currentIndex,
      )!;

      handleSelectItem(currentItem);
    }
  };

  const handleSelectItem = (item: SearchResultValue) => {
    if (isSelected(item.item)) {
      if (!!onDeselectItem) {
        onDeselectItem(item.item);
      }
    } else {
      if (!!onSelectItem) {
        if (!!item) {
          onSelectItem(item.item);
        } else if (!!onEnterClick) {
          onEnterClick();
        }
      }

      if (closeOnSelect) {
        setResults(null);
      }
    }
  };

  return (
    <React.Fragment>
      <InputText
        name="search"
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={({ target: { value } }) => handleSearch(value)}
        onKeyUp={({ code }: { code: string }) => handleKeyUp(code)}
      />

      {!!results && (
        <ResultsContainer ref={clickOutsideRef}>
          {Object.keys(results.results).map((group) => (
            <React.Fragment key={group}>
              <Text fontWeight="bold">{group}</Text>
              {results.results[group].map((item) => (
                <ItemResult
                  key={item.index}
                  item={item}
                  highlighted={item.index === currentIndex}
                  selected={!!isSelected(item.item)}
                  onClick={() => handleSelectItem(item)}
                />
              ))}
            </React.Fragment>
          ))}
        </ResultsContainer>
      )}
    </React.Fragment>
  );
};
