import { createContext, useContext } from "react";
import { UseStore, StateSelector } from "zustand";
import { AssetManagerState } from "../state";

export const AssetManagerContext = createContext<UseStore<AssetManagerState> | null>(
  null,
);

export const useAssetManager = <U>(
  selector: StateSelector<AssetManagerState, U>,
) => useContext(AssetManagerContext)!(selector);
