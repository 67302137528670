import "./cybertorial/container";

import { Container } from "framework";

declare module "framework/container" {
  interface Container {
    registerFeatures(): Container;
  }
}

Container.prototype.registerFeatures = function () {
  this.registerCybertorial();

  return this;
};
