import React, { useState } from "react";
import { Text } from "@buffetjs/core";

import { useAssetManager } from "../hooks";
import { TagGroupManager } from "./lib/tag-group-manager";
import { Spacer } from "./lib/spacer";
import { Button } from "@stockopedia/cms-ui";
import { LoadingState } from "../models";
import { ErrorText } from "./lib/error";

interface Props {
  tagName: string;

  onCreated: () => void;
}

export const NewTagCreator: React.FC<Props> = ({ tagName, onCreated }) => {
  const loadTagGroups = useAssetManager((s) => s.loadTagGroups);
  const tagGroups = useAssetManager((s) => s.tagGroups);
  const addTagGroup = useAssetManager((s) => s.addTagGroup);
  const addTagValue = useAssetManager((s) => s.addTagValue);
  const addTagValueStatus = useAssetManager((s) => s.addTagGroupStatus);

  const [selectedTagGroup, setSelectedTagGroup] = useState<string>("");

  const handleCreateClick = async () => {
    await addTagValue(tagName, selectedTagGroup);
    onCreated();
  };

  return (
    <React.Fragment>
      <Text fontSize="lg" fontWeight="bold">
        Select tag group for new tag '{tagName}'
      </Text>

      <Spacer />

      <TagGroupManager
        tagGroups={tagGroups}
        selectedTagGroup={selectedTagGroup}
        onLoadTagGroups={async () => loadTagGroups()}
        onAddTagGroup={async (name: string) => addTagGroup(name)}
        onSelectTagGroup={setSelectedTagGroup}
      />

      <Spacer />

      {!!selectedTagGroup && selectedTagGroup.length && (
        <Button
          color="primary"
          disabled={
            !selectedTagGroup ||
            selectedTagGroup.length === 0 ||
            addTagValueStatus === LoadingState.Loading
          }
          onClick={handleCreateClick}
        >
          Create
        </Button>
      )}

      {addTagValueStatus === LoadingState.Error && (
        <ErrorText>There was an issue with adding the tag</ErrorText>
      )}
    </React.Fragment>
  );
};
