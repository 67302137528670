import Add from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import { NumberInput, Row } from "@stockopedia/cms-ui";
import { useState } from "react";
import { useEffect } from "react";
import { RatioUnit } from "shared/components/ratio-picker/types";
import styled from "styled-components";

import { Button } from "../shared/button";
import { useScreenRuleContext } from "./";
import { AdvancedRuleBuilder } from "./advanced-builder";
import { AdvancedTypePicker } from "./advanced-type-picker";

const InputWrapper = styled.div`
  width: 150px;
  padding-right: var(--padding-xs);
  display: flex;
  flex-direction: row;
`;

export const NumberComparison = () => {
  const [isAdvanced, setIsAdvanced] = useState(false);
  const {
    actions: ruleActions,
    advancedType,
    comparisonNumber,
    subject,
  } = useScreenRuleContext();

  useEffect(() => {
    setIsAdvanced(!!advancedType);
  }, [advancedType]);

  const handleSetAdvanced = () => {
    if (isAdvanced) {
      ruleActions.setAdvancedType(undefined);
    }
    setIsAdvanced(!isAdvanced);
  };

  const renderStandard = () => (
    <Row className="items-center">
      <InputWrapper>
        {subject?.unit && <UnitRenderer unit={subject.unit} />}
        <NumberInput
          name="comparison_value"
          onChange={ruleActions.setComparisonNumber}
          value={comparisonNumber ?? 0}
        />
      </InputWrapper>
    </Row>
  );

  const renderAdvanced = () => {
    if (!advancedType) {
      return <AdvancedTypePicker />;
    }
    return <AdvancedRuleBuilder />;
  };

  return (
    <Row className="items-center">
      {isAdvanced ? renderAdvanced() : renderStandard()}
      <Button type="button" onClick={handleSetAdvanced}>
        {!isAdvanced ? (
          <>
            <Add />
            Advanced
          </>
        ) : (
          <Close />
        )}
      </Button>
    </Row>
  );
};

const StyledUnit = styled.div`
  display: flex;
  align-items: center;
  background: var(--color-background);
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: bold;
`;

const UnitRenderer = ({ unit }: { unit: RatioUnit }) => {
  const makeText = () => {
    switch (unit) {
      case RatioUnit.PERCENTAGE:
        return "%";
      case RatioUnit.MILLION:
        return "m";
      case RatioUnit.BASIS_POINTS:
        return "bps";
    }
  };

  return <StyledUnit>{makeText()}</StyledUnit>;
};
