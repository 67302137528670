import { Select as BuffetSelect } from '@buffetjs/core'
import React from 'react'

interface Props {
  value: string
  name: string
  onChange: (v: string) => void
  options: string[]
}

export const SelectInput = ({ value, onChange, options, name }: Props) => {
  return (
    <BuffetSelect
      name={name}
      onChange={({ target: { value } }) => {
        onChange(value)
      }}
      options={options}
      value={value}
    />
  )
}
