import { Toggle } from '@buffetjs/core'
import React from 'react'

interface Props {
  name: string
  onChange: (value: boolean) => void
  value?: boolean
}

export const BooleanInput = ({ value, onChange, name }: Props) => {
  return (
    <Toggle
      name={name}
      onChange={({ target: { value } }) => onChange(value)}
      value={value}
    />
  )
}
