import { container } from "framework";
import { Module } from "framework/types";
import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

interface Props {
  modules: string[];
  children?: JSX.Element;
}

export function ModuleLoader({
  modules: moduleKeys,
  children,
}: Props): JSX.Element {
  const modules = moduleKeys.map((x) => container.resolve<Module>(x));

  useEffect(() => {
    modules.forEach(({ onBeforeStart }) => {
      onBeforeStart && onBeforeStart();
    });
  }, [modules]);

  return (
    <>
      {modules.map((module) => {
        const Root =
          module.rootComponent ?? (({ children }) => <>{children}</>);
        return (
          <Root key={module.id}>
            <Switch>
              {Object.keys(module.pages).map((url) => {
                return (
                  <Route key={url} path={url} component={module.pages[url]} />
                );
              })}
              )
            </Switch>
          </Root>
        );
      })}

      {children}
    </>
  );
}
