import { Container } from "framework";
import { Module } from "framework/types";
import { urls } from "urls";

import { CybertorialModule } from "./routes";

export const CYBERTORIAL_MODULE_TOKEN = "cybertorial";

export const cybertorialModule = (_: Container): Module => ({
  id: CYBERTORIAL_MODULE_TOKEN,
  pages: {
    [urls.cybertorial.root.url]: CybertorialModule,
  },
});
