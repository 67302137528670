import { Modal } from "@stockopedia/cms-ui";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import {
  RatioPickerContext,
  useRatioPickerContext,
} from "shared/components/ratio-picker/context";
import { RatiosDisplay } from "shared/components/ratio-picker/picker";
import {
  Ratio,
  Category as RatioCategory,
} from "shared/components/ratio-picker/types";

import { RuleState } from "../types";

type ratioSelectFn = (ratio: Ratio) => void;

interface BuilderContext {
  ratios: RatioCategory[];
  rules: RuleState[];
  actions: {
    setRatioSelectAction: (fn: ratioSelectFn) => void;
    ratioSelectAction: ratioSelectFn;
    addRule: () => void;
    deleteRule: (index: number) => void;
    updateRule: (index: number, state: RuleState) => void;
  };
}

const ScreenBuilderContext = createContext<BuilderContext>(
  {} as BuilderContext,
);

export const useScreenBuilderContext = () => useContext(ScreenBuilderContext);

interface BuilderProps {
  children: JSX.Element;
  value: State;
  ratios: RatioCategory[];
  onChange: (rules: RuleState[]) => void;
}

interface State {
  rules: RuleState[];
}

const reducer = (state: State, merge: Partial<State>) => {
  return { ...state, ...merge };
};

export const ScreenBuilderProvider = ({
  children,
  ratios,
  value,
  onChange,
}: BuilderProps) => {
  const [state, dispatch] = useReducer(reducer, value);
  const [ratioSelectActionState, setRatioSelectActionState] = useState({
    action: (_: Ratio) => {},
  });

  useEffect(() => {
    if (state.rules.every((x) => RuleState.isValid(x))) {
      onChange(state.rules);
    }
  }, [state, onChange]);

  const contextState = useMemo(() => {
    const addRule = () => {
      dispatch({ rules: [...state.rules, RuleState.empty()] });
    };

    const deleteRule = (index: number) => {
      const newRules = [...state.rules];
      newRules.splice(index, 1);
      dispatch({ rules: newRules });
    };

    const updateRule = (index: number, rule: RuleState) => {
      const newRules = [...state.rules];
      newRules[index] = rule;
      dispatch({ rules: newRules });
    };

    const setRatioSelectAction = (fn: (ratio: Ratio) => void) => {
      setRatioSelectActionState({ action: fn });
    };

    return {
      rules: state.rules,
      ratios,
      actions: {
        ratioSelectAction: ratioSelectActionState.action,
        setRatioSelectAction,
        addRule,
        deleteRule,
        updateRule,
      },
    };
  }, [ratios, state, ratioSelectActionState]);

  return (
    <ScreenBuilderContext.Provider value={contextState}>
      <Wrapper>{children}</Wrapper>
    </ScreenBuilderContext.Provider>
  );
};

const Wrapper = ({ children }) => {
  const { actions, ratios } = useScreenBuilderContext();

  return (
    <RatioPickerContext onSelected={actions.ratioSelectAction} ratios={ratios}>
      {children}
      <RatioPicker />
    </RatioPickerContext>
  );
};

const RatioPicker = () => {
  const { actions, showPicker, ratios } = useRatioPickerContext();

  return (
    <>
      {showPicker && (
        <Modal onHide={() => actions.showModal(false)}>
          <RatiosDisplay categories={ratios} />
        </Modal>
      )}
    </>
  );
};
