import { EntityListManger } from "@stockopedia/cms-ef";
import { Loading } from "@stockopedia/cms-ui";
import { useCallback } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { enumKeys } from "shared/utils";
import { urls } from "urls";

import { ArticleStatus } from "../services/model";

export const ListArticles = () => {
  const history = useHistory();
  const onError = useCallback(() => {}, []);
  return (
    <EntityListManger
      columns={[
        {
          key: "title",
          title: "Title",
          projection: (value) =>
            value.blocks.find(({ label }) => label === "title")?.content,
        },
        {
          key: "status",
          title: "Status",
          projection: (value) => value.status,
        },
        {
          key: "template",
          title: "Template",
          projection: (value) => (
            <Link
              to={`${urls.cybertorial.templates.root.url}/${value.template.id}`}
            >
              {value.template.title}
            </Link>
          ),
        },
        {
          key: "updatedAt",
          title: "updated at",
          projection: (value) => value.updatedAt,
        },
      ]}
      onError={onError}
      name="articles"
      loadingView={() => <Loading />}
      link={(item) => `${urls.cybertorial.articles.root.url}/${item.id}`}
      onCreate={() => history.push(`${urls.cybertorial.articles.create.url}`)}
      filters={[
        {
          field: "title",
          type: "search",
        },
        {
          field: "status",
          type: "select",
          operator: "equals",
          options: enumKeys(ArticleStatus),
        },
        {
          field: "template",
          type: "search",
          operator: "contains",
        },
        {
          field: "author",
          type: "search",
          operator: "contains",
        },
      ]}
      sort={{
        fields: ["title", "updatedAt"],
        default: {
          field: "updatedAt",
          direction: "desc",
        },
      }}
    />
  );
};
