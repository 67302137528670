import {
  Button,
  Column,
  Field,
  NumberInput,
  Padding,
  Row,
} from "@stockopedia/cms-ui";
import { useState } from "react";

import { useControlPlane } from "../../hooks/use-control-plane";
import { AdminSettings as AdminSettingsModel } from "../../state/types";

export const AdminSettings = (settings: AdminSettingsModel) => {
  const [daysBetweenArticles, setDaysBetweenArticles] = useState(
    settings.daysBetweenArticles,
  );

  const saveSettings = useControlPlane((s) => s.saveSettings);

  const handleSave = () => {
    saveSettings({
      daysBetweenArticles,
    });
  };

  return (
    <Column>
      <Row>
        <Padding bottom>
          <Column className="w-quater">
            <Field title="Days between articles">
              <NumberInput
                name="days_between_articles"
                value={daysBetweenArticles}
                onChange={(v) => v >= 0 && setDaysBetweenArticles(v)}
              />
            </Field>
            <p>
              This controls the number of days between publishing any article
              for a specific security.
            </p>
          </Column>
        </Padding>
      </Row>
      <Row>
        <Button type="button" color="primary" onClick={handleSave}>
          Update
        </Button>
      </Row>
    </Column>
  );
};
