import {
  EntityContextProvider,
  EntityServiceProvider,
} from "@stockopedia/cms-ef";
import { Container } from "framework";
import { Module } from "framework/types";
import { urls } from "urls";

import { AuthorsService } from "./services/authors.service";
import { CreateAuthorView, EditAuthorView } from "./views/crud.view";
import { ListAuthors } from "./views/list.view";

export const AUTHORS_MODULE_TOKEN = "authors";

export const authorsModule = (c: Container): Module => ({
  id: AUTHORS_MODULE_TOKEN,
  rootComponent: ({ children }) => (
    <EntityServiceProvider service={c.resolve(AuthorsService)}>
      <EntityContextProvider basePath={urls.cybertorial.BASE_PATH.url}>
        {children}
      </EntityContextProvider>
    </EntityServiceProvider>
  ),
  pages: {
    [urls.cybertorial.authors.create.url]: CreateAuthorView,
    [urls.cybertorial.authors.edit.url]: EditAuthorView,
    [urls.cybertorial.authors.root.url]: ListAuthors,
  },
});
