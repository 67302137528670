import { OneToOneRelationalSelectList } from "@stockopedia/cms-ef";
import {
  EditEntityView,
  Entity,
  Field,
  Relation,
  RelationList,
  useEntityService,
} from "@stockopedia/cms-ef";
import {
  BooleanInput,
  Loading,
  NumberInput,
  PlusIcon,
  ReadonlyTextInput,
  TextArea,
  TextInput,
} from "@stockopedia/cms-ui";
import { useRouteState } from "hooks/use-route-state";
import { Article } from "modules/cybertorial/articles";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { urls } from "urls";
import * as Yup from "yup";

import { ScreenRulesInput } from "../components/screener-rules";
import { BlueprintService } from "../services/blueprints.service";
import { Blueprint, BlueprintBuilder, BlueprintStatus } from "..";

const schema = Yup.object().shape({
  name: Yup.string().required(),
  description: Yup.string().required(),
});

interface Props {
  state: Blueprint;
  context: "create" | "edit";
}

const View: React.FC<Props> = ({ state, context }) => {
  const service = useEntityService<BlueprintService>();
  const history = useHistory();

  return (
    <Entity
      context={context}
      validationSchema={schema}
      actions={[
        {
          show: state.status !== BlueprintStatus.ARCHIVED,
          scopes: ["edit", "create"],
          label: "Archive",
          color: "delete",
          onClick: () => {
            service.archive(state);
            history.goBack();
          },
        },
        {
          show: state.status === BlueprintStatus.ARCHIVED,
          scopes: ["edit", "create"],
          label: "Unarchive",
          color: "cancel",
          onClick: () => {
            service.unarchive(state);
            history.goBack();
          },
        },
      ]}
      state={state}
      onSubmit={async (values) => {
        await service.save(values);
        history.goBack();
      }}
      name="blueprint"
      fields={[
        <Field required name="name" component={TextInput} width="full" />,
        <Field required name="description" component={TextArea} width="full" />,
        <Field
          name="publishLimitSecurity"
          component={NumberInput}
          width="half"
        />,
        <Field name="autoPublish" component={BooleanInput} width="third" />,
        <Field name="updatedAt" component={ReadonlyTextInput} width="third" />,
        <Field
          name="dataSourceType"
          component={ReadonlyTextInput}
          width="full"
        />,
        <Field
          name="dataSourceQuery"
          component={ScreenRulesInput}
          width="full"
        />,
      ]}
      relations={[
        <Relation
          name="theme"
          component={(props) => (
            <OneToOneRelationalSelectList
              {...props}
              options={{
                getOptions: (params) =>
                  service.listThemes({
                    ...params,
                    page: 0,
                    take: 200,
                    sort: {
                      field: "updatedAt",
                      direction: "desc",
                    },
                  }),
                searchFields: ["title"],
              }}
            />
          )}
        />,
        <RelationList
          actions={[
            {
              label: "Create",
              color: "cancel",
              onClick: () => {
                history.push({
                  pathname: urls.cybertorial.templates.create.url,
                  state: {
                    blueprint: state,
                  },
                });
              },
              scopes: ["list"],
              icon: <PlusIcon color="#9ea7b8" />,
            },
          ]}
          name="templates"
          link={(item) => `${urls.cybertorial.templates.root.url}/${item.id}`}
          columns={[
            {
              title: "Title",
              projection: (values) => values.title,
              key: "title",
            },
            {
              title: "Status",
              projection: (values) => values.status,
              key: "status",
            },
          ]}
          count={state.numTemplates}
          rows={state.templates!}
        />,
      ]}
      sections={[
        <RelationList
          key="articles"
          name="Recent Articles"
          link={(item) => `${urls.cybertorial.articles.root.url}/${item.id}`}
          columns={[
            {
              title: "Title",
              projection: (values) =>
                values.blocks.find(({ label }) => label === "title")?.content,
              key: "title",
            },
            {
              title: "Updated At",
              projection: (values) => values.updatedAt,
              key: "updatedAt",
            },
            {
              title: "Author",
              projection: (values: Article) => (
                <Link
                  to={`${urls.cybertorial.authors.root.url}/${
                    values.author!.id
                  }`}
                >
                  {values.author!.firstName} {values.author!.lastName}
                </Link>
              ),
              key: "author",
            },
          ]}
          count={state.numArticles}
          rows={state.articles!}
        />,
      ]}
    />
  );
};

export const CreateArticleView = () => {
  const state = useRouteState<Blueprint>();

  return (
    <View
      context={"create"}
      state={new BlueprintBuilder().withTheme(state?.theme).build()}
    />
  );
};

export const EditArticleView = () => {
  const onError = useCallback((e) => {}, []);

  return (
    <EditEntityView
      onError={onError}
      loadingView={() => <Loading />}
      render={(state: Blueprint) => <View context={"edit"} state={state} />}
    />
  );
};
