import { EditorState } from "@stockopedia/rte";
import { IMapper } from "@stockopedia/typed-mapper";
import { StockopediaClient } from "clients/stockopedia";
import {
  DataResponse,
  IEntityController,
  MutationResult,
} from "framework/types";
import {
  Author,
  ListParams as AuthorsListParams,
  AuthorsService,
} from "modules/cybertorial/authors";
import { TemplateService } from "modules/cybertorial/templates";
import { Template } from "modules/cybertorial/templates/services/model";
import { Direction } from "shared/types";
import { makeSortParams } from "shared/utils";
import { gqlResult } from "shared/utils";

import { Article, ArticleStatus } from "./model";

const ARTICLES_QUERY = `
query GetArticles(
  $filters: ArticleFilters!, $paging: PagingArgs, $sort: SortArgs) {
   articles(paging: $paging, filters: $filters, sort: $sort) 
       {
          pageInfo {
            totalPages
            totalCount
            currentPage
          }
          nodes {
              id,
              blocks {
                label
                content
              }
              status
              updatedAt
              template {
                title
                id
              }
          }
   }
}
`;

const ARTICLE_QUERY = `
query GetArticle($id: ID!) {
  article(
    id: $id,
  ) 
  {
    id
    blocks {
      label
      content
    }
    createdAt
    updatedAt
    status
    author {
      firstName
      lastName
      id
    }
    template {
      id
      title
      blueprint {
        id
        name
        theme {
          callToAction
        }
      }
    }
    tags {
      title
      id
    }
  }
}
`;

const ARTICLE_MUTATION = `
mutation UpsertArticle(
  $content: String!
  $id: ID
  $title: String!
  $authorId: ID
  $templateId: ID
  $tags: [ID!]
  $image: String!
  $status: ArticleStatus) {
  upsertArticle(
      content: $content
      status: $status
      templateId: $templateId
      image: $image
      tags: $tags
      id: $id
      title: $title
      authorId: $authorId) 
      {
          id,
      }
}
`;

interface ListParams {
  page: number;
  take: number;
  filters: {
    title?: string;
    template?: string;
    author?: string;
    status?: ArticleStatus;
  };
  sort: {
    field: string;
    direction: Direction;
  };
}

interface Block {
  label: string;
  content: string | EditorState;
}

export interface ArticleResponse {
  id: string;
  blocks: Block[];
  tags: {
    title: string;
    id: string;
  }[];
  author: {
    firstName: string;
    lastName: string;
    id: string;
  };
  template: {
    title: string;
    id: string;
  };
  status: ArticleStatus;
  updatedAt: Date;
}

interface ArticleResult {
  article: ArticleResponse;
}

interface ListResult {
  articles: {
    pageInfo: {
      currentPage: number;
      totalPages: number;
      totalCount: number;
    };
    nodes: ArticleResponse[];
  };
}

interface ArticleUpsertResult {
  upsertArticle: {
    id: string;
  };
}

export type ArticleInput = Omit<Article, "blocks"> & {
  title: string;
  content: string;
  image: string;
};

export class ArticleService implements IEntityController<Article> {
  constructor(
    private readonly client: StockopediaClient,
    private readonly authorsService: AuthorsService,
    private readonly templatesService: TemplateService,
    private readonly mapper: IMapper<ArticleResponse, Article>,
  ) {}

  async archive(params: ArticleInput): Promise<DataResponse<MutationResult>> {
    const { data, errors } = await gqlResult(() =>
      this.client.query<ArticleUpsertResult>({
        query: ARTICLE_MUTATION,
        variables: {
          ...params,
          tags: params.tags?.map((t) => t.id),
          authorId: params.author?.id,
          templateId: params.template?.id,
          status: ArticleStatus.ARCHIVED,
        },
      }),
    );

    return {
      errors,
      data: data?.upsertArticle,
    };
  }

  async unarchive(params: ArticleInput): Promise<DataResponse<MutationResult>> {
    const { data, errors } = await gqlResult(() =>
      this.client.query<ArticleUpsertResult>({
        query: ARTICLE_MUTATION,
        variables: {
          ...params,
          tags: params.tags?.map((t) => t.id),
          authorId: params.author?.id,
          templateId: params.template?.id,
          status: ArticleStatus.DRAFT,
        },
      }),
    );

    return {
      errors,
      data: data?.upsertArticle,
    };
  }

  async get(id: string): Promise<DataResponse<Article>> {
    const { data, errors } = await gqlResult<ArticleResult>(() =>
      this.client.query({
        query: ARTICLE_QUERY,
        variables: {
          id,
        },
      }),
    );

    return {
      errors,
      data: data ? this.mapper.map(data!.article) : undefined,
    };
  }

  async list(params: ListParams): Promise<DataResponse<Article[]>> {
    const { data, errors } = await gqlResult<ListResult>(() =>
      this.client.query({
        query: ARTICLES_QUERY,
        variables: {
          filters: {
            ...params.filters,
            author: {
              firstName: params.filters?.author,
              lastName: params.filters?.author,
            },
            template: {
              id: params.filters?.template,
              title: params.filters?.template,
            },
          },
          paging: {
            page: params.page,
            take: params.take,
          },
          sort: makeSortParams(params.sort),
        },
      }),
    );

    return {
      data: data?.articles.nodes.map(this.mapper.map.bind(this.mapper)),
      paging: data?.articles.pageInfo,
      errors,
    };
  }

  async listAuthors(
    params: AuthorsListParams,
  ): Promise<DataResponse<Author[]>> {
    return this.authorsService.list(params);
  }

  async listTemplates(params): Promise<DataResponse<Template[]>> {
    return this.templatesService.list(params);
  }

  async save(params: ArticleInput): Promise<DataResponse<MutationResult>> {
    const { data, errors } = await gqlResult<ArticleUpsertResult>(() =>
      this.client.query({
        query: ARTICLE_MUTATION,
        variables: {
          ...params,
          tags: params.tags?.map((t) => t.id),
          authorId: params.author?.id,
          templateId: params.template?.id,
        },
      }),
    );

    return {
      data: data?.upsertArticle,
      errors,
    };
  }
}
