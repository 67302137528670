import { OneToOneRelationalSelectList } from "@stockopedia/cms-ef";
import {
  EditEntityView,
  Entity,
  Field,
  Relation,
  RelationItem,
  RelationList,
  useEntityService,
} from "@stockopedia/cms-ef";
import {
  Loading,
  ReadonlyTextArea,
  ReadonlyTextInput,
  SelectFromArrayInput,
  TextInput,
} from "@stockopedia/cms-ui";
import { useRouteState } from "hooks/use-route-state";
import { Article } from "modules/cybertorial/articles/services/model";
import { Blueprint } from "modules/cybertorial/blueprints";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { RteEditor } from "shared/components/inputs/rte-editor";
import { enumKeys } from "shared/utils";
import { urls } from "urls";
import * as Yup from "yup";

import { Template, TemplateBuilder, TemplateStatus } from "../services/model";
import { TemplateService } from "../services/templates.service";

const schema = Yup.object().shape({
  title: Yup.string().required(),
  callToAction: Yup.string().required(),
  content: Yup.lazy((val) =>
    typeof val === "string" ? Yup.string().required() : Yup.object().required(),
  ),
});

interface Props {
  state: Template;
  context: "create" | "edit";
}

const View: React.FC<Props> = ({ state, context }) => {
  const service = useEntityService<TemplateService>();
  const history = useHistory();

  const [blueprint, setBlueprint] = useState<Blueprint>();

  const onChange = (
    values: {
      blueprint?: Blueprint;
    },
    setFieldValue: (name: string, value: any) => void,
    validateField: (field: string) => void,
  ) => {
    if (blueprint !== values.blueprint) {
      setBlueprint(values.blueprint);
      setFieldValue("callToAction", values.blueprint?.theme?.callToAction);
      validateField("callToAction");
    }
  };

  return (
    <Entity
      context={context}
      validationSchema={schema}
      onChange={onChange}
      actions={[
        {
          label: "Duplicate",
          color: "cancel",
          scopes: ["create", "edit"],
          onClick: () => {
            history.push({
              pathname: urls.cybertorial.templates.create.url,
            });
          },
        },
        {
          label: "Preview",
          color: "cancel",
          scopes: ["create", "edit"],
          onClick: () => {},
          href:
            context === "edit"
              ? urls.cybertorial.templates.preview.build(state.id)
              : undefined,
          disabled: context === "create",
          title:
            context === "create"
              ? "Templates must be saved before previewing"
              : undefined,
        },
        {
          show: state.status !== TemplateStatus.ARCHIVED,
          label: "Archive",
          color: "delete",
          scopes: ["edit"],
          onClick: async () => {
            await service.archive(state);
            history.goBack();
          },
        },
      ]}
      state={state}
      onSubmit={async (values) => {
        const res = await service.save(values);

        // TODO: currently assuming the template is always successfully created and navigating
        // to the Edit Template page - we should handle errors when saving the template.
        if (context === "create") {
          history.push({
            pathname: `${urls.cybertorial.templates.root.url}/${res.data!.id}`,
          });
        } else {
          history.push({ pathname: urls.cybertorial.templates.root.url });
        }
      }}
      name={
        state?.status === TemplateStatus.ARCHIVED
          ? `template - Archived`
          : "template"
      }
      fields={[
        <Field
          required
          name="title"
          key="title"
          component={TextInput}
          width="full"
        />,
        <Field
          required
          name="content"
          key="content"
          component={RteEditor}
          width="full"
        />,
        <Field
          required
          name="status"
          key="status"
          component={(props) => (
            <SelectFromArrayInput
              {...props}
              options={enumKeys(TemplateStatus).filter(
                (x) => x !== TemplateStatus.ARCHIVED,
              )}
              defaultValue={TemplateStatus.DRAFT}
            />
          )}
          width="full"
        />,
        <Field
          required
          name="callToAction"
          key="callToAction"
          component={ReadonlyTextArea}
          width="full"
        />,
        <Field
          key="updatedAt"
          name="updatedAt"
          component={ReadonlyTextInput}
          width="third"
        />,
      ]}
      relations={[
        <Relation
          name="author"
          component={(props) => (
            <OneToOneRelationalSelectList
              {...props}
              options={{
                getOptions: (params) =>
                  service.listAuthors({
                    ...params,
                    page: 0,
                    take: 200,
                    sort: {
                      field: "firstName",
                      direction: "desc",
                    },
                  }),
                searchFields: ["firstName", "lastName"],
              }}
            />
          )}
        />,
        context === "create" ? (
          <Relation
            name="blueprint"
            component={(props) => (
              <OneToOneRelationalSelectList
                {...props}
                options={{
                  getOptions: (params) =>
                    service.listBlueprints({
                      ...params,
                      page: 0,
                      take: 200,
                      sort: {
                        field: "updatedAt",
                        direction: "desc",
                      },
                    }),
                  searchFields: ["name"],
                }}
              />
            )}
          />
        ) : (
          <RelationItem
            name="blueprint"
            link={(item) =>
              `${urls.cybertorial.blueprints.root.url}/${item?.id}`
            }
            columns={[
              {
                title: "Name",
                projection: (values) => values?.name,
                key: "name",
              },
            ]}
            item={state.blueprint!}
          />
        ),
        <RelationItem
          name="theme"
          link={(item) => `${urls.cybertorial.themes.root.url}/${item?.id}`}
          columns={[
            {
              title: "Title",
              projection: (values) => values?.title,
              key: "title",
            },
          ]}
          item={blueprint?.theme!}
        />,
      ]}
      sections={[
        <RelationList
          key="articles"
          name="Recent Articles"
          link={(item) => `${urls.cybertorial.articles.root.url}/${item.id}`}
          columns={[
            {
              title: "Title",
              projection: (values) =>
                values.blocks.find(({ label }) => label === "title")?.content,
              key: "title",
            },
            {
              title: "Updated At",
              projection: (values) => values.updatedAt,
              key: "updatedAt",
            },
            {
              title: "Author",
              projection: (values: Article) => (
                <Link
                  to={`${urls.cybertorial.authors.root.url}/${
                    values.author!.id
                  }`}
                >
                  {values.author!.firstName} {values.author!.lastName}
                </Link>
              ),
              key: "author",
            },
          ]}
          count={state.numArticles}
          rows={state.articles!}
        />,
      ]}
    />
  );
};

export const CreateView = () => {
  const state = useRouteState<Partial<Template>>();

  return (
    <View
      context={"create"}
      state={new TemplateBuilder()
        .withAuthor(state?.author)
        .withBlueprint(state?.blueprint)
        .build()}
    />
  );
};

export const EditView = () => (
  <EditEntityView
    onError={(e) => {}}
    loadingView={() => <Loading />}
    render={(state: Template) => <View context={"edit"} state={state} />}
  />
);
