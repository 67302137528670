import { ApolloClient, NormalizedCacheObject, gql } from "@apollo/client";

interface QueryProps {
  query: string;
  variables?: any;
}

interface IUserService {
  getUser(): Promise<{ token: string } | undefined>;
}

export class StockopediaClient {
  constructor(
    private readonly userService: IUserService,
    private readonly client: ApolloClient<NormalizedCacheObject>,
  ) {}

  async query<T>({ query, variables }: QueryProps) {
    const user = await this.userService.getUser();
    return this.client.query<T>({
      query: gql`
        ${query}
      `,
      variables,
      context: {
        headers: {
          authorization: `Bearer ${user?.token}`,
        },
      },
    });
  }
}
