import { env as builder } from "@stockopedia/typed-env";

export const env = builder.load({
  api_url: builder.string("REACT_APP_API_URL"),
  auth_post_logout_redirect_uri: builder.string(
    "REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI",
  ),
  auth_redirect_uri: builder.string("REACT_APP_AUTH_REDIRECT_URI"),
  auth_silent_redirect_uri: builder.string(
    "REACT_APP_AUTH_SILENT_REDIRECT_URI",
  ),
  auth_authority: builder.string("REACT_APP_AUTH_AUTHORITY"),
  node_env: builder.string("NODE_ENV"),
  auth_enabled: builder.bool("REACT_APP_AUTH_ENABLED"),
  assets_host: builder.string("REACT_APP_ASSETS_HOST"),
});
