import { useEffect } from "react";

import { useAuth } from "../hooks/use-auth";

export const SilentSigninOidc = () => {
  const silentSigninRedirect = useAuth((s) => s.silentSigninRedirect);

  useEffect(() => {
    silentSigninRedirect();
  }, [silentSigninRedirect]);

  return <div>Signing in...</div>;
};
