import { container } from "./container";
import { constructor } from "./types";

export const resolveDependency = <T>(clazz: constructor<T>) => {
  return container.resolve(clazz);
};

export const useDependency = <T>(clazz: constructor<T>) => {
  return resolveDependency(clazz);
};
