import React, { useState } from "react";
import { Loading } from "@stockopedia/cms-ui";
import { LoadingState, TagValue } from "../../models";
import { ErrorText } from "./error";
import { Spacer } from "./spacer";
import { UsedTagsView } from "./used-tags-view";
import { AutoCompleteSearch, SearchResultValue } from "./auto-complete-search";

interface Props {
  loadTagValuesStatus: LoadingState | null;
  onSelectTag: (tag: TagValue) => void;
  onDeselectTag: (tag: TagValue) => void;
  selectedTags: TagValue[];
  handleSearch: (term: string) => Promise<SearchResultValue[]>;
  onClearAll: () => void;
}

export const TagsSelector: React.FC<Props> = ({
  loadTagValuesStatus,
  onSelectTag,
  onDeselectTag,
  selectedTags,
  handleSearch,
  onClearAll,
}) => {
  const [term, setTerm] = useState("");

  const isTagSelected = (tag: TagValue) =>
    !!selectedTags.find(
      (val) => val.group === tag.group && val.value === tag.value,
    );

  if (loadTagValuesStatus === null) {
    return null;
  }

  return (
    <React.Fragment>
      {loadTagValuesStatus === LoadingState.Loading && <Loading />}

      {loadTagValuesStatus === LoadingState.Error && (
        <ErrorText>There was an error loading the tags</ErrorText>
      )}

      {loadTagValuesStatus === LoadingState.Success && (
        <React.Fragment>
          <UsedTagsView
            tags={selectedTags}
            onClearAll={onClearAll}
            onRemove={onDeselectTag}
          />

          <Spacer />

          <AutoCompleteSearch
            placeholder="Search Tags"
            value={term}
            onInput={setTerm}
            isSelected={(item) => isTagSelected(item)}
            onSelectItem={onSelectTag}
            onDeselectItem={onDeselectTag}
            onSearch={handleSearch}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
