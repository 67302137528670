import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.items-center {
    align-items: center;
  }
`
