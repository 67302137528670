import { ModuleLoader } from "framework/components";
import { ARTICLES_MODULE_TOKEN } from "./articles/module";

import { AUTHORS_MODULE_TOKEN } from "./authors/module";
import { BLUEPRINTS_MODULE_TOKEN } from "./blueprints/module";
import { CYBERTORIAL_CONTROL_PLANE_MODULE } from "./control-plane/module";
import { TEMPLATES_MODULE_TOKEN } from "./templates/module";
import { THEMES_MODULE_TOKEN } from "./themes/module";

export const CybertorialModule = () => {
  return (
    <>
      <ModuleLoader
        modules={[
          AUTHORS_MODULE_TOKEN,
          ARTICLES_MODULE_TOKEN,
          CYBERTORIAL_CONTROL_PLANE_MODULE,
          BLUEPRINTS_MODULE_TOKEN,
          THEMES_MODULE_TOKEN,
          TEMPLATES_MODULE_TOKEN,
        ]}
      />
    </>
  );
};
