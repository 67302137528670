import { IMapper } from "@stockopedia/typed-mapper";
import { StockopediaClient } from "clients/stockopedia";
import {
  DataResponse,
  IEntityController,
  MutationResult,
} from "framework/types";
import { Block } from "modules/cybertorial/articles";
import { Theme } from "modules/cybertorial/themes/services/model";
import {
  ListParams as ThemeListParams,
  ThemeService,
} from "modules/cybertorial/themes/services/themes.service";
import { Direction } from "shared/types";
import { gqlResult, makeSortParams } from "shared/utils";

import { Blueprint, BlueprintStatus, DataSourceType } from "./model";

const BLUEPRINTS_QUERY = `
query GetBlueprints($paging: PagingArgs, $filters: BlueprintFilters!, $sort: SortArgs) {
  blueprints(paging: $paging, filters: $filters, sort: $sort) {
      nodes {
          id,
          name,
          sentiment
          description
          autoPublish
          updatedAt
          theme {
            id
            title
            callToAction
          }
          templates {
            pageInfo {
              totalCount
            }
         }
      }
  }
}
`;

const BLUEPRINT_QUERY = `
query GetBlueprint($id: ID!) {
  blueprint(id: $id) {
     id
     name
     description
     sentiment
     autoPublish
     status
     publishingLimits {
         security
     },
     createdAt
     updatedAt
     theme {
        id
        title
     }
     dataSources {
        type
        criteria
     }
     templates {
        pageInfo {
          totalCount
        }
       nodes {
         id
         title
         status
       }
     }
     articles(paging: { take: 10 }) {
      pageInfo {
        totalCount
      }
      nodes {
        id
        blocks {
          label
          content
        }
        updatedAt
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
}
`;

const UPSERT_MUTATION = `
mutation UpsertBlueprint(
  $id: ID, 
  $autoPublish: Boolean,
  $description: String!,
  $publishLimitSecurity: Int
  $sentiment: Sentiment
  $status: BlueprintStatus
  $dataSources: [BlueprintDataSourceInput!]
  $name: String!
  $themeId: ID) {
  upsertBlueprint(id: $id, description: $description, autoPublish: $autoPublish, publishLimitSecurity: $publishLimitSecurity, sentiment: $sentiment, status: $status, themeId: $themeId, name: $name, dataSources: $dataSources) {
     id
  }
}
`;

interface ListParams {
  page: number;
  take: number;
  filters: {
    name?: string;
    status?: BlueprintStatus;
    autoPublish?: string;
  };
  sort: {
    field: string;
    direction: Direction;
  };
}

export interface BlueprintResponseModel {
  id: string;
  name: string;
  description: string;
  sentiment: string;
  autoPublish: boolean;
  status: BlueprintStatus;
  publishingLimits: {
    security: number;
  };
  updatedAt: Date;
  theme: {
    id: string;
    title: string;
  };
  dataSources: {
    type: DataSourceType;
    criteria: string;
  }[];
  templates: {
    pageInfo: {
      totalCount: number;
    };
    nodes: {
      id: string;
      title: string;
      status: string;
    }[];
  };
  articles: {
    pageInfo: {
      totalCount: number;
    };
    nodes: {
      id: string;
      blocks: Block[];
      updatedAt: Date;
      author: {
        firstName: string;
        lastName: string;
      };
    }[];
  };
}

interface BlueprintResult {
  blueprint: BlueprintResponseModel;
}

interface ThemeMutationResult {
  upsertBlueprint: BlueprintResponseModel;
}

interface ListResult {
  blueprints: {
    pageInfo: {
      currentPage: number;
      totalPages: number;
      totalCount: number;
    };
    nodes: BlueprintResponseModel[];
  };
}

export class BlueprintService implements IEntityController<Blueprint> {
  constructor(
    private readonly client: StockopediaClient,
    private readonly themesService: ThemeService,
    private readonly mapper: IMapper<BlueprintResponseModel, Blueprint>,
  ) {}

  async archive(params: Blueprint): Promise<DataResponse<MutationResult>> {
    const { data, errors } = await gqlResult(() =>
      this.client.query<ThemeMutationResult>({
        query: UPSERT_MUTATION,
        variables: {
          name: params.name,
          description: params.description,
          id: params.id,
          publishLimitSecurity: Number(params.publishLimitSecurity),
          autoPublish: Boolean(params.autoPublish),
          status: BlueprintStatus.ARCHIVED,
          themeId: params.theme!.id,
        },
      }),
    );

    return {
      errors,
      data: data?.upsertBlueprint,
    };
  }

  async unarchive(params: Blueprint): Promise<DataResponse<MutationResult>> {
    const { data, errors } = await gqlResult(() =>
      this.client.query<ThemeMutationResult>({
        query: UPSERT_MUTATION,
        variables: {
          name: params.name,
          description: params.description,
          id: params.id,
          publishLimitSecurity: Number(params.publishLimitSecurity),
          autoPublish: Boolean(params.autoPublish),
          status: BlueprintStatus.ACTIVE,
          themeId: params.theme!.id,
        },
      }),
    );

    return {
      errors,
      data: data?.upsertBlueprint,
    };
  }

  async get(id: string): Promise<DataResponse<Blueprint>> {
    const { data, errors } = await gqlResult(() =>
      this.client.query<BlueprintResult>({
        query: BLUEPRINT_QUERY,
        variables: {
          id,
        },
      }),
    );

    return {
      errors,
      data: this.mapper.map(data!.blueprint),
    };
  }

  async list(params: ListParams): Promise<DataResponse<Blueprint[]>> {
    const { data, errors } = await gqlResult(() =>
      this.client.query<ListResult>({
        query: BLUEPRINTS_QUERY,
        variables: {
          paging: {
            take: params.take,
            page: params.page,
          },
          filters: {
            ...params.filters,
            autoPublish: convertAutoPublish(params.filters?.autoPublish),
          },
          sort: makeSortParams(params.sort),
        },
      }),
    );

    return {
      data: data!.blueprints.nodes.map(this.mapper.map.bind(this.mapper)),
      paging: data?.blueprints.pageInfo,
      errors,
    };
  }

  async listThemes(params: ThemeListParams): Promise<DataResponse<Theme[]>> {
    return this.themesService.list(params);
  }

  async save(params: Blueprint): Promise<DataResponse<MutationResult>> {
    const { data, errors } = await gqlResult(() =>
      this.client.query<ThemeMutationResult>({
        query: UPSERT_MUTATION,
        variables: {
          name: params.name,
          description: params.description,
          id: params.id,
          publishLimitSecurity: Number(params.publishLimitSecurity),
          autoPublish: Boolean(params.autoPublish),
          themeId: params.theme!.id,
          dataSources: [
            {
              type: DataSourceType.SCREENER,
              criteria: params.dataSourceQuery,
            },
          ],
        },
      }),
    );

    return {
      errors,
      data: data?.upsertBlueprint,
    };
  }
}

const convertAutoPublish = (value?: string) => {
  return value !== undefined && value.length > 0 ? value === "true" : undefined;
};
