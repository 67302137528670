import { Textarea as BuffetTextArea } from '@buffetjs/core'
import React from 'react'

interface Props {
  value: string
  name: string
}

export const ReadonlyTextArea = ({ value, name }: Props) => {
  return <BuffetTextArea name={name} disabled value={value} />
}
