import { Text } from "@buffetjs/core";
import { Author } from "modules/cybertorial/authors/services/model";
import React from "react";
import styled from "styled-components";

interface Props {
  author: Author;
}

const AuthorThumbnail = styled.img`
  width: 64px;
  height: 64px;
`;

export const PreviewAuthor: React.FC<Props> = ({ author }) => {
  return (
    <>
      {!!author.image && (
        <AuthorThumbnail src={author.image} alt={author.fullName()} />
      )}
      <Text>By {author.fullName()}</Text>
    </>
  );
};
