export const EXCHANGE_SEGMENTS = [
  {
    value: "LON",
    display: "LSE Main Market",
  },
  {
    value: "SEA",
    display: "LSE AIM Market",
  },
  {
    value: "LSS",
    display: "LSE SEATS Market",
  },
  {
    value: "ISD",
    display: "NEX Securities Exchange",
  },
];
