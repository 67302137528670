import {
  DependencyContainer,
  InjectionToken,
  injectable as baseInjectable,
  instanceCachingFactory,
  container as tsContainer,
} from "tsyringe";

import { Logger } from "./logger";
import { Module } from "./types";

declare type constructor<T> = {
  new (...args: any[]): T;
};

const LOGGER = Symbol("Logger");

export const injectable = baseInjectable;

export class Container {
  private readonly tsContainer: DependencyContainer;

  constructor() {
    this.tsContainer = tsContainer;
  }

  registerValue<T>(token: InjectionToken<T>, value: T) {
    this.tsContainer.register<T>(token, {
      useValue: value,
    });
    return this;
  }

  registerFactorySingleton<T>(
    token: InjectionToken<T>,
    factory: (c: Container) => T,
  ) {
    this.tsContainer.register<T>(token, {
      useFactory: instanceCachingFactory((_) => factory(this)),
    });
    return this;
  }

  registerModule<T>(token: string, moduleBuilder: (c: Container) => Module<T>) {
    this.registerFactorySingleton(token, (c) => moduleBuilder(c));
    return this;
  }

  registerFactory<T>(token: InjectionToken<T>, factory: (c: Container) => T) {
    this.tsContainer.register<T>(token, {
      useFactory: (_) => factory(this),
    });
    return this;
  }

  registerClass<T>(clazz: constructor<T>) {
    this.tsContainer.register<T>(clazz, {
      useClass: clazz,
    });
    return this;
  }

  registerLogger(logger: Logger): Container {
    this.tsContainer.register<Logger>(LOGGER, {
      useValue: logger,
    });

    return this;
  }

  resolve<T>(token: InjectionToken<T>): T {
    return this.tsContainer.resolve<T>(token);
  }

  resolveLogger(): Logger {
    return this.tsContainer.resolve<Logger>(LOGGER);
  }
}

export const container = new Container();
