import React, { createContext, useContext } from 'react'

interface State {
  by?: string[]
  value: string
  onSearch: (value: string) => void
}
const SearchContext = createContext<State>({} as any)

export const useSearchContext = () => useContext(SearchContext)

export const SearchProvider = ({
  value,
  children
}: {
  value: State
  children: React.ReactNode
}) => {
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}
