import React from "react";
import styled from "styled-components";
import { Text } from "@buffetjs/core";
import { useAssetManager } from "../hooks";
import { Spacer } from "./lib/spacer";
import { SelectedTile } from "./selected-tile";

const Container = styled.div`
  padding: 3px;
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SelectedAssetsView: React.FC = () => {
  const selectedAssets = useAssetManager((s) => s.selectedAssets);

  return (
    <React.Fragment>
      <Container>
        <Text fontWeight="bold">Selected</Text>
        <Spacer />
        <Grid>
          {selectedAssets.map((asset, index) => (
            <SelectedTile key={index} asset={asset} />
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
};
