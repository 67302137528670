import { Select as BuffetSelect } from '@buffetjs/core'
import React from 'react'

interface EnumProps {
  value: string
  name: string
  onChange: (v: string) => void
}

export const ArraySelectInput = (
  options: string[],
  defaultValue: string = ''
) => ({ value, onChange, name }: EnumProps) => {
  return (
    <BuffetSelect
      name={name}
      onChange={({ target: { value } }) => {
        onChange(value)
      }}
      options={Array.from(new Set([defaultValue, ...options]))}
      value={value ?? ''}
    />
  )
}

interface Props {
  value: string
  name: string
  onChange: (v: string) => void
  options: string[]
  defaultValue?: string
}

export const SelectFromArrayInput = ({
  options,
  defaultValue = '',
  value,
  onChange,
  name
}: Props) => {
  return (
    <BuffetSelect
      name={name}
      onChange={({ target: { value } }) => {
        onChange(value)
      }}
      options={Array.from(new Set([defaultValue, ...options]))}
      value={value ?? ''}
    />
  )
}
