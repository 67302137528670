import { StockopediaClient } from "clients/stockopedia";
import { Container } from "framework";

import { AuthorsService } from "../authors/services/authors.service";
import { BlueprintService } from "../blueprints";
import { templateMapper, templatePreviewMapper } from "../mappers";
import { TEMPLATES_MODULE_TOKEN, templatesModule } from "./module";
import { TemplateService } from "./services/templates.service";

declare module "framework/container" {
  interface Container {
    registerTemplateModule(): Container;
  }
}

Container.prototype.registerTemplateModule = function () {
  this.registerFactorySingleton(
    TemplateService,
    (c) =>
      new TemplateService(
        c.resolve(StockopediaClient),
        c.resolve(AuthorsService),
        c.resolve(BlueprintService),
        templateMapper,
        templatePreviewMapper,
      ),
  ).registerModule(TEMPLATES_MODULE_TOKEN, templatesModule);

  return this;
};
