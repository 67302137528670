import { AssetManager, AssetSelectionType } from "@stockopedia/cms-am";
import { container } from "framework";
import { AssetManagerService } from "modules/admin/assets/services/assetManagerService";
import React from "react";

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const ImagePicker: React.FC<Props> = ({ value, onChange }) => (
  <AssetManager
    value={value ? [value] : []}
    onChange={([asset]) => onChange(asset)}
    options={{
      assetManagerService: container.resolve(AssetManagerService),
      selectionType: AssetSelectionType.Single,
      clickToEdit: true,
    }}
  />
);
