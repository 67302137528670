import {
  EntityContextProvider,
  EntityServiceProvider,
} from "@stockopedia/cms-ef";
import { Container } from "framework";
import { Module } from "framework/types";
import { urls } from "urls";

import { BlueprintService } from "./services/blueprints.service";
import { CreateArticleView, EditArticleView } from "./views/crud.view";
import { ListBlueprints } from "./views/list.view";

export const BLUEPRINTS_MODULE_TOKEN = "blueprints";

export const blueprintsModule = (c: Container): Module => ({
  id: BLUEPRINTS_MODULE_TOKEN,
  rootComponent: ({ children }) => (
    <EntityServiceProvider service={c.resolve(BlueprintService)}>
      <EntityContextProvider basePath={urls.cybertorial.BASE_PATH.url}>
        {children}
      </EntityContextProvider>
    </EntityServiceProvider>
  ),
  pages: {
    [urls.cybertorial.blueprints.create.url]: CreateArticleView,
    [urls.cybertorial.blueprints.edit.url]: EditArticleView,
    [urls.cybertorial.blueprints.root.url]: ListBlueprints,
  },
});
