import { Container } from "framework";
import { Module } from "framework/types";
import { urls } from "urls";

import { ControlPlaneContext } from "./hooks/use-control-plane";
import { CybertorialControlPlaneService } from "./services/control-plane.service";
import { ControlPlaneState, createControlPlaneState } from "./state";
import { ControlPlane } from "./view";

export const CYBERTORIAL_CONTROL_PLANE_MODULE =
  "cybertorial_control_plane_module";

export const cybertorialControlPlaneModule = (
  c: Container,
): Module<ControlPlaneState> => {
  const controlPlaneService = c.resolve(CybertorialControlPlaneService);
  const store = createControlPlaneState(controlPlaneService);

  return {
    id: CYBERTORIAL_CONTROL_PLANE_MODULE,
    store,
    onBeforeStart: () => {
      store.getState().loadDashboard();
    },
    rootComponent: ({ children }) => (
      <ControlPlaneContext.Provider value={store}>
        {children}
      </ControlPlaneContext.Provider>
    ),
    pages: {
      [urls.cybertorial.controlPlane.url]: ControlPlane,
    },
  };
};
