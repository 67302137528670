import { Button, Field, NumberInput, Padding } from "@stockopedia/cms-ui";
import { useState } from "react";

import { useControlPlane } from "../../hooks/use-control-plane";
import { Throughput as ThroughputModel } from "../../state/types";

export const Throughput = (throughput: ThroughputModel) => {
  const [dailyArticles, setDailyArticles] = useState(throughput.dailyArticles);
  const [publishingWindow, setPublishingWindow] = useState(
    throughput.publishingWindow,
  );
  const saveThroughput = useControlPlane((s) => s.saveThroughput);

  const handleSave = () => {
    saveThroughput({
      dailyArticles,
      publishingWindow,
      region: throughput.region,
    });
  };

  return (
    <form>
      <Field title="Target number of articles per day">
        <NumberInput
          value={dailyArticles}
          onChange={(v) => v >= 0 && setDailyArticles(v)}
          name="daily_limit"
        />
      </Field>
      <Field title="Publishing window (in hours)">
        <NumberInput
          value={publishingWindow}
          onChange={(v) => v >= 0 && setPublishingWindow(v)}
          name="publishing_window"
        />
      </Field>
      <Padding bottom size="standard">
        <p>
          This controls how long we have to publish articles after new data is
          recieved.
        </p>
      </Padding>
      <Button type="button" color="primary" onClick={handleSave}>
        Update
      </Button>
    </form>
  );
};
