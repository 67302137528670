import { StockopediaClient } from "clients/stockopedia";
import { Container } from "framework";

import { blueprintMapper } from "../mappers";
import { ThemeService } from "../themes/services/themes.service";
import { BLUEPRINTS_MODULE_TOKEN, blueprintsModule } from "./module";
import { BlueprintService } from "./services/blueprints.service";

declare module "framework/container" {
  interface Container {
    registerBlueprintsModule(): Container;
  }
}

Container.prototype.registerBlueprintsModule = function () {
  this.registerFactorySingleton(
    BlueprintService,
    (c) =>
      new BlueprintService(
        c.resolve(StockopediaClient),
        c.resolve(ThemeService),
        blueprintMapper,
      ),
  ).registerModule(BLUEPRINTS_MODULE_TOKEN, blueprintsModule);

  return this;
};
