import { InputText } from '@buffetjs/core'
import React from 'react'

import { useSearchContext } from './context'

export const Search = () => {
  const { by, onSearch, value } = useSearchContext()

  if (!by || !by.length) {
    return null
  }

  return (
    <InputText
      name='input'
      onChange={({ target: { value } }) => {
        onSearch(value)
      }}
      placeholder={by.join(', ')}
      type='search'
      value={value}
    />
  )
}
