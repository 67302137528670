import { LoadingState } from "shared/types";
import create from "zustand";

import { UserService } from "../services";
import { AuthState, AuthValues } from "./types";

export const defaultInitialState: AuthValues = {
  loadingState: LoadingState.waiting,
};

export const createAuthState = (
  userService: UserService,
  initialState?: Partial<AuthValues>,
) =>
  create<AuthState>((set, self) => ({
    ...{
      ...defaultInitialState,
      ...initialState,
    },
    loadUser: async () => {
      set({
        loadingState: LoadingState.waiting,
      });

      try {
        const user = await userService.getUser();

        set({
          loadingState: LoadingState.loaded,
          user,
        });
      } catch (e) {
        set({
          loadingState: LoadingState.loaded,
        });
      }
    },
    logout: async () => {
      await userService.signoutRedirect();
      set({ user: undefined });
    },
    signinRedirect: () => {
      if (!self().user) {
        userService.signinRedirect();
      }
    },
    signinAsync: async () => {
      const user = await userService.signinRedirectCallback();
      set({ user });
    },
    silentSigninRedirect: async () => {
      const user = await userService.silentSigninRedirect();
      set({ user });
    },
    signoutRedirectCallback: () => {
      userService.signinRedirectCallback();
    },
  }));
