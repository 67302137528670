export const ECONOMIC_SECTORS = [
  { value: "50", display: "Energy" },
  { value: "52", display: "Industrials" },
  { value: "53", display: "Consumer Cyclicals" },
  { value: "54", display: "Consumer Defensives" },
  { value: "55", display: "Financials" },
  { value: "56", display: "Healthcare" },
  { value: "57", display: "Technology" },
  { value: "58", display: "Telecoms" },
  { value: "59", display: "Utilities" },
];
