import { Container } from "framework";
import { Module } from "framework/types";
import { UserService } from "modules/auth/services/user/service";
import { AuthState, createAuthState } from "modules/auth/state";
import { urls } from "urls";

import { AUTH_MODULE_TOKEN } from "./index";
import { Login } from "./pages/login";
import { Logout } from "./pages/logout";
import { SigninOidc } from "./pages/signin-oidc";
import { SignoutOidc } from "./pages/signout-oidc";
import { SilentSigninOidc } from "./pages/silent-signin-oidc";

export const authModule = (c: Container): Module<AuthState> => {
  const store = createAuthState(c.resolve(UserService));

  return {
    id: AUTH_MODULE_TOKEN,
    store,
    onBeforeStart: () => {
      store.getState().loadUser();
    },
    pages: {
      [urls.auth.signedInOidc.url]: SigninOidc,
      [urls.auth.signoutCallback.url]: SignoutOidc,
      [urls.auth.silentSignedInOidc.url]: SilentSigninOidc,
      [urls.auth.logout.url]: Logout,
      [urls.auth.login.url]: Login,
    },
  };
};
