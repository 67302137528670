import {
  EntityContextProvider,
  EntityServiceProvider,
} from "@stockopedia/cms-ef";
import { Container } from "framework";
import { Module } from "framework/types";
import { urls } from "urls";

import { TemplatesContext } from "./hooks/use-templates";
import { PreviewTemplateView } from "./preview/preview.view";
import { TemplateService } from "./services/templates.service";
import { createTemplatesState } from "./state";
import { CreateView, EditView } from "./views/crud.view";
import { ListView } from "./views/list.view";

export const TEMPLATES_MODULE_TOKEN = "templates";

export const templatesModule = (c: Container): Module => {
  const templateService = c.resolve(TemplateService);

  return {
    id: TEMPLATES_MODULE_TOKEN,
    rootComponent: ({ children }) => (
      <EntityServiceProvider service={templateService}>
        <EntityContextProvider basePath={urls.cybertorial.BASE_PATH.url}>
          <TemplatesContext.Provider
            value={createTemplatesState(templateService)}
          >
            {children}
          </TemplatesContext.Provider>
        </EntityContextProvider>
      </EntityServiceProvider>
    ),
    pages: {
      [urls.cybertorial.templates.preview.url]: PreviewTemplateView,
      [urls.cybertorial.templates.create.url]: CreateView,
      [urls.cybertorial.templates.edit.url]: EditView,
      [urls.cybertorial.templates.root.url]: ListView,
    },
  };
};
