import React from "react";
import { TemplatePreview } from "../../services/preview.model";

interface Props {
  template: TemplatePreview;
}

export const PreviewBody: React.FC<Props> = ({ template }) => {
  return (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: template.content }} />

      {!!template.callToAction && (
        <div dangerouslySetInnerHTML={{ __html: template.callToAction }} />
      )}
    </React.Fragment>
  );
};
