import { StockopediaClient } from "clients/stockopedia";
import { Container } from "framework";

import { themeMapper } from "../mappers";
import { THEMES_MODULE_TOKEN, themesModule } from "./module";
import { ThemeService } from "./services/themes.service";

declare module "framework/container" {
  interface Container {
    registerThemeModule(): Container;
  }
}

Container.prototype.registerThemeModule = function () {
  this.registerFactorySingleton(
    ThemeService,
    (c) => new ThemeService(c.resolve(StockopediaClient), themeMapper),
  ).registerModule(THEMES_MODULE_TOKEN, themesModule);

  return this;
};
