import React from "react";
import styled from "styled-components";
import { Text } from "@buffetjs/core";

import { TemplatePreview } from "../../services/preview.model";

interface Props {
  template: TemplatePreview;
}

const HeaderImage = styled.img`
  width: 100%;
  max-height: 200px;
`;

export const PreviewHeader: React.FC<Props> = ({ template }) => {
  return (
    <React.Fragment>
      {!!template.image && (
        <HeaderImage src={template.image} alt={template.title} />
      )}
      <Text>Last updated {template.updatedAt}</Text>
    </React.Fragment>
  );
};
