import { StockopediaClient } from "clients/stockopedia";
import { Container } from "framework";

import { authorMapper } from "../mappers";
import { AUTHORS_MODULE_TOKEN, authorsModule } from "./module";
import { AuthorsService } from "./services/authors.service";

declare module "framework/container" {
  interface Container {
    registerAuthorsModule(): Container;
  }
}

Container.prototype.registerAuthorsModule = function () {
  this.registerFactorySingleton(
    AuthorsService,
    (c) => new AuthorsService(c.resolve(StockopediaClient), authorMapper),
  ).registerModule(AUTHORS_MODULE_TOKEN, authorsModule);

  return this;
};
