import { StockopediaClient } from "clients/stockopedia";
import { Container } from "framework";

import { RatiosClient } from "./client";
import { LinksDataSource, RatiosApiDataSource } from "./datasource";

declare module "framework/container" {
  interface Container {
    registerRatios(): Container;
  }
}

Container.prototype.registerRatios = function () {
  this.registerFactory(
    RatiosApiDataSource,
    (c) => new RatiosApiDataSource(c.resolve(StockopediaClient)),
  );
  this.registerClass(LinksDataSource);
  this.registerFactory(
    RatiosClient,
    (c) =>
      new RatiosClient([
        c.resolve(RatiosApiDataSource),
        c.resolve(LinksDataSource),
      ]),
  );

  return this;
};
