import { Media, Pencil as ManageTagsIcon } from "@buffetjs/icons";
import React from "react";
import styled from "styled-components";

import { Asset, AssetSelectionType } from "../models";
import { useAssetManager, useSelectionType } from "../hooks";
import { NewItemField } from "./lib/new-item-field";
import { Paper } from "./lib/paper";
import { createThumbnailClass } from "./lib/thumbnail";
import { Spacer } from "./lib/spacer";
import { compareAssets } from "../utils/compare-assets";

interface AssetTileProps {
  asset: Asset;
}

const height = "100px";

const ContainerOuter = styled.div`
  margin: var(--padding-xs);
`;

const ContainerInner = styled.div`
  height: ${height};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

const NameAndEdit = styled.div`
  display: flex;
`;

const EditIconContainer = styled.div`
  padding-top: 5px;
`;

const Thumbnail = createThumbnailClass("100%", height);

export const AssetTile: React.FC<AssetTileProps> = ({ asset }) => {
  const updateAssetName = useAssetManager((s) => s.updateAssetName);
  const selectedAssets = useAssetManager((s) => s.selectedAssets);
  const selectAsset = useAssetManager((s) => s.selectAsset);
  const selectAssetExclusively = useAssetManager(
    (s) => s.selectAssetExclusively,
  );
  const deselectAsset = useAssetManager((s) => s.deselectAsset);
  const editAsset = useAssetManager((s) => s.editAsset);

  const [assetName, setAssetName] = React.useState(asset.assetName);
  const [edittingName, setEdittingName] = React.useState(false);

  const selectionType = useSelectionType();

  const isSelected = !!selectedAssets.find((a) => compareAssets(a, asset));

  const handleNameChange = (newName: string) => {
    setEdittingName(false);
    setAssetName(newName);

    updateAssetName(asset, newName);
  };

  const handleCancelNameChange = () => {
    setEdittingName(false);
  };

  const handleClick = () => {
    if (selectionType === AssetSelectionType.None) {
      return;
    }

    if (isSelected) {
      deselectAsset(asset);
    } else {
      if (selectionType === AssetSelectionType.Single) {
        selectAssetExclusively(asset);
      } else {
        selectAsset(asset);
      }
    }
  };

  const handleEditClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    e.stopPropagation();

    editAsset(asset);
  };

  return (
    <ContainerOuter
      onClick={handleClick}
      style={{
        boxShadow: isSelected ? "0px 0px 5px 0px #3D2EC7" : "none",
      }}
    >
      <Paper clickable>
        <ContainerInner>
          {asset.mimeType!.startsWith("image") ? (
            <Thumbnail style={{ backgroundImage: `url(${asset.url})` }} />
          ) : (
            <Media fill="black" />
          )}

          <NameAndEdit>
            {!edittingName && (
              <p onClick={() => setEdittingName(true)}>{assetName}</p>
            )}

            {edittingName && (
              <NewItemField
                defaultValue={assetName}
                onChange={handleNameChange}
                onCancel={handleCancelNameChange}
              />
            )}

            <Spacer />

            <EditIconContainer>
              <abbr title="Manage Tags">
                <a onClick={handleEditClick}>
                  <ManageTagsIcon />
                </a>
              </abbr>
            </EditIconContainer>
          </NameAndEdit>
        </ContainerInner>
      </Paper>
    </ContainerOuter>
  );
};
