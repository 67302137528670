import { Grouping, Operator } from "@stockopedia/screener-query-sdk";
import { Ratio, RatioUnit } from "shared/components/ratio-picker/types";

export enum AdvancedType {
  Median = "median",
  Average = "average",
  Rank = "rank",
  Comparison = "comparison",
}

const numberOptions = [
  {
    display: "Greater Than",
    value: Operator.GREATER_THAN,
  },
  {
    display: "Greater Than or equals",
    value: Operator.GREATER_THAN_OR_EQUAL_TO,
  },
  {
    display: "Less than",
    value: Operator.LESS_THAN,
  },
  {
    display: "Less than or equals",
    value: Operator.LESS_THAN_OR_EQUAL_TO,
  },
  {
    display: "Equals",
    value: Operator.EQUALS,
  },
  {
    display: "Not equals",
    value: Operator.NOT_EQUALS,
  },
];

export const OPERATOR_OPTIONS = {
  string: [
    {
      display: "Includes",
      value: Operator.IN,
    },
    {
      display: "Excludes",
      value: Operator.NOT_IN,
    },
  ],
  number: numberOptions,
  int: numberOptions,
  double: numberOptions,
};

export class Subject {
  field!: string;
  dataType!: string;
  name!: string;
  modifier?: string;
  unit?: RatioUnit;

  constructor(ratio: Ratio) {
    this.dataType = ratio.dataType;
    this.modifier = ratio.modifier;
    this.name = ratio.name;
    this.field = ratio.field;
    this.unit = ratio.unit;
  }
}

export class RuleState {
  public readonly advancedType?: AdvancedType;
  public readonly advancedComparisonRatio?: Subject;
  public readonly subject?: Subject;
  public readonly comparisonNumber: number = 0;
  public readonly operator: Operator = Operator.GREATER_THAN;
  public readonly stringComparisonList: string[] = [];
  public readonly advancedComparisonType?: Grouping = Grouping.Market;

  static empty() {
    return new RuleState();
  }

  static isValid(state: RuleState) {
    const isStringComparison = () => {
      return (
        state.operator === Operator.IN || state.operator === Operator.NOT_IN
      );
    };
    if (state.advancedType) {
      return (
        state.subject &&
        (state.stringComparisonList.length ||
          state.advancedComparisonType ||
          state.advancedComparisonRatio)
      );
    }
    if (isStringComparison()) {
      return state.subject && state.stringComparisonList.length;
    }
    return state.subject;
  }
}
