import { IDataSource } from "./datasource";
import { Category } from "./types";

export interface RatiosResponse {
  categories: {
    nodes: Category[];
  };
}

export class RatiosClient {
  constructor(private readonly dataSources: IDataSource[]) {}

  async getRatios(): Promise<Category[]> {
    const results = await Promise.all(this.dataSources.map((d) => d.query()));

    return results.flat();
  }
}
