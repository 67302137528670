import { EditorState } from "@stockopedia/rte";
import { Author } from "modules/cybertorial/authors";
import { Tag } from "modules/cybertorial/tags";
import { Template } from "modules/cybertorial/templates";

export enum ArticleStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  READY = "READY",
  ARCHIVED = "ARCHIVED",
}

export interface Block {
  label: string;
  content: string | EditorState;
}

export class Article {
  id!: string;

  blocks: Block[] = [
    { label: "title", content: "" },
    { label: "image", content: "" },
    { label: "content", content: "" },
  ];

  tags?: Tag[];

  author?: Author;

  template?: Template;

  status!: ArticleStatus;

  updatedAt!: string;

  static empty() {
    return new Article();
  }
}

export class ArticleBuilder {
  private readonly article: Article = Article.empty();

  withAuthor(author?: Author) {
    this.article.author = author;

    return this;
  }

  build() {
    return this.article;
  }
}
