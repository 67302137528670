import { Textarea as BuffetTextArea } from '@buffetjs/core'
import React from 'react'

interface Props {
  value: string
  name: string
  onChange: (v: string) => void
}

export const TextArea = ({ onChange, value, name }: Props) => {
  return (
    <BuffetTextArea
      name={name}
      onChange={({ target: { value } }) => onChange(value)}
      value={value}
    />
  )
}
