import Add from "@material-ui/icons/Add";
import Check from "@material-ui/icons/Check";
import { Column, Loading, Margin, Padding, Row } from "@stockopedia/cms-ui";
import { Divider, TextInput } from "@stockopedia/cms-ui";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { useRatioPickerContext } from "./context";
import { Category, Ratio } from "./types";
import { containsRatio, ratioEquals, sortRatios } from "./utils";

interface RatioDisplayProps {
  categories?: Category[];
}

const CategoriesContainer = styled.div`
  margin-right: var(--padding-sm);
  display: flex;
  flex-direction: row;
  height: calc(100% - 50px);

  ul {
    width: 200px;
    overflow-y: scroll;
    height: calc(100% - 80px);
  }

  li {
    margin-bottom: var(--padding-sm);
    background: var(--color-background);
    padding: var(--padding-sm);
    border-radius: 2px;
    cursor: pointer;
  }

  li.active {
    background: var(--color-primary);
    color: white;
  }

  .full-height {
    height: 100%;
  }
`;

interface CategoryProps {
  categories: Category[];
  title: string;
}
const Categories = ({ categories, title }: CategoryProps) => {
  const [selectedCategory, setSelectedCategory] = useState(0);
  const { selected } = useRatioPickerContext();

  useEffect(() => {
    categories.forEach((x, index) => {
      if (containsRatio(x, selected)) {
        setSelectedCategory(index);
      }
    });
  }, [selected, categories]);

  const renderRatios = () => {
    if (categories[selectedCategory]?.ratios?.length > 0) {
      return (
        <RatiosContainer>
          <Padding left right>
            <>
              <p
                dangerouslySetInnerHTML={{
                  __html: categories[selectedCategory].description,
                }}
              />
              <Divider />
              {sortRatios(categories[selectedCategory].ratios).map(
                (ratio, index) => (
                  <RatioDisplay key={index} value={ratio} />
                ),
              )}
            </>
          </Padding>
        </RatiosContainer>
      );
    }

    return null;
  };

  const renderSubCategories = () => {
    if (categories[selectedCategory]?.categories?.length > 0) {
      return (
        <Padding left className="full-height">
          <Categories
            title="Sub Categories"
            categories={categories[selectedCategory].categories}
            key={categories[selectedCategory].name}
          />
        </Padding>
      );
    }
    return null;
  };

  return (
    <CategoriesContainer>
      <Column>
        <h2>{title}</h2>
        <ul>
          {categories.map((c, index) => (
            <li
              className={index === selectedCategory ? "active" : ""}
              key={index}
              onClick={() => setSelectedCategory(index)}
            >
              {c.name}
            </li>
          ))}
        </ul>
      </Column>
      <Column>
        {renderRatios()}
        {renderSubCategories()}
      </Column>
    </CategoriesContainer>
  );
};

const RatiosContainer = styled.div`
  margin-top: 30px;
  overflow-y: scroll;
  height: 100%;
`;

const RatiosPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const PaddedContainer = styled(Padding)`
  height: 100%;
`;

const SearchContainer = styled.div`
  height: calc(100% - 100px);
`;

export const RatiosDisplay = ({ categories }: RatioDisplayProps) => {
  const { search, searchedRatios } = useRatioPickerContext();
  if (!categories) {
    return <Loading />;
  }

  const renderSearch = () => {
    return (
      <SearchContainer>
        <RatiosContainer>
          <Padding left right>
            {searchedRatios?.map((ratio, index) => (
              <RatioDisplay key={index} value={ratio} />
            ))}
          </Padding>
        </RatiosContainer>
      </SearchContainer>
    );
  };

  const renderCategories = () => {
    return (
      <RatiosPickerContainer>
        <Categories title="Categories" categories={categories} />
      </RatiosPickerContainer>
    );
  };

  return (
    <PaddedContainer>
      <SearchBox />
      <h2>Available Ratios</h2>
      {search ? renderSearch() : renderCategories()}
    </PaddedContainer>
  );
};

const SearchBox = () => {
  const {
    actions: { setSearch },
    search,
  } = useRatioPickerContext();
  return (
    <TextInput
      name="search"
      icon="search"
      onChange={setSearch}
      value={search}
    />
  );
};

const PlusButton = styled.button`
  cursor: pointer;
  border-radius: 100%;
  background: var(--color-background);
  color: #000;
  border: none;
  height: 28px;
  width: 28px;

  &[disabled] {
    background: var(--color-success);
    color: #fff;
  }
`;

const RatioFieldSpan = styled.span`
  background: yellow;
`;

const RatioDisplay = ({ value: ratio }: { value: Ratio }) => {
  const { actions: pickerActions, selected } = useRatioPickerContext();
  const isSelected = ratioEquals(ratio, selected);

  return (
    <Margin bottom>
      <Margin bottom size="xs">
        <Row className="items-center">
          <h3>
            {ratio.name}{" "}
            {ratio.modifier && ratio.modifier !== "NULL" ? ratio.modifier : ""}
          </h3>
          <Padding left className="auto-width">
            <PlusButton
              disabled={isSelected}
              onClick={() => {
                pickerActions.onSelected(ratio);
                pickerActions.showModal(false);
              }}
            >
              {isSelected ? <Check /> : <Add />}
            </PlusButton>
          </Padding>
        </Row>
        <RatioFieldSpan>{ratio.field}</RatioFieldSpan>
      </Margin>
      <p dangerouslySetInnerHTML={{ __html: ratio.description }} />
    </Margin>
  );
};
