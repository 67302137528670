import React from 'react'
import styled from 'styled-components'

import { H4 } from '../heading/index'

const StyledCard = styled.div<{ noPadding?: boolean }>`
  width: 100%;
  padding: ${(props) => (props.noPadding ? 'none' : 'var(--padding-standard)')};
  position: relative;
  border-radius: 3px;
  box-shadow: 0 2px 4px #e3e9f3;
  background: white;
`

const StyledH4 = styled(H4)`
  margin-top: 0;
  margin-bottom: var(--padding-standard);
`

interface Props {
  title?: string
  noPadding?: boolean
  children?: JSX.Element
}

export const Card = ({ children, title, noPadding }: Props) => {
  return (
    <StyledCard noPadding={noPadding}>
      {title && <StyledH4>{title}</StyledH4>}
      {children}
    </StyledCard>
  )
}
