import { EntityListManger } from "@stockopedia/cms-ef";
import { Loading } from "@stockopedia/cms-ui";
import { useHistory } from "react-router";
import { urls } from "urls";

export const ListView = () => {
  const history = useHistory();
  return (
    <EntityListManger
      columns={[
        {
          key: "title",
          title: "Title",
          projection: (value) => value.title,
        },
        {
          key: "updatedAt",
          title: "Updated At",
          projection: (value) => value.updatedAt,
        },
      ]}
      onError={() => {}}
      name="themes"
      loadingView={() => <Loading />}
      link={(item) => `${urls.cybertorial.themes.root.url}/${item.id}`}
      onCreate={() => history.push(`${urls.cybertorial.themes.create.url}`)}
      filters={[
        {
          field: "title",
          type: "search",
        },
      ]}
      sort={{
        fields: ["title", "updatedAt"],
        default: {
          field: "updatedAt",
          direction: "desc",
        },
      }}
    />
  );
};
