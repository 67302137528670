export const MARKET_CAP_GROUP = [
  {
    value: "1",
    display: "Large Cap",
  },
  {
    value: "2",
    display: "Mid Cap",
  },
  {
    value: "3",
    display: "Small Cap",
  },
  {
    value: "4",
    display: "Micro Cap",
  },
];
