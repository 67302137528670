import { Inputs } from '@buffetjs/custom'
import React from 'react'
import styled from 'styled-components'

interface Props {
  value: number
  name: string
  onChange: (value: number) => void
}

const StyledInput = styled.div`
  label {
    display: none;
  }
  div {
    padding-bottom: 0px;
  }
`

export const NumberInput = ({ onChange, value, name }: Props) => {
  return (
    <StyledInput>
      <Inputs
        name={name}
        type='number'
        onChange={({ target: { value } }) => onChange(value)}
        value={value}
      />
    </StyledInput>
  )
}
