import { OneToOneRelationalSelectList } from "@stockopedia/cms-ef";
import {
  EditEntityView,
  Entity,
  Field,
  Relation,
  RelationItem,
  useEntityService,
} from "@stockopedia/cms-ef";
import {
  Loading,
  ReadonlyTextArea,
  ReadonlyTextInput,
  SelectFromArrayInput,
  TextInput,
} from "@stockopedia/cms-ui";
import { Template } from "modules/cybertorial/templates";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { ImagePicker } from "shared/components/image-picker/image-picker";
import { RteEditor } from "shared/components/inputs/rte-editor";
import { enumKeys } from "shared/utils";
import { urls } from "urls";
import * as Yup from "yup";

import { ArticleInput, ArticleService } from "../services/articles.service";
import { Article, ArticleBuilder, ArticleStatus } from "../services/model";

const schema = Yup.object().shape({
  title: Yup.string().required(),
  content: Yup.lazy((val) =>
    typeof val === "string" ? Yup.string().required() : Yup.object().required(),
  ),
  image: Yup.string().required(),
});

interface Props {
  state: Article;
  context: "create" | "edit";
}

type FormState = ArticleInput & { callToAction?: string };

const View: React.FC<Props> = ({ state, context }) => {
  const service = useEntityService<ArticleService>();
  const history = useHistory();

  const formState = articleToFormState(state);
  const [template, setTemplate] = useState<Template>();

  const onChange = (
    values: {
      template?: Template;
    },
    setFieldValue: (name: string, value: any) => void,
  ) => {
    if (template !== values.template) {
      setTemplate(values.template);
      setFieldValue(
        "callToAction",
        values.template?.blueprint?.theme?.callToAction,
      );
    }
  };

  return (
    <Entity
      context={context}
      validationSchema={schema}
      onChange={onChange}
      actions={[
        {
          show: state.status !== ArticleStatus.ARCHIVED,
          scopes: ["edit", "create"],
          label: "Archive",
          color: "delete",
          onClick: () => {
            service.archive(formState);
            history.goBack();
          },
        },
        {
          show: state.status === ArticleStatus.ARCHIVED,
          scopes: ["edit", "create"],
          label: "Unarchive",
          color: "cancel",
          onClick: () => {
            service.unarchive(formState);
            history.goBack();
          },
        },
      ]}
      state={formState}
      onSubmit={async (values) => {
        await service.save(values);
        history.goBack();
      }}
      name="article"
      fields={[
        <Field required name="image" component={ImagePicker} width="full" />,
        <Field required name="title" component={TextInput} width="full" />,
        <Field required name="content" component={RteEditor} width="full" />,
        <Field
          name="callToAction"
          key="callToAction"
          component={ReadonlyTextArea}
          width="full"
        />,
        <Field
          required
          name="status"
          component={(props) => (
            <SelectFromArrayInput
              {...props}
              options={enumKeys(ArticleStatus)}
              defaultValue={ArticleStatus.DRAFT}
            />
          )}
          width="third"
        />,
        <Field name="updatedAt" component={ReadonlyTextInput} width="third" />,
      ]}
      relations={[
        <Relation
          name="author"
          component={(props) => (
            <OneToOneRelationalSelectList
              {...props}
              options={{
                getOptions: (params) =>
                  service.listAuthors({
                    ...params,
                    page: 0,
                    take: 200,
                    sort: {
                      field: "updatedAt",
                      direction: "desc",
                    },
                  }),
                searchFields: ["firstName", "lastName"],
              }}
            />
          )}
        />,
        context === "create" ? (
          <Relation
            name="template"
            component={(props) => (
              <OneToOneRelationalSelectList
                {...props}
                options={{
                  getOptions: (params) =>
                    service.listTemplates({
                      ...params,
                      page: 0,
                      take: 200,
                      sort: {
                        field: "updatedAt",
                        direction: "desc",
                      },
                    }),
                  searchFields: ["title"],
                }}
              />
            )}
          />
        ) : (
          <RelationItem
            name="template"
            link={(item) =>
              `${urls.cybertorial.templates.root.url}/${item?.id}`
            }
            columns={[
              {
                title: "Title",
                projection: (values) => values?.title,
                key: "title",
              },
            ]}
            item={state.template!}
          />
        ),
        <RelationItem
          name="blueprint"
          link={(item) => `${urls.cybertorial.blueprints.root.url}/${item?.id}`}
          columns={[
            {
              title: "Name",
              projection: (values) => values?.name,
              key: "name",
            },
          ]}
          item={template?.blueprint!}
        />,
      ]}
    />
  );
};

export const CreateArticleView = ({ location: { state } }) => {
  return (
    <View
      context={"create"}
      state={new ArticleBuilder().withAuthor(state?.author).build()}
    />
  );
};

export const EditArticleView = () => {
  const onError = useCallback((e) => {}, []);
  return (
    <EditEntityView
      onError={onError}
      loadingView={() => <Loading />}
      render={(state: Article) => <View context={"edit"} state={state} />}
    />
  );
};

const BLOCK_LABELS = ["title", "content", "image"];

const articleToFormState = (article: Article): FormState => {
  const blockFields = BLOCK_LABELS.reduce((formState, blockLabel) => {
    formState[blockLabel] =
      article.blocks.find(({ label }) => label === blockLabel)?.content || "";

    return formState;
  }, {});

  const { blocks, ...formStateWithoutBlocks } = article;

  const callToAction = article?.template?.blueprint?.theme?.callToAction;

  return {
    ...formStateWithoutBlocks,
    ...blockFields,
    callToAction,
  } as FormState;
};
