import { Container } from "framework";
import { Module } from "framework/types";
import { Redirect } from "react-router";
import { urls } from "urls";

import { Assets } from "./assets";
import { Dashboard } from "./dashboard";
import { Settings } from "./settings";

export const ADMIN_MODULE_TOKEN = "admin";

export const adminModule = (_: Container): Module => ({
  id: ADMIN_MODULE_TOKEN,
  pages: {
    [urls.admin.dashboard.url]: Dashboard,
    [urls.admin.settings.url]: Settings,
    [urls.admin.assetManager.url]: Assets,
    [urls.admin.root.url]: () => <Redirect to={urls.admin.dashboard.url} />,
  },
});
