import { Profile, User, UserManager } from "oidc-client";
import { User as UserModel } from "shared/models/user";

import { IUserService } from "./service.interface";

export class UserService implements IUserService {
  private user?: UserModel;

  constructor(private readonly userManager: UserManager) {}

  async getUser(): Promise<UserModel | undefined> {
    if (this.user) {
      return this.user;
    }

    try {
      const user = await this.userManager.getUser();
      return this.processUser(user);
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  signinRedirect(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  async silentSigninRedirect(): Promise<UserModel | undefined> {
    return this.processUser(await this.userManager.signinSilentCallback());
  }

  async signinRedirectCallback(): Promise<UserModel> {
    const user = await this.userManager.signinRedirectCallback();
    this.user = this.processUser(user)!;
    await this.userManager.storeUser(user);
    return this.user;
  }

  async signoutRedirect(): Promise<void> {
    const user = await this.getUser();

    if (!user) {
      return;
    }

    await this.userManager.revokeAccessToken();
    await this.userManager.clearStaleState();
    await this.userManager.removeUser();

    return this.userManager.signoutRedirect({ id_token_hint: user.idToken });
  }

  async signoutRedirectCallback(): Promise<{
    error?: string;
    error_description?: string;
    error_uri?: string;
    state?: any;
  }> {
    await this.userManager.clearStaleState();
    await this.userManager.removeUser();

    return this.userManager.signoutRedirectCallback();
  }

  processUser(user?: User | null): UserModel | undefined {
    if (!user) {
      return;
    }
    const profile = (user.profile as unknown) as Profile;

    return {
      username: profile.name!,
      roles: Array.isArray(profile.role) ? profile.role : [profile.role],
      token: user.access_token,
      idToken: user.id_token,
    };
  }
}
