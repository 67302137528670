import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Input = styled.input<{ error: boolean }>`
  outline: ${(props) => (props.error ? "2px solid #ff0000" : "inherit")};
`;

interface NewItemFieldProps {
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  onInput?: (value: string) => void;
  onChange: (value: string) => void;
  onCancel: () => void;
}

export const NewItemField: React.FC<NewItemFieldProps> = ({
  defaultValue = "",
  disabled = false,
  error = false,
  onInput = () => {},
  onChange,
  onCancel,
}) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    document.addEventListener("keyup", (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onCancel();
      }
    });
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setValue(e.target.value);
  };

  const handleInput = (
    e: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    onInput((e.target as any).value as string);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (value !== "") {
        onChange(value);
      } else {
        onCancel();
      }
    }
  };

  const handleBlur = () => {
    if (value.length > 0) {
      onChange(value);
    } else {
      onCancel();
    }
  };

  return (
    <Input
      error={error}
      value={value}
      disabled={disabled}
      onInput={handleInput}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      onBlur={handleBlur}
    />
  );
};
