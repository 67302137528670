import { RatiosFetcher } from "shared/components/ratio-picker/context";
import { DataSource } from "shared/components/ratio-picker/types";

import { ScreenRuleBuilder } from "./screen-builder";

interface Props {
  value: string;
  onChange: (val: string) => void;
}

export const ScreenRulesInput = ({ value, onChange }: Props) => (
  <RatiosFetcher filter={(r) => r.dataSource === DataSource.SCREENER}>
    {(ratios) => {
      if (!ratios?.length) {
        return <></>;
      }
      return (
        <ScreenRuleBuilder
          value={value}
          onChange={onChange}
          ratios={ratios ?? []}
        />
      );
    }}
  </RatiosFetcher>
);
