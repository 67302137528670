import { TagValue } from "..";
import { SearchResultValue } from "../components/lib/auto-complete-search";

export const searchTags = (
  term: string,
  tags: TagValue[],
): SearchResultValue[] =>
  tags
    ?.filter(
      (tagValue) =>
        tagValue.value
          .toLocaleLowerCase()
          .includes(term.trim().toLocaleLowerCase()) ||
        tagValue.group
          .toLocaleLowerCase()
          .includes(term.trim().toLocaleLowerCase()),
    )
    .map(
      (item, index) =>
        ({
          item,
          index,
        } as SearchResultValue),
    )!;
