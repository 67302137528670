import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { useAssetManager } from "../hooks";
import {
  AutoCompleteSearch,
  SearchResultValue,
} from "./lib/auto-complete-search";
import { searchTags } from "../utils";

export const SearchTags: React.FC = () => {
  const [searchString, setSearchString] = useState("");
  const handleSearchTags = useAssetManager((s) => s.handleSearchTags);

  const allTagValues = useAssetManager((s) => s.allTagValues);
  const loadAllTagValuesStatus = useAssetManager(
    (s) => s.loadAllTagValuesStatus,
  );
  const loadAllTagValues = useAssetManager((s) => s.loadAllTagValues);

  useEffect(() => {
    if (loadAllTagValuesStatus === null) {
      loadAllTagValues();
    }
  }, [loadAllTagValuesStatus]);

  const doAssetSearch = useDebouncedCallback(
    async (term: string) => await handleSearchTags(term),
    200,
  );

  const handleAssetSearch = (term: string) => {
    setSearchString(term);

    doAssetSearch(term);
  };

  const handleTagSearch = async (
    term: string,
  ): Promise<SearchResultValue[]> => {
    setSearchString(term);

    return searchTags(term, allTagValues!);
  };

  return (
    <AutoCompleteSearch
      value={searchString}
      placeholder="Search"
      closeOnSelect
      onInput={handleAssetSearch}
      isSelected={() => false}
      onSelectItem={(item) => handleAssetSearch(`"${item.value}"`)}
      onEnterClick={() => doAssetSearch(searchString)}
      onSearch={handleTagSearch}
    />
  );
};
