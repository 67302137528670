import { Padding, Remove, TextInput } from "@stockopedia/cms-ui";
import { uniqBy } from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";

import { useScreenRuleContext } from "./context";
import { COUNTRIES } from "./string-options/country";
import { ECONOMIC_SECTORS } from "./string-options/economic-sector";
import { EXCHANGE_SEGMENTS } from "./string-options/exchange-segment";
import { EXCHANGES } from "./string-options/exchanges";
import { INDICES } from "./string-options/indices";
import { INDUSTRY_GROUPS } from "./string-options/industry-group";
import { MARKET_CAP_GROUP } from "./string-options/market-cap-group";
import { REGIONS } from "./string-options/region";
import { RISK_RATING } from "./string-options/risk-rating";
import { STOCK_RANK_STYLES } from "./string-options/stock-rank-styles";

const makeFilteredOptions = (options: Option[], filter: string[]) => {
  return options.filter((x) => !filter?.includes(x.value));
};

export const StringComparison = () => {
  const options = useOptions();
  const {
    actions,
    subject,
    stringComparisonList = [],
  } = useScreenRuleContext();
  const [showList, setShowList] = useState(false);
  const [value, setValue] = useState<string>();
  const [fileredOptions, setFileredOptions] = useState(
    makeFilteredOptions(options, stringComparisonList),
  );
  const selection = options.filter((x) =>
    stringComparisonList.includes(x.value),
  );

  useEffect(() => {
    setFileredOptions(makeFilteredOptions(options, stringComparisonList));
  }, [stringComparisonList, options]);

  const handleChange = (value: string) => {
    setShowList(true);
    setValue(value);
    setFileredOptions(
      makeFilteredOptions(
        options.filter((x) =>
          x.display.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
        ),
        stringComparisonList,
      ),
    );
  };

  const handleBlur = () => {
    setShowList(false);
  };

  return (
    <InputContainer
      tabIndex={0}
      onBlur={() => {
        handleBlur();
      }}
    >
      <TextInput
        autoComplete="off"
        name={"relational-select"}
        placeholder={subject?.name}
        value={value}
        type="search"
        onChange={handleChange}
        onClick={() => setShowList(true)}
      />
      {showList && (
        <SelectList
          options={fileredOptions}
          onSelect={(item) => {
            const uniqueValues = uniqBy([...(selection ?? []), item], "value");
            actions.setStringComparisonList(uniqueValues.map((x) => x.value));
            setShowList(false);
          }}
        />
      )}
      <Selection
        values={selection}
        onRemove={(option) => {
          actions.setStringComparisonList(
            stringComparisonList.filter((x) => x !== option.value),
          );
        }}
      />
    </InputContainer>
  );
};

const useOptions = (): { display: string; value: string }[] => {
  const { subject } = useScreenRuleContext();

  return options[subject!.field];
};

//TODO - in future, these will come from an api
const options = {
  "region.country": COUNTRIES,
  "ratios._TRBCEconSectCode": ECONOMIC_SECTORS,
  "ratios._TRBCIndCode": INDUSTRY_GROUPS,
  "ratios._TRBCIndGrpCode": INDUSTRY_GROUPS,
  "region.region": REGIONS,
  "ratios._RiskRating": RISK_RATING,
  "ratios._MktCapGroup_gb": MARKET_CAP_GROUP,
  "ratios._StockRankStylePrev_zz": STOCK_RANK_STYLES,
  "ratios._StockRankStyle_zz": STOCK_RANK_STYLES,
  "ratios._ExchangeCode": EXCHANGES,
  "ratios._Indices": INDICES,
  "ratios._Exchange": EXCHANGE_SEGMENTS,
};

const InputContainer = styled.div`
  position: relative;
  width: 400px;

  & > ul {
  }

  &:focus {
    outline: none;
  }
`;

type Option = { display: string; value: string };

const StyledSelectList = styled.ul`
  position: absolute;
  width: 100%;
  background: #fff;
  padding-bottom: var(--padding-sm);
  z-index: 9999;
  max-height: 200px;
  overflow-y: scroll;
  box-shadow: 0 2px 4px #e3e9f3;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  li {
    cursor: pointer;
  }
`;

interface SelectListProps {
  options: Option[];
  onSelect: (value: Option) => void;
}
const SelectList = ({ options, onSelect }: SelectListProps) => {
  return (
    <StyledSelectList>
      {options.map((o, index) => {
        return (
          <li
            key={index}
            onMouseDown={(e) => {
              e.preventDefault();
              onSelect(o);
            }}
          >
            <Padding size="sm" top left right>
              {o.display}
            </Padding>
          </li>
        );
      })}
    </StyledSelectList>
  );
};

interface SelectionProps {
  values: Option[];
  onRemove: (index: Option) => void;
}

const IconButton = styled.button`
  background: transparent;
  border: none;
`;

const SelectionUl = styled.ul`
  margin-top: var(--padding-sm);

  & > li {
    display: flex;
    align-items: center;
    float: left;
    margin-right: var(--padding-xs);
    margin-bottom: var(--padding-xs);
    background: var(--color-background);
    border-radius: 10px;
    padding-top: var(--padding-xs);
    padding-bottom: var(--padding-xs);
    padding-left: var(--padding-sm);
    padding-right: var(--padding-sm);
  }
`;

const Selection = ({ values, onRemove }: SelectionProps) => {
  return values.length > 0 ? (
    <SelectionUl>
      {values.map((item, index) => {
        return (
          <li key={index}>
            {item.display}
            <IconButton type="button" onClick={() => onRemove(item)}>
              <Remove name="remove" />
            </IconButton>
          </li>
        );
      })}
    </SelectionUl>
  ) : (
    <></>
  );
};
