import { EntityListManger } from "@stockopedia/cms-ef";
import { Loading } from "@stockopedia/cms-ui";
import { useHistory } from "react-router";
import { urls } from "urls";

export const ListAuthors = () => {
  const history = useHistory();
  return (
    <EntityListManger
      columns={[
        {
          key: "id",
          title: "ID",
          projection: (value) => value.id,
        },
        {
          key: "firstName",
          title: "First Name",
          projection: (value) => value.firstName,
        },
        {
          key: "lastName",
          title: "Last Name",
          projection: (value) => value.lastName,
        },
      ]}
      onError={() => {}}
      name="authors"
      loadingView={() => <Loading />}
      link={(item) => `${urls.cybertorial.authors.root.url}/${item.id}`}
      onCreate={() => history.push(`${urls.cybertorial.authors.create.url}`)}
      filters={[
        {
          field: "firstName",
          type: "search",
        },
        {
          field: "lastName",
          type: "search",
        },
      ]}
      sort={{
        fields: ["firstName", "lastName"],
        default: {
          field: "firstName",
          direction: "desc",
        },
      }}
    />
  );
};
