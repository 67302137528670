import React from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export { Success, Fail, Pencil, Remove } from '@buffetjs/icons'

interface Props {
  color?: string
}

export const StyledPlusIcon = styled(FontAwesomeIcon)`
  background: transparent;
  font-size: 1.2rem;
  color: ${(props) => props.color ?? 'white'};
`

export const PlusIcon = (props: Props) => (
  <StyledPlusIcon {...props} icon={faPlus} />
)
