import "reflect-metadata";

import "./assets/index.css";

import { GlobalStyle } from "@buffetjs/styles";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { Fonts } from "./Fonts";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <>
    <GlobalStyle />
    <Fonts />
    <App />
  </>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
