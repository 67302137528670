import "modules/cybertorial/control-plane/container";
import "modules/cybertorial/authors/container";
import "modules/cybertorial/articles/container";
import "modules/cybertorial/blueprints/container";
import "modules/cybertorial/themes/container";
import "modules/cybertorial/templates/container";

import { StockopediaClient } from "clients/stockopedia";
import { Container } from "framework";
import { TagController } from "modules/cybertorial/tags";

declare module "framework/container" {
  interface Container {
    registerCybertorial(): Container;
  }
}

Container.prototype.registerCybertorial = function () {
  this.registerFactorySingleton(
    TagController,
    (c) => new TagController(c.resolve(StockopediaClient)),
  )
    .registerCybertorialControlPlane()
    .registerArticlesModule()
    .registerAuthorsModule()
    .registerBlueprintsModule()
    .registerThemeModule()
    .registerTemplateModule();

  return this;
};
