import { Article } from "modules/cybertorial/articles";
import { Template } from "modules/cybertorial/templates";
import { Theme } from "modules/cybertorial/themes";

export enum BlueprintStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum DataSourceType {
  SCREENER = "SCREENER",
}

export class Blueprint {
  id!: string;

  name!: string;

  description!: string;

  publishLimitSecurity!: number;

  autoPublish!: boolean;

  updatedAt!: string;

  status!: BlueprintStatus;

  theme?: Theme;

  templates?: Template[];

  dataSourceType!: DataSourceType;

  dataSourceQuery!: string;

  numTemplates!: number;

  articles?: Article[];

  numArticles!: number;

  static empty() {
    return new Blueprint();
  }
}

export class BlueprintBuilder {
  private readonly blueprint: Blueprint = Blueprint.empty();

  withTheme(theme?: Theme) {
    this.blueprint.theme = theme;

    return this;
  }

  build() {
    return this.blueprint;
  }
}
