import styled from "styled-components";

export const Button = styled.button<{ active?: boolean }>`
  cursor: pointer;
  border-radius: 8px;
  background: ${(props) =>
    props.active ? "var(--color-success)" : "var(--color-background)"};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  border: none;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
`;
