import create from "zustand";
import { TagValue } from "..";
import {
  LoadingState,
  IAssetManagerService,
  Asset,
  AssetEditMode,
} from "../models";
import { compareAssets } from "../utils/compare-assets";
import { AssetManagerState, AssetManagerValues } from "./types";

export const defaultInitialState: AssetManagerValues = {
  editMode: AssetEditMode.Viewing,
  tagGroups: null,
  tagValues: null,
  allTagValues: null,
  assetTagValues: null,
  loadAssetTagValuesStatus: null,
  loadTagGroupsStatus: null,
  addTagGroupStatus: null,
  loadTagValuesStatus: null,
  addTagValueStatus: null,
  loadAllTagValuesStatus: null,
  selectedTags: [],
  browserAssets: [],
  loadAssetsStatus: null,
  uploadStatus: null,
  updateAssetNameStatus: null,
  selectedAssets: [],
  selectedFileForUpload: null,
  showingSelected: false,
  edittingAsset: null,
};

export const createAssetManagerState = (
  amService: IAssetManagerService,
  initialState: Partial<AssetManagerValues>,
) =>
  create<AssetManagerState>((set, self) => ({
    ...{
      ...defaultInitialState,
      ...initialState,
    },

    setEditMode: (editMode: AssetEditMode) => set({ editMode }),

    handleSearchTags: async (term: string) => {
      try {
        set({ loadAssetsStatus: LoadingState.Loading });

        set({
          browserAssets: await amService.searchAssets(term),
          loadAssetsStatus: LoadingState.Success,
        });
      } catch {
        set({ loadAssetsStatus: LoadingState.Error });
      }
    },

    loadTagGroups: async () => {
      try {
        set({ loadTagGroupsStatus: LoadingState.Loading });
        set({
          tagGroups: await amService.loadTagGroups(),
          loadTagGroupsStatus: LoadingState.Success,
        });
      } catch {
        set({ loadTagGroupsStatus: LoadingState.Error });
      }
    },

    addTagGroup: async (groupName: string) => {
      set({ addTagGroupStatus: LoadingState.Loading });

      try {
        set({ addTagGroupStatus: LoadingState.Loading });
        await amService.addTagGroup(groupName);

        set({
          tagGroups: await amService.loadTagGroups(),
          addTagGroupStatus: LoadingState.Success,
        });

        self().loadTagValues(groupName);
      } catch {
        set({ addTagGroupStatus: LoadingState.Error });
      }
    },

    loadTagValues: async (groupName: string) => {
      try {
        set({ loadTagValuesStatus: LoadingState.Loading });

        set({
          tagValues: await amService.loadTagValues(groupName),
          loadTagValuesStatus: LoadingState.Success,
        });
      } catch {
        set({ loadTagValuesStatus: LoadingState.Error });
      }
    },

    loadAssetTagValues: async () => {
      try {
        set({ loadAssetTagValuesStatus: LoadingState.Loading });

        set({
          assetTagValues: await amService.loadAssetTagValues(
            self().edittingAsset!,
          ),
          loadAssetTagValuesStatus: LoadingState.Success,
        });
      } catch {
        set({ loadAssetTagValuesStatus: LoadingState.Error });
      }
    },

    addTagValue: async (tagName: string, group: string) => {
      try {
        set({ addTagValueStatus: LoadingState.Loading });
        await amService.addTagValue(group, tagName);
        set({
          tagValues: await amService.loadTagValues(group),
          allTagValues: await amService.loadAllTagValues(),
          addTagValueStatus: LoadingState.Success,
        });
      } catch {
        set({ addTagValueStatus: LoadingState.Error });
      }
    },

    loadAllTagValues: async () => {
      try {
        set({ loadAllTagValuesStatus: LoadingState.Loading });

        set({
          allTagValues: await amService.loadAllTagValues(),
          loadAllTagValuesStatus: LoadingState.Success,
        });
      } catch {
        set({ loadAllTagValuesStatus: LoadingState.Error });
      }
    },

    selectTag: (tag: TagValue) => {
      set({
        selectedTags: [...self().selectedTags, tag],
      });
    },

    selectAssetTag: async (tag: TagValue) => {
      try {
        const newTags = [...self().assetTagValues!, tag];

        await amService.tagAsset(self().edittingAsset!, newTags);

        set({ assetTagValues: newTags });
      } catch {}
    },

    deselectAssetTag: async (tag: TagValue) => {
      try {
        const newTags = self().assetTagValues?.filter(
          (tv) => tv.value !== tag.value && tv.group !== tag.group,
        )!;

        await amService.tagAsset(self().edittingAsset!, newTags);

        set({ assetTagValues: newTags });
      } catch {}
    },

    clearAllAssetTags: async () => {
      try {
        await amService.tagAsset(self().edittingAsset!, []);

        set({ assetTagValues: [] });
      } catch {}
    },

    deselectTags: () => {
      set({ selectedTags: [] });
    },

    deselectTag: (tag: TagValue) => {
      set({
        selectedTags: self().selectedTags.filter(
          (sel) => !(sel.group === tag.group && sel.value === tag.value),
        ),
      });
    },

    uploadAsset: (onProgress: (progress: number) => void) => {
      set({ uploadStatus: LoadingState.Loading });

      try {
        amService.uploadFile(
          self().selectedFileForUpload!,
          onProgress,
          async (asset) => {
            await amService.tagAsset(asset, self().selectedTags);

            set({ uploadStatus: LoadingState.Success, selectedTags: [] });
          },
          () => set({ uploadStatus: LoadingState.Error }),
        );
      } catch {
        set({ uploadStatus: LoadingState.Error });
      }
    },

    loadAssets: async () => {
      try {
        set({ loadAssetsStatus: LoadingState.Loading });

        set({
          browserAssets: await amService.getAssetsByTags(self().selectedTags),
          loadAssetsStatus: LoadingState.Success,
        });
      } catch {
        set({ loadAssetsStatus: LoadingState.Error });
      }
    },

    updateAssetName: async (asset: Asset, newName: string) => {
      try {
        set({ updateAssetNameStatus: LoadingState.Loading });

        await amService.updateAssetName(asset, newName);

        set({ updateAssetNameStatus: LoadingState.Success });
      } catch {
        set({ updateAssetNameStatus: LoadingState.Error });
      }
    },

    selectAsset: (asset: Asset | string) => {
      set({ selectedAssets: [...self().selectedAssets, asset] });
    },

    selectAssetExclusively: (asset: Asset | string) => {
      set({ selectedAssets: [asset] });
    },

    deselectAsset: (asset: Asset | string) => {
      set({
        selectedAssets: self().selectedAssets.filter(
          (a) => !compareAssets(a, asset),
        ),
      });
    },

    deselectAllAssets: () => {
      set({ selectedAssets: [] });
    },

    setFileForUpload: (file: File | null) => {
      set({ selectedFileForUpload: file });
    },

    clearUploadData: () => {
      set({
        selectedFileForUpload: null,
        uploadStatus: null,
      });
    },

    setShowingSelected: (show: boolean) => {
      set({ showingSelected: show });
    },

    editAsset: (asset) => set({ edittingAsset: asset }),
  }));
