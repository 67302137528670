import React from "react";
import styled from "styled-components";
import { Remove, Pencil as Edit } from "@buffetjs/icons";

import { Asset, AssetEditMode } from "../models";
import { Paper } from "./lib/paper";
import { useAssetManager } from "../hooks";

interface Props {
  asset: Asset | string;
}

const Container = styled.div`
  padding: var(--padding-xs);
  margin: var(--padding-xs);
`;

const Image = styled.img`
  height: 100px;
`;

const ToolsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 20px;
`;

export const SelectedTile: React.FC<Props> = ({ asset }) => {
  const editMode = useAssetManager((s) => s.editMode);
  const setEditMode = useAssetManager((s) => s.setEditMode);
  const deselectAsset = useAssetManager((s) => s.deselectAsset);

  const handleRemove = () => deselectAsset(asset);
  const handleClick = () => {
    if (editMode !== AssetEditMode.Editing) {
      setEditMode(AssetEditMode.Editing);
    }
  };

  return (
    <Container>
      <Paper
        clickable={editMode === AssetEditMode.Viewing}
        onClick={handleClick}
      >
        <ToolsWrapper>
          {editMode === AssetEditMode.Editing && (
            <abbr title="Remove">
              <a onClick={handleRemove}>
                <Remove />
              </a>
            </abbr>
          )}

          {editMode === AssetEditMode.Viewing && (
            <abbr title="Edit">
              <a onClick={handleClick}>
                <Edit />
              </a>
            </abbr>
          )}
        </ToolsWrapper>

        <Image
          src={typeof asset === "string" ? asset : asset.url}
          alt="Selected Asset"
        />
      </Paper>
    </Container>
  );
};
