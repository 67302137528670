import { Button } from "@stockopedia/cms-ui";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useAssetManager } from "../hooks";

export const FileDropper: React.FC = () => {
  const setFileForUpload = useAssetManager((s) => s.setFileForUpload);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFileForUpload(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...(getRootProps() as any)}>
      <input {...getInputProps()} />

      <Button color={isDragActive ? "primary" : "secondary"}>
        {isDragActive ? "Drop Item" : "Click or drag to upload"}
      </Button>
    </div>
  );
};
