const CYBERTORIAL_BASE_PATH = "cybertorial/entities";

export const urls = {
  root: {
    url: "/",
  },
  admin: {
    dashboard: {
      url: "/admin/dashboard",
    },
    settings: {
      url: "/admin/settings",
    },
    assetManager: {
      url: "/admin/assets",
    },
    root: {
      url: "/admin",
    },
  },
  auth: {
    signedInOidc: {
      url: "/signin-oidc",
    },
    logout: {
      url: "/logout",
    },
    signoutCallback: {
      url: "/signout-oidc",
    },
    silentSignedInOidc: {
      url: "/silent-signin-oidc",
    },
    login: {
      url: "/login",
    },
  },
  cybertorial: {
    root: {
      url: "/cybertorial",
    },
    BASE_PATH: {
      url: CYBERTORIAL_BASE_PATH,
    },
    entities: {
      url: `/${CYBERTORIAL_BASE_PATH}`,
    },
    authors: {
      root: {
        url: `/${CYBERTORIAL_BASE_PATH}/authors`,
      },
      create: {
        url: `/${CYBERTORIAL_BASE_PATH}/authors/create`,
      },
      edit: {
        url: `/${CYBERTORIAL_BASE_PATH}/authors/:id`,
      },
    },
    articles: {
      root: {
        url: `/${CYBERTORIAL_BASE_PATH}/articles`,
      },
      create: {
        url: `/${CYBERTORIAL_BASE_PATH}/articles/create`,
      },
      edit: {
        url: `/${CYBERTORIAL_BASE_PATH}/articles/:id`,
      },
    },
    templates: {
      root: {
        url: `/${CYBERTORIAL_BASE_PATH}/templates`,
      },
      create: {
        url: `/${CYBERTORIAL_BASE_PATH}/templates/create`,
      },
      edit: {
        url: `/${CYBERTORIAL_BASE_PATH}/templates/:id`,
      },
      preview: {
        url: `/${CYBERTORIAL_BASE_PATH}/templates/:id/preview`,
        build: (id: string) =>
          `/${CYBERTORIAL_BASE_PATH}/templates/${id}/preview`,
      },
    },
    blueprints: {
      root: {
        url: `/${CYBERTORIAL_BASE_PATH}/blueprints`,
      },
      create: {
        url: `/${CYBERTORIAL_BASE_PATH}/blueprints/create`,
      },
      edit: {
        url: `/${CYBERTORIAL_BASE_PATH}/blueprints/:id`,
      },
    },
    themes: {
      root: {
        url: `/${CYBERTORIAL_BASE_PATH}/themes`,
      },
      create: {
        url: `/${CYBERTORIAL_BASE_PATH}/themes/create`,
      },
      edit: {
        url: `/${CYBERTORIAL_BASE_PATH}/themes/:id`,
      },
    },
    entityList: {
      url: `/${CYBERTORIAL_BASE_PATH}/:name`,
    },
    entityCreate: {
      url: `/${CYBERTORIAL_BASE_PATH}/:name/create`,
    },
    entityEdit: {
      url: `/${CYBERTORIAL_BASE_PATH}/:name/:id`,
    },
    templatePreview: {
      url: "/cybertorial/template/preview",
    },
    templatesPreview: {
      url: "/cybertorial/templates/preview",
    },
    controlPlane: {
      url: "/cybertorial/control-plane",
    },
  },
};
