import { Grouping, Operator } from "@stockopedia/screener-query-sdk";
import { createContext, useContext, useMemo } from "react";

import { AdvancedType, RuleState, Subject } from "../types";
import { getDefaultOperator } from "../utils/utils";

interface RuleContext {
  advancedType?: AdvancedType;
  advancedComparisonRatio?: Subject;
  advancedComparisonType?: Grouping;
  subject?: Subject;
  comparisonNumber?: number;
  operator: Operator;
  stringComparisonList: string[];
  actions: {
    setComparisonNumber: (val: number) => void;
    setSubject: (val: Subject) => void;
    setOperator: (val: Operator) => void;
    setAdvancedType: (val?: AdvancedType) => void;
    setAdvancedComparisonRatio: (val: Subject) => void;
    setAdvancedComparisonType: (val: Grouping) => void;
    setStringComparisonList: (val: string[]) => void;
  };
}

const ScreenRuleContext = createContext<RuleContext>({} as RuleContext);

export const useScreenRuleContext = () => useContext(ScreenRuleContext);

interface Props {
  children: JSX.Element;
  value?: RuleState;
  onChange: (state: RuleState) => void;
}

export const ScreenRuleProvider = ({
  children,
  onChange,
  value = RuleState.empty(),
}: Props) => {
  const state = useMemo(() => {
    const setAdvancedType = (type?: AdvancedType) =>
      onChange({ ...value, advancedType: type });
    const setComparisonNumber = (num: number = 0) =>
      onChange({ ...value, comparisonNumber: num });
    const setAdvancedComparisonRatio = (ratio: Subject) =>
      onChange({ ...value, advancedComparisonRatio: ratio });
    const setAdvancedComparisonType = (grouping: Grouping) =>
      onChange({ ...value, advancedComparisonType: grouping });
    const setStringComparisonList = (list: string[]) =>
      onChange({ ...value, stringComparisonList: list });
    const setSubject = (ratio: Subject) =>
      onChange({
        ...value,
        subject: ratio,
        operator:
          ratio.dataType === value.subject?.dataType
            ? value.operator
            : getDefaultOperator(ratio),
      });
    const setOperator = (operator: Operator) =>
      onChange({ ...value, operator });

    return {
      ...value,
      actions: {
        setComparisonNumber,
        setAdvancedComparisonRatio,
        setAdvancedComparisonType,
        setAdvancedType,
        setSubject,
        setOperator,
        setStringComparisonList,
      },
    };
  }, [value, onChange]);

  return (
    <ScreenRuleContext.Provider value={state}>
      {children}
    </ScreenRuleContext.Provider>
  );
};
