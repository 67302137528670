import { LoadingState } from "shared/types";
import create from "zustand";

import { TemplateService } from "../services/templates.service";
import { TemplatesState, TemplatesValues } from "./types";

export const defaultInitialState: TemplatesValues = {
  previewLoadingState: null,
  preview: null,
};

export const createTemplatesState = (
  templateService: TemplateService,
  initialState?: Partial<TemplatesValues>,
) =>
  create<TemplatesState>((set, self) => ({
    ...{
      ...defaultInitialState,
      ...initialState,
    },
    loadPreview: async (id: string) => {
      set({ previewLoadingState: LoadingState.waiting });

      try {
        const data = await templateService.getPreview(id);
        set({ previewLoadingState: LoadingState.loaded, preview: data.data! });
      } catch (error) {
        set({ error: error as Error });
      }
    },
  }));
