import { Label } from '@buffetjs/core'
import React from 'react'
import styled from 'styled-components'

import { Margin } from '../../padding'

const Required = styled.span`
  color: red;
`

const StyledLabel = styled(Label)`
  text-transform: capitalize;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

interface Props {
  title: string
  required?: boolean
  noMargin?: boolean
  actions?: React.ReactNode
  children: React.ReactNode | React.ReactNode[]
  component?: (props) => JSX.Element
}
export const Field = ({
  children,
  required,
  title,
  noMargin,
  actions,
  component: Component = ({ children }) => (
    <React.Fragment>{children}</React.Fragment>
  )
}: Props) => {
  return (
    <div>
      <Margin bottom size='sm'>
        <TitleContainer>
          <StyledLabel htmlFor='input'>
            <Component>{title}</Component> {required && <Required>*</Required>}
          </StyledLabel>
          {actions}
        </TitleContainer>
      </Margin>
      <Margin bottom={!noMargin}>{children}</Margin>
    </div>
  )
}
