export enum LoadingState {
  Loading,
  Success,
  Error,
}

export interface TagGroup {
  group: string;
}

export interface TagValue {
  group: string;
  value: string;
}

export interface Asset {
  id: string;
  assetName: string;
  url: string;
  mimeType: string;
}

export enum AssetSelectionType {
  None = "none",
  Single = "single",
  Multiple = "multiple",
}

export enum AssetEditMode {
  Viewing = "viewing",
  Editing = "editing",
}

export interface IAssetManagerService {
  searchAssets(term: string): Promise<Asset[]>;

  loadTagGroups(): Promise<TagGroup[]>;
  loadTagValues(group: string): Promise<TagValue[]>;
  loadAssetTagValues(asset: Asset): Promise<TagValue[]>;

  loadAllTagValues(): Promise<TagValue[]>;

  addTagGroup(group: string): Promise<void>;
  addTagValue(group: string, value: string): Promise<void>;

  getAssetsByTags(tags: TagValue[]): Promise<Asset[]>;

  // Ugly callbacks, might change this
  uploadFile(
    file: File,
    onProgress: (amount: number) => void,
    onComplete: (asset: Asset) => void,
    onError: (error: Error) => void,
  ): Promise<void>;

  tagAsset(asset: Asset, tags: TagValue[]): Promise<void>;

  updateAssetName(asset: Asset, newName: string): Promise<void>;
}
