import React, { createContext, useContext } from 'react'
import { useMemo } from 'react'

type Direction = 'asc' | 'desc'

interface Props {
  field?: string
  direction: Direction
  sortableFields?: string[]
}

interface State extends Props {
  onSort: (field: string) => void
}

const SortContext = createContext<State>({} as any)

export const useSortContext = () => useContext(SortContext)

const invertDirection = (direction: Direction) =>
  direction === 'asc' ? 'desc' : 'asc'

export const SortProvider = ({
  value,
  onSort,
  children
}: {
  value: Props
  onSort: (field: string, direction: Direction) => void
  children: React.ReactNode
}) => {
  const sortState = useMemo<State>(
    () => ({
      ...value,
      onSort: (field) => {
        if (field === value.field) {
          onSort(field, invertDirection(value.direction))
        } else {
          onSort(field, 'desc')
        }
      }
    }),
    [value]
  )

  return (
    <SortContext.Provider value={sortState}>{children}</SortContext.Provider>
  )
}
