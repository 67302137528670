import ImageIcon from '@material-ui/icons/Image'
import { Editor as BaseEditor } from 'medium-draft'
import React from 'react'

export const ImageButton = (_: BaseEditor) => {
  const onClick = () => {}

  /*
  This is an example of how an image button can be added
  on the side control. This Button handles the image addition locally
  by creating an object url. You can override this method to upload
  images to your server first, then get the image url in return and then
  add to the editor.
  */
  // const onChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file.type.indexOf('image/') === 0) {
  //     const src = URL.createObjectURL(file);
  //     props.setEditorState(addNewBlock(
  //       props.getEditorState(),
  //       Block.IMAGE, {
  //         src,
  //       }
  //     ));
  //   }
  //   props.close();
  // }

  return (
    <button
      className='md-sb-button md-sb-img-button'
      type='button'
      onClick={onClick}
      title='Add an Image'
    >
      <ImageIcon />
    </button>
  )
}
