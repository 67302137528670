import styled from 'styled-components'

type Size = 'standard' | 'lg' | 'sm' | 'xs'

interface Props {
  top?: boolean
  bottom?: boolean
  left?: boolean
  right?: boolean
  all?: boolean
  size?: Size
}

const sizeMap = {
  sm: 'var(--padding-sm)',
  standard: 'var(--padding-standard)',
  lg: 'var(--padding-large)',
  xs: 'var(--padding-xs)'
}

const makeProperties = (
  prefix: string,
  { all = true, size = 'standard', ...rest }: Props
) => {
  const possibilities = ['left', 'right', 'top', 'bottom']
  const mappedSize = sizeMap[size]
  if (all && !possibilities.some((p) => !(rest[p] === undefined))) {
    return `${prefix}: ${mappedSize};`
  }
  let res = ''
  possibilities.forEach((p) => {
    if (rest[p]) {
      res += `${prefix}-${p}: ${mappedSize};`
    }
  })

  return res
}

export const Padding = styled.div<Props>`
  ${(props) => makeProperties('padding', props)}
  width: 100%;
  &.auto-width {
    width: auto;
  }
`

export const Margin = styled.div<Props>`
  ${(props) => makeProperties('margin', props)}
  width: 100%;
  &.auto-width {
    width: auto;
  }
`
