import { StockopediaClient } from "clients/stockopedia";
import { injectable } from "tsyringe";

import { CATEGORIES } from "./categories";
import { Category } from "./types";
import { hasScreenableRatios } from "./utils";

export interface IDataSource {
  query(): Promise<Category[]>;
}

export class RatiosApiDataSource implements IDataSource {
  constructor(private readonly client: StockopediaClient) {}

  async query(): Promise<Category[]> {
    return CATEGORIES.filter(hasScreenableRatios).map(function f(
      category: Category,
    ) {
      if (category.categories?.length && category.ratios?.length) {
        category.categories.push({
          name: "General",
          ratios: [...category.ratios],
          categories: [],
          description: category.description,
        });
        category.categories = category.categories.map(f);
        category.ratios = [];
      }

      return category;
    });
  }
}

@injectable()
export class LinksDataSource implements IDataSource {
  async query(): Promise<Category[]> {
    return [
      {
        name: "Web Links",
        description: "short hand hyperlink tags",
        categories: [],
        ratios: [
          {
            field: "links.nameAndTicker",
            name: "Name and ticker link",
            dataType: "string",
            description:
              'Vodafone <a href="https://www.stockopedia.com/share-prices/vodafone-LON:VOD/">(LON:VOD)</a>',
          },
          {
            field: "links.name",
            name: "Name link",
            description:
              '<a href="https://www.stockopedia.com/share-prices/vodafone-LON:VOD/">Vodafone</a>',
            dataType: "string",
          },
          {
            field: "links.ticker",
            name: "Ticker link",
            dataType: "string",
            description:
              '<a href="https://www.stockopedia.com/share-prices/vodafone-LON:VOD/">(LON:VOD)</a>',
          },
          {
            field: "links.raw",
            name: "Raw",
            dataType: "string",
            description:
              "https://www.stockopedia.com/share-prices/vodafone-LON:VOD/",
          },
          {
            field: "links.dividend",
            name: "Dividend link",
            dataType: "string",
            description:
              '<a href="https://www.stockopedia.com/share-prices/vodafone-LON:VOD/dividends">Vodafone Dividends</a>',
          },
          {
            field: "links.sharePrice",
            name: "Share Price link",
            dataType: "string",
            description:
              '<a href="https://www.stockopedia.com/share-prices/vodafone-LON:VOD/">Vodafone Share Price</a>',
          },
          {
            field: "links.chart",
            name: "Chart link",
            dataType: "string",
            description:
              '<a href="https://www.stockopedia.com/share-prices/vodafone-LON:VOD/chart/">Vodafone Chart</a>',
          },
        ],
      },
    ];
  }
}
