export const EXCHANGES = [
  {
    value: "LSE",
    display: "London Stock Exchange - LSE",
  },
  {
    value: "ISD",
    display: "NEX Exchange",
  },
  {
    value: "NYQ",
    display: "New York Stock Exchange",
  },
  {
    value: "ASQ",
    display: "NYSE MKT",
  },
  {
    value: "PCQ",
    display: "NYSE Arca",
  },
  {
    value: "NAQ",
    display: "NASDAQ Capital Market",
  },
  {
    value: "NMQ",
    display: "NASDAQ Global Market",
  },
  {
    value: "NSQ",
    display: "NASDAQ Global Select Market",
  },
  {
    value: "OTC",
    display: "OTC Markets Group",
  },
  {
    value: "PNK",
    display: "Pink Sheets on Nasdaq",
  },
  {
    value: "OBB",
    display: "OTC Bulletin Board",
  },
  {
    value: "VIE",
    display: "Vienna Stock Exchange",
  },
  {
    value: "BRU",
    display: "Euronext - Brussels",
  },
  {
    value: "BNL",
    display: "Bosnia Herzegovina Stock Exchange",
  },
  {
    value: "BLG",
    display: "Bulgarian Stock Exchange",
  },
  {
    value: "ZAG",
    display: "Zagreb Stock Exchange",
  },
  {
    value: "CYS",
    display: "Cyprus Stock Exchange",
  },
  {
    value: "PRA",
    display: "Prague Stock Exchange",
  },
  {
    value: "CPH",
    display: "OMX Nordic Exchange - Copenhagen",
  },
  {
    value: "TLX",
    display: "OMX Baltic Exchange - Tallinn",
  },
  {
    value: "HEX",
    display: "OMX Nordic Exchange - Helsinki",
  },
  {
    value: "PAR",
    display: "Euronext - Paris",
  },
  {
    value: "BER",
    display: "Berlin Stock Exchange",
  },
  {
    value: "GER",
    display: "XETRA",
  },
  {
    value: "DUS",
    display: "Dusseldorf Stock Exchange",
  },
  {
    value: "HAM",
    display: "Hamburg Stock Exchange",
  },
  {
    value: "HAN",
    display: "Hanover Stock Exchange",
  },
  {
    value: "FRA",
    display: "Frankfurt Stock Exchange",
  },
  {
    value: "MUN",
    display: "Munich Stock Exchange",
  },
  {
    value: "STU",
    display: "Stuttgart Stock Exchange",
  },
  {
    value: "ATH",
    display: "Athens Exchange",
  },
  {
    value: "BUD",
    display: "Budapest Stock Exchange",
  },
  {
    value: "ICX",
    display: "Iceland Stock Exchange",
  },
  {
    value: "ISE",
    display: "Irish Stock Exchange",
  },
  {
    value: "MIL",
    display: "Borsa Italiana",
  },
  {
    value: "RIX",
    display: "OMX Baltic Exchange - Riga",
  },
  {
    value: "VLX",
    display: "OMX Baltic Exchange - Vilnius",
  },
  {
    value: "MLT",
    display: "Malta Stock Exchange",
  },
  {
    value: "AEX",
    display: "Euronext - Amsterdam",
  },
  {
    value: "OSL",
    display: "Oslo Stock Exchange",
  },
  {
    value: "WSE",
    display: "Warsaw Stock Exchange",
  },
  {
    value: "LIS",
    display: "Euronext - Lisbon",
  },
  {
    value: "BUH",
    display: "Bucharest Stock Exchange",
  },
  {
    value: "MSE",
    display: "Russian Trading System",
  },
  {
    value: "MCX",
    display: "Moscow Interbank Currency Exchange",
  },
  {
    value: "BEL",
    display: "Belgrade Stock Exchange",
  },
  {
    value: "BRA",
    display: "Bratislava Stock Exchange",
  },
  {
    value: "LJU",
    display: "Ljubljana Stock Exchange",
  },
  {
    value: "BAR",
    display: "Barcelona Stock Exchange",
  },
  {
    value: "MCE",
    display: "Madrid Stock Exchange",
  },
  {
    value: "BRN",
    display: "Berne eXchange",
  },
  {
    value: "SWX",
    display: "SWX",
  },
  {
    value: "VTX",
    display: "SIX SwissExchange",
  },
  {
    value: "NGM",
    display: "Nordic Growth Market†- Stockholm",
  },
  {
    value: "STO",
    display: "OMX Nordic Exchange Stockholm",
  },
  {
    value: "IST",
    display: "Istanbul Stock Exchange",
  },
  {
    value: "LUX",
    display: "Luxembourg Stock†Exchange",
  },
  {
    value: "MKE",
    display: "Macedonian Stock†Exchange",
  },
  {
    value: "FSI",
    display: "FTSE International",
  },
  {
    value: "NAS",
    display: "NASDAQ Stock Exchange Market",
  },
  {
    value: "DJI",
    display: "Dow Jones Global Indices",
  },
  {
    value: "MXP",
    display: "Standard &amp; Poors indices",
  },
  {
    value: "IOM",
    display: "CME:Chicago Mercantile Commodities",
  },
  {
    value: "CME",
    display: "CME Index and Options Market",
  },
  {
    value: "WCB",
    display: "Chicago Board Options Exchange",
  },
  {
    value: "IND",
    display: "FTSE International",
  },
  {
    value: "MSI",
    display: "MSCI Inc",
  },
  {
    value: "STX",
    display: "STOXX",
  },
  {
    value: "PLU",
    display: "PLUS Markets Exchange",
  },
  {
    value: "LON",
    display: "London Stock Exchange - LON",
  },
  {
    value: "LSS",
    display: "London SEATS Stock Exchange",
  },
  {
    value: "OFE",
    display: "PLUS Markets Exchange",
  },
  {
    value: "AKT",
    display: "AktieTorget Stock Exchange",
  },
];
