import React, { createContext, useContext } from 'react'

interface State {
  path: string
  search: object
  last: number
  page: number
}
const PaginationContext = createContext<State>({} as any)

export const usePaginationContext = () => useContext(PaginationContext)

export const PaginationProvider = ({
  value,
  children
}: {
  value: State
  children: React.ReactNode
}) => {
  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  )
}
