import styled from "styled-components";

export const createThumbnailClass = (
  width: string,
  height: string,
) => styled.div`
  height: ${height};
  width: ${width};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;
