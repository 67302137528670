import { Table, Field, SortProvider } from "@stockopedia/cms-ui";
import { autoMapper } from "@stockopedia/typed-mapper";
import { StockopediaClient } from "clients/stockopedia";
import { resolveDependency } from "framework/hooks";
import { useEffect, useState } from "react";

import { formatNumber } from "../../../../../../shared/utils";
import { RuleState } from "../types";

interface SecurityResponse {
  name: string;
  ratios: RatioResponse[];
}

interface RatioResponse {
  field: string;
  value: string;
}

class Security {
  name!: string;
  ratios!: Ratio[];
}

class Ratio {
  field!: string;
  value!: string;
}

type SortDirection = "asc" | "desc";

export interface Props {
  ast: RuleState[];
}

interface Obj {
  [key: string]: string;
}

const ratiosReducer = (ratios: RatioResponse[]): Obj => {
  const result = {};

  for (const ratio of ratios) {
    result[ratio.field] = ratio.value;
  }

  return result;
};

const makeSortDirection = (isDesc: boolean): SortDirection => {
  return isDesc ? "desc" : "asc";
};

const QUERY = `
query GetSecurities($ast: String!, $paging: PagingArgs, $sort: SortArgs!) {
  securities(ast: $ast, paging: $paging, sort: $sort) {
    pageInfo {
        totalPages
        totalCount
        currentPage
    }
    nodes {
        name
        ratios {
          field
          value
        }
    }
  }
}`;

interface SecuritiesResponse {
  securities: {
    nodes: SecurityResponse[];
    pageInfo: {
      totalCount: number;
    };
  };
}

const getSecurities = async (
  ast: string,
  sortField: string,
  sortDirection: string,
) => {
  const apiClient = resolveDependency(StockopediaClient);
  return apiClient.query<SecuritiesResponse>({
    query: QUERY,
    variables: {
      paging: {
        page: 0,
        take: 10,
      },
      sort: {
        by: sortField,
        direction: sortDirection.toUpperCase(),
      },
      ast,
    },
  });
};

const securitiesMapper = autoMapper
  .createMap<SecurityResponse, Security>(Security)
  .forMember("name", (opt) => opt.mapFrom((x) => x.name))
  .forMember("ratios", (opt) =>
    opt.mapFrom((x) =>
      x.ratios.map((r) => ({
        ...r,
        value: parseInt(r.value) ? formatNumber(parseInt(r.value)) : r.value,
      })),
    ),
  );

export const SecuritiesTable = ({ ast }: Props) => {
  const [sortField, setSortField] = useState("ratios._MktCap");
  const [sortDesc, setSortDesc] = useState(true);
  const [securities, setSecurities] = useState<Security[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [error, setError] = useState<string>();

  const rows = securities.map((x) => ({
    name: x.name,
    ...ratiosReducer(x.ratios),
  }));
  const columns = rows.length > 0 ? Object.keys(rows[0]) : ["name"];

  useEffect(() => {
    if (ast.length > 0) {
      const fetch = async () => {
        const result = await getSecurities(
          JSON.stringify(ast),
          sortField,
          makeSortDirection(sortDesc),
        );

        if (result.errors) {
          setError("Unable to get securities for screener query");
        } else {
          setError(undefined);
          setSecurities(
            result.data!.securities.nodes.map((x) => securitiesMapper.map(x)),
          );
          setTotal(result.data!.securities.pageInfo.totalCount);
        }
      };
      fetch();
    }
  }, [ast, sortField, sortDesc]);

  const renderTable = () => {
    return (
      <SortProvider
        value={{
          field: sortField,
          direction: makeSortDirection(sortDesc),
          sortableFields: columns.filter((x) => x !== "name"),
        }}
        onSort={(field) => {
          if (field === sortField) {
            setSortDesc(!sortDesc);
          } else {
            setSortDesc(true);
            setSortField(field);
          }
        }}
      >
        <Table
          link={() => ""}
          columns={columns.map((x) => ({
            key: x,
            title: x,
            projection: (v) => v[x],
          }))}
          rows={rows}
        />
      </SortProvider>
    );
  };

  const renderError = () => {
    return <div>Error: {error}</div>;
  };

  return (
    <Field title={`Securities - Total: ${total ?? "0"}`}>
      {error ? renderError() : renderTable()}
    </Field>
  );
};
