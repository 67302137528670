import { useAuth } from "modules/auth/hooks/use-auth";
import { useEffect } from "react";

export const Logout = () => {
  const logout = useAuth((s) => s.logout);

  useEffect(() => {
    logout();
  }, [logout]);

  return <></>;
};
