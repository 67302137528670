import { chain } from "lodash";

import { Category, DataSource, Ratio } from "./types";

export const hasScreenableRatios = (category: Category) => {
  if (category.categories?.length > 0) {
    return category.categories.some(hasScreenableRatios);
  }
  return category.ratios?.some((r) => r.dataSource === DataSource.SCREENER);
};

export const sortRatios = (ratios: Ratio[]) => {
  return chain(ratios).sortBy("modifier").sortBy("name").value();
};

export const containsRatio = (category: Category, ratio?: Ratio): boolean => {
  if (!ratio) {
    return false;
  }
  const f = (cat) => cat.ratios.some((r) => ratioEquals(r, ratio));

  return f(category) || category.categories.some(f);
};

export const ratioEquals = (left?: Ratio, right?: Ratio) => {
  if (!left || !right) {
    return false;
  }
  return left?.field === right?.field && left?.modifier === right?.modifier;
};
