import { EditorState } from "@stockopedia/rte";
import { Article } from "modules/cybertorial/articles";
import { Blueprint } from "modules/cybertorial/blueprints";

export class Theme {
  id!: string;

  title!: string;

  callToAction?: EditorState;

  recentArticles?: Article[];

  updatedAt!: string;

  blueprints?: Blueprint[];

  numArticles: number = 0;

  static empty() {
    return new Theme();
  }
}
