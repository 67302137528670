import { LoadingState } from "shared/types";

export enum BatchRegion {
  EUROPE_AND_AFRICA = "Europe, Africa and Middle East",
  AUSTRALASIA = "Australasia",
  ASIA = "Asia",
  AMERICAS = "Americas",
  GLOBAL = "Global",
}

export interface ControlPlaneValues {
  throughputs?: Throughput[];
  error?: Error;
  loadingState: LoadingState;
  saveActionResult?: boolean;
  adminSettings?: AdminSettings;
}

export interface AdminSettings {
  daysBetweenArticles: number;
}

export interface Throughput {
  dailyArticles: number;
  publishingWindow: number;
  region: BatchRegion;
}

export class ControlPlaneData {
  throughputs?: Throughput[];
  adminSettings?: AdminSettings;
}

export interface ControlPlaneActions {
  saveThroughput: (Throughput) => void;
  saveSettings: (AdminSettings) => void;
  loadDashboard: () => void;
}

export type ControlPlaneState = ControlPlaneValues & ControlPlaneActions;
