import { Author } from "modules/cybertorial/authors";
import { Blueprint } from "modules/cybertorial/blueprints";

export class TemplatePreview {
  id!: string;
  image!: string;
  title!: string;
  content!: string;
  author!: Author;
  callToAction!: string;
  updatedAt!: Date;
  blueprint!: Blueprint;
}
