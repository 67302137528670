export const INDUSTRY_GROUPS = [
  {
    value: "501010",
    display: "Coal",
  },
  {
    value: "501020",
    display: "Oil & Gas",
  },
  {
    value: "501030",
    display: "Oil & Gas Related Equipment and Services",
  },
  {
    value: "502010",
    display: "Renewable Energy",
  },
  {
    value: "503010",
    display: "Uranium",
  },
  {
    value: "511010",
    display: "Chemicals",
  },
  {
    value: "512010",
    display: "Metals & Mining",
  },
  {
    value: "512020",
    display: "Construction Materials",
  },
  {
    value: "513010",
    display: "Paper & Forest Products",
  },
  {
    value: "513020",
    display: "Containers & Packaging",
  },
  {
    value: "521010",
    display: "Aerospace & Defense",
  },
  {
    value: "521020",
    display: "Machinery, Equipment & Components",
  },
  {
    value: "522010",
    display: "Construction & Engineering",
  },
  {
    value: "522020",
    display: "Diversified Trading & Distributing",
  },
  {
    value: "522030",
    display: "Professional & Commercial Services",
  },
  {
    value: "523010",
    display: "Industrial Conglomerates",
  },
  {
    value: "524050",
    display: "Freight & Logistics Services",
  },
  {
    value: "524060",
    display: "Passenger Transportation Services",
  },
  {
    value: "524070",
    display: "Transport Infrastructure",
  },
  {
    value: "531010",
    display: "Automobiles & Auto Parts",
  },
  {
    value: "532020",
    display: "Textiles & Apparel",
  },
  {
    value: "532030",
    display: "Homebuilding & Construction Supplies",
  },
  {
    value: "532040",
    display: "Household Goods",
  },
  {
    value: "532050",
    display: "Leisure Products",
  },
  {
    value: "533010",
    display: "Hotels & Entertainment Services",
  },
  {
    value: "533020",
    display: "Media & Publishing",
  },
  {
    value: "534020",
    display: "Diversified Retail",
  },
  {
    value: "534030",
    display: "Specialty Retailers",
  },
  {
    value: "541010",
    display: "Beverages",
  },
  {
    value: "541020",
    display: "Food & Tobacco",
  },
  {
    value: "542010",
    display: "Personal & Household Products & Services",
  },
  {
    value: "543010",
    display: "Food & Drug Retailing",
  },
  {
    value: "551010",
    display: "Banking Services",
  },
  {
    value: "551020",
    display: "Investment Banking & Investment Services",
  },
  {
    value: "553010",
    display: "Insurance",
  },
  {
    value: "554020",
    display: "Real Estate Operations",
  },
  {
    value: "554030",
    display: "Residential & Commercial REITs",
  },
  {
    value: "555010",
    display: "Collective Investments",
  },
  {
    value: "556010",
    display: "Holding Companies",
  },
  {
    value: "561010",
    display: "Healthcare Equipment & Supplies",
  },
  {
    value: "561020",
    display: "Healthcare Providers & Services",
  },
  {
    value: "562010",
    display: "Pharmaceuticals",
  },
  {
    value: "562020",
    display: "Biotechnology & Medical Research",
  },
  {
    value: "571010",
    display: "Semiconductors & Semiconductor Equipment",
  },
  {
    value: "571020",
    display: "Communications & Networking",
  },
  {
    value: "571040",
    display: "Electronic Equipments & Parts",
  },
  {
    value: "571050",
    display: "Office Equipment",
  },
  {
    value: "571060",
    display: "Computers, Phones & Household Electronics",
  },
  {
    value: "572010",
    display: "Software & IT Services",
  },
  {
    value: "581010",
    display: "Telecommunications Services",
  },
  {
    value: "591010",
    display: "Electric Utilities & IPPs",
  },
  {
    value: "591020",
    display: "Natural Gas Utilities",
  },
  {
    value: "591030",
    display: "Water Utilities",
  },
  {
    value: "591040",
    display: "Multiline Utilities",
  },
];
