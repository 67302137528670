export interface Ratio {
  name: string;
  modifier?: string;
  description: string;
  field: string;
  dataType: RatioDataType;
  dataSource?: DataSource;
  unit?: RatioUnit;
}

export enum RatioUnit {
  PERCENTAGE = "PERCENTAGE",
  MILLION = "MILLION",
  BASIS_POINTS = "BASIS_POINTS",
}

export type RatioDataType =
  | "string"
  | "number"
  | "double"
  | "int"
  | "datetime"
  | "time";

export enum DataSource {
  SCREENER = "SCREENER",
  STATEMENTS = "STATEMENTS",
  BATCH = "BATCH",
  INSTRUMENTS = "INSTRUMENTS",
  QUOTES = "QUOTES",
  ARCHIVES = "ARCHIVES",
  GENERAL_INFORMATION = "GENERAL_INFORMATION",
}

export interface Category {
  name: string;
  description: string;
  ratios: Ratio[];
  categories: Category[];
}
