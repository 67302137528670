import React from "react";
import { Text } from "@buffetjs/core";
import { createThumbnailClass } from "./thumbnail";

interface Props {
  file: File;
}

const Thumbnail = createThumbnailClass("100%", "200px");

export const UploadPreview = React.memo(
  ({ file }: Props) => (
    <React.Fragment>
      <Text fontWeight="bold" fontSize="lg">
        Upload Asset
      </Text>
      <Thumbnail
        style={{ backgroundImage: `url(${URL.createObjectURL(file)})` }}
      />
      <p>{file.name}</p>
    </React.Fragment>
  ),
  (prev, next) =>
    prev.file.name === next.file.name && prev.file.size === next.file.size,
);
