import React from "react";

export const Settings = () => {
  return (
    <>
      <div className="flex flex-wrap">
        <div>{/* <CardSettings /> */}</div>
        <div>{/* <CardProfile /> */}</div>
      </div>
    </>
  );
};
