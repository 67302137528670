import { EditorState } from "@stockopedia/rte";
import { Article } from "modules/cybertorial/articles";
import { Author } from "modules/cybertorial/authors";
import { Blueprint } from "modules/cybertorial/blueprints";
import { Theme } from "modules/cybertorial/themes";

export enum TemplateStatus {
  DRAFT = "DRAFT",
  AWAITING_REVIEW = "AWAITING_REVIEW",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  ARCHIVED = "ARCHIVED",
}

export class Template {
  id!: string;

  image!: string;

  title!: string;

  content!: EditorState;

  status!: TemplateStatus;

  articles?: Article[];

  author?: Author;

  callToAction?: EditorState;

  blueprint?: Blueprint;

  theme!: Theme;

  updatedAt!: Date;

  numArticles!: number;

  static empty() {
    return new Template();
  }
}

export class TemplateBuilder {
  private readonly template: Template = Template.empty();

  withAuthor(author?: Author) {
    this.template.author = author;

    return this;
  }

  withBlueprint(blueprint?: Blueprint) {
    this.template.blueprint = blueprint;
    this.template.callToAction = blueprint?.theme?.callToAction;

    return this;
  }

  build() {
    return this.template;
  }
}
