import { IMapper } from "@stockopedia/typed-mapper";
import { StockopediaClient } from "clients/stockopedia";
import {
  DataResponse,
  IEntityController,
  MutationResult,
} from "framework/types";
import { Block } from "modules/cybertorial/articles";
import { Direction } from "shared/types";
import { gqlResult, makeSortParams } from "shared/utils";

import { Author } from "./model";

const AUTHORS_QUERY = `
query GetAuthorsConnection(
    $paging: PagingArgs, 
    $filters: AuthorFilters!
    $sort: SortArgs) {
  authors(
    paging: $paging,
    filters: $filters,
    sort: $sort
  ) 
  {
    pageInfo {
      totalPages
      totalCount
      currentPage
    }
    nodes {
      id
      firstName
      lastName
      image
    }
  }
}
`;

const AUTHOR_QUERY = `
query GetAuthor($id: ID!) {
  author(
    id: $id,
  ) 
  {
    id
    firstName
    lastName
    biography
    image
    templates {
      nodes {
        title
        id
      }
      pageInfo {
        totalCount
      }
    }
    articles {
      nodes {
        blocks {
          label
          content
        }
        id
      }
      pageInfo {
        totalCount
      }
    }
  }
}
`;

const SAVE_QUERY = `
mutation AddAuthor($firstName: String!, $lastName: String!, $image: String!, $biography: String, $id: ID) {
  upsertAuthor(firstName: $firstName, lastName: $lastName, image: $image, biography: $biography, id: $id) {
      id
  }
}`;

export interface ListParams {
  page: number;
  take: number;
  filters: {
    firstName?: string;
    lastName?: string;
  };
  sort: {
    field: string;
    direction: Direction;
  };
}

export interface AuthorResponse {
  firstName: string;
  lastName: string;
  id: string;
  image: string;
  biography: string;
  templates: {
    nodes: {
      title: string;
      id: string;
    }[];
    pageInfo: {
      totalCount: number;
    };
  };
  articles: {
    nodes: {
      blocks: Block[];
      id: string;
    }[];
    pageInfo: {
      totalCount: number;
    };
  };
}

interface AuthorResult {
  author: AuthorResponse;
}

interface AuthorSaveResult {
  upsertAuthor: {
    firstName: string;
    lastName: string;
    id: string;
    image: string;
  };
}

interface ListResult {
  authors: {
    pageInfo: {
      currentPage: number;
      totalPages: number;
      totalCount: number;
    };
    nodes: AuthorResponse[];
  };
}

interface SaveParams {
  firstName: string;
  lastName: string;
  image: string;
  id?: string;
  biography?: string;
}

export class AuthorsService implements IEntityController<Author> {
  constructor(
    private readonly client: StockopediaClient,
    private readonly mapper: IMapper<AuthorResponse, Author>,
  ) {}

  delete(_: any): Promise<DataResponse<MutationResult>> {
    throw new Error("Method not implemented.");
  }

  async get(id: string): Promise<DataResponse<Author>> {
    const { data, errors } = await gqlResult<AuthorResult>(() =>
      this.client.query<AuthorResult>({
        query: AUTHOR_QUERY,
        variables: {
          id,
        },
      }),
    );

    return {
      data: data ? this.mapper.map(data!.author) : undefined,
      errors,
    };
  }

  async list(params: ListParams): Promise<DataResponse<Author[]>> {
    const { data, errors } = await gqlResult<ListResult>(() =>
      this.client.query({
        query: AUTHORS_QUERY,
        variables: {
          paging: {
            page: params.page,
            take: params.take,
          },
          filters: params.filters,
          sort: makeSortParams(params.sort),
        },
      }),
    );

    return {
      data: data?.authors.nodes.map(this.mapper.map.bind(this.mapper)),
      paging: data?.authors.pageInfo,
      errors,
    };
  }

  async save(params: SaveParams): Promise<DataResponse<MutationResult>> {
    const { data, errors } = await gqlResult<AuthorSaveResult>(() =>
      this.client.query({
        query: SAVE_QUERY,
        variables: params,
      }),
    );

    return {
      data: data?.upsertAuthor,
      errors,
    };
  }
}
