import { StockopediaClient } from "clients/stockopedia";
import {
  DataResponse,
  IEntityController,
  MutationResult,
} from "framework/types";
import { gqlResult } from "shared/utils";

import { Tag } from "./model";

const TAGS_QUERY = `
query GetTags($paging: PagingArgs, $filters: TagFilters!) {
  tags(paging: $paging, filters: $filters) {
    nodes {
      id
      title
      description
    }
  }
}
`;

const TAG_QUERY = `
query GetTag($id: ID!) {
  tag(id: $id) {
    id
    title
    description
  }
}
`;

const MUTATION_QUERY = `
mutation UpsertTag($id: ID, $title: String!, $description: String) {
  upsertTag(id: $id, title: $title, description: $description) {
    id
  }
}`;

interface ListParams {
  page: number;
  take: number;
  filters: {
    title?: string;
  };
}

interface TagResult {
  tag: Tag;
}

interface ListResult {
  tags: {
    pageInfo: {
      currentPage: number;
      totalPages: number;
      totalCount: number;
    };
    nodes: Tag[];
  };
}

interface UpsertResult {
  upsertTag: Tag;
}

export class TagController implements IEntityController<Tag> {
  constructor(private readonly client: StockopediaClient) {}

  delete(props: any): Promise<DataResponse<MutationResult>> {
    throw new Error("Method not implemented.");
  }

  async get(id: string): Promise<DataResponse<Tag>> {
    const { data, errors } = await gqlResult(() =>
      this.client.query<TagResult>({
        query: TAG_QUERY,
        variables: {
          id,
        },
      }),
    );

    return {
      data: data?.tag,
      errors,
    };
  }

  async list(params: ListParams): Promise<DataResponse<Tag[]>> {
    const { data, errors } = await gqlResult(() =>
      this.client.query<ListResult>({
        query: TAGS_QUERY,
        variables: {
          page: params.page,
          take: params.take,
          filters: params.filters,
        },
      }),
    );

    return {
      data: data?.tags.nodes,
      errors,
    };
  }

  async save(params: Tag): Promise<DataResponse<MutationResult>> {
    const { data, errors } = await gqlResult(() =>
      this.client.query<UpsertResult>({
        query: MUTATION_QUERY,
        variables: {
          id: params.id,
          title: params.title,
          description: params.description,
        },
      }),
    );

    return {
      data: data?.upsertTag,
      errors,
    };
  }
}
