import { LoadingState } from "shared/types";
import create from "zustand";

import { CybertorialControlPlaneService } from "../services";
import {
  AdminSettings,
  ControlPlaneState,
  ControlPlaneValues,
  Throughput,
} from "./types";

export const defaultInitialState: ControlPlaneValues = {
  loadingState: LoadingState.loaded,
};

export const createControlPlaneState = (
  service: CybertorialControlPlaneService,
  initialState?: Partial<ControlPlaneValues>,
) =>
  create<ControlPlaneState>((set) => ({
    ...{
      ...defaultInitialState,
      ...initialState,
    },
    saveThroughput: async (throughput: Throughput) => {
      try {
        await service.updateThroughput(
          throughput.dailyArticles,
          throughput.publishingWindow,
          throughput.region,
        );
        set({ saveActionResult: true });
      } catch (error) {
        set({ saveActionResult: false });
      }
    },
    saveSettings: async (adminSettings: AdminSettings) => {
      try {
        await service.updateSettings(adminSettings.daysBetweenArticles);
        set({ saveActionResult: true });
      } catch (error) {
        set({ saveActionResult: false });
      }
    },
    loadDashboard: async () => {
      set({ loadingState: LoadingState.waiting });
      try {
        const controlPlaneData = await service.getDashboardData();
        set({ ...controlPlaneData, loadingState: LoadingState.loaded });
      } catch (error) {
        set({ loadingState: LoadingState.loaded, error: error as Error });
      }
    },
  }));
