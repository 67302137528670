import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

const StyledOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  display: column;
  justify-content: center;
  align-items: center;
`

export const Portal = ({ children, overlay = true, onOverlayClicked }) => {
  return ReactDOM.createPortal(
    overlay ? (
      <Overlay onClick={onOverlayClicked}>{children}</Overlay>
    ) : (
      children
    ),
    document.body
  )
}
export const Overlay = ({ onClick, ...props }) => {
  const overlayRef = useRef<HTMLDivElement>(null)

  return (
    <StyledOverlay
      ref={overlayRef}
      {...props}
      onClick={(e) => {
        if (overlayRef.current === e.target) {
          onClick(e)
        }
      }}
    />
  )
}
