export const RISK_RATING = [
  {
    value: "aggressive",
    display: "Adventurous",
  },
  {
    value: "speculative",
    display: "Speculative",
  },
  {
    value: "highly_speculative",
    display: "Highly Speculative",
  },
  {
    value: "conservative",
    display: "Conservative",
  },
  {
    value: "moderate",
    display: "Moderate",
  },
];
