export const STOCK_RANK_STYLES = [
  {
    value: "unclassified",
    display: "Unclassified",
  },
  {
    value: "falling_star",
    display: "Falling Start",
  },
  {
    value: "turnaround",
    display: "Turnaround",
  },
  {
    value: "contrarian",
    display: "Contrarian",
  },
  {
    value: "high_flyer",
    display: "High Flyer",
  },
  {
    value: "super_stock",
    display: "Super stock",
  },
  {
    value: "sucker_stock",
    display: "Sucker stock",
  },
  {
    value: "value_trap",
    display: "Value trap",
  },
  {
    value: "momentum_trap",
    display: "Momement Trap",
  },
];
