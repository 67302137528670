import { Article } from "modules/cybertorial/articles";
import { Template } from "modules/cybertorial/templates";

export class Author {
  id!: string;

  firstName!: string;

  lastName!: string;

  image!: string;

  updatedAt!: string;

  biography?: string;

  articles?: Article[];

  templates?: Template[];

  totalTemplates: number = 0;

  totalArticles: number = 0;

  fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  static empty() {
    return new Author();
  }
}
