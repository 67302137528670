import 'medium-draft/lib/index.css'

import { CompositeDecorator, EditorState } from 'draft-js'
import {
  BLOCK_BUTTONS,
  Editor as BaseEditor,
  Block,
  INLINE_BUTTONS,
  Link,
  createEditorState,
  findLinkEntities,
  rendererFn as oldRendererFn
} from 'medium-draft'
import React, { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { AtomicBlock } from './components/atomic-block'
import { Portal } from './components/portal'
import { AtomicSeparatorComponent } from './components/separator'
import { Toolbar, ToolbarContext } from './components/toolbar'
import { ImageButton } from './sides/image'
import { SeparatorSideButton } from './sides/separator'
import { handleDroppedFiles, importer, insertData } from './utils'

type DraftDecorator = any

const blockButtons = [
  {
    label: 'H1',
    style: 'header-one',
    icon: 'header',
    description: 'Heading 1'
  },
  {
    label: 'H2',
    style: 'header-two',
    icon: 'header',
    description: 'Heading 2'
  }
].concat(BLOCK_BUTTONS)

const inlineButtons = [
  {
    label: 'C',
    style: 'CODE',
    icon: 'code',
    description: 'Code'
  },
  {
    label: 'S',
    style: 'STRIKETHROUGH',
    icon: 'strike',
    description: 'Strike through'
  }
].concat(INLINE_BUTTONS)

interface Props {
  toolbar?: () => React.ReactNode
  decorators?: DraftDecorator[]
  sideButtons?: {
    title: string
    component: JSX.Element
  }[]
  value?: EditorState | string
  onChange: (state: EditorState) => void
}

const FullscreenDiv = styled.div`
  background: #fafafb;
  height: 100%;
  width: 100%;
  padding: 40px;
  padding-top: 40px;
  & > div {
    box-shadow: 0 2px 4px #e3e9f3;
    background: #fff;
    padding: 20px;
  }
`

const EditorContainer = styled.div`
  font-size: 1.3rem;
  max-width: 950px;
  margin: auto;

  & .md-RichEditor-root {
    padding: 15px;
  }

  & > .md-RichEditor-editor .md-RichEditor-blockquote {
    border-left: 5px solid #bebebe;
    color: #555;
    font-size: 1.2em;
    margin: 0;
    padding: 10px 0 10px 20px;
    background-color: #efefef;
  }

  &
    > .public-DraftEditor-content
    .md-RichEditor-blockquote
    .md-block:first-child {
    padding-top: 10px;
  }

  & > .md-sb-button .MuiSvgIcon-root {
    width: 15px;
  }

  & .md-side-toolbar {
    left: -30px;

    & > div {
      background: var(--color-background);
      padding: 6px;
      margin-top: -2px;
      margin-left: -2px;
      padding-top: 2px;
      border-radius: 2px;
      padding-bottom: 2px;
      padding-right: 0px;
    }

    & .md-sb-button.md-sb-img-button {
      background: none;
      border-right: 1px solid #ccc;
      margin-right: 10px;
      border-radius: 0;

      &:last-of-type {
        border: none;
      }

      & svg {
        padding-right: 5px;
      }
    }
  }

  & .md-sb-button {
    background: none;
    border: none;
    color: #6d6d6d;
    cursor: pointer;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    font-weight: 700;
    font-size: 10px;
    margin-right: 2px;
    text-align: center;
    transition: all 0.2s ease;
  }
`

const defaultSides = [
  {
    title: 'Separator',
    component: SeparatorSideButton
  },
  {
    title: 'Image',
    component: ImageButton
  }
]

const defaultDecorators: DraftDecorator[] = [
  {
    strategy: findLinkEntities,
    component: Link
  }
]

const createState = (value?: EditorState, decorators?: DraftDecorator[]) =>
  EditorState.set(value ?? createEditorState(), {
    decorator: new CompositeDecorator([
      ...(decorators ?? []),
      ...defaultDecorators
    ])
  })

const rendererFn = (setEditorState, getEditorState, ...args) => {
  const atomicRenderers = {
    separator: AtomicSeparatorComponent
  }
  const rFnOld = oldRendererFn(setEditorState, getEditorState, ...args)
  const rFnNew = (contentBlock) => {
    const type = contentBlock.getType()
    switch (type) {
      case Block.ATOMIC:
        return {
          component: AtomicBlock,
          editable: false,
          props: {
            components: atomicRenderers,
            getEditorState
          }
        }
      default:
        return rFnOld(contentBlock)
    }
  }
  return rFnNew
}

export const Editor = ({
  toolbar,
  sideButtons = [],
  value: incomingState,
  onChange,
  decorators
}: Props) => {
  const newState =
    typeof incomingState === 'string' || incomingState instanceof String
      ? importer(incomingState.toString())
      : incomingState
  const editorRef = useRef()
  const [isFullScreen, setIsFullScreen] = useState(false)
  const value = createState(newState, decorators)

  const renderPortal = () => {
    return (
      <Portal onOverlayClicked={() => setIsFullScreen(false)}>
        <FullscreenDiv>{renderStandard()}</FullscreenDiv>
      </Portal>
    )
  }

  const actions = useMemo(
    () => ({
      toggleFullscreen: () => setIsFullScreen(!isFullScreen),
      insertData: insertData(onChange)
    }),
    [onChange, isFullScreen, setIsFullScreen]
  )

  const toolbarState = useMemo(() => ({ actions, state: value }), [
    actions,
    value
  ])

  const renderStandard = () => {
    return (
      <EditorContainer>
        <ToolbarContext.Provider value={toolbarState}>
          <Toolbar render={toolbar} />
        </ToolbarContext.Provider>
        <BaseEditor
          sideButtons={[...defaultSides, ...sideButtons]}
          handleDroppedFiles={handleDroppedFiles(onChange, value)}
          blockButtons={blockButtons}
          inlineButtons={inlineButtons}
          ref={editorRef}
          rendererFn={rendererFn}
          editorState={value}
          onChange={onChange}
        />
      </EditorContainer>
    )
  }

  return isFullScreen ? renderPortal() : renderStandard()
}
