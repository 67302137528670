import { createContext, useContext } from "react";
import { StateSelector, UseStore } from "zustand";

import { TemplatesState } from "../state";

export const TemplatesContext =
  createContext<UseStore<TemplatesState> | null>(null);

export const useTemplates = <U>(selector: StateSelector<TemplatesState, U>) =>
  useContext(TemplatesContext)!(selector);
