import { Loading } from "@stockopedia/cms-ui";
import React, { useEffect, useState } from "react";
import { LoadingState, TagGroup } from "../../models";
import { ErrorText } from "./error";
import { NewItemField } from "./new-item-field";

const SELECT_ITEM_MENU_ITEM_VALUE = "$SELECT_VALUE";
const NEW_GROUP_MENU_ITEM_VALUE = "$NEW_GROUP";

export interface TagGroupManagerProps {
  selectedTagGroup: string;
  tagGroups: TagGroup[] | null;

  onLoadTagGroups: () => Promise<void>;
  onSelectTagGroup: (group: string) => void;
  onAddTagGroup: (name: string) => Promise<void>;
}

export const TagGroupManager: React.FC<TagGroupManagerProps> = ({
  selectedTagGroup,
  tagGroups,
  onLoadTagGroups,
  onSelectTagGroup,
  onAddTagGroup,
}) => {
  const [loadStatus, setLoadStatus] = useState<LoadingState | null>(null);
  const [addStatus, setAddStatus] = useState<LoadingState | null>(null);
  const [isCreatingGroup, setCreatingGroup] = useState(false);
  const [hasNameError, setHasNameError] = useState(false);

  useEffect(() => {
    if (!tagGroups) {
      setLoadStatus(LoadingState.Loading);
      onLoadTagGroups()
        .then(() => setLoadStatus(LoadingState.Success))
        .catch(() => setLoadStatus(LoadingState.Error));
    }
  }, [tagGroups]);

  const handleSelectTagGroup = async (
    e: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const group = e.target.value as string;

    if (group === SELECT_ITEM_MENU_ITEM_VALUE) return;

    if (group === NEW_GROUP_MENU_ITEM_VALUE) {
      setCreatingGroup(true);
    } else {
      await onSelectTagGroup(group);
    }
  };

  const handleAddGroup = async (newGroupName: string) => {
    if (!hasNameError) {
      setAddStatus(LoadingState.Loading);
      try {
        await onAddTagGroup(newGroupName.trim());
        setAddStatus(LoadingState.Success);
      } catch {
        setAddStatus(LoadingState.Error);
      }
      setCreatingGroup(false);
    }
  };

  const handleCancelAddGroup = () => {
    setCreatingGroup(false);
  };

  const handleNameInput = (name: string) => {
    setHasNameError(
      name.trim().length === 0 ||
        !!tagGroups?.find((tagGroup) => tagGroup.group === name),
    );
  };

  if (isCreatingGroup) {
    return (
      <React.Fragment>
        <NewItemField
          error={hasNameError}
          onInput={handleNameInput}
          onChange={handleAddGroup}
          onCancel={handleCancelAddGroup}
          disabled={addStatus === LoadingState.Loading}
        />

        {addStatus === LoadingState.Error && (
          <ErrorText>Error adding tag group. Please try again.</ErrorText>
        )}
      </React.Fragment>
    );
  }

  if (
    loadStatus === LoadingState.Loading ||
    addStatus === LoadingState.Loading
  ) {
    return <Loading />;
  }

  if (!!tagGroups) {
    return (
      <select
        value={selectedTagGroup || SELECT_ITEM_MENU_ITEM_VALUE}
        onChange={handleSelectTagGroup}
      >
        <option value={SELECT_ITEM_MENU_ITEM_VALUE}>Select Tag Group</option>

        {tagGroups.map(({ group }) => (
          <option key={group} value={group}>
            {group}
          </option>
        ))}

        <option value={NEW_GROUP_MENU_ITEM_VALUE}>Add New...</option>
      </select>
    );
  }

  return null;
};
