import {
  Column,
  Padding,
  Row,
  SelectInput,
  TextInput,
} from "@stockopedia/cms-ui";
import { Operator } from "@stockopedia/screener-query-sdk";
import { useRatioPickerContext } from "shared/components/ratio-picker/context";
import { Ratio, RatioDataType } from "shared/components/ratio-picker/types";

import { useScreenBuilderContext } from "../builder/context";
import { OPERATOR_OPTIONS, RuleState, Subject } from "../types";
import { ScreenRuleProvider, useScreenRuleContext } from "./context";
import { NumberComparison } from "./number-comparison";
import { StringComparison } from "./string-comparison";

const Col = ({ children }) => (
  <Column>
    <Padding right size="xs">
      {children}
    </Padding>
  </Column>
);

export interface Props {
  value: RuleState;
  onChange: (state: RuleState) => void;
}

export const Rule = ({ onChange, value }: Props) => {
  return (
    <ScreenRuleProvider onChange={onChange} value={value}>
      <View />
    </ScreenRuleProvider>
  );
};

const View = () => {
  const { actions } = useScreenBuilderContext();
  const { actions: ruleActions, subject, operator } = useScreenRuleContext();
  const { actions: pickerActions } = useRatioPickerContext();

  const ratioType = showStringComparison(subject) ? "string" : "number";

  return (
    <Row>
      <Col>
        <TextInput
          value={
            subject &&
            `${subject?.name} ${subject.modifier ? subject.modifier : ""}`
          }
          onClick={() => {
            actions.setRatioSelectAction((ratio) => {
              ruleActions.setSubject(new Subject(ratio));
              pickerActions.showModal(false);
            });
            pickerActions.setSelected(subject as Ratio);
            pickerActions.showModal(true);
          }}
          onChange={() => {}}
          name="ratio_name"
        />
      </Col>
      <Col>
        <SelectInput
          value={getOperatorDisplay(operator, ratioType)}
          options={optionsList(ratioType).map((x) => x.display)}
          onChange={(v) =>
            ruleActions.setOperator(getOperatorValue(v, ratioType))
          }
          name="operator"
        />
      </Col>
      <Col>
        {showStringComparison(subject) ? (
          <StringComparison />
        ) : (
          <NumberComparison />
        )}
      </Col>
    </Row>
  );
};

const getOperatorValue = (value: string, ratioType: RatioDataType) =>
  optionsList(ratioType).find((x) => x.display === value)!.value;

const getOperatorDisplay = (value: Operator, ratioType: RatioDataType) =>
  (optionsList(ratioType).find((x) => x.value === value) ??
    optionsList(ratioType)[0])!.display;

const optionsList = (type: RatioDataType) => {
  return OPERATOR_OPTIONS[type];
};

const showStringComparison = (ratio?: Subject) => {
  if (!ratio) {
    return false;
  }
  return ratio.dataType === "string" || stringRatios.includes(ratio.field);
};

const stringRatios = [
  "region.country",
  "ratios._TRBCEconSectCode",
  "ratios._TRBCIndCode",
  "ratios._TRBCIndGrpCode",
  "region.region",
  "ratios._RiskRating",
  "ratios._MktCapGroup_gb",
  "ratios._StockRankStylePrev_zz",
  "ratios._StockRankStyle_zz",
  "ratios._ExchangeCode",
  "ratios._Indices",
  "ratios._Exchange",
];
