import { Category, DataSource, RatioUnit } from "./types";

export const CATEGORIES: Category[] = [
  {
    name: "Valuation Ratios",
    description:
      "<p><em>Valuation Ratios</em> provide a means to compare the price of a company's shares against some measure of fundamental value (for example earnings, sales or assets). These ratios are useful rules of thumb for comparing companies against their peer groups to assess their relative cheapness. Stockopedia includes a range of price & enterprise value based ratios in both standard ratio and inverted yield formats.</p>",
    ratios: [],
    categories: [
      {
        name: "Value Rankings",
        description:
          "<p>The <em>Value Rankings</em> are Stockopedia's proprietary approach to measuring the value of stocks. Stockopedia's Value Rank is based on an equal weighted blend of traditional value ratios including the P/B, P/E, P/S, P/FCF, Earnings Yield and Dividend Yield. The QV Rank (Quality + Value) and VM Rank (Value + Momentum) are equally weighted combinations of Stockopedia's Quality and Value Ranks and Value and Momentum Ranks respectively.</p>",
        ratios: [
          {
            name: "Stockopedia Value Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>Value Rank</strong> is a ranking system based on an equal weighted blend of traditional value ratios including the P/B, P/E, P/S, P/FCF, Earnings Yield and Dividend Yield.   These inputs are computed for every company in the market on a daily basis, ranked, blended and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__ValueScore",
          },
          {
            name: "Stockopedia QV Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>QV Rank</strong> provides a simple but effective system for finding good, cheap (quality+value) shares. It is an equally weighted combination of Stockopedia's QualityRank and ValueRank.  These two basic ranks are computed for every company in the market on a daily basis, summed and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__QVScore",
          },
          {
            name: "Stockopedia VM Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>VM Rank</strong> provides a simple but effective system for finding cheap, improving (value+momentum) shares. It is an equally weighted combination of Stockopedia's ValueRank and MomentumRank.  These two basic ranks are computed for every company in the market on a daily basis, summed and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__VMScore_zz",
          },
        ],
        categories: [],
      },
      {
        name: "Price to Income Ratios",
        description:
          "<p>The <em>Price to Income Ratios</em> section contains some of the most commonly used valuation multiples and measures that compare a company's share price against common measures of earnings. These include the price to earnings ratio (P/E ratio), the price to sales ratio (P/S ratio) and the PEG ratio and its variants. The cyclically adjusted price to earnings ratio (CAPE) or Shiller P/E is a popular long run valuation ratio that divides the share price by the average of the past 10 years' earnings data.</p>",
        ratios: [
          {
            name: "Price to Earnings Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and earnings are diluted and normalised.</p>',
            field: "data.ratios__PENormal_TTM",
          },
          {
            name: "Price to Earnings Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling",
            description:
              "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  This is measured on a rolling basis and earnings are diluted and normalised.</p>",
            field: "data.ratios__PENormal_Ro",
          },
          {
            name: "Price to Earnings Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling 1y",
            description:
              "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  This is measured on a 1 year rolling basis and earnings are diluted and normalised.</p>",
            field: "data.ratios__PENormal_Ro1",
          },
          {
            name: "Price to Earnings Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling 2y",
            description:
              "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  This is measured on a 2 year rolling basis and earnings are diluted and normalised.</p>",
            field: "data.ratios__PENormal_Ro2",
          },
          {
            name: "Price to Earnings Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Forecast 1y",
            description:
              "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  This is a 1 year forecast and earnings are diluted and normalised.</p>",
            field: "data.ratios__PENormal_FY1",
          },
          {
            name: "Price to Earnings Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Forecast 2y",
            description:
              "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  This is a 2 year forecast and earnings are diluted and normalised.</p>",
            field: "data.ratios__PENormal_FY2",
          },
          {
            name: "Price to Earnings Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Avg",
            description:
              "<p>The <strong>Price to Average Earnings Ratio</strong> is a common long run valuation ratio used by equity investors. It is the price per share divided by earnings per share.  This is an average of the past 5 years’ earnings data and earnings are diluted and normalised.</p>",
            field: "data.ratios__AvgPENormal_5Yrs",
          },
          {
            name: "Price to Sales Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Price to Sales Ratio</strong>, or <strong>PS Ratio</strong>, is a popular valuation ratio.  It is the share price of a company divided by its sales per share.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and earnings are diluted and normalised.</p>',
            field: "data.ratios__Pr2Sales_TTM",
          },
          {
            name: "Price to Sales Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Price to Sales Ratio</strong>, or <strong>PS Ratio</strong>, is a popular valuation ratio.  It is the share price of a company divided by its sales per share.  This is measured on a historical basis and earnings are diluted and normalised.</p>",
            field: "data.ratios__Pr2Sales_RA",
          },
          {
            name: "Price to Average Earnings",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "10y",
            description:
              "<p>The <strong>Cyclically Adjusted Price to Earnings Ratio</strong>, or <strong>CAPE Ratio</strong>, is a common long run valuation ratio used by equity investors. It is the price per share divided by earnings per share.  This is an average of the past 10 years’ earnings data and earnings are diluted and normalised.</p>",
            field: "data.ratios__PENormal_Avg_10Yr",
          },
          {
            name: "Price to Average Earnings",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "6y",
            description:
              "<p>The <strong>Cyclically Adjusted Price to Earnings Ratio</strong>, or <strong>CAPE Ratio</strong>, is a common long run valuation ratio used by equity investors. It is the price per share divided by earnings per share.  This is an average of the past 6 years’ earnings data and earnings are diluted and normalised.</p>",
            field: "data.ratios__PENormal_Avg_6Yr",
          },
          {
            name: "Price to Average Earnings",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3y",
            description:
              "<p>The <strong>Cyclically Adjusted Price to Earnings Ratio</strong>, or <strong>CAPE Ratio</strong>, is a common long run valuation ratio used by equity investors. It is the price per share divided by earnings per share.  This is an average of the past 3 years’ earnings data and earnings are diluted and normalised.</p>",
            field: "data.ratios__PENormal_Avg_3Yr",
          },
          {
            name: "PEG Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Price to Earnings Growth Ratio</strong>, or <strong>PEG Ratio</strong>, measures of the value of a company against its earnings and growth rate.  It is calculated by taking the historic Price to Earnings Ratio (based on last year\'s diluted normalised Earnings) and dividing it by the consensus forecast EPS growth for the next year.   This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and earnings are diluted and normalised.</p>',
            field: "data.ratios__PEG_TTM",
          },
          {
            name: "PEG Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling",
            description:
              "<p>The <strong>Price to Earnings Growth Ratio</strong>, or <strong>PEG Ratio</strong>, measures of the value of a company against its earnings and growth rate.  It is calculated by taking the historic Price to Earnings Ratio (based on last year's diluted normalised Earnings) and dividing it by the consensus forecast EPS growth for the next year.   This is measured on a rolling basis and earnings are diluted and normalised.</p>",
            field: "data.ratios__PEG_Ro",
          },
          {
            name: "PEG Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Jim Slater",
            description:
              "<p>Jim Slater defined his own version of the <strong>PEG</strong> ratio in the book 'The Zulu Principle. This version uses both forecast rolling PE ratio and forecast eps growth rates, and incorporates the additional restriction that a companies must have 4 consecutive growth periods, albeit potentially including two forecast periods.</p>",
            field: "data.ratios__PEG_Slater",
          },
          {
            name: "PEG Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Growth",
            description:
              "<p>The <strong>Price to Earnings Growth Ratio</strong>, or <strong>PEG Ratio</strong>, measures of the value of a company against its earnings and growth rate.  It is calculated by taking the historic Price to Earnings Ratio (based on last year's diluted normalised Earnings) and dividing it by the consensus forecast EPS growth for the next year.   This is measured as an average of the past 5 years' historical TTM earnings values and earnings are diluted and normalised.</p>",
            field: "data.ratios__PEG_5yr_TTM",
          },
          {
            name: "Risk Adjusted PEG Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling",
            description:
              "<p>The <strong>Risk Adjusted PEG Ratio</strong>, or <strong>PEGR</strong>, is the PEG ratio adjusted for risk.  It is calculated as the Rolling PEG Ratio multiplied by the Beta of the share price.  This is measured on a rolling basis and earnings are diluted and normalised.</p>",
            field: "data.ratios__PEGR_Ro",
          },
          {
            name: "PEGY Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Price to Earnings Growth and Yield Ratio</strong>, or <strong>PEGY Ratio</strong>, tries to find cheap growth companies which are paying good dividend yields and is calculated as the PE Ratio divided by the sum of the Earnings Growth Rate and the Dividend Yield.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and earnings are diluted and normalised.</p>',
            field: "data.ratios__PEGY_TTM",
          },
          {
            name: "PEGY Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling",
            description:
              "<p>The <strong>Price to Earnings Growth and Yield Ratio</strong>, or <strong>PEGY Ratio</strong>, tries to find cheap growth companies which are paying good dividend yields and is calculated as the PE Ratio divided by the sum of the Earnings Growth Rate and the Dividend Yield.  This version uses the rolling current PE Ratio and Dividend Yield with 12m forward rolling EPS Growth rate.</p>",
            field: "data.ratios__PEGY_Ro",
          },
          {
            name: "Price to Research Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Price to Research Ratio</strong>, or <strong>PR Ratio</strong>, is used to value a company against the amount it spends on R&D.  It is calculated as Market Capitalisation divided by R&D Expenditure.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and the uses diluted shares outstanding.</p>',
            field: "data.ratios__Pr2Research_TTM",
          },
          {
            name: "Price to Pre Tax Earnings",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Price to Pre-Tax Profit Ratio</strong>, or <strong>P / PTE Ratio</strong>, measures a company’s value against its Pre-Tax Earnings.  It is calculated as the company\'s market capitalisation divided by the pre-tax profit.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and uses diluted shares outstanding.</p>',
            field: "data.ratios__P_PTE_TTM",
          },
        ],
        categories: [],
      },
      {
        name: "Price to Cashflow Ratios",
        description:
          "<p><em>Price to Cashflow Ratios</em> allow a comparison of a company's valuation against is ability to generate cash. Stockopedia lists price to free cashflow (P/FCF), price to operating cashflow (P/CFO) and price to cash flow growth (P/CF Growth), which is similar to the PEG ratio. The operating cashflow yield (OCF yield) is similar to the earnings yield but strips out non-cash income and expenses and certain one-off items.</p>",
        ratios: [
          {
            name: "Price to Free Cash Flow",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Price to Free Cash Flow Ratio</strong>, or <strong>P / FCF Ratio</strong>, values a company against its Free Cash Flow.  It is the Share Price of the company divided by its Free Cash Flow per Share.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and uses diluted shares outstanding.</p>',
            field: "data.ratios__Pr2FCF_TTM",
          },
          {
            name: "Price to Operating Cash Flow",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Price to Operating Cash Flow Ratio</strong>, or <strong>P / OCF Ratio</strong>, values a company against its Operating Cash Flow.  It is the Share Price of the company divided by its Free Cash Flow per Share.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and uses diluted shares outstanding.</p>',
            field: "data.ratios__Pr2OCF_TTM",
          },
          {
            name: "Price to Cash Flow Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The ** Price to Cash Flow Growth Ratio**, or <strong>P / CFG Ratio</strong>, is a Cash Flow oriented valuation measure that takes into account the growth rate of a firm.  It is the Share Price divided by the Cash Flow per Share divided by the Cash Flow growth rate.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and uses diluted shares outstanding.</p>',
            field: "data.ratios__PCFG_TTM",
          },
          {
            name: "Operating Cash Flow Yield",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Opearting Cash Flow Yield</strong>, or <strong>OCF Yield</strong>, is the cash return that a company earns on its assets.  It is the Operating Cash Flow per Share divided by Total Assets.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and uses diluted shares outstanding.</p>',
            field: "data.ratios__CashOpActYieldPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
      {
        name: "Price to Assets Ratios",
        description:
          "<p>This following <em>Price to Assets</em> ratios compare a company's share price against a range of its tangible assets. These include price to book (P/B), price to net current asset value (P/NCAV) and price to net cash/debt.</p>",
        ratios: [
          {
            name: "Price to Book Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Price to Book Ratio</strong>, or <strong>P / B Ratio</strong>, is a financial ratio used to compare a company's Book Value to its current market price and is a key metric for value investors.  This is calculated as the Current Price divided by the latest annual Book Value Per Share.  This figure is computed from the latest available interim accounts.</p>",
            field: "data.ratios__PBV_RQ",
          },
          {
            name: "Price to Tangible Book Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Price to Tangible Book Ratio</strong>, or <strong>P / TB Ratio</strong>, is a financial ratio used to compare a company's Tangible Book Value to its current market price and is a key metric for value investors.  This is calculated as the Current Price divided by the latest annual Tangible Book Value Per Share.  This figure is computed from the latest available interim accounts.</p>",
            field: "data.ratios__PTanBV_RQ",
          },
          {
            name: "Price to Net Current Asset Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Price to Net Current Asset Value Ratio</strong>, or <strong>P / NCAV Ratio</strong>, attempts to value a share against its Net Current Asset Value.  It is the share price divided by the firm’s Net Current Asset Value.  This figure is computed from the latest available interim accounts.</p>",
            field: "data.ratios__Pr2NCAV_RQ",
          },
          {
            name: "Price to Net Current Asset Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Price to Net Current Asset Value Ratio</strong>, or <strong>P / NCAV Ratio</strong>, attempts to value a share against its Net Current Asset Value.  It is the share price divided by the firm’s Net Current Asset Value.  This figure is computed from last year’s accounts.</p>",
            field: "data.ratios__Pr2NCAV_RA",
          },
          {
            name: "Price to Net Net Working Capital",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Price to Net Net Working Capital Ratio</strong>, or <strong>P / NNWC Ratio</strong>, values a company against its “fire sale” value.  It is the share priced divided by the firm’s Net Net Working Capital per Share.  This figure is computed from the latest available interim accounts.</p>",
            field: "data.ratios__Pr2NNWC_RQ",
          },
          {
            name: "Price to Net Debt",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Price to Net Debt Ratio</strong> values a company against its Net Debt figure.  It is the share price divided by the Net Debt per share.  This figure is computed from the latest available interim accounts.</p>",
            field: "data.ratios__Pr2NetDebt_RQ",
          },
          {
            name: "Price to Cash",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Price to Cash Ratio</strong> attempts to value a share against the Cash it owns.  It is the share price divided by the firm’s Cash.  This figure is computed from the latest available interim accounts.</p>",
            field: "data.ratios__Pr2Cash_RQ",
          },
          {
            name: "Price to Net Cash",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Price to Net Cash Ratio</strong> attempts to value a share against the Net Cash it owns.  It is the share price divided by the firm’s Net Cash.  This figure is computed from the latest available interim accounts.</p>",
            field: "data.ratios__Pr2NetCash_RQ",
          },
          {
            name: "Price to Cash Net of Burn",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Price to Cash Net of Burn Ratio</strong> values a company against it’s Cash, net of the Cash Burn Rate.  it is the Share Price divided by the Cash it owns, net of the Cash Burn Rate.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__Pr2CashNetofBurn_TTM",
          },
          {
            name: "Price to Cash Net of Current Liabilities",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Price to Cash Net of Current Liabilities Ratio</strong> attempts to value a company against the Cash Net of Current Liabilities that it owns.  It is the share price divided by the firm’s Cash Net of Current Liabilities.  This figure is computed from the latest available interim accounts.</p>",
            field: "data.ratios__Pr2CashNetofCL_RQ",
          },
          {
            name: "Net Debt to Market Cap",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Net Debt to Price Ratio</strong> is the inverse of the Price to Net Debt Ratio.  This figure is computed from the latest available interim accounts.</p>",
            field: "data.ratios__NetDebt2Price_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
      {
        name: "Enterprise Value Ratios and Multiples",
        description:
          "<p>These <em>Enterprise Value Ratios</em> allow investors to compare the total valuation of a company's enterprise (including the market value of its stock and its net debt) to a variety of fundamental measures. This allows companies with different capital structures to be compared. The Earnings Yield is a measure of how much a company earns relative to its Enterprise Value, while EV/EBIT, EV, EBITDA, EV/Sales and EV/FCF are all widely-used relative valuation multiples.</p>",
        ratios: [
          {
            name: "Earnings Yield",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Earnings Yield</strong> is a measure of how much a company earns relative to its Enterprise Value.  It is defined as the Earnings Before Interest and Tax divided by the Enterprise Value.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__EYPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Earnings Yield - EBIT/EV",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Earnings Yield</strong> is a measure of how much a company earns relative to its Enterprise Value.  It is defined as the Earnings Before Interest and Tax divided by the Enterprise Value.  This figure is computed from last year’s accounts.</p>",
            field: "data.ratios__EYPercentRA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Enterprise Value to Operating Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Enterprise Value to Operating Profit Ratio</strong>, or <strong>EV / EBIT Ratio</strong>, contrasts a company’s Enterprise Value to its EBIT.  It is defined as Enterprise Value divided by EBIT.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__EV2OpInc_TTM",
          },
          {
            name: "Enterprise Value to EBITDA",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Enterprise Value to EBITDA Ratio</strong>, or <strong>EV / EBITDA Ratio</strong> contrasts a company’s Enterprise Value relative to its EBITDA.  It is defined as Enterprise Value divided by EBITDA.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__EV2EBITDA_TTM",
          },
          {
            name: "Enterprise Value / Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Enterprise Value to Sales Ratio</strong> contrasts a company’s Enterprise Value relative to its Total Sales.  It is defined as Enterprise Value divided by the Sales outstanding.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__EV2REV_TTM",
          },
          {
            name: "Enterprise Value to Free Cash Flow",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Enterprise Value to Free Cash Flow Ratio</strong>, or <strong>EV / FCF Ratio</strong>, contrasts a company’s Enterprise Value relative to its Free Cash Flow.  It is defined as Enterprise Value divided by Free Cash Flow.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__EV2FCF_TTM",
          },
        ],
        categories: [],
      },
      {
        name: "Guru Valuation Metrics",
        description:
          '<p><em>The Guru Valuation</em> category of metrics contains several measures of value - two inspired by the "father of value investing" Benjamin Graham (The Graham Multiplier and the Graham Deep Value Score) and one from Joel Greenblatt (The Magic Formula, taken from his bestselling book "The Little Book That Beats The Market").</p>',
        ratios: [
          {
            name: "Earnings Yield (Greenblatt) Rank",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description: "<p></p>",
            field: "ratios._MagicFormulaEYPosition_TTM_zz",
          },
          {
            name: "Magic Formula (Greenblatt)",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            modifier: "Rank",
            description:
              "<p>This is Stockopedia's version of the <strong>Magic Formula</strong> as defined in Joel Greenblatt's bestselling book \"The Little Book that Beats the Market\".  The is the Rank stated as a number.</p>",
            field: "data.ratios__MagicFormulaRank_TTM_zz",
          },
          {
            name: "Magic Formula (Greenblatt)",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Percentile",
            description:
              "<p>This is Stockopedia's version of the <strong>Magic Formula</strong> as defined in Joel Greenblatt's bestselling book \"The Little Book that Beats the Market\".  The is the Rank stated as a percentage.</p>",
            field: "data.ratios__MagicFormulaPercentile_TTM_zz",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Ben Graham Multiplier",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>In the Intelligent Investor, the classic book on Value Investing by Benjamin Graham, <strong>The Graham Multiplier</strong> is defined as the Price to Earnings Ratio multiplied by the Price to Book Ratio.</p>",
            field: "data.ratios__GrahamMultiplier",
          },
          {
            name: "Ben Graham Deep Value Score",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p><strong>The Graham Deep Value Score</strong> is a scoring system developed by Benjamin Graham and aeronautical engineer James Rea.  It scores stocks out of 10 depending on how many tests they pass across 5 valuation and 5 risk measures.</p>",
            field: "data.ratios__GrahamChecklist",
          },
          {
            name: "Return on Capital (Greenblatt) Rank",
            dataSource: DataSource.BATCH,
            dataType: "int",
            description: "<p></p>",
            field: "ratios._MagicFormulaROCPosition_TTM_zz",
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "Technicals",
    description: "<p></p>",
    ratios: [],
    categories: [
      {
        name: "Overlays",
        description: "<p></p>",
        ratios: [
          {
            name: "Moving Average",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>A <strong>Moving Average</strong> simply plots the average share price over a specified number of periods (e.g. 200 days). The values plotted on the chart are calculated by taking the sum of all prices over a given timeframe and then dividing by the number of periods (in this case 200). The period used depends on the timescale of the chart but is generally days, weeks or months, or an intraday period like minutes or hours.</p>",
            field: "technicals._MovingAverage",
          },
          {
            name: "Bollinger Bands",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p><strong>Bollinger Bands</strong> are overlay channels that are drawn using a stock’s <strong>Moving Average (MA)</strong> and the standard deviation from the Moving Average. They consist of three bands:</p><ol><li><p>A middle band (typically plotted using a stock’s 20-day moving average)</p></li><li><p>A lower band (typically plotted one or two standard deviations <em>below</em> the moving average)</p></li><li><p>An upper band (typically plotted one or two standard deviations <em>above</em> the moving average)</p></li></ol>",
            field: "technicals._BollingerBands",
          },
          {
            name: "Donchian Channel",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Donchian Channel</strong> is an overlay which shows the price range within which a stock traded during a given period. The Channel consists of two bands: an upper band which plots the highest price for a given period (ie. the Highest High); and a lower band charting the lowest price reached during a given period (ie. the Lowest Low). When the indicator was first created, the Donchian Channels typically tracked one month’s worth of data, so upper and lower bands plotted the highest and lowest prices reached over a 20-day period.</p>",
            field: "technicals._DonchianChannel",
          },
          {
            name: "Ichimoku Clouds",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>Ichimoku Clouds are essentially the shaded areas between various trend lines. Indeed, Ichimoku Clouds consist of the following trend lines:</p><ul><li><p>The <strong>Conversion Line (or Tenkan-Sen)</strong>: This is typically the midpoint (ie. average) of a 9-period High-Price and a 9-period Low-Price. The period can be days, weeks or even months.</p></li><li><p>The <strong>Baseline (or Kijun-Sen)</strong>: This is typically the midpoint of a 26-period High-Price and a 26-period Low-Price.</p></li><li><p>The <strong>Leading (Senkou) Span A</strong>: This is the midpoint between the Conversion and Base lines. It is also plotted 26 periods ahead of the current period.</p></li><li><p>The <strong>Leading (Senkou) Span B</strong>: This is the midpoint between the 52-period High and the 52-period Low. Like the Senkou Span A, it is plotted 26 periods into the future.</p></li><li><p>The space between Span A and Span B is a shaded area known as the <strong>Kumo Cloud</strong>. It is red when Span A is below Span B. It turns green when Span A rises above Span B.</p></li><li><p>The <strong>Lagging (Chikou) Span</strong> is the Closing Price, plotted over the previous 26 periods.</p></li></ul>",
            field: "technicals._IchimokuClouds",
          },
          {
            name: "Pivot Points",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p><strong>Pivot Points</strong> are horizontal lines that plot the average of a company’s most recent High, Low and Closing price. They also overlay a series of support and resistance levels. In the image below, the top red lines represent resistance, while the bottom green levels represent support. The white line in the middle is the Pivot Point.</p><p>The support and resistance levels are plotted using the following calculations:</p><ul><li><p>P = Price</p></li><li><p>First resistance level (R1) = (P x 2) - L</p></li><li><p>First support level (S1) = (P x 2) - H</p></li><li><p>Second resistance level (R2) = P + (H - L) = P + (R1 - S1)</p></li><li><p>Second support level (S2) = P - (H - L) = P - (R1 - S1)</p></li><li><p>Third support level (S3) =  P – 2 x (H - L)</p></li><li><p>Third resistance level (R3) =  P + 2 x (H – L)</p><p>Where H, L, C are the previous period’s high, low and close and P is the Pivot Point Value.</p></li></ul>",
            field: "technicals._PivotPoints",
          },
          {
            name: "ATR Bands",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p><strong>ATR Bands</strong> are channels that are plotted around a stock's <strong>Average True Range (ATR)</strong>.</p><ul><li><p>The upper band is plotted along the value of the ATR plus a specified multiple of the ATR.</p></li><li><p>The lower band is plotted along the value of the ATR minus a specified multiple of the ATR.</p></li></ul>",
            field: "technicals._ATRBands",
          },
          {
            name: "ATR Trailing Stop",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>ATR Trailing Stop</strong> is an overlay which plots potential stop losses based on a multiple of the <strong>Average True Range (ATR)</strong>. The ATR Trailing Stop is plotted above (or below) the price when the stock is in a downtrend (or uptrend). For example, if the stock price was 100p, the Average True Range was 2p and a trader chose an ATR multiple of 3, then the Trailing Stop would be plotted at 94p during an uptrend (ie. 100p - &#91;2p x 3]). Conversely, during a downtrend the Trailing Stop would be plotted at 106p (ie. 100p + &#91;2p x 3]). Welles Wilder, who invented the ATR Trailing Stop preferred ATR multiple of 3.</p>",
            field: "technicals._ATRTrailingStop",
          },
          {
            name: "Parabolic SAR",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Parabolic SAR</strong> is an overlay which plots a series of dots above or below the price, depending on which direction a stock is trading in. If the stock is in an uptrend, the dot is placed below the price. Conversely, the dot is placed above the price if the stock is in a downtrend.</p>",
            field: "technicals._ParabolicSAR",
          },
          {
            name: "ZigZag",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The ZigZag overlay ignores any price movement which is less than a given percentage (typically 10%). The overlay is drawn using a series of lines which change direction when the share price moves more than the predefined percentage.</p>",
            field: "technicals._ZigZag",
          },
        ],
        categories: [],
      },
      {
        name: "Indicators",
        description: "<p></p>",
        ratios: [
          {
            name: "Moving Average Convergence/Divergence",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p><strong>MACD</strong> is a momentum and trend-following indicator. It is the difference between a longer-period and a shorter-period exponential moving average of closing prices. Typically, 12-day and 26-day periods are used and the formula is as follows:</p><p>MACD Line = 12-day exponential moving average - 26-day exponential moving average</p><p>MACDs also plot a <strong>Signal</strong> <strong>Line</strong>, which is the 9-day EMA of MACD Line</p>",
            field: "technicals._MACD",
          },
          {
            name: "Relative Strength Index",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Relative Strength Index (RSI)</strong> is a technical momentum indicator that compares the magnitude of recent gains to recent losses. It is calculated as follows: RSI = 100 - (100 / (1+ RS))</p><p>where RS = AU / AD</p><p>and AU = Average of the 'up closes' = number of days where the price closes higher than the open / number of days</p><p>and AD = Average of the 'down closes' = number of days where the price closes lower than the open / number of days</p><p>The number of days used in the formula is a matter of preference. The default value for stocks is 14.</p>",
            field: "technicals._RSI",
          },
          {
            name: "Momentum Indicator",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Momentum Indicator</strong> is simply the difference between the current price and the closing price a specified number of periods ago (typically 10 days, 21 days or 52 weeks ago). The indicator measures the rate at which the price of a particular stock is rising or falling.</p>",
            field: "technicals._MomentumIndicator",
          },
          {
            name: "Stochastic Oscillator",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Stochastic</strong> <strong>Oscillator</strong> is a momentum indicator that measures where the stock price is in relation to the recent trading range. A 14-day trading period is usually used. The Oscillator is made up of two lines: %K and %D. If the Oscillator is <em>not</em> <em>smoothed (ie. Fast)</em>, the lines are calculated as follows:</p><ul><li><p>Fast %K = 100&#42;(C - L)/(H - L), where C is the most recent close, while L and H represent the lowest and highest prices traded during a given period.</p></li><li><p>Fast %D = 3-day moving average of Fast %K</p></li></ul><p>If the Oscillator <em>is</em> <em>smoothed (ie. Slow)</em>, the lines are calculated as follows:</p><ul><li><p>Slow %K = 3-day moving average of Fast %K</p></li><li><p>Slow %D = 3-day moving average of Slow %K</p></li></ul>",
            field: "technicals._StochasticOscillator",
          },
          {
            name: "Commodity Channel Index",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Commodity Channel Index (CCI)</strong> is an oscillator which measures the current price of an asset in relation to its average price over a given period of time (typically 20 days). It is calculated using the following formula:</p><p><strong>CCI</strong> = &#91;Typical Price - Moving Average of Typical Price] / (0.015 &#42; Mean Deviation)</p><p><strong>where Typical Price</strong> = (High + Low + Close) / 3</p><p><strong>and Mean Absolute Deviation</strong> = Summation of the Absolute Value of the Typical Price and its N-period Moving Average / N</p>",
            field: "technicals._CCI",
          },
          {
            name: "Williams %R",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Williams %R</strong> is a momentum indicator which compares a stock’s current price to its Highest High and Lowest Low price over a specified period of time (typically 14 days). It is calculated using the following formula:</p><p>Williams %R = &#91;(Period Highest High - Current Price) /  (Period Highest High - Period Lowest Low)] &#42; -100</p>",
            field: "technicals._Williams%R",
          },
          {
            name: "Aroon Indicator",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Aroon Indicator</strong> consists of two lines: the Aroon Up and the Aroon Down. The Aroon Up measures the strength of the uptrend, while the Aroon Down measures the strength of the downtrend. The values plotted for each line are calculated as follows:</p><ul><li><p>Aroon Up = ((n- Days Since n-day High)/n) x 100</p></li><li><p>Aroon Down = ((n – Days Since n-day Low)/n) x 100</p><p>n is simply the number of days in a given period. For example, if we were plotting the Aroon Up for a 25 day period, n would simply be 25 (ie. Aroon Up = ((25-Days Since 25-day High)/25) x 100).</p></li></ul>",
            field: "technicals._AroonIndicator",
          },
          {
            name: "Aroon Oscillator",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Aroon Oscillator</strong> is simply the difference between the Aroon Up and the Aroon Down. The Aroon Up measures the strength of the uptrend, while the Aroon Down measures the strength of the downtrend. The values plotted for each Aroon line are calculated as follows:</p><ul><li><p>Aroon Up = ((n- Days Since n-day High)/n) x 100</p></li><li><p>Aroon Down = ((n – Days Since n-day Low)/n) x 100</p></li><li><p>Aroon Oscillator = Aroon Up - Aroon Down</p><p>n is simply the number of days in a given period. For example, if we were plotting the Aroon Up for a 25 day period, n would simply be 25 (ie. Aroon Up = ((25-Days Since 25-day High)/25) x 100).</p></li></ul>",
            field: "technicals._AroonOscillator",
          },
          {
            name: "Volume Oscillator",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "integer",
            description:
              "<p>Volume is simply the number of shares that have been traded over a given period (eg. weeks, days or hours). In turn, the <strong>Volume Oscillator</strong> identifies volume trends by using a Fast Moving Average (typically a 28 day MA of volume) and a Slow Moving Average (typically 14 day MA of volume). Their difference is then plotted as a histogram.</p>",
            field: "technicals._VolumeOscillator",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Bollinger Bandwidth",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Bollinger Bandwidth</strong> measures the difference between the upper and the lower bollinger Bands, as a percentage of the Moving Average. The indicator is calculated as follows:</p><p>Bandwidth = ((Upper Bollinger Band – Lower Bollinger Band) / Moving Average) &#42; 100</p>",
            field: "technicals._BollingerBandwidth",
          },
          {
            name: "Standard Deviation",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p><strong>Standard Deviation</strong> is a volatility indicator which measures the dispersion of a set of data values around the average.</p>",
            field: "technicals._StandardDeviation",
          },
          {
            name: "Average True Range",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Average True Range (ATR)</strong> is a volatility indicator. It is a moving average of the True Range (typically a 14 day moving average). In turn, the True Range is the greatest of the following:</p><ul><li><p>Today's high - Today's low</p></li><li><p>Today's high - Yesterday's close</p></li><li><p>Yesterday's close - Today's low</p></li></ul>",
            field: "technicals._ATR",
          },
          {
            name: "Volume Chart",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "integer",
            description:
              "<p><strong>Volume</strong> is simply the number of shares that have been traded over a given period (eg. weeks, days or hours).</p>",
            field: "technicals._VolumeChart",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Average Directional Index and Directional Movement System",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Average Directional Index and Directional Movement System (ADX/DMS)</strong> is a technical indicator which plots the <strong>Average Directional Index (ADX)</strong> alongside the <strong>Plus Directional Indicator (+DI)</strong> and the <strong>Minus Directional Indicator (-DI)</strong>.</p><ul><li><p>The -DI measures the strength of a downtrend. It is calculated using the following formula: <em>100 x Exponential Moving Average of (-DM / Average True Range)</em>. In turn, -DM, or Minus Directional Movement is the value of a stock's current low price minus the previous low price.</p></li><li><p>The +DI measures the strength of an uptrend. It is calculated using the following formula: <em>100 x Exponential Moving Average of (+DM / Average True Range)</em>. In turn, +DM, or Plus Directional Movement is the value of a stock's current high price minus the previous high price.</p></li><li><p>The ADX is an indicator that helps traders gauge the strength and direction of a trend. It is calculated using the following formula: <em>100 x Exponential Moving Average of the Absolute Value of (+DI - -DI) / (+DI + -DI)</em>.</p></li></ul>",
            field: "technicals._AdxDms",
          },
          {
            name: "On Balance Volume",
            dataSource: DataSource.BATCH,
            dataType: "int",
            description:
              "<p>On Balance Volume (OBV) is a momentum indicator that plots a running total of the volume. OBV is charted using three variables: the closing price, the trading volume and the trend direction (ie. whether the most recent close is above, below or equal to the previous close).</p><ul><li><p>If the most recent close is <strong>higher</strong> than the previous close, then today's OBV would be yesterday's OBV <strong>plus</strong> today's trading volume.</p></li><li><p>If the most recent close is <strong>lower</strong> than the previous close, then today's OBV would be yesterday's OBV <strong>minus</strong> today's trading volume.</p></li><li><p>If today's close is the <strong>same</strong> as yesterday's close, then today's OBV is the same as yesterday's OBV.</p></li></ul>",
            field: "technicals._OBV",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Williams AD",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The Williams Accumulation/Distribution (AD) is calculated using the following formula:</p><p>Williams AD = Today’s Accumulation/Distribution + Yesterday’s Williams AD - where:</p><p>AD = Today’s Price Move x Today’s Volume</p><p>Today’s Price Move = one of the following:</p><p>Today’s Close - True Range Low (if today’s close is greater than yesterday’s close)</p><p>Today’s Close - True Range High (if today’s close is less than yesterday’s close)</p><p>Zero (if today’s close is equal to yesterday’s close)</p><p>True Range High = the greater of today’s high price and yesterday's closing price</p><p>True Range Low = the lesser of today’s low price and yesterday's closing price</p>",
            field: "technicals.Williams_Accumulation_Distribution",
          },
          {
            name: "Money Flow Index",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Money Flow Index (MFI)</strong> is a volume-weighted Relative Strength Index (RSI). It is calculated using the following formula:</p><p><em>Money Flow Index = 100 - (100/ (1 + Money Ratio))</em>  <em>where Money Ratio = Positive Money Flow/Negative Money Flow</em><br /> <em>and Money Flow = Typical price * Volume</em>  <em>and Typical Price = (High + Low + Close) / 3</em></p>",
            field: "technicals._MFI",
          },
          {
            name: "Correlation Coefficient",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Correlation</strong> <strong>Coefficient</strong> measures the extent to which the price trends of two securities move in the same direction.</p>",
            field: "technicals._Correlation",
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "Stockopedia StockRanks",
    description:
      "<p>Stockopedia's proprietary <em>StockRanks™</em> are designed to assess stocks using the principles of factor-investing. We use a multi-factor approach to create rankings across quality, value and momentum. Our basic ranks are the QualityRank, ValueRank & MomentumRank which are then combined in various ways into composites. We provide a range of combinations of these basic rankings as crossover ranks and composite ranks.</p>",
    ratios: [],
    categories: [
      {
        name: "Composite Stock Ranks",
        description:
          "<p>There are two <em>Composite StockRanks</em> which combine 3 or more of the basic Quality, Value, Momentum and Growth Ranks. The StockRank - which rates companies according to an equally weighted combination of the ValueRank, GrowthRank and MomentumRank, and the QVGM Rank - which adds the growth factor into the mix.</p>",
        ratios: [
          {
            name: "StockRank™",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>StockRank™</strong> provides a simple but effective system to discover higher expected return shares.  It is an equally weighted combination of the ValueRank, QualityRank and MomentumRank.  These three basic ranks are computed for every company in the market on a daily basis, summed and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__QVMScore_zz",
          },
          {
            name: "QVGM Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>QVGM Rank</strong> is an equally weighted combination of Stockopedia's QualityRank, ValueRank, GrowthRank and MomentumRank.  These four basic ranks are computed for every company in the market on a daily basis, summed and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__CompositeScore_zz",
          },
          {
            name: "StockRank™",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Previous Day",
            description:
              "<p>This is the <strong>StockRank™</strong> for the previous trading day. The <strong>StockRank™</strong> provides a simple but effective system to discover higher expected return shares.  It is an equally weighted combination of the ValueRank, QualityRank and MomentumRank.  These three basic ranks are computed for every company in the market on a daily basis, summed and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__QVMScorePrev_zz",
          },
        ],
        categories: [],
      },
      {
        name: "The Crossover Ranks",
        description:
          "<p>The three <em>Crossover StockRanks</em> blend the basic Quality, Value and Momentum StockRanks in various ways. They are: the QV Rank - which blends the Quality Rank and Value Rank, the VM Rank - which blends the Value Rank and Momentum Rank, and the QM Rank - which blends the Quality Rank and Momentum Rank. Each of these crossover ranks are designed to take advantage of powerful investing factors.</p>",
        ratios: [
          {
            name: "Stockopedia QV Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>QV Rank</strong> provides a simple but effective system for finding good, cheap (quality+value) shares. It is an equally weighted combination of Stockopedia's QualityRank and ValueRank.  These two basic ranks are computed for every company in the market on a daily basis, summed and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__QVScore",
          },
          {
            name: "Stockopedia VM Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>VM Rank</strong> provides a simple but effective system for finding cheap, improving (value+momentum) shares. It is an equally weighted combination of Stockopedia's ValueRank and MomentumRank.  These two basic ranks are computed for every company in the market on a daily basis, summed and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__VMScore_zz",
          },
          {
            name: "Stockopedia QM Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>QM Rank</strong> provides a simple but effective system for finding good, improving (quality+momentum) shares. It is an equally weighted combination of Stockopedia's QualityRank and MomentumRank.  These two basic ranks are computed for every company in the market on a daily basis, summed and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__QMScore",
          },
          {
            name: "Stockopedia GM Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>GM Rank</strong> provides a simple but effective system for finding high momentum growth shares. It is an equally weighted combination of Stockopedia's GrowthRank and MomentumRank.  These two basic ranks are computed for every company in the market on a daily basis, summed and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__GMScore",
          },
        ],
        categories: [],
      },
      {
        name: "Solo StockRanks",
        description:
          "<p>There are four Solo <em>Stock Ranks</em>: the Quality Rank - which prioritises profitable, cash generative, high margin, low debt businesses, the Value Rank - which identifies cheap stocks according to a range of measures, the Momentum Rank - which looks at stocks hitting new highs with earnings upgrades, and the Growth Rank - which focuses on a blend of historic and forecast earnings and sales growth ratios.</p>",
        ratios: [
          {
            name: "Stockopedia Quality Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>Quality Rank</strong> is ranking system that aims to find the best quality listed companies.  It is based on a blend of measures that include an assessment of company franchise (profitability, cashflow, margins), company risk (bankruptcy, volatility) and fundamental trend (F-Score).  These inputs are computed for every company in the market on a daily basis, ranked, blended and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__QualityScore_zz",
          },
          {
            name: "Stockopedia Value Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>Value Rank</strong> is a ranking system based on an equal weighted blend of traditional value ratios including the P/B, P/E, P/S, P/FCF, Earnings Yield and Dividend Yield.   These inputs are computed for every company in the market on a daily basis, ranked, blended and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__ValueScore",
          },
          {
            name: "Stockopedia Momentum Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>Momentum Rank</strong> is a ranking system based on a blend of price momentum (52 week highs, relative strength) and earnings momentum (recommendation upgrades, earnings upgrades and earnings surprise) factors.  These inputs are computed for every company in the market on a daily basis, ranked, blended and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__MomentumScore_zz",
          },
          {
            name: "Stockopedia Growth Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>Growth Rank</strong> is a ranking system based on a blend of historic and forecast earnings and sales growth ratios. These inputs are computed for every company in the market on a daily basis, ranked, blended and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__GrowthScore_zz",
          },
          {
            name: "Stockopedia Quality Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Previous Day",
            description:
              "<p>This is the <strong>Quality Rank</strong> for the <em>previous trading day</em>. The <strong>Quality Rank</strong> is ranking system that aims to find the best quality listed companies.  It is based on a blend of measures that include an assessment of company franchise (profitability, cashflow, margins), company risk (bankruptcy, volatility) and fundamental trend (F-Score).  These inputs are computed for every company in the market on a daily basis, ranked, blended and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__QualityScorePrev_zz",
          },
          {
            name: "Stockopedia Value Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Previous Day",
            description:
              "<p>This is the <strong>Value Rank</strong> for the <em>previous trading day</em>. The <strong>Value Rank</strong> is a ranking system based on an equal weighted blend of traditional value ratios including the P/B, P/E, P/S, P/FCF, Earnings Yield and Dividend Yield.   These inputs are computed for every company in the market on a daily basis, ranked, blended and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__ValueScorePrev_zz",
          },
          {
            name: "Stockopedia Momentum Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Previous Day",
            description:
              "<p>This is the <strong>Momentum Rank</strong> for the <em>previous trading day</em>. The <strong>Momentum Rank</strong> is a ranking system based on a blend of price momentum (52 week highs, relative strength) and earnings momentum (recommendation upgrades, earnings upgrades and earnings surprise) factors.  These inputs are computed for every company in the market on a daily basis, ranked, blended and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__MomentumScorePrev_zz",
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "StatementFields",
    description: "<p></p>",
    ratios: [],
    categories: [
      {
        name: "INC",
        description: "<p></p>",
        ratios: [
          {
            name: "Amortization of Policy Acquisition Costs",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Amortization of Policy Acquisition Costs</strong> represents policy acquisition costs that are capitalised and amortised over the contractual period of the underlying policy.</p>",
            field: "statements.EPAC",
          },
          {
            name: "Selling / General / Administrative Expenses",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Selling / General / Administrative Expenses</strong> represents the sum of all expenses relating to selling, administration and other general business expenses.</p>",
            field: "statements.SSGA",
          },
          {
            name: "Research And Development",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Research & Development</strong> represents expenses for the research and development of new products and services by a company.</p>",
            field: "statements.ERAD",
          },
          {
            name: "Depreciation and Amortization",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Depreciation and Amortization</strong> represents the total <strong>Depreciation and Amortization</strong> expense for the period.</p>",
            field: "statements.SDPR",
          },
          {
            name: "Revenue",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Revenue</strong> represents all income that comes directly from the company's primary operations.  It is sometimes referred to as <strong>Sales</strong> or <strong>Turnover</strong>.</p>",
            field: "statements.SREV",
          },
          {
            name: "Total Premiums Earned",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Premiums Earned</strong> represents total insurance related premiums that the company has earned in the period.</p>",
            field: "statements.SPRE",
          },
          {
            name: "Net Investment Income",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Investment Income</strong> represents an insurance company's total investment income net of any investment expenses directly related to investing activities.</p>",
            field: "statements.RNII",
          },
          {
            name: "Realized & Unrealized Gains / Losses",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Realized & Unrealized Gains / Losses</strong> represents realised and unrealised capital gains and losses on securities held by the insurance company.</p>",
            field: "statements.RRGL",
          },
          {
            name: "Interest Income",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Interest Income</strong> is made up of interest and fees on loans, interest and dividends on investments, interest on deposits and other such items.</p>",
            field: "statements.SIIB",
          },
          {
            name: "Other Revenue",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Other Revenue</strong> represents all other income that counts as revenue but that is not recorded elsewhere.</p>",
            field: "statements.SORE",
          },
          {
            name: "Total Revenue",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Revenue</strong> represents all income that comes directly from the company's primary operations and any additional sources.  It is sometimes referred to as <strong>Total Sales</strong> or <strong>Total Turnover</strong>.</p>",
            field: "statements.RTLR",
          },
          {
            name: "Cost of Revenue",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Cost of Revenue</strong> represents total costs that can be directly attributed to the company's main operating activities. These may include things like raw materials or staff costs directly associated with production of goods or provision of a service.</p>",
            field: "statements.SCOR",
          },
          {
            name: "Gross Profit",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Gross Profit</strong> is what is left of <strong>Revenue</strong> after the <strong>Cost of Revenue</strong> have been deducted.  It represents a measure of a company's operating performance.</p>",
            field: "statements.SGRP",
          },
          {
            name: "Fuel Expense",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Fuel Expense</strong> represents the cost of fuel used for generating electricity and the distribution of natural gas, water, heat or steam, reported by utility companies.</p>",
            field: "statements.EFEX",
          },
          {
            name: "Operations & Maintenance",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Operations & Maintenance</strong> represents costs incurred in the operation and maintenance of utility facilities and distribution networks, other than direct costs of goods and services sold, related to the generation, storage, transmission and distribution of electric power, natural gas, water and heat.</p>",
            field: "statements.EDOE",
          },
          {
            name: "Interest Expense",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Interest Expense</strong> represents total operating interest expense for financial institutions such as interest on deposits or borrowings.</p>",
            field: "statements.STIE",
          },
          {
            name: "Net Interest Income",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Interest Income</strong> represents <strong>Interest Income</strong> net of <strong>Interest Expense</strong>.</p>",
            field: "statements.ENII",
          },
          {
            name: "Loan Loss Provision",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Loan Loss Provision</strong> represents provisions for possible default on loans by customers.</p>",
            field: "statements.ELLP",
          },
          {
            name: "Net Interest Income After Loan Loss Provision",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Interest Income After Loan Loss Provision</strong> represents <strong>Net Interest Income</strong> net of <strong>Loan Loss Provisions</strong>.</p>",
            field: "statements.SIAP",
          },
          {
            name: "Losses, Benefits, and Adjustments",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Losses, Benefits, and Adjustments</strong> represents the sum of all insurance <strong>Losses, Benefits, and Adjustments</strong> and also includes underwriting and commissions and reinsurance.</p>",
            field: "statements.SLBA",
          },
          {
            name: "Operating Interest Expense / Income",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Operating Interest Expense / Income</strong> represents the net <strong>Interest Expense or Income</strong> reported for the period.  This is generally where interest on loans and other similar items are accounted for.</p>",
            field: "statements.EINN",
          },
          {
            name: "Unusual Expense / Income",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Unusual Expense / Income</strong> represents <strong>Income</strong> or <strong>Expenses</strong> that are not expected to occur regularly.  A common example is something like inventory being lost due to a fire or litigation costs.  These are real costs but are one off items that should not happen again.</p>",
            field: "statements.SUIE",
          },
          {
            name: "Other Operating Expenses",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Other Operating Expenses</strong> represents the sum of all other <strong>Operating Expenses</strong> that are not reported elsewhere.</p>",
            field: "statements.SOOE",
          },
          {
            name: "Total Operating Expenses",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Operating Expense</strong> represents the sum of all <strong>Operating Expenses</strong> for the business.</p>",
            field: "statements.ETOE",
          },
          {
            name: "Operating Profit",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Operating Profit</strong> is what is left of <strong>Revenue</strong> after the <strong>Costs of Revenue</strong> and <strong>Operating Expenses</strong> have been deducted.  It represents a measure of a company's operating performance.</p>",
            field: "statements.SOPI",
          },
          {
            name: "Net Non Operating Interest Income / Expense",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Non Operating Interest Income / Expense</strong> represents the net interest <strong>Income</strong> / <strong>Expense</strong> reported in the non-operating section by the company.</p>",
            field: "statements.NINN",
          },
          {
            name: "Gain / Loss on Sale of Assets",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Gain / Loss on Sale of Assets</strong> represents gains / losses on the sale of fixed assets.</p>",
            field: "statements.NGLA",
          },
          {
            name: "Allowance for Funds Used During Construction",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Allowance for Funds Used During Construction</strong> represents imputed investment income from equity funds that are employed for power plant construction in utility companies.</p>",
            field: "statements.NAFC",
          },
          {
            name: "Total Net Non Operating Interest Income / Expense",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Net Non Operating Interest Income / Expense</strong> represents the sum of all non operating interest income and expenses.</p>",
            field: "statements.SNIN",
          },
          {
            name: "Other Net Non Operating Costs",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Other Net Non Operating Costs</strong> represents the sum of all other net non operating costs that are not accounted for elsewhere.</p>",
            field: "statements.SONT",
          },
          {
            name: "Net Income Before Taxes",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Income Before Taxes</strong> represents <strong>Operating Income</strong> after non operating costs have been deducted but before taxes have been paid.</p>",
            field: "statements.EIBT",
          },
          {
            name: "Provision for Income Taxes",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Provision for Income Taxes</strong> represents the charge for all taxes due on profits that may be owed to governments.</p>",
            field: "statements.TTAX",
          },
          {
            name: "Net Income After Taxes",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Income After Taxes</strong> is <strong>Net Income Before Taxes</strong> after the <strong>Provision for Income Taxes</strong> has been deducted.</p>",
            field: "statements.TIAT",
          },
          {
            name: "Minority Interest",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Minority Interest</strong> represents the proportion of <strong>Net Income After Taxes</strong> that is due to shareholders in subsidiary businesses.</p>",
            field: "statements.CMIN",
          },
          {
            name: "Equity in Affiliates",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Equity in Affiliates</strong> represents the share of earnings or losses, net of taxes, that the company is entitled to from unconsolidated associated companies, affiliates or joint ventures, which have not been distributed as dividends.</p>",
            field: "statements.CEIA",
          },
          {
            name: "U.S. GAAP Adjustment",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>U.S. GAAP Adjustment</strong> represents an adjustment made by non U.S. companies using international or local accounting standards to bring their accounting net income in line with U.S. accounting standards.</p>",
            field: "statements.CGAP",
          },
          {
            name: "Net Income Before Extraordinary Items",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Income Before Extraordinary Items</strong> represents net income after taxes, adjusted by <strong>Minority Interest</strong>, <strong>Equity in Affiliates</strong> and the <strong>U.S. GAAP Adjustment</strong> but before <strong>Extraordinary Items</strong>, <strong>Preferred Distributions</strong> and other adjustments to net income.</p>",
            field: "statements.NIBX",
          },
          {
            name: "Extraordinary Items",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Extraordinary Items</strong> represents the sum of one off costs such as accounting changes or discontinued operations.</p>",
            field: "statements.STXI",
          },
          {
            name: "Net Income",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Income</strong> represents <strong>Net Income After Taxes</strong>, adjusted by <strong>Minority Interest</strong>, <strong>Equity in Affiliates</strong>, the <strong>U.S. GAAP Adjustment</strong> and <strong>Extraordinary Items</strong> but before <strong>Preferred Distributions</strong> and other adjustments to net income.</p>",
            field: "statements.NINC",
          },
          {
            name: "Adjustments to Net Income",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Adjustments to Net Income</strong> represents the sum of all the adjustments made to <strong>Net Income</strong>.</p>",
            field: "statements.SANI",
          },
          {
            name: "Income Available to Common Shareholders Excluding Extraordinary Items",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Income Available to Common Shareholders Excluding Extraordinary Items</strong> represents the income that is left for common shareholders before <strong>Extraordinary Items</strong> have been accounted for.</p>",
            field: "statements.CIAC",
          },
          {
            name: "Income Available to Common Shareholders Including Extraordinary Items",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Income Available to Common Shareholders Including Extraordinary Items</strong> represents the income that is left for common shareholders after <strong>Extraordinary Items</strong> have been accounted for.</p>",
            field: "statements.XNIC",
          },
          {
            name: "Normalised Income Before Taxes",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Normalised Income Before Taxes</strong> represents <strong>Net Income Before Taxes</strong>, excluding the effects of all non-recurring, unusual, one-off or extraordinary items.</p>",
            field: "statements.VPTI",
          },
          {
            name: "Normalised Income After Taxes",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Normalised Income After Taxes</strong> represents <strong>Net Income After Taxes</strong>, excluding the effects of all non-recurring, unusual, one-off or extraordinary items.</p>",
            field: "statements.VIAT",
          },
          {
            name: "Normalised Income Available to Common Shareholders",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Normalized Income Available to Common Shareholders</strong> represents <strong>Net Income Available to Common Shareholders</strong>, excluding the effects of all non-recurring, unusual, one-off or extraordinary items.</p>",
            field: "statements.VIAC",
          },
          {
            name: "Diluted Normalised EPS",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Diluted Normalised EPS</strong> represents the earnings attributable to each share outstanding, excluding the effects of all non-recurring, unusual, one-off or extraordinary items.</p>",
            field: "statements.VDES",
          },
          {
            name: "Dividends per Share",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Dividends per Share</strong> represents the total dividend paid out to shareholders in the period for each share that they own.</p>",
            field: "statements.DDPS1",
          },
          {
            name: "Special Dividends per Share",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Special Dividends per Share</strong> represents the total special dividend paid out to shareholders in the period for each share that they own.</p>",
            field: "statements.DSPS1",
          },
          {
            name: "Non Interest Income",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Non Interest Income</strong> represents income from all non interest sources for banks.  This typically includes items such as commissions and fees.</p>",
            field: "statements.SNII",
          },
          {
            name: "Non Interest Expense",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Non Interest Expense</strong> represents expenses from all non interest sources for banks.  This typically includes items such as trading losses, staff costs, rents etc.</p>",
            field: "statements.SNIE",
          },
          {
            name: "Dilution Adjustment",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Dilution Adjustment</strong> represents an adjustment for interest paid on convertible securities, assuming that they were all converted.</p>",
            field: "statements.SDAJ",
          },
          {
            name: "Diluted Net Income",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Diluted Net Income</strong> represents the income available to common shareholders after all costs and the effects of dilution have been taken into account.</p>",
            field: "statements.SDNI",
          },
          {
            name: "Diluted Weighted Average Shares",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Diluted Weighted Average Shares</strong> represents the average number of shares outstanding for the period, assuming that all convertible securities have been converted into common shares.</p>",
            field: "statements.SDWS",
          },
          {
            name: "Basic EPS Including Extraordinary Items",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Basic EPS Including Extraordinary Items</strong> represents the earnings attributable to each share outstanding, including the effects of <strong>Extraordinary Items</strong>.</p>",
            field: "statements.SBAI",
          },
          {
            name: "Diluted EPS Including Extraordinary Items",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Diluted EPS Including Extraordinary Items</strong> represents the earnings attributable to each share outstanding, after the diluting effects of convertible securities has been accounted for.</p>",
            field: "statements.SDAI",
          },
          {
            name: "Diluted EPS Excluding Extraordinary Items",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Diluted EPS Exluding Extraordinary Items</strong> represents the earnings attributable to each share outstanding, after the diluting effects of convertible securities has been accounted for and one off or unusual items have been excluded from the earnings figure.</p>",
            field: "statements.SDBF",
          },
        ],
        categories: [],
      },
      {
        name: "BAL",
        description: "<p></p>",
        ratios: [
          {
            name: "Cash",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Cash</strong> represents cash on hand, balances on demand deposits, and near-term savings and time deposits. These should be readily available for use and must be free from any contractual restrictions that limit their use in satisfying debts and other operational cash needs.</p>",
            field: "statements.ACSH",
          },
          {
            name: "Cash and Equivalents",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Cash and Equivalents</strong> represents short-term, highly liquid investments that are both readily convertible to known amounts of cash and so close to their maturity that they present insignificant risk of changes in interest rates. Only investments with original maturities of three months or less qualify under these definitions.</p>",
            field: "statements.ACAE",
          },
          {
            name: "Short Term Investments",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Short Term Investments</strong> consists of any investments in debt and equity securities with maturity of one year or less.</p>",
            field: "statements.ASTI",
          },
          {
            name: "Cash and Short Term Investments",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Cash and Short Term Investments</strong> is the sum of Cash, Cash & Equivalents and Short-Term Investments.</p>",
            field: "statements.SCSI",
          },
          {
            name: "Net Total Accounts Receivable",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Total Accounts Receivable</strong> represents claims held against customers for goods sold or services rendered as part of normal business operations. This figure is reported net of any provisions for doubtful accounts.</p>",
            field: "statements.AACR",
          },
          {
            name: "Net Total Receivables",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Total Receivables</strong> represents the total of all <strong>Receivables</strong>, net of any provisions made for doubtful accounts.</p>",
            field: "statements.ATRC",
          },
          {
            name: "Total Inventory",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Inventory</strong> consists of all assets held for sale in the ordinary course of business or goods that are used and/or consumed in the production of goods to be sold.</p>",
            field: "statements.AITL",
          },
          {
            name: "Prepaid Expenses",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Prepaid Expenses</strong> represents goods or services that have already been purchased, but not fully consumed or used. As <strong>Prepaid Expenses</strong> is defined as a part of current assets, the remain value from these purchases is expected to be consumed within one year of the current fiscal period end date.</p>",
            field: "statements.APPY",
          },
          {
            name: "Total Other Current Assets",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Other Current Assets</strong> represents all other <strong>Current Assets</strong>.</p>",
            field: "statements.SOCA",
          },
          {
            name: "Total Current Assets",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Current Assets</strong> is the sum of all <strong>Current Assets</strong>.</p>",
            field: "statements.ATCA",
          },
          {
            name: "Cash & Due From Banks",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Cash & Due From Banks</strong> represents cash on hand and due from banks. Due from banks represents receivables from, or short-term loans to, other banks and/or financial institutions, which usually bear minor interest earnings.</p>",
            field: "statements.ACDB",
          },
          {
            name: "Total Other Earning Assets",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Other Earning Assets</strong> represents earning assets other than loans to customers. It includes items like Interest-Earning Deposits, Federal Funds Sold/Securities Purchased Under Resale Agreement, Trading Account Assets, FHLB Stock, Total Investment Securities, Loans Held for Sale, Other Earning Assets and Customer Acceptances.</p>",
            field: "statements.SOEA",
          },
          {
            name: "Net Loans",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Loans</strong> represents total loans to customers, net of any provisions for possible default losses and unearned interest income.</p>",
            field: "statements.ANTL",
          },
          {
            name: "Gross Property, Plant And Equipment",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Gross Property, Plant And Equipment</strong> is the total value of fixed assets before depreciation has been accounted for.</p>",
            field: "statements.APTC",
          },
          {
            name: "Accumulated Depreciation",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Accumulated Depreciation</strong> represents the sum of <strong>Depreciation</strong> to date on <strong>Property, Plant and Equipment</strong> (<strong>PPE</strong>).</p>",
            field: "statements.ADEP",
          },
          {
            name: "Net Property, Plant And Equipment",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p>Net Property, Plant And Equipment represents <strong>Gross Property, Plant And Equipment</strong> net of any <strong>Accumulated Depreciation</strong>.</p>",
            field: "statements.APPN",
          },
          {
            name: "Net Goodwill",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Goodwill</strong> represents the excess of purchase price over the fair market value of net assets acquired. The figure is stated net of any <strong>Amorisation</strong> on <strong>Gross Goodwill</strong>.</p>",
            field: "statements.AGWI",
          },
          {
            name: "Net Intangible Assets",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Intangible Assets</strong> represents the value of assets that do not exist physically, net of <strong>Accumulated Amortisation</strong> and any <strong>Impairments</strong></p>",
            field: "statements.AINT",
          },
          {
            name: "Net Total Utility Plant",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Total Utility Plant</strong> represents <strong>Total Utility Plant</strong> net of any <strong>Accumulated Depreciation</strong>.</p>",
            field: "statements.SUPN",
          },
          {
            name: "Long Term Investments",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Long Term Investments</strong> represents the sum of all <strong>Long Term Investments</strong>, including those in Affiliate Companies.</p>",
            field: "statements.SINV",
          },
          {
            name: "Insurance Receivables",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Insurance Receivables</strong> represents receivables from policyholders, agencies, and other operating parties in insurance companies, but excludes receivables from reinsurers.</p>",
            field: "statements.APRE",
          },
          {
            name: "Long Term Notes Receivable",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Long Term Notes Receivable</strong> represents long term notes receivable, excluding the current portion of the long-term receivables. It also represents all other long term receivables reported in the <strong>Non Current Assets</strong> section.</p>",
            field: "statements.ALTR",
          },
          {
            name: "Other Long Term Assets",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Other Long Term Assets</strong> represents the sum of deferred charges, overfunded pension benefits, long term deferred income tax assets, long term assets from discontinued operations, long term restricted cash and any other long term assets not covered elsewhere.</p>",
            field: "statements.SOLA",
          },
          {
            name: "Deferred Policy Acquisition Costs",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Deferred Policy Acquisition Costs</strong> represents varying costs related to the acquisition of insurance contracts that are deferred and amortized over the respective policy terms.</p>",
            field: "statements.ADPA",
          },
          {
            name: "Total Other Assets",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Other Assets</strong> is the sum of all <strong>Other Assets</strong>, whether short or long term.</p>",
            field: "statements.SOAT",
          },
          {
            name: "Total Assets",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Assets</strong> represents the sum of all <strong>Assets</strong>, whether short or long term.</p>",
            field: "statements.ATOT",
          },
          {
            name: "Accounts Payable",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Accounts Payable</strong> represents amounts owed to suppliers for materials and merchandise acquired on an open account.</p>",
            field: "statements.LAPB",
          },
          {
            name: "Payable / Accrued",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Payable / Accrued</strong> is used when trade Accounts Payable and Accrued Expenses are not reported separately.</p>",
            field: "statements.LPBA",
          },
          {
            name: "Accrued Expenses",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Accrued Expenses</strong> represents operational expenses accrued, but not yet paid. These expenses are expected to paid within a year as they are <strong>Current Liabilities</strong>.</p>",
            field: "statements.LAEX",
          },
          {
            name: "Policy Liabilities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Policy Liabilities</strong> represents total liabilities related to the insurance operations of an insurance company.</p>",
            field: "statements.SPOL",
          },
          {
            name: "Total Deposits",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Deposits</strong> represents the sum of non-interest bearing deposit, interest bearing deposits and other deposits.</p>",
            field: "statements.LDBT",
          },
          {
            name: "Other Interest Bearing Liabilities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Other Interest Bearing Liabilities</strong> represents <strong>Interest Bearing Liabilities</strong> other than <strong>Total Deposits</strong> and <strong>Total Short Term Borrowings</strong>.</p>",
            field: "statements.SOBL",
          },
          {
            name: "Total Short Term Borrowings",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Short Term Borrowings</strong> represents all <strong>Short Term Borrowings</strong> of a bank, which includes items such as commercial paper.</p>",
            field: "statements.LSTB",
          },
          {
            name: "Notes Payable / Short Term Debt",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Notes Payable / Short Term Debt</strong> represents short term bank borrowings, notes payable that are issued to suppliers and any other short-term interest-bearing liabilities not reported elsewhere.</p>",
            field: "statements.LSTD",
          },
          {
            name: "Current Portion of Long Term Debt / Capital Leases",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Current Portion of Long Term Debt / Capital Leases</strong> represents the portion of long-term debt or capitalised lease obligations that is due within the next year.</p>",
            field: "statements.LCLD",
          },
          {
            name: "Total Other Current Liabilities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Other Current Liabilities</strong> represents the sum of all other liabilities not reported elsewhere.</p>",
            field: "statements.SOCL",
          },
          {
            name: "Total Current Liabilities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Current Liabilities</strong> represents the sum of all current liabilities for industrial and utility companies.</p>",
            field: "statements.LTCL",
          },
          {
            name: "Long Term Debt",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Long Term Debt</strong> represents interest-bearing debt with maturities beyond one year. Long term debt may consist of long-term bank borrowings, bonds, convertible bonds and other similar items.</p>",
            field: "statements.LLTD",
          },
          {
            name: "Capital Lease Obligations",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Capital Lease Obligations</strong> represents the portion of capitalised lease obligations that are not due for at least one year.</p>",
            field: "statements.LCLO",
          },
          {
            name: "Total Long Term Debt",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Long Term Debt</strong> represents the sum of <strong>Long Term Debt</strong> and <strong>Capital Lease Obligations</strong>.</p>",
            field: "statements.LTTD",
          },
          {
            name: "Total Debt",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Debt</strong> represents total interest-bearing debt outstanding, it does not include derivative liabilities.</p>",
            field: "statements.STLD",
          },
          {
            name: "Deferred Income Tax",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Deferred Income Tax</strong> represents the sum of all long term <strong>Deferred Income Tax</strong> liabilities.</p>",
            field: "statements.SBDT",
          },
          {
            name: "Minority Interest",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Minority Interest</strong> represents accumulated interest for minority shareholders in subsidiaries that are less than 100 percent owned by the reporting parent company.</p>",
            field: "statements.LMIN",
          },
          {
            name: "Total Other Liabilities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Other Liabilities</strong> represents the sum of all other <strong>Liabilities</strong> not accounted for elsewhere.</p>",
            field: "statements.SLTL",
          },
          {
            name: "Total Funded Status",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Funded Status</strong> is determined by deducting the <strong>Projected Benefit Obligation</strong> from the fair value of <strong>Plan Assets</strong>.</p>",
            field: "statements.VFSS",
          },
          {
            name: "Total Liabilities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Liabilities</strong> is the sum of all liabilities, both short and long term.</p>",
            field: "statements.LTLL",
          },
          {
            name: "Redeemable Preferred Stock",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Redeemable Preferred Stock</strong> represents all <strong>Preferred Stock</strong> that can be redeemed with the issuing company.</p>",
            field: "statements.SRPR",
          },
          {
            name: "Non Redeemable Preferred Stock",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Redeemable Preferred Stock</strong> represents all <strong>Preferred Stock</strong> that can not be redeemed with the issuing company.</p>",
            field: "statements.SPRS",
          },
          {
            name: "Common Stock",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Common Stock</strong> represents all outstanding shares, where each one represents ownership in the issuing company.</p>",
            field: "statements.SCMS",
          },
          {
            name: "Additional Paid In Capital",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Additional Paid In Capital</strong> represents capital contributed by shareholders in excess of par value of common stock in return for shares issued to them.</p>",
            field: "statements.QPIC",
          },
          {
            name: "Retained Earnings (Accumulated Deficit)",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Retained Earnings (Accumulated Deficit)</strong> represents the sum of profits from prior periods that was not distributed to shareholders. It may represent accumulated deficit if a company has made net losses over time.</p>",
            field: "statements.QRED",
          },
          {
            name: "Treasury Stock",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Treasury Stock</strong> represents the value of <strong>Common Stock</strong> that the company has bought back from the open market.</p>",
            field: "statements.QTSC",
          },
          {
            name: "ESOP Debt Guarantee",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>ESOP Debt Guarantee</strong> represents all transactions related to a company's <strong>Employee Stock Ownership Plan</strong>.</p>",
            field: "statements.QEDG",
          },
          {
            name: "Unrealized Gain / Loss",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Unrealized Gain / Loss</strong> represents all unrealised gains and losses on investments and fixed assets that the company reports in the Equity section.</p>",
            field: "statements.QUGL",
          },
          {
            name: "Other Equity",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Other Equity</strong> represents all other components of <strong>Equity</strong> that are not reported separately.</p>",
            field: "statements.SOTE",
          },
          {
            name: "Total Equity",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Equity</strong> represents the sum of all <strong>Equity</strong> items.</p>",
            field: "statements.QTLE",
          },
          {
            name: "Total Liabilities & Shareholders' Equity",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Liabilities & Shareholders' Equity</strong> represents <strong>Total Equity</strong> less <strong>Total Liabilities</strong> and this is always equal to <strong>Total Assets</strong>.</p>",
            field: "statements.QTEL",
          },
          {
            name: "Total Common Shares Outstanding",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Common Shares Outstanding</strong> represents the number of primary common shares outstanding.</p>",
            field: "statements.QTCO",
          },
          {
            name: "Total Preferred Shares Outstanding",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Total Preferred Shares Outstanding</strong> represents an aggregated number of preferred shares outstanding.</p>",
            field: "statements.QTPO",
          },
        ],
        categories: [],
      },
      {
        name: "CAS",
        description: "<p></p>",
        ratios: [
          {
            name: "Non-Cash Items",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Non-Cash Items</strong> is the adjustment in the cash flow statement for income and expenses that do not affect the cash flow statement.</p>",
            field: "statements.SNCI",
          },
          {
            name: "Accounting Change",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Accounting Change</strong> gains / losses are gains / losses a company makes when changing accounting principles.</p>",
            field: "statements.OACG",
          },
          {
            name: "Other Investing Cash Flow",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Other Investing Cash Flow</strong> is the sum of all other items relating to <strong>Investing Cash Flow</strong>.</p>",
            field: "statements.IICF",
          },
          {
            name: "Amortisation",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Amortisation</strong> represents the costs incurred by companies as a result of intangible assets declining in value (eg. due to a patent expiring).</p>",
            field: "statements.SAMT",
          },
          {
            name: "Deferred Taxes",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Deferred Taxes</strong> are income taxes that are recorded on the income statement, but do not affect the cash flow statement (ie. no cash is paid or recieved).</p>",
            field: "statements.OBDT",
          },
          {
            name: "Net Income/Starting Line",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Income/Starting Line</strong> is the first line of a cash flow statement when a company employs the Indirect Method to calculate operating cash flows.  This is only applicable to Utility companies.</p>",
            field: "statements.ONET",
          },
          {
            name: "Depreciation",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Depreciation</strong> represents the costs incurred by companies as a result of assets declining in value (eg. due to wear and tear).</p>",
            field: "statements.SDED",
          },
          {
            name: "Discontinued Operations",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p>Gains and losses from <strong>Discontinued Operations</strong> include gains / losses from the sale of discontinued operations, or changes in the cash assets of them.</p>",
            field: "statements.OIDO",
          },
          {
            name: "Extraordinary Items",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Extraordinary Items</strong> are one off or unusual expenses/income that are not expected to occur regularly.  A common example is something like inventory being lost due to a fire or flooding.  These are real costs but are one off items that should not happen again.</p>",
            field: "statements.OTRA",
          },
          {
            name: "Unusual Items",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Unusual Items</strong> are one off or unusual expenses/income that are not expected to occur regularly.  A common example is something like inventory being lost due to a fire or litigation costs.  These are real costs but are one off items that should not happen again.</p>",
            field: "statements.OUIE",
          },
          {
            name: "Purchased R&D",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Purchased R&D</strong> is the non-cash expense incurred from the amortization of research and development assets.  This is only applicable to Industrial and Utility companies.</p>",
            field: "statements.OPRD",
          },
          {
            name: "Equity in Net Earnings/Losses",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Equity in Net Earnings/Losses</strong> is non-cash earnings/losses from investments under the Equity Method.</p>",
            field: "statements.OEIA",
          },
          {
            name: "Other Non-Cash Items",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Other Non-Cash Items</strong> are miscellaneous non-cash items that are not accounted for elsewhere in this schema.</p>",
            field: "statements.ONCI",
          },
          {
            name: "Changes in Working Capital",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Working Capital</strong> represents changes in items like <strong>Inventory</strong> or <strong>Accounts Payable</strong> that must be included on the Cashflow Statement.</p>",
            field: "statements.SOCF",
          },
          {
            name: "Loan Loss Provision",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Loan Loss Provision</strong> are non-cash expenses incurred for the possibility that some customers will default on a loan.</p>",
            field: "statements.OLLP",
          },
          {
            name: "Change in Accounts Receivable",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Accounts Receivables</strong> represents changes in all <strong>Accounts Receivable</strong> that must be included on the Cashflow Statement.</p>",
            field: "statements.OACR",
          },
          {
            name: "Change in Inventories",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Inventories</strong> represents changes in <strong>Inventories</strong> during the period that must be included on the Cashflow Statement.</p>",
            field: "statements.OITL",
          },
          {
            name: "Change in Prepaid Expenses",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Prepaid Expenses</strong> represents changes in <strong>Prepaid Expenses</strong> during the period that must be included on the Cashflow Statement.</p>",
            field: "statements.OPPY",
          },
          {
            name: "Change in Other Assets",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Other Assets</strong> represents changes in <strong>Other Assets</strong> during the period that must be included on the Cashflow Statement.</p>",
            field: "statements.OOAS",
          },
          {
            name: "Change in Accounts Payable",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Accounts Payable</strong> represents changes in all <strong>Accounts Payable</strong> that must be included on the Cashflow Statement.</p>",
            field: "statements.OAPB",
          },
          {
            name: "Change in Accrued Expenses",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Accrued Expenses</strong> represents changes in <strong>Accrued Expenses</strong> during the period that must be included on the Cashflow Statement.</p>",
            field: "statements.OAEX",
          },
          {
            name: "Change in Payable / Accrued Expenses",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Payable / Accrued Expenses</strong> represents changes in <strong>Payable / Accrued Expenses</strong> during the period that must be included on the Cashflow Statement.</p>",
            field: "statements.OPBA",
          },
          {
            name: "Change in Taxes Payable",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Taxes Payable</strong> represents changes in taxes payable during the period that must be included on the Cashflow Statement.</p>",
            field: "statements.OTXP",
          },
          {
            name: "Change in Other Liabilities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Other Liabilities</strong> represents changes in other liabilities during the period that must be included on the Cashflow Statement.</p>",
            field: "statements.OOLB",
          },
          {
            name: "Net Change in Other Assets & Liabilities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Change in Other Assets & Liabilities</strong> represents the changes in the combined sum of <strong>Assets</strong> and <strong>Liabilities</strong> when a company reports these totals as a single line.</p>",
            field: "statements.OOAL",
          },
          {
            name: "Other Operating Cash Flow",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Other Operating Cash Flow</strong> items are items that cannot be classified as an adjustment item to <strong>Net Income</strong>, or an <strong>Asset</strong> / <strong>Liability</strong> as part of changes in non-cash working capital.</p>",
            field: "statements.OOCF",
          },
          {
            name: "Gains / Losses on Investment Securities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Gains / Losses on Investment Securities</strong> represents a reversal of gains/losses on the sale of investment securities for banks and insurance companies that were accounted for as part of <strong>Operating Income</strong>.</p>",
            field: "statements.OINV",
          },
          {
            name: "Gains / Losses on Loans",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Gains / Losses on Loans</strong> represents a reversal of gains/losses on the sale of loans by a bank. These are accounted for in <strong>Operating Income</strong> on a Bank's income statement, but are recognised as part of investment cashflow on the cashflow statement.</p>",
            field: "statements.OLOA",
          },
          {
            name: "Other Real Estate Owned",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Other Real Estate Owned</strong> represents a reversal of gains/losses on the sale of other real estate owned to avoid double counting these cashflows.</p>",
            field: "statements.OORE",
          },
          {
            name: "Operating Loans Origination",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Operating Loans Origination</strong> represents an increase in loans to customers when such loans to customers are treated as operating assets by a bank.</p>",
            field: "statements.OLOR",
          },
          {
            name: "Sale of Loans",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Sale of Loans</strong> represents a decrease in loans made by a bank to its customers. These loans to customers are treated as operating assets by a bank.</p>",
            field: "statements.OSOL",
          },
          {
            name: "Loss Adjustment",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Loss Adjustment</strong> represents the reversal of non-cash expenses of estimated losses on unreported insurance cases and insurance cases that have been reported, but are still being processed.</p>",
            field: "statements.OLOS",
          },
          {
            name: "Change in Policy Benefits/Liabilities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Policy Benefits/Liabilities</strong> represents changes in reserves for future policy benefits. Reserves for life and other policies are estimated on the basis of various actuarial factors.</p>",
            field: "statements.OBEN",
          },
          {
            name: "Change in Deferred Policy Acquisition Costs",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Deferred Policy Acquisition Costs</strong> represents changes in deferred policy acquisition costs that have been capitalised. This does not include the <strong>Amortisation</strong> of such costs.</p>",
            field: "statements.OPAC",
          },
          {
            name: "Change in Policy Refunds",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Policy Refunds</strong> represents a change in the refunding of policy assets and investment capital to policyholders. This can be the result of the cancellation of a policy or related to the policyholder accessing the investment component of the policy.</p>",
            field: "statements.OREF",
          },
          {
            name: "Change in Reinsurance Receivable",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Reinsurance Receivable</strong> represents changes in receivables from reinsurers during the period.</p>",
            field: "statements.ORER",
          },
          {
            name: "Change in Reinsurance Payable",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Reinsurance Payable</strong> represents changes in payables to reinsurers during the period.</p>",
            field: "statements.OREP",
          },
          {
            name: "Change in Insurance Reserves",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Insurance Reserves</strong> represents changes in insurance reserves, such as reserves for claims and losses or reserves for benefits to policyholders, during the period.</p>",
            field: "statements.OINR",
          },
          {
            name: "Change in Unearned Premiums",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Unearned Premiums</strong> represents changes in reserve for unearned premiums during the period.</p>",
            field: "statements.OUPR",
          },
          {
            name: "Cash from Operating Activities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Cash From Operating Activities</strong> is the total of all Cashflow that can be attributed to the company's operating activities.</p>",
            field: "statements.OTLO",
          },
          {
            name: "Capital Expenditures",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Capital Expenditures</strong> is the sum of purchases of <strong>Fixed Assets</strong>, <strong>Intangible Assets</strong> and <strong>Software Development Costs</strong>.</p>",
            field: "statements.SCEX",
          },
          {
            name: "Purchase of Fixed Assets",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Purchase of Fixed Assets</strong> encompasses all cashflow expenditures for factories and equipment that have a useful life of more than one year.</p>",
            field: "statements.ICEX",
          },
          {
            name: "Purchase / Acquisition of Intangibles",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Purchase / Acquisition of Intangibles</strong> represents a cash outflow for the acquisition of intangibles.</p>",
            field: "statements.IIAQ",
          },
          {
            name: "Software Development Costs",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Software Development Costs</strong> represents costs accumulated and capitalized for software development.</p>",
            field: "statements.ISDC",
          },
          {
            name: "Other Investing Cash Flow Items",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Other Investing Cash Flow Items</strong> represents all items reported within the investing activities in the cash flow statement, other than capital expenditures.</p>",
            field: "statements.SICF",
          },
          {
            name: "Acquisition of Business",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Acquisition of Business</strong> represents cash outflow for acquiring new businesses.</p>",
            field: "statements.IBAQ",
          },
          {
            name: "Sale of Business",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Sale of Business</strong> represents cash inflow from the disposal of existing businesses.</p>",
            field: "statements.ISOB",
          },
          {
            name: "Sale of Fixed Assets",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Sale of Fixed Assets</strong> represents cash inflow from the sale of fixed assets.</p>",
            field: "statements.ISFA",
          },
          {
            name: "Sale/Maturity of Investment",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Sale/Maturity of Investment</strong> represents cash inflow on the sale of securities, the repayment of securities matured, or the sale of other investments.</p>",
            field: "statements.IINS",
          },
          {
            name: "Change in Net Investments",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Net Investments</strong> represents changes in investments in securities and other investments.</p>",
            field: "statements.IIVN",
          },
          {
            name: "Purchase of Investments",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Purchase of Investments</strong> represents the cash outflow on the purchase of investments in securities and other investments.</p>",
            field: "statements.IINP",
          },
          {
            name: "Principal Payments from Securities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Principal Payments From Securities</strong> represents cash inflow from the repayment of securities that have been invested and have matured.</p>",
            field: "statements.ISPP",
          },
          {
            name: "Sale of Intangible Assets",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Sale of Intangible Assets</strong> represents cash inflow from the sale of intangible assets.</p>",
            field: "statements.ISOI",
          },
          {
            name: "Change in Net Intangibles",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Net Intangibles</strong> represents changes in intangible assets.</p>",
            field: "statements.IIAN",
          },
          {
            name: "Change in Loans",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Change in Loans</strong> represents changes in loans to customers for banks.</p>",
            field: "statements.ILOA",
          },
          {
            name: "Loans Origination - Investing",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Loans Origination – Investing</strong> represents cash inflow from increases in loans to customers, which may be directly originated from customers, or acquired from other loan originators.</p>",
            field: "statements.ILOR",
          },
          {
            name: "Foreclosed Real Estate",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Foreclosed Real Estate</strong> represents an increase/decrease in cash flow due to foreclosed real estate for banks.</p>",
            field: "statements.IFRE",
          },
          {
            name: "Policy Loans",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Policy Loans</strong> represents an increase/decrease in cash flow resulting from changes in the total outstanding policy loans to policyholders during a period.</p>",
            field: "statements.IPOL",
          },
          {
            name: "Cash from Investing Activities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Cash From Investing Activities</strong> represents the sum of <strong>Capital Expenditures</strong> and <strong>Other Investing Cash Flow Items</strong></p>",
            field: "statements.ITLI",
          },
          {
            name: "Financing Cash Flow Items",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Financing Cash Flow Items</strong> represents the sum of <strong>Increase / Decrease in Deposits</strong>, <strong>Increase / Decrease in FHLB</strong>, <strong>Increase / Decrease in Federal Funds Sold/REPOs</strong> and <strong>Other Financing Cash Flow</strong>.</p>",
            field: "statements.SFCF",
          },
          {
            name: "Deposits",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Deposits</strong> represents an increase/decrease in cash flow due to changes in the level of deposits from customers for banks.</p>",
            field: "statements.FDPT",
          },
          {
            name: "FHLB Borrowings",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>FHLB Borrowings</strong> represents an increase/decrease in cash flow due to changes in the level of borrowings from Federal Home Loan Banks (FHLB).</p>",
            field: "statements.FFLB",
          },
          {
            name: "Federal Funds/REPOs",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Federal Funds/REPOs</strong> represents an increase/decrease in cash flow due to changes in the level of Federal Funds Sold/Securities Purchased Under Resale Agreement.</p>",
            field: "statements.FFFS",
          },
          {
            name: "Other Financing Cash Flow",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Other Financing Cash Flow</strong> represents all cash flow from financing activities not covered by another item.</p>",
            field: "statements.FFCF",
          },
          {
            name: "Total Cash Dividends Paid",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Cash Dividends Paid</strong> represents cash dividends paid to shareholders, which is the sum of <strong>Common Cash Dividends Paid</strong> and <strong>Preferred Cash Dividends Paid</strong>.</p>",
            field: "statements.FCDP",
          },
          {
            name: "Net Issuance / Retirement of Stock",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Issuance / Retirement of Stock</strong> is the sum of net movements in <strong>Common Stock</strong>, <strong>Preferred Stock</strong>, <strong>Options</strong>, <strong>Warrants</strong> and <strong>Treasury Stock</strong>.</p>",
            field: "statements.FPSS",
          },
          {
            name: "Net Issuance / Retirement of Debt",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Issuance / Retirement of Debt</strong> is the sum of net movements in both <strong>Short Term Debt</strong> and <strong>Long Term Debt</strong>.</p>",
            field: "statements.FPRD",
          },
          {
            name: "Cash from Financing Activities",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Cash From Financing Activities</strong> is the sum of all <strong>Financing Cash Flow Items</strong>, <strong>Cash Dividends Paid</strong>, the <strong>Net Issuance / Retirement of Stock</strong> and the <strong>Net Issuance / Retirement of Debt</strong></p>",
            field: "statements.FTLF",
          },
          {
            name: "Foreign Exchange Effects",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Foreign Exchange Effects</strong> represents the increase/decrease in cash and cash equivalents due to changes in exchange rates.</p>",
            field: "statements.SFEE",
          },
          {
            name: "Beginning Cash Balance",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Beginning Cash Balance</strong> represents the balance of cash and cash equivalents at the start of the accounting period.</p>",
            field: "statements.SNCB",
          },
          {
            name: "Ending Cash Balance",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Ending Cash Balance</strong> represents the sum of <strong>Net Change in Cash</strong> and the <strong>Beginning Cash Balance</strong>.</p>",
            field: "statements.SNCE",
          },
          {
            name: "Net Change in Cash",
            dataSource: DataSource.STATEMENTS,
            dataType: "double",
            description:
              "<p><strong>Net Change in Cash</strong> represents the sum of <strong>Cash From Operating Activities</strong>, <strong>Cash From Investing Activities</strong>, <strong>Cash From Financing Activities</strong> and <strong>Foreign Exchange Effects</strong>.</p>",
            field: "statements.SNCC",
          },
        ],
        categories: [],
      },
      {
        name: "metadata",
        description: "<p></p>",
        ratios: [
          {
            name: "Update Type",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.stmt.updateType",
          },
          {
            name: "Period Type",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.period.periodType",
          },
          {
            name: "Period Length",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.stmt.periodLength",
          },
          {
            name: "Source Type",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.stmt.source",
          },
          {
            name: "Statement Date",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.perFiling.statDate",
          },
          {
            name: "Period End Date",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.period.asOf",
          },
          {
            name: "Announcement Date",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.stmt.announced",
          },
          {
            name: "Accounting Standard",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.stmt.reportingStandard",
          },
          {
            name: "Consolidated",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.stmt.consolidated",
          },
          {
            name: "Reported Currency",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.stmt.reportedCurrencyId",
          },
          {
            name: "Converted Currency",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.stmt.convertedCurrencyId",
          },
          {
            name: "Reported to Converted Exchange Rate",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.stmt.repToConvExRate",
          },
          {
            name: "Auditor",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.perFiling.auditorName",
          },
          {
            name: "Audit Opinion",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.perFiling.auditorOpinionCode",
          },
          {
            name: "Complete Statement",
            dataSource: DataSource.STATEMENTS,
            dataType: "int",
            description: "<p></p>",
            field: "statements.stmt.complete",
          },
          {
            name: "Flash Statement",
            dataSource: DataSource.STATEMENTS,
            dataType: "int",
            description: "<p></p>",
            field: "statements.stmt.flash",
          },
          {
            name: "Period Length Code",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.stmt.periodLengthCode",
          },
          {
            name: "Update Type Code",
            dataSource: DataSource.STATEMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "statements.stmt.updateTypeCode",
          },
          {
            name: "Final Filing",
            dataSource: DataSource.STATEMENTS,
            dataType: "int",
            description: "<p></p>",
            field: "statements.perFiling.finalFiling",
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "SmartMoney",
    description: "<p></p>",
    ratios: [],
    categories: [
      {
        name: "Ranks",
        description: "<p></p>",
        ratios: [
          {
            name: "Stockopedia SmartMoneyRank&trade;",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The Stockopedia Smart Money Rank is a blend of Institutional, Insider, Analyst Momentum and Short Selling metrics.</p>",
            field: "ratios._2SmartMoneyRank_zz",
          },
          {
            name: "Stockopedia Insider Rank™",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The Stockopedia Insider Rank is a proprietary seven-factor model incorporating insider and senior director (board member) buying, cluster buying factors, net transaction values, net percentage position changes, relative percentage position changes, and short-term insider buying trends. Each factor is time-weighted to give extra weight to recent transactions.</p>",
            field: "ratios._InsiderRank_zz",
          },
        ],
        categories: [],
      },
      {
        name: "Institutions",
        description: "<p></p>",
        ratios: [
          {
            name: "Institutional Best Ideas Rank",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description: "<p></p>",
            field: "ratios._BestIdeasRank_zz",
          },
        ],
        categories: [],
      },
      {
        name: "Insiders",
        description: "<p></p>",
        ratios: [
          {
            name: "No. of Insider Owners",
            dataSource: DataSource.BATCH,
            dataType: "int",
            description:
              "<p>This is the total number of company insiders.  This includes board, management and 10%+ owners who are party to insider information.</p>",
            field: "ratios._InsidersCount",
          },
          {
            name: "Insider Percent Owned",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>This is the percentage of shares outstanding held by company insiders. This includes board, management and 10%+ owners who are party to insider information.</p>",
            field: "ratios._InsiderShares%",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Net Buyer Ratio",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>This the percentage of all insiders that have been net buyers of the company’s stock over each period.</p>",
            field: "ratios._NetBuyerRatio",
          },
        ],
        categories: [],
      },
      {
        name: "Shorts",
        description: "<p></p>",
        ratios: [
          {
            name: "Short Interest Ratio (Days to Cover)",
            dataSource: DataSource.BATCH,
            dataType: "int",
            description:
              "<p>This is a ratio created by dividing the prevailing short interest in a stock by the average daily volume of shares traded.</p>",
            field: "ratios._ShortDaysToCover",
          },
          {
            name: "Short Interest",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>This is the percentage of shares that funds and large investors are currently short of in the stock.</p>",
            field: "ratios._ShortInterest%",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Short Risk",
            dataSource: DataSource.INSTRUMENTS,
            dataType: "int",
            description:
              "<p>This is a proprietary <strong>Stockopedia Short Rank</strong> where 100 is most risky and 0 is least risky.  Only stocks being shorted are ranked on this metric, which is generally about 15 % of the market.</p>",
            field: "ratios._BasicShortRank_zz",
          },
          {
            name: "Short Squeeze Risk",
            dataSource: DataSource.BATCH,
            dataType: "int",
            description:
              "<p>This is a proprietary <strong>Stockopedia Short Squeeze Risk</strong> indicator where 100 is most risky and 0 is least risky.  Only stocks being shorted are ranked on this metric, which is generally about 15 % of the market.</p>",
            field: "ratios._ShortSqueezeRank_zz",
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "Size and Capitalisation",
    description:
      "<p>The <em>size</em> of a company affects its riskiness, liquidity and growth potential. Stockopedia provides information on the scale of an enterprise by reporting its market capitalisation and enterprise value, while the 'Tradability' section helps investors assess how expensive it is to deal in a company's shares and how easy it is to take or exit a position.</p>",
    ratios: [],
    categories: [
      {
        name: "Company Scale",
        description:
          "<p>Our measures of the <em>Scale</em> of a company include a company's market capitalisation (market cap), its enterprise value (which makes companies with different capital profiles more comparable by adding net debt to market cap) and total sales.</p>",
        ratios: [
          {
            name: "Market Capitalisation",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "USD",
            description:
              "<p>The <strong>Market Cap</strong> is the most popular measure of a company's size - or specifically its total equity valuation.  It is calculated by multiplying the current Share Price by the current number of Shares Outstanding.   This field is stated in US Dollars.</p>",
            field: "data.ratios__MktCap_USD",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Market Capitalisation",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "EUR",
            description:
              "<p>The <strong>Market Cap</strong> is the most popular measure of a company's size - or specifically its total equity valuation.  It is calculated by multiplying the current Share Price by the current number of Shares Outstanding.  This field is stated in Euros.</p>",
            field: "data.ratios__MktCap_EUR",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Market Capitalisation",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "GBP",
            description:
              "<p>The <strong>Market Cap</strong> is the most popular measure of a company's size - or specifically its total equity valuation.  It is calculated by multiplying the current Share Price by the current number of Shares Outstanding. This field is stated in British Pounds Sterling.</p>",
            field: "ratios._MktCap_GBP",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Market Capitalisation",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Statements",
            description:
              "<p>The <strong>Market Cap</strong> is the most popular measure of a company's size - or specifically its total equity valuation.  It is calculated by multiplying the current Share Price by the current number of Shares Outstanding.  This field has been adjusted to match the financial statements currency of the current share.</p>",
            field: "data.ratios__MktCap",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Market Capitalisation",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Market Cap</strong> is the most popular measure of a company's size - or specifically its total equity valuation.  It is calculated by multiplying the current Share Price by the current number of Shares Outstanding.</p><p>NB - the currency of this field is dynamic and will match the value set in your site settings.</p>",
            field: "data.ratios__MktCap_CCY",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Market Capitalisation",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "AUD",
            description:
              "<p>The <strong>Market Cap</strong> is the most popular measure of a company's size - or specifically its total equity valuation.  It is calculated by multiplying the current Share Price by the current number of Shares Outstanding.</p><p>NB - the currency of this field is dynamic and will match the value set in your site settings.  This field is stated in Australian Dollars.</p>",
            field: "data.ratios__MktCap_AUD",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Market Capitalisation",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "SGD",
            description:
              "<p>The <strong>Market Cap</strong> is the most popular measure of a company's size - or specifically its total equity valuation.  It is calculated by multiplying the current Share Price by the current number of Shares Outstanding.</p><p>NB - the currency of this field is dynamic and will match the value set in your site settings.  This field is stated in Singapore Dollars.</p>",
            field: "data.ratios__MktCap_SGD",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Market Capitalisation",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "CAD",
            description:
              "<p>The <strong>Market Cap</strong> is the most popular measure of a company's size - or specifically its total equity valuation.  It is calculated by multiplying the current Share Price by the current number of Shares Outstanding.</p><p>NB - the currency of this field is dynamic and will match the value set in your site settings.  This field is stated in Canadian Dollars.</p>",
            field: "data.ratios__MktCap_CAD",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Market Capitalisation",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "JPY",
            description:
              "<p>The <strong>Market Cap</strong> is the most popular measure of a company's size - or specifically its total equity valuation.  It is calculated by multiplying the current Share Price by the current number of Shares Outstanding.</p><p>NB - the currency of this field is dynamic and will match the value set in your site settings.  This field is stated in Japanese Yen.</p>",
            field: "data.ratios__MktCap_JPY",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Market Capitalisation",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "INR",
            description:
              "<p>The <strong>Market Cap</strong> is the most popular measure of a company's size - or specifically its total equity valuation.  It is calculated by multiplying the current Share Price by the current number of Shares Outstanding.</p><p>NB - the currency of this field is dynamic and will match the value set in your site settings.  This field is stated in Indian Rupees.</p>",
            field: "data.ratios__MktCap_INR",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Enterprise Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "USD",
            description:
              "<p>The <strong>Enterprise Value</strong> represents a more complete evaluation of a company’s size than the Market Cap as it adds the net debt to the value of the equity.  It is defined as Market Cap plus Net Debt.  Net Debt is calculated as Total Debt, Minority Interest and Preferred Stock minus Cash and Short Term Investments.  This field is stated in US Dollars.</p>",
            field: "data.ratios__EV_USD",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Enterprise Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "EUR",
            description:
              "<p>The <strong>Enterprise Value</strong> represents a more complete evaluation of a company’s size than the Market Cap as it adds the net debt to the value of the equity.  It is defined as Market Cap plus Net Debt.  Net Debt is calculated as Total Debt, Minority Interest and Preferred Stock minus Cash and Short Term Investments.  This field is stated in Euros.</p>",
            field: "data.ratios__EV_EUR",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Enterprise Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "GBP",
            description:
              "<p>The <strong>Enterprise Value</strong> represents a more complete evaluation of a company’s size than the Market Cap as it adds the net debt to the value of the equity.  It is defined as Market Cap plus Net Debt.  Net Debt is calculated as Total Debt, Minority Interest and Preferred Stock minus Cash and Short Term Investments.   This field is stated in British Pounds Sterling.</p>",
            field: "data.ratios__EV_GBP",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Enterprise Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Statements",
            description:
              "<p>The <strong>Enterprise Value</strong> represents a more complete evaluation of a company’s size than the Market Cap as it adds the net debt to the value of the equity.  It is defined as Market Cap plus Net Debt.  Net Debt is calculated as Total Debt, Minority Interest and Preferred Stock minus Cash and Short Term Investments.  This field has been adjusted to match the financial statements currency of the current share.</p>",
            field: "data.ratios__EV",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Enterprise Value",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Enterprise Value</strong> represents a more complete evaluation of a company’s size than the Market Cap as it adds the net debt to the value of the equity.  It is defined as Market Cap plus Net Debt.  Net Debt is calculated as Total Debt, Minority Interest and Preferred Stock minus Cash and Short Term Investments.</p><p>NB - the currency of this field is dynamic and will match the value set in your site settings.</p>",
            field: "ratios._EV_CCY",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Enterprise Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "AUD",
            description:
              "<p>The <strong>Enterprise Value</strong> represents a more complete evaluation of a company’s size than the Market Cap as it adds the net debt to the value of the equity.  It is defined as Market Cap plus Net Debt.  Net Debt is calculated as Total Debt, Minority Interest and Preferred Stock minus Cash and Short Term Investments.   This field is stated in Australian Dollars.</p>",
            field: "data.ratios__EV_AUD",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Enterprise Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "SGD",
            description:
              "<p>The <strong>Enterprise Value</strong> represents a more complete evaluation of a company’s size than the Market Cap as it adds the net debt to the value of the equity.  It is defined as Market Cap plus Net Debt.  Net Debt is calculated as Total Debt, Minority Interest and Preferred Stock minus Cash and Short Term Investments.   This field is stated in Singapore Dollars.</p>",
            field: "data.ratios__EV_SGD",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Enterprise Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "CAD",
            description:
              "<p>The <strong>Enterprise Value</strong> represents a more complete evaluation of a company’s size than the Market Cap as it adds the net debt to the value of the equity.  It is defined as Market Cap plus Net Debt.  Net Debt is calculated as Total Debt, Minority Interest and Preferred Stock minus Cash and Short Term Investments.   This field is stated in Canadian Dollars.</p>",
            field: "data.ratios__EV_CAD",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Enterprise Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "JPY",
            description:
              "<p>The <strong>Enterprise Value</strong> represents a more complete evaluation of a company’s size than the Market Cap as it adds the net debt to the value of the equity.  It is defined as Market Cap plus Net Debt.  Net Debt is calculated as Total Debt, Minority Interest and Preferred Stock minus Cash and Short Term Investments.   This field is stated in Japanese Yen.</p>",
            field: "data.ratios__EV_JPY",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Enterprise Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "INR",
            description:
              "<p>The <strong>Enterprise Value</strong> represents a more complete evaluation of a company’s size than the Market Cap as it adds the net debt to the value of the equity.  It is defined as Market Cap plus Net Debt.  Net Debt is calculated as Total Debt, Minority Interest and Preferred Stock minus Cash and Short Term Investments.   This field is stated in Indian Rupees.</p>",
            field: "data.ratios__EV_INR",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Total Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "USD TTM",
            description:
              "<p>The <strong>Total Sales</strong> field is a useful measure of a company’s size.  It  represents the sum of revenue from all of a company’s operating activities after deducting any sales adjustments over the last 12 months.  This field is measured on a trailing twelve month basis and has been translated to US Dollars for all companies.</p>",
            field: "data.ratios__TotRevUSD_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Total Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "EUR TTM",
            description:
              "<p>The <strong>Total Sales</strong> field is a useful measure of a company’s size.  It  represents the sum of revenue from all of a company’s operating activities after deducting any sales adjustments over the last 12 months.  This field is measured on a trailing twelve month basis and has been translated to Euros for all companies.</p>",
            field: "data.ratios__TotRevEUR_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Total Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "GBP TTM",
            description:
              "<p>The <strong>Total Sales</strong> field is a useful measure of a company’s size.  It  represents the sum of revenue from all of a company’s operating activities after deducting any sales adjustments over the last 12 months.  This field is measured on a trailing twelve month basis and has been translated to British Pounds Sterling for all companies.</p>",
            field: "data.ratios__TotRevGBP_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Total Sales",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description:
              "<p>The <strong>Total Sales</strong> field is a useful measure of a company’s size.  It  represents the sum of revenue from all of a company’s operating activities after deducting any sales adjustments over the last 12 months.  This field is measured on a trailing twelve month basis.</p><p>NB - the currency of this field is dynamic and will match the value set in your site settings.</p>",
            field: "data.ratios__TotRevccy_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Total Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "GBP RA",
            description:
              "<p>The <strong>Total Sales</strong> field is a useful measure of a company’s size.  It  represents the sum of revenue from all of a company’s operating activities after deducting any sales adjustments over the last 12 months.  This field measures the last full year accounting period and for the company and has been translated to British Pounds Sterling for all companies.</p>",
            field: "data.ratios__TotRevGBP_RA",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Total Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "AUD TTM",
            description:
              "<p>The <strong>Total Sales</strong> field is a useful measure of a company’s size.  It  represents the sum of revenue from all of a company’s operating activities after deducting any sales adjustments over the last 12 months.  This field is measured on a trailing twelve month basis and has been translated to Australian Dollars for all companies.</p>",
            field: "data.ratios__TotRevAUD_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Total Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "CAD TTM",
            description:
              "<p>The <strong>Total Sales</strong> field is a useful measure of a company’s size.  It  represents the sum of revenue from all of a company’s operating activities after deducting any sales adjustments over the last 12 months.  This field is measured on a trailing twelve month basis and has been translated to Canadian Dollars for all companies.</p>",
            field: "data.ratios__TotRevCAD_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Total Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "SGD TTM",
            description:
              "<p>The <strong>Total Sales</strong> field is a useful measure of a company’s size.  It  represents the sum of revenue from all of a company’s operating activities after deducting any sales adjustments over the last 12 months.  This field is measured on a trailing twelve month basis and has been translated to Singapore Dollars for all companies.</p>",
            field: "data.ratios__TotRevSgd_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Total Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "JPY TTM",
            description:
              "<p>The <strong>Total Sales</strong> field is a useful measure of a company’s size.  It  represents the sum of revenue from all of a company’s operating activities after deducting any sales adjustments over the last 12 months.  This field is measured on a trailing twelve month basis and has been translated to Japanese Yen for all companies.</p>",
            field: "data.ratios__TotRevJpy_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Total Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "INR TTM",
            description:
              "<p>The <strong>Total Sales</strong> field is a useful measure of a company’s size.  It  represents the sum of revenue from all of a company’s operating activities after deducting any sales adjustments over the last 12 months.  This field is measured on a trailing twelve month basis and has been translated to Indian Rupees for all companies.</p>",
            field: "data.ratios__TotRevInr_TTM",
            unit: RatioUnit.MILLION,
          },
        ],
        categories: [],
      },
      {
        name: "Tradability Measures",
        description:
          "<p>Measures of the <em>tradability</em> of a company's shares help investors make appropriate decisions to ensure they can execute their trade sizes successfully. Stockopedia includes information on the total shares outstanding of a company, the percentage of those that are listed and available for trading on a stock exchange (percentage free float), and the bid-ask spread of those shares.</p>",
        ratios: [
          {
            name: "Bid-Ask Spread",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>The <strong>Bid Ask Spread</strong> is a popular measure of the liquidity and tradeability of a share.  It measures the difference between the Sell Price (know as the ‘bid’) and the Buy Price (known as the ‘ask’).  It is calculated as a percentage of the Mid Price and quoted in basis points.</p>",
            field: "data.ratios__SpreadPercent",
            unit: RatioUnit.BASIS_POINTS,
          },
          {
            name: "Total Shares Outstanding",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p><strong>Total Shares Outstanding</strong> measures the total number of common shares that have been authorised and issued by the company, and purchased by investors. They have voting rights and represent ownership in the corporation by the person or institution that holds the shares. They should be distinguished from treasury stock, which is held by the company.  This figure is quotes in millions of shares.</p>",
            field: "data.ratios__sharesout",
          },
          {
            name: "Percentage Free Float",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>Percentage Free Float</strong> is a useful additional measure of the liquidity of a share.   It shows the percentage of total Shares Outstanding that are freely floated on the stock exchange and available for trading.  This field is quoted as a percentage.</p>",
            field: "data.ratios__FloatPercent",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "Series",
    description: "<p></p>",
    ratios: [],
    categories: [
      {
        name: "Financial Summary",
        description: "<p></p>",
        ratios: [
          {
            name: "Total Revenue",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>The <strong>Total Sales</strong> field is a useful measure of a company’s size.  It  represents the sum of revenue from all of a company’s operating activities after deducting any sales adjustments over the last 12 months.</p>",
            field: "series._TotRev",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Diluted Weighted Average Shares",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>The <strong>Diluted Weighted Average Shares Outstanding</strong> is the number of shares that a company has in issue.  The number is diluted, meaning it takes account of outsanding convertible securities.  The number is a weighted average for a period, meaning it is the average number of shares outstanding during a period.</p>",
            field: "series._DilWeightedShs",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Diluted Normalised EPS",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>The <strong>Normalised Earnings per Share</strong>, or <strong>NEPS</strong>, figure indicates the earnings per-share a business would have generated if all stock options and other sources of dilution that were currently exercisable are taken into account.</p>",
            field: "series._DilNEPS",
          },
          {
            name: "PE Ratio",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.</p>",
            field: "series._PENormal",
          },
          {
            name: "Operating Profit",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p><strong>Operating Profit</strong> is a measure of a company's earning power from ongoing operations, calculated as the difference between operating revenues and operating expenses.</p>",
            field: "series._OpInc",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Net Profit",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p><strong>Net Profit</strong> is the profit reported net of all taxes, interest and expenses.  Often referred to as the 'bottom line' and used to calculate per share earnings.</p>",
            field: "series._NetInc",
            unit: RatioUnit.MILLION,
          },
          {
            name: "EPS Reported",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description: "<p></p>",
            field: "series._DilNEPS_REP",
          },
          {
            name: "EPS Growth",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p><strong>Earnings per Share Growth</strong> is used to determine the rate at which a company is growing its profitability.  It is measured as a percentage change over a given period.</p>",
            field: "series._DilNEPSChg%",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "PEG",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>The <strong>Price to Earnings Growth Ratio</strong>, or <strong>PEG Ratio</strong>, measures of the value of a company against its earnings and growth rate.  It is calculated by taking the historic Price to Earnings Ratio (based on last year's diluted normalised Earnings) and dividing it by the consensus forecast EPS growth for the next year.</p>",
            field: "series._PEG",
          },
          {
            name: "Operating Margin",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>The <strong>Operating Profit Margin</strong> is a measure of how much income a company has left after paying its Operating Costs such as Rent and Salaries. It is calculated as Operating Profit divided by Revenue.</p>",
            field: "series._OpMgn%",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "ROA",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>The <strong>Return on Average Assets</strong>, or <strong>ROA</strong>, is a measure of how efficiently a company is using its assets to generate income.  It is calculated by dividing a company's annual earnings by its average total assets.</p>",
            field: "series._ROA%",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "ROCE",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>The <strong>Return on Capital Employed</strong>, or <strong>ROCE</strong>, measures how effectively a company uses its total capital employed to generate income. It is calculated as the Operating Income divided by the Capital Employed.</p>",
            field: "series._ROCE%",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "ROE",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>The <strong>Return on Equity</strong>, or <strong>ROE</strong>, measures how efficiently a company uses Shareholders’ Equity to generate profits.  It is calculated as the Net Profit for the year, divided by Average Book Value, or Equity, for the period.</p>",
            field: "series._ROE%",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Op. Cashflow ps",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>The <strong>Operating Cash Flow Per Share</strong> refers to the amount of cash a company generates from the revenues it brings in, excluding costs associated with long-term capital investment. It is similar to operating profit but excludes non-cash items and accruals.</p>",
            field: "series._CashOpActPS",
          },
          {
            name: "Capex ps",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p><strong>Capital Expenditure Per Share</strong> is the amount of Capital Expenditure Per Share which the company incurs in order to maintain its operating assets. It is calculated as the Capital Expenditure from the Statement of Cash Flows divided by the Average Shares Outstanding for the same period.</p>",
            field: "series._CapExPS",
          },
          {
            name: "Free Cashflow ps",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p><strong>Free Cash Flow Per Share</strong> is calculated from the Statement of Cash Flows as Cash From Operations minus Capital Expenditures, divided by the Diluted Number of Ordinary Shares Outstanding.</p>",
            field: "series._FCFPS",
          },
          {
            name: "Dividend ps",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p><strong>Dividend per Share</strong> is the amount amount of dividend payment that a shareholder receives for each share held. It can be calculated by taking the total amount of dividends paid and dividing it by the total shares outstanding.</p>",
            field: "series._DPSCom",
          },
          {
            name: "Dividend Growth",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>The <strong>Dividend per Share Growth</strong> measures the year on year growth of dividend payments by the company. It is calculated as the year on year change in Dividends per Share.</p>",
            field: "series._DPSChg%",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend Yield",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.</p>",
            field: "series._DPSYield%",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend Cover",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p><strong>Dividend Cover</strong> is a popular measure of dividend safety.  It is calculated as earnings per share divided by the dividend per share. It provides a quick fix on how many times the dividend is ‘covered’ by earnings.</p>",
            field: "series._DivCover",
          },
          {
            name: "Cash etc",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p><strong>Cash and Short Term Investments</strong> is the sum of Cash, Cash & Equivalents and Short-Term Investments.</p>",
            field: "series._CashSTInv",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Working Capital",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p><strong>Working Capital</strong> is calculated as Current Assets minus Current Liabilities. It is a measure of the Operating Liquidity available to a business.</p>",
            field: "series._WC",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Net Debt",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p><strong>Net Debt</strong> is the sum of all Short Term Debt, Notes Payable, Long Term debt and Preferred Equity minus the total cash and equivalents and short term investments for the most recent reporting period.</p>",
            field: "series._NetDebt",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Book Value",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p><strong>Book Value</strong> is calculate as Total Assets mins Total Liabilities.  It is a measure of Shareholder's Equity.</p>",
            field: "series._BV",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Book Value ps",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p><strong>Book Value per Share</strong> is calculate as Total Assets mins Total Liabilities, divided by the number of <strong>Shares Outstanding</strong>.  It is a measure of Shareholder's Equity per Share.</p>",
            field: "series._BVPS",
          },
          {
            name: "metadata",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description: "<p>n/a</p>",
            field: "series._metadata",
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "Quality Ratios",
    description:
      "<p><em>Quality Ratios</em> allow an investor to understand the profitability of a company and its ability to defend its competitive operating advantages over time. Basic economics dictates that if one company identifies a profitable niche with low barriers to entry, it will attract competitors that take market share by whittling away at profit margins (i.e. undercutting on price) over time. High quality companies often have brands or proprietary technology that give them strong pricing power and a market-leading position. Stockopedia provides a range of Quality factors across profitability, margins, efficiency, liquidity and more.</p>",
    ratios: [],
    categories: [
      {
        name: "Quality Rankings",
        description:
          "<p>Stockopedia's <em>Quality Rankings</em> are a unique way of assessing a company's overall profitability, stability and financial trend. These standardised fields allow an easier comparability between peers. The Quality Rank and its relations, the QV Rank (Quality + Value) and QM Rank (Quality + Momentum) classify enterprises according to the strength of their company franchise (profit margins & cash generation), company risk (bankruptcy risk & volatility) and financial health (Piotroski F-Score).</p>",
        ratios: [
          {
            name: "Stockopedia Quality Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>Quality Rank</strong> is ranking system that aims to find the best quality listed companies.  It is based on a blend of measures that include an assessment of company franchise (profitability, cashflow, margins), company risk (bankruptcy, volatility) and fundamental trend (F-Score).  These inputs are computed for every company in the market on a daily basis, ranked, blended and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__QualityScore_zz",
          },
          {
            name: "Stockopedia QV Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>QV Rank</strong> provides a simple but effective system for finding good, cheap (quality+value) shares. It is an equally weighted combination of Stockopedia's QualityRank and ValueRank.  These two basic ranks are computed for every company in the market on a daily basis, summed and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__QVScore",
          },
          {
            name: "Stockopedia QM Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>QM Rank</strong> provides a simple but effective system for finding good, improving (quality+momentum) shares. It is an equally weighted combination of Stockopedia's QualityRank and MomentumRank.  These two basic ranks are computed for every company in the market on a daily basis, summed and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__QMScore",
          },
        ],
        categories: [],
      },
      {
        name: "Profitability Ratios",
        description:
          "<p><em>Profitability Ratios</em> measure how effectively a company turns its capital (equity or assets) into earnings and free cashflow. Profitability metrics that we list at Stockopedia include the Return on Equity, Return on Capital Employed, and Return on Assets amongst others.</p>",
        ratios: [
          {
            name: "Return on Equity",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Return on Equity</strong>, or <strong>ROE</strong>, measures how efficiently a company uses Shareholders’ Equity to generate profits.  It is calculated as the Net Profit for the year, divided by Average Book Value, or Equity, for the period.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and earnings are normalised.</p>',
            field: "data.ratios__ROEPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Equity",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Return on Equity</strong>, or <strong>ROE</strong>, measures how efficiently a company uses Shareholders’ Equity to generate profits.  It is calculated as the Net Profit for the year, divided by Average Book Value, or Equity, for the period.  This is measured on a historical basis and earnings are normalised.</p>",
            field: "data.ratios__ROEPercentRA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Equity",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "2y Ago",
            description:
              "<p>The <strong>Return on Equity</strong>, or <strong>ROE</strong>, measures how efficiently a company uses Shareholders’ Equity to generate profits.  It is calculated as the Net Profit for the year, divided by Average Book Value, or Equity, for the period.  This is measured on a 2 year historical basis and earnings are normalised.</p>",
            field: "data.ratios__ROEPercentRPY2",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Equity",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Avg",
            description:
              "<p>The <strong>Return on Equity</strong>, or <strong>ROE</strong>, measures how efficiently a company uses Shareholders’ Equity to generate profits.  It is calculated as the Net Profit for the year, divided by Average Book Value, or Equity, for the period.  This is an average of the past 5 years’ earnings data and earnings are normalised.</p>",
            field: "data.ratios__ROEPercentAvg_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Equity",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Prior TTM",
            description:
              "<p>The <strong>Return on Equity</strong>, or <strong>ROE</strong>, measures how efficiently a company uses Shareholders’ Equity to generate profits.  It is calculated as the Net Profit for the year, divided by Average Book Value, or Equity, for the period.  This is measured on a Prior TTM basis and earnings are normalised.</p>",
            field: "data.ratios__ROEPercentPTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Equity",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "2y Prior TTM",
            description:
              "<p>The <strong>Return on Equity</strong>, or <strong>ROE</strong>, measures how efficiently a company uses Shareholders’ Equity to generate profits.  It is calculated as the Net Profit for the year, divided by Average Book Value, or Equity, for the period.  This is measured on a 2 year Prior TTM basis and earnings are normalised.</p>",
            field: "data.ratios__ROEPercentPTTM2",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Capital Employed",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Return on Capital Employed</strong>, or <strong>ROCE</strong>, measures how effectively a company uses its total capital employed to generate income. It is calculated as the Operating Income divided by the Capital Employed.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__ROCEPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Capital Employed",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Return on Capital Employed</strong>, or <strong>ROCE</strong>, measures how effectively a company uses its total capital employed to generate income. It is calculated as the Operating Income divided by the Capital Employed.  This is measured on a historical basis.</p>",
            field: "data.ratios__ROCEPercentRA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Capital Employed",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Avg",
            description:
              "<p>The <strong>Return on Capital Employed</strong>, or <strong>ROCE</strong>, measures how effectively a company uses its total capital employed to generate income. It is calculated as the Operating Income divided by the Capital Employed.  This is measured as an average of the past 5 years' historical values.</p>",
            field: "data.ratios__ROCEPercentAvg_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Capital Employed",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Long Term Avg",
            description:
              "<p>The <strong>Return on Capital Employed</strong>, or <strong>ROCE</strong>, measures how effectively a company uses its total capital employed to generate income. It is calculated as the Operating Income divided by the Capital Employed.  This is measured as an average of all available historical values.</p>",
            field: "data.ratios__ROCEPercentAvg_Max",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Capital Employed",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Prior TTM",
            description:
              "<p>The <strong>Return on Capital Employed</strong>, or <strong>ROCE</strong>, measures how effectively a company uses its total capital employed to generate income. It is calculated as the Operating Income divided by the Capital Employed.  This is measured on a Prior TTM basis.</p>",
            field: "data.ratios__ROCEPercentPTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Capital (Greenblatt)",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>This version of the <strong>Return on Capital</strong>, or <strong>ROC</strong>, is used by Joel Greenblatt in his Magic Formula to measure the rate of return a business is making on its total capital.  It is calculated as EBIT divided by Capital Employed.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__ROCPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Capital (Greenblatt Definition)",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Avg",
            description:
              "<p>This version of the <strong>Return on Capital</strong>, or <strong>ROC</strong>, is used by Joel Greenblatt in his Magic Formula to measure the rate of return a business is making on its total capital.  It is calculated as EBIT divided by Capital Employed.  This is measured as an average of the past 5 years’ historical values.</p>",
            field: "data.ratios__ROCPercentAvg_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Average Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Return on Average Assets</strong>, or <strong>ROA</strong>, is a measure of how efficiently a company is using its assets to generate income.  It is calculated by dividing a company\'s annual earnings by its average total assets.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__ROAPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Average Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Avg",
            description:
              "<p>The <strong>Return on Average Assets</strong>, or <strong>ROA</strong>, is a measure of how efficiently a company is using its assets to generate income.  It is calculated by dividing a company's annual earnings by its average total assets.  This is measured as an average of the past 5 years’ historical values.</p>",
            field: "data.ratios__ROAPercentAvg_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Invested Capital",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Return on Invested Capital</strong>, or <strong>ROIC</strong>, is a measure of how efficiently a company generates cash flow compared to how much capital is invested in the company.  It is calculated as the Operating Profit after Tax divided by Total Capital Invested.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__ROICPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Gross Profit to Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Gross Profits to Assets</strong>, or <strong>GPA</strong>, is used to help determine how efficiently a firm uses its assets to generate Gross Profits.  It is calculate as Gross Profits divided by the firm’s Total Assets.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__GP2Assets",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Free Cash Flow to Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Free Cash Flow to Sales</strong>, or <strong>FCF / S</strong>, is a measure of how effectively a company generates surplus Cash Flow from Revenues. It is calculated by dividing the Free Cash Flow by Revenue.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__FCF2RevPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Long Term Average Free Cashflow to Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              '<p>The <strong>Long Term Average Free Cash Flow to Assets</strong>, or <strong>FCF LT / Assets</strong>, is a measure of how effectively a company generates surplus Cash Flow from Revenues.  It is the Average Free Cash Flow over the last 5 years, divided by Total Assets.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__LTAvgFCF_Assets",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Cash Return On Invested Capital",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Cash Return On Invested Capital</strong>, or  <strong>CROIC</strong>, measures how effectively a company uses its Invested Capital to generate Cash.  It is calculated as Free Cash Flow divided by Invested Capital.  This is measured on a historical basis.</p>",
            field: "ratios._CROIC%RA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Asset Turnover",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Asset Turnover</strong> is a measure of how effectively a company is using its Assets to generate Revenue.  It is calculated as Revenue divided by Total Assets.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__Astturn_TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Receivables Turnover",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>The <strong>Receivables Turnover</strong> ratio measures the number of times, on average, receivables (money owed by customers) is collected during a reporting period. While it is best calculated by dividing sales by average accounts receivable, we have used total sales since many companies do not disclose how much of the sales were made on credit. The <strong>Receivables Turnover</strong> ratio is measured on a TTM basis.</p>",
            field: "data.ratios__RECTURN_TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Inventory Turnover",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>The <strong>Inventory Turnover</strong> ratio is an efficiency ratio used to calculate the number of times inventory is sold and replaced in given time period. It is calculated by taking the Cost of Goods Sold and dividing this by the Average Inventory. COGS is used instead of sales because sales are recorded at market value, while inventories are usually recorded at cost. The <strong>Inventory Turnover</strong> ratio is measured on a TTM basis.</p>",
            field: "ratios._INVTURN_TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Cashflow Return on Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year (Piotroski)",
            description:
              "<p>The <strong>Cashflow Return on Assets</strong> is a profitability metric that measures how efficiently a company uses Assets to generate Operating Cashflows. It is calculated by dividing Operating Cashflows by the Asset position on the balance sheet at the beginning of the financial period.</p>",
            field: "data.ratios__CFROA_PiotrPercentRA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM (Piotroski)",
            description:
              '<p>The <strong>Return on Assets</strong>, or <strong>ROA</strong>, is a measure of how efficiently a company uses assets to generate income. This ratio is calculated as per the method used by the financial academic, <a href="http://www.stockopedia.com/content/the-piotroski-f-score-a-fundamental-screen-for-value-stocks-55711/">Joseph Piotroski</a>. He calculates the ROA by dividing a company\'s net income by the asset position on the balance sheet at the beginning of the financial period. Elsewhere, Stockopedia calculates ROA as net income divided by average assets (ie. opening asset position + ending asset position / 2).</p><p>This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r">TTM</a> basis.</p>',
            field: "ratios._ROA_Piotr%TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Prior TTM (Piotroski)",
            description:
              '<p>The <strong>Return on Assets</strong>, or <strong>ROA</strong>, is a measure of how efficiently a company uses assets to generate income. This ratio is calculated as per the method used by the financial academic, <a href="http://www.stockopedia.com/content/the-piotroski-f-score-a-fundamental-screen-for-value-stocks-55711/">Joseph Piotroski</a>. He calculates the ROA by dividing a company\'s net income by the asset position on the balance sheet at the beginning of the financial period. Elsewhere, Stockopedia calculates ROA as net income divided by average assets (ie. opening asset position + ending asset position / 2).</p><p>This is measured for the most recent financial year.</p>',
            field: "ratios._ROA_Piotr%PTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Assets",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "Last Year (Piotroski)",
            description:
              "<p>The <strong>Return on Assets</strong>, or <strong>ROA</strong>, is a measure of how efficiently a company is using its assets to generate income. Joseph Piotroski calculates the ROA by dividing a company's net income by the asset position on the balance sheet at the beginning of the financial period. This is measured on an annual basis. Elsewhere, Stockopedia calculates ROA as net income divided by average assets (ie. opening asset position + ending asset position / 2).</p>",
            field: "ratios._ROA_Piotr%RA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Assets",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "1y Ago (Piotroski)",
            description:
              "<p>The <strong>Return on Assets</strong>, or <strong>ROA</strong>, is a measure of how efficiently a company is using its assets to generate income. Joseph Piotroski calculates the ROA by dividing a company's net income by the asset position on the balance sheet at the beginning of the financial period. Elsewhere, Stockopedia calculates ROA as net income divided by average assets (ie. opening asset position + ending asset position / 2).</p>",
            field: "ratios._ROA_Piotr%RPY",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Cashflow Return on Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM (Piotroski)",
            description:
              '<p>The <strong>Cashflow Return on Assets</strong> is a profitability metric that measures how efficiently a company uses assets to generate operating cashflows. It is calculated by dividing operating cashflows by the asset position on the balance sheet at the beginning of the financial period. The financial academic, <a href="http://www.stockopedia.com/content/the-piotroski-f-score-a-fundamental-screen-for-value-stocks-55711/">Joseph Piotroski</a> used this metric to construct his 9 point Piotroski F-Score</p>',
            field: "ratios._CFROA_Piotr%TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Asset Turnover",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM (Piotroski)",
            description:
              '<p><strong>Asset Turnover</strong> measures how effectively a company uses assets to generate revenue. This ratio is calculated as per the method used by the financial academic, Joseph Piotroski. He calculates the Asset Turnover by dividing revenue by the value of total assets on the balance sheet at the beginning of the finanical year. Elsewhere, Stockopedia divides revenue by the average assets (ie. opening asset position + ending asset position / 2). This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__Astturn_TTM",
          },
          {
            name: "Asset Turnover",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Prior TTM (Piotroski)",
            description:
              "<p><strong>Asset Turnover</strong> is a measure of how effectively a company uses assets to generate revenue. This ratio is calculated as per the method used by the financial academic, Joseph Piotroski. He calculates the Asset Turnover by dividing revenue by the value of total assets on the balance sheet at the beginning of a finanical year. Elsewhere, Stockopedia divides revenue by the average assets (ie. opening asset position + ending asset position / 2). This metric is calculated using financial statement data from the previous 12 month period, rather than using the most recent 12 month period.</p>",
            field: "ratios._Astturn_Piotr_PTTM",
          },
          {
            name: "Asset Turnover",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year (Piotroski)",
            description:
              "<p><strong>Asset Turnover</strong> is a measure of how effectively a company uses assets to generate revenue. Joseph Piotroski calculates the Asset Turnover by dividing revenue by the value of total assets on the balance sheet at the beginning of the finanical year. Elsewhere, Stockopedia divides revenue by the average assets (ie. opening asset position + ending asset position / 2). This is measured on an annual basis.</p>",
            field: "ratios._Astturn_Piotr_RA",
          },
          {
            name: "Asset Turnover",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1y Ago (Piotroski)",
            description:
              "<p><strong>Asset Turnover</strong> is a measure of how effectively a company uses assets to generate revenue. Joseph Piotroski calculates the Asset Turnover by dividing revenue by the value of total assets on the balance sheet at the beginning of a finanical year. Elsewhere, Stockopedia divides revenue by the average assets (ie. opening asset position + ending asset position / 2). This metric is calculated using balance sheet values from the previous financial period, rather than using the most recent balance sheet data.</p>",
            field: "ratios._Astturn_Piotr_RPY",
          },
          {
            name: "Return on Capital (Greenblatt) Rank",
            dataSource: DataSource.BATCH,
            dataType: "int",
            description: "<p></p>",
            field: "ratios._MagicFormulaROCPosition_TTM_zz",
          },
          {
            name: "Earnings Yield (Greenblatt) Rank",
            dataSource: DataSource.BATCH,
            dataType: "double",
            description: "<p></p>",
            field: "ratios._MagicFormulaEYPosition_TTM_zz",
          },
        ],
        categories: [],
      },
      {
        name: "Profit Margin Ratios",
        description:
          "<p><em>Profit Margins</em> - including the Gross Profit Margin, Operating Profit Margin, and Net Profit Margin - are important indicators of a company's overall profitability. A company that makes higher margins than its peers has a competitive advantage over its rivals as it has more money to reinvest or return to stakeholders.</p>",
        ratios: [
          {
            name: "Gross Profit Margin",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>The <strong>Gross Profit Margin</strong> is a measure of how much income a comany has left after paying all direct production expenses.  It is calculated as Gross Profit divided by Revenue.  This item is only available for Industrial and Utility companies and is measured on a TTM basis.</p>",
            field: "data.ratios__GMgnPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Gross Profit Margin",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Prior TTM",
            description:
              "<p>The <strong>Gross Profit Margin</strong> is a measure of how much income a comany has left after paying all direct production expenses.  It is calculated as Gross Profit divided by Revenue.  This item is only available for Industrial and Utility companies and is measured on a Prior TTM basis.</p>",
            field: "data.ratios__GMgnPercentPTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Gross Profit Margin",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Avg",
            description:
              "<p>The <strong>Gross Profit Margin</strong> is a measure of how much income a comany has left after paying all direct production expenses.  It is calculated as Gross Profit divided by Revenue.  This item is only available for Industrial and Utility companies and is measured as an average of the past 5 years' historical values.</p>",
            field: "data.ratios__GMgnPercentAvg_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Gross Profit Margin",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Gross Profit Margin</strong> is a measure of how much income a comany has left after paying all direct production expenses.  It is calculated as Gross Profit divided by Revenue and is available for the most recent financial year.</p>",
            field: "data.ratios__GMgnPercentRA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Gross Profit Margin",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Gross Profit Margin</strong> is a measure of how much income a comany has left after paying all direct production expenses.  It is calculated as Gross Profit divided by Revenue.  This item is only available for Industrial and Utility companies.</p>",
            field: "data.ratios__GMgnPercentRQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Gross Profit Margin",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "1y Ago",
            description:
              "<p>The <strong>Gross Profit Margin</strong> is a measure of how much income a comany has left after paying all direct production expenses.  It is calculated as Gross Profit divided by Revenue.  This item is only available for Industrial and Utility companies.</p>",
            field: "data.ratios__GMgnPercentRPY",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Operating Profit Margin",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Operating Profit Margin</strong> is a measure of how much income a company has left after paying its Operating Costs such as Rent and Salaries. It is calculated as Operating Profit divided by Revenue. This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__OpMgnPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Operating Profit Margin",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Operating Profit Margin</strong> is a measure of how much income a company has left after paying its Operating Costs such as Rent and Salaries. It is calculated as Operating Profit divided by Revenue. This is measured on a historical basis.</p>",
            field: "data.ratios__OpMgnPercentRA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Operating Profit Margin",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Avg",
            description:
              "<p>The <strong>Operating Profit Margin</strong> is a measure of how much income a company has left after paying its Operating Costs such as Rent and Salaries. It is calculated as Operating Profit divided by Revenue. This is measured as an average of the past 5 years' historical values.</p>",
            field: "data.ratios__OpMgnPercentAvg_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Net Profit Margin",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Net Profit Margin</strong> is a measure of how much profit a company has left over after paying all of its expenses, including Interest and Taxes.  It is calculated as the Net Profit divided by Revenue.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and earnings are normalised.</p>',
            field: "data.ratios__NMgnPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Net Profit Margin",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Prior TTM",
            description:
              "<p>The <strong>Net Profit Margin</strong> is a measure of how much profit a company has left over after paying all of its expenses, including Interest and Taxes.  It is calculated as the Net Profit divided by Revenue.  This is measured on a Prior TTM basis and earnings are normalised.</p>",
            field: "data.ratios__NMgnPercentPTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Net Profit Margin",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1y Ago",
            description:
              "<p>The <strong>Net Profit Margin</strong> is a measure of how much profit a company has left over after paying all of its expenses, including Interest and Taxes.  It is calculated as the Net Profit divided by Revenue.  This is the historical figure from one year ago and earnings are normalised.</p>",
            field: "data.ratios__NMgnPercentRPY",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Net Profit Margin",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Avg",
            description:
              "<p>The <strong>Net Profit Margin</strong> is a measure of how much profit a company has left over after paying all of its expenses, including Interest and Taxes.  It is calculated as the Net Profit divided by Revenue.  This is measured as an average of the past 5 years' historical values and earnings are normalised.</p>",
            field: "data.ratios__NMgnPercentAvg_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
      {
        name: "Liquidity Ratios",
        description:
          "<p><em>Liquidity</em> ratios are used to assess a company's ability to pay off current debt obligations with liquid assets. The most widely used liquidity measure - the current ratio - is found here, as is the cash conversion cycle and amount of interest cover.</p>",
        ratios: [
          {
            name: "Current Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>The <strong>Current Ratio</strong> is a measure of how many times a company can pay its Current Liabilities with its Current Assets.  It is calculated as Total Current Assets divided by Total Current Liabilities.  This item is not available for Banks, Insurance companies and other companies that do not distinguish between current and long term assets and liabilities and is calculated on a TTM basis.</p>",
            field: "ratios._CurRatio_RQ",
          },
          {
            name: "Interest Cover",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>The <strong>Interest Cover</strong> is a measure of how many times a company can pay its Interest Expenses from its Earnings.  It is calculated as Operating Profit divided by the Inerest Expense.  This is calculated on a TTM basis.</p>",
            field: "data.ratios__INTCOV_TTM",
          },
          {
            name: "Cash to Assets Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Cash to Assets Ratio</strong> is a measure of the proportion of a company's Assets that are made up of Cash and Short Term Investments.  It is calculated as Cash divided by Total Assets.  It is measured using the most recent Balance Sheet available.</p>",
            field: "data.ratios__Cash2Assets_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Quick Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Quick Ratio</strong> is a measure of how many times a company can pay its Current Liabilities using its Cash only.  It is calculated as Cash divided by its Current Liabilities.  It offers a more stringent measure of liquidity than the current ratio.  This is calculated on a historical basis.</p>",
            field: "data.ratios__QuickRatio_RA",
          },
          {
            name: "Cash Conversion Cycle",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>The <strong>Cash Conversion Cycle</strong> shows how long cash is tied up in the working capital cycle from the first investment in inventory to the collection of accounts receivable.  It is measured as Days Inventory Outstanding, plus Days Sales Outstanding minus Days Payable Outstanding and is measured on a TTM basis.</p>",
            field: "data.ratios__CashConvCyc_TTM",
          },
          {
            name: "Quick Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Quick Ratio</strong> is a measure of how many times a company can pay its Current Liabilities using its Cash only.  It is calculated as Cash divided by its Current Liabilities.  It offers a more stringent measure of liquidity than the current ratio.  This is calculated on a historical basis.</p>",
            field: "data.ratios__QuickRatio_RQ",
          },
          {
            name: "Current Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Current Ratio</strong> is a measure of how many times a company can pay its Current Liabilities with its Current Assets.  It is calculated as Total Current Assets divided by Total Current Liabilities.  This item is not available for Banks, Insurance companies and other companies that do not distinguish between current and long term assets and liabilities.</p>",
            field: "data.ratios__CurRatio_RA",
          },
          {
            name: "Current Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1y Ago",
            description:
              "<p>The <strong>Current Ratio</strong> is a measure of how many times a company can pay its Current Liabilities with its Current Assets.  It is calculated as Total Current Assets divided by Total Current Liabilities.  This item is not available for Banks, Insurance companies and other companies that do not distinguish between current and long term assets and liabilities.</p>",
            field: "data.ratios__CurRatio_RPY",
          },
          {
            name: "Current Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Prior Yr Qtr",
            description:
              "<p>The <strong>Current Ratio</strong> is a measure of how many times a company can pay its Current Liabilities with its Current Assets.  It is calculated as Total Current Assets divided by Total Current Liabilities.  This item is not available for Banks, Insurance companies and other companies that do not distinguish between current and long term assets and liabilities. This ratio reflects</p>",
            field: "ratios._CurRatio_PYQ",
          },
        ],
        categories: [],
      },
      {
        name: "Solvency Ratios",
        description:
          "<p><em>Solvency Ratios</em> focus on a company's ability to fund its long-term financial obligations. Stockopedia shows this with a couple of different Debt to Assets and Debt to Equity measures. Also listed here are some famous composite financial health gauges including the Altman Z2-Score, the Beneish M-Score, and the Montier C-Score.</p>",
        ratios: [
          {
            name: "Net Debt to Equity",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>Net Gearing</strong>, or <strong>Net Debt to Equity</strong>, is a measure of a company's financial leverage.  It is calculated by dividing its net liabilities by stockholders' equity.  This is measured using the most recent balance sheet available, whether interim or end of year and includes the effect of intangibles.</p>",
            field: "data.ratios__ND2Eq_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Net Debt to Tangible Equity",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>Net Gearing</strong>, or <strong>Net Debt to Equity</strong>, is a measure of a company's financial leverage.  It is calculated by dividing its net liabilities by stockholders' equity.  This is measured using the most recent balance sheet available, whether interim or end of year and excludes the effect of intangibles.</p>",
            field: "data.ratios__ND2TanEq_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Gross Gearing",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>Gross Gearing</strong>, or <strong>Debt to Equity</strong>, is a measure of a company's financial leverage.  It is calculated by dividing its total liabilities by stockholders' equity.  This is measured using the most recent balance sheet available, whether interim or end of year and includes the effect of intangibles.</p>",
            field: "data.ratios__ATOTD2Eq_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Gross Gearing excluding Intangibles",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>Gross Gearing</strong>, or <strong>Debt to Equity</strong>, is a measure of a company's financial leverage.  It is calculated by dividing its total liabilities by stockholders' equity.  This is measured using the most recent balance sheet available, whether interim or end of year and excludes the effect of intangibles.</p>",
            field: "data.ratios__ATOTD2TanEq_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Gross Gearing inc Pension",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Gross Gearing inc Pension Deficit</strong> ratio shows the Gross Gearing of a company once the Pension Deficit (or Surplus) has been taken into account. It is computed as Total Debt, plus the Pension Deficit, divided by Book Value. The figure is as of the most recent set of annual accounts. This is measured on an interim basis.</p>",
            field: "data.ratios__ATOTD2EqincPDeficit_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Net Gearing inc Pension",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Net Gearing inc Pension Deficit</strong> ratio shows the Net Gearing of a company once the Pension Deficit (or Surplus) has been taken into account. It is computed as Net Debt, plus the Pension Deficit, divided by Book Value. The figure is as of the most recent set of annual accounts. This is measured on an interim basis.</p>",
            field: "data.ratios__ND2EqincPDeficit_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Cash to Assets inc Pension",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Cash to Assets including Pension Deficit</strong> ratio assesses a company’s cash and short term investments as a portion of its total assets, after its Pension Deficit (or Surplus) is accounted for. It is calculated by taking the sum of the company’s cash and short term investments and dividing this by its total assets after its Pension Deficit has been deducted. This is measured on an interim basis.</p>",
            field: "data.ratios__Cash2AssetsincPDeficit_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Long Term Debt to Equity",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Long Term Debt to Equity</strong> is a measure of a company's financial leverage.  It is calculated as Long Term Debt divided by Equity.  This is measured using the most recent balance sheet available, whether interim or end of year.</p>",
            field: "data.ratios__DbtLT2Eq_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Assets to Equity Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Assets to Equity Ratio</strong> shows the relationship of the Total Assets of the Firm to the portion owned by shareholders and is an indicator of the level of the company’s leverage.  It is calculated as Total Assets divided by Equity.  This is measured using the most recent balance sheet available, whether interim or end of year.</p>",
            field: "data.ratios__Assets2Equity_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Assets to Equity Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Assets to Equity Ratio</strong> shows the relationship of the Total Assets of the Firm to the portion owned by shareholders and is an indicator of the level of the company’s leverage.  It is calculated as Total Assets divided by Equity.  This is measured using the prior year’s balance sheet.</p>",
            field: "data.ratios__Assets2Equity_RA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Debt to Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Debt to Assets Ratio</strong> is a measure of the financial leverage of the company. It tells you what percentage of the firm’s Assets is financed by Debt and is a measure of the level of the company’s leverage.  It is calculated as Debt divided by Total Assets.  This is measured using the most recent balance sheet available, whether interim or end of year.</p>",
            field: "data.ratios__ATOTD2AST_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Long Term Debt to Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Long Term Debt to Assets Ratio</strong> is a measure of the financial leverage of the company. It tells you what percentage of the firm’s Assets is financed by Long Term Debt and is a measure of the level of the company’s leverage.  It is calculated as Long Term Debt divided by Total Assets.  This is measured using the most recent balance sheet available, whether interim or end of year.</p>",
            field: "data.ratios__DbtLT2AST_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Long Term Debt to Average Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Long Term Debt to Average Assets Ratio</strong> is a measure of the financial leverage of the company. It tells you what percentage of the firm’s Assets is financed by Long Term Debt and is a measure of the level of the company’s leverage.  It is calculated as Long Term Debt divided by Average Total Assets.</p><p>This is measured using the most recent balance sheets available, whether interim or end of year.</p>",
            field: "data.ratios__ALTD2AST_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Net Debt to Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Net Debt to Assets Ratio</strong> is a measure of the financial leverage of the company. It tells you what percentage of the firm’s Assets is financed by Net Debt and is a measure of the level of the company’s leverage.  It is calculated as Net Debt divided by Total Assets.  This is measured using the most recent balance sheet available, whether interim or end of year and includes intangibles and goodwill.</p>",
            field: "data.ratios__ND2AST_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Net Debt to Tangible Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Net Debt to Assets Ratio</strong> is a measure of the financial leverage of the company. It tells you what percentage of the firm’s Assets is financed by Net Debt and is a measure of the level of the company’s leverage.  It is calculated as Net Debt divided by Total Assets.  This is measured using the most recent balance sheet available, whether interim or end of year and excludes intangibles and goodwill.</p>",
            field: "data.ratios__ND2TanAST_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Tangible Assets to Equity Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>The <strong>Tangible Assets to Equity Ratio</strong> shows the relationship of the Total Tangible Assets of the Firm to the portion owned by shareholders and is an indicator of the level of the company’s leverage.  It is calculated as Total Tangible Assets divided by Equity.  This is measured using the most recent balance sheet available, whether interim or end of year.</p>",
            field: "data.ratios__TanAssets2Equity_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Tangible Assets to Equity Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Tangible Assets to Equity Ratio</strong> shows the relationship of the Total Tangible Assets of the Firm to the portion owned by shareholders and is an indicator of the level of the company’s leverage.  It is calculated as Total Tangible Assets divided by Equity.  This is measured using the most recent balance sheet available, whether interim or end of year.</p>",
            field: "data.ratios__ALTD2AST_RA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Tangible Assets to Equity Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1y Ago",
            description:
              "<p>The <strong>Tangible Assets to Equity Ratio</strong> shows the relationship of the Total Tangible Assets of the Firm to the portion owned by shareholders and is an indicator of the level of the company’s leverage.  It is calculated as Total Tangible Assets divided by Equity.  This is measured using the most recent balance sheet available, whether interim or end of year.</p>",
            field: "data.ratios__ALTD2AST_RPY",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Free Cash Flow to Long Term Debt",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Free Cash Flow to Long Term Debt</strong> ratio measures the sustainability of the debt structure based on available free cash flow and is an indicator of the company’s financial leverage.  It is calculated as Free Cash Flow divided by Long Term Debt.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__FCF2DbtLT_TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Free Cash Flow to Long Term Debt",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Prior TTM",
            description:
              "<p>The <strong>Free Cash Flow to Long Term Debt</strong> ratio measures the sustainability of the debt structure based on available free cash flow and is an indicator of the company’s financial leverage.  It is calculated as Free Cash Flow divided by Long Term Debt.  This is measured on a prior TTM basis.</p>",
            field: "data.ratios__FCF2DbtLT_PTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Altman Z-Score",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>The <strong>Altman Z-Score</strong> is a shorthand to describe the financial health of a company, and its likelihood of financial distress. If the Z Score is falling towards 1.8 then it may be a sell signal. Z1 is the Z-Score for manufacturing companies and this version is calculated using the latest available interim financials.</p>",
            field: "data.ratios__AltmanZ1_TTM",
          },
          {
            name: "Altman Z2-Score",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>The <strong>Altman Z2-Score</strong> is a shorthand to describe the financial health of a company, and its likelihood of financial distress. If the Z Score is falling towards 1.8 then it may be a sell signal. Z2 is the Z-Score for non-manufacturing companies (but excluding property/financial companies) and this version is calculated using the latest available interim financials.</p>",
            field: "data.ratios__AltmanZ2_TTM",
          },
          {
            name: "Long Term Debt / Average Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Prior Yr Qtr",
            description:
              "<p>The <strong>Long Term Debt to Average Assets Ratio</strong> is a measure of the financial leverage of the company. It tells you what percentage of the firm’s Assets are financed by Long Term Debt and is a measure of the level of the company’s leverage. It is calculated as Long Term Debt divided by Average Total Assets.</p><p>This metric is calculated using balance sheet values from the same interim financial period as the current quarter, except from the previous year. For example, if the most recent quarter was Q2 for 2016, this ratio would represent the value for Q2 2015.</p>",
            field: "ratios._ALTD2AST_PYQ",
          },
          {
            name: "Accruals to Assets Index",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Accruals to Assets Index</strong> is the ratio of total accruals to total assets, where total accruals are calculated as the change in working capital accounts other than cash less depreciation.</p>",
            field: "ratios._TATA_RA",
          },
          {
            name: "Working Capital/Total Assets",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p><strong>Working Capital / Total Assets</strong> simply measures liquid assets as a proportion of total assets. It can be used to asses the financial strength of a company, given that a firm facing financials problems may experience shrinking liquidity.</p>",
            field: "ratios._AltmanT1_TTM",
          },
          {
            name: "Retained Earnings/Total Assets",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p><strong>Retained Earnings / Total Assets</strong> simply measures cumulative profitability over time (ie. retained earnings) as a proportion of total assets. If retained earnings constitute a larger (smaller) proportion of total assets, then this indicates that a company is more (less) profitable. In turn, shrinking profitability may well be be a warning sign that a firm is heading towards financial difficulties.</p>",
            field: "ratios._AltmanT2_TTM",
          },
          {
            name: "Earnings Before Interest and Taxes/Total Assets",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p><strong>Earnings Before Interest and Taxes / Total Assets</strong> is calculated by simply dividing a company's EBIT by the firm's total assets. Investors can use this ratio to ascertain how effective a company is at using assets to generate profits (ie. earnings before interest and taxes).</p>",
            field: "ratios._AltmanT3_TTM",
          },
          {
            name: "Market Value of Equity/Book Value of Total Liabilities",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p><strong>Market Value of Equity/Book Value of Total Liabilities</strong> simply compares the market value of equity to the book value of total liabilities.</p>",
            field: "ratios._AltmanT4_TTM",
          },
          {
            name: "Sales/Total Assets",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>Sales/Total Assets is a measure of how effectively a company is using its assets to generate revenue. It is calculated as revenue divided by total assets and is measured on a TTM basis.</p>",
            field: "ratios._AltmanT5_TTM",
          },
          {
            name: "Altman Type",
            dataSource: DataSource.BATCH,
            dataType: "int",
            description:
              "<p>This field is used to determine whether we gague the bankruptcy risk of a company using the Altman 1 system, or the Altman 2 system.</p>",
            field: "ratios._AltmanType",
          },
          {
            name: "Pension Deficit to Market Cap",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>This shows the ratio of a <strong>Pension Deficit to the Market Cap</strong> of the associated company.  It is computed by dividing the Plan Deficit by the company's Market Cap.  The Deficit is as of the most recent set of annual accounts and the Market Cap is the current Market Cap.</p>",
            field: "data.ratios__PensionDeficitToMktCap_RA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Pension Deficit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>This shows the size of a <strong>Pension Deficit or Surplus</strong>. A positive figure indicates a Deficit (Liabilities are larger than Assets) and a negative figure indicates a Surplus. The figure is as of the most recent set of annual accounts.</p>",
            field: "data.ratios__PensionDeficit_RQ",
            unit: RatioUnit.MILLION,
          },
        ],
        categories: [],
      },
      {
        name: "Earnings Quality Ratios",
        description:
          "<p>Many frauds report consecutive quarters of earnings growth before abruptly going bankrupt. <em>Earnings Quality Ratios</em> can help to identify such stocks before disaster strikes. Metrics such as the Accrual Ratio, the Beneish M-Score and the Montier C-Score are a good way to spot red flags and potential earnings manipulation.</p>",
        ratios: [
          {
            name: "Accrual Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Accrual Ratio</strong> is a way to identify firms where Non-Cash or Accrual-Derived Earnings make up a significant proportion of Total Earnings.  It is calculated as Net Income, minus Free Cash Flow, all divided by Total Assets.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and earnings are diluted and normalised.</p>',
            field: "data.ratios__AccrualRatio_TTM",
          },
          {
            name: "Beneish M-Score",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Beneish M-Score</strong> is a mathematical model created by Professor Beneish that uses eight financial ratios to identify whether a company may have manipulated its earnings figures for reporting purposes.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__MScore_TTM",
          },
          {
            name: "Beneish M-Score, Last Year",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Beneish M-Score</strong> is a mathematical model created by Professor Beneish that uses eight financial ratios to identify whether a company may have manipulated its earnings figures for reporting purposes.  This is measured on a historical basis.</p>",
            field: "data.ratios__MScore_RA",
          },
          {
            name: "Beneish M-Score",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1y Ago",
            description:
              "<p>The <strong>Beneish M-Score</strong> is a mathematical model created by Professor Beneish that uses eight financial ratios to identify whether a company may have manipulated its earnings figures for reporting purposes.  This is measured on a 1 year historical basis.</p>",
            field: "data.ratios__MScore_RPY",
          },
          {
            name: "Beneish M-Score",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "2y Ago",
            description:
              "<p>The <strong>Beneish M-Score</strong> is a mathematical model created by Professor Beneish that uses eight financial ratios to identify whether a company may have manipulated its earnings figures for reporting purposes.  This is measured on a 2 year historical basis.</p>",
            field: "data.ratios__MScore_RPY2",
          },
          {
            name: "Montier 'Cooking the Books' Score",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Montier C-Score</strong> is a way identify companies that have an increased risk of earnings manipulation.  James Montier aimed to create a simple scoring system that would highlight such companies and the C-Score was the result.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__C_Score",
          },
          {
            name: "Accruals to Assets Index",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Accruals to Assets Index</strong> is the ratio of total accruals to total assets, where total accruals are calculated as the change in working capital accounts other than cash less depreciation.</p>",
            field: "ratios._TATA_RA",
          },
        ],
        categories: [],
      },
      {
        name: "Financial Health Trend",
        description:
          "<p>The <em>Financial Health Trend</em> of a company is an important leading indicator of future stock performance. Stockopedia lists the Piotroski F-Score as the primary financial health trend indicator. The F score itself is composed of nine criteria and conveys a lot of information at a glance. The F-Score was created to identify companies with improving financial positions and takes into account cash generation, profitability, indebtedness, productivity, and pricing power.</p>",
        ratios: [
          {
            name: "Piotroski F-Score",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            modifier: "TTM",
            description:
              '<p>The <strong>Piotroski F-Score</strong> is a nine-criteria scoring system developed by financial academic, Joseph Piotroski.  It’s aim is to identify companies which are improving their financial position in performance.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__PiotroskiScore_TTM",
          },
          {
            name: "Piotroski F-Score",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            modifier: "Last Year",
            description:
              "<p>The <strong>Piotroski F-Score</strong> is a nine-criteria scoring system developed by financial academic, Joseph Piotroski.  It’s aim is to identify companies which are improving their financial position in performance.  This is the score for the last financial year.</p>",
            field: "data.ratios__PiotroskiScore_RA",
          },
          {
            name: "Days Sales in Receivables Index",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Days Sales in Receivables Index</strong> is an earnings quality metric that can be used to analyse whether earnings quality is rising or falling. The metric is calculated as the ratio of this year's Days Sales in Receivables versus last year's Days Sales in Receivables.</p>",
            field: "ratios._DSRI_RA",
          },
          {
            name: "Gross Margin Index",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Gross Margin Index</strong> is a fundamental momentum metric that can be used to judge whether a company's profitability and pricing power is rising or falling. It is calculated as the ratio of last year's Gross Margin versus this year's Gross Margin.</p>",
            field: "ratios._GMI_RA",
          },
          {
            name: "Asset Quality Index",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Asset Quality Index</strong> can be used to judge whether companies are excessively capitalising expenses. The index is the ratio of non-current assets other than plant, property, and equipment to total assets this year relative to last year.</p>",
            field: "ratios._AQI_RA",
          },
          {
            name: "Sales Growth Index",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Sales Growth Index</strong> can be used to judge whether company sales are rising or falling. The index simpy measures the ratio of current year sales versus prior year sales.</p>",
            field: "ratios._SGI_RA",
          },
          {
            name: "Depreciation Index",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Depreciation Index</strong> can be used to judge whether companies are depreciating assets at faster or slower rate. The index is the ratio of last year's depreciation rate versus the current year depreciation rate.</p>",
            field: "ratios._DEPI_RA",
          },
          {
            name: "SGA Expenses Index",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Sales, General and Administrative Expenses Index</strong> measures the ratio of current SGA expenses to prior year SGA expenses.</p>",
            field: "ratios._SGAI_RA",
          },
          {
            name: "Leverage Index",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Leverage Index</strong> measures the ratio of current leverage (ie. total debt over total assets) to prior year leverage.</p>",
            field: "ratios._LVGI_RA",
          },
        ],
        categories: [],
      },
      {
        name: "Share Dilution Ratios",
        description:
          "<p><em>Share Dilution</em> is an important aspect of investment to track but can easily be ignored in the face of more obvious trends such as sales growth. In general less dilutive companies have been shown to be stronger investments over the longer term. Stockopedia lists the percentage change in diluted average shares outstanding, which looks at the year-on-year change in a firm's weighted average number of shares and shows the impact of rights issues, share placings, and share buybacks.</p>",
        ratios: [
          {
            name: "Share Dilution",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>The Percentage Change in Shares Outstanding</strong> is the year on year change in a firm's Diluted Weighted Average Shares Outstanding. If a company has been buying back shares, this number will be negative.  This is measured on a historical basis.</p>",
            field: "data.ratios__DilWASChgPercentRA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Share Dilution",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "CAGR 3y",
            description:
              "<p>The <strong>The 3 Year Compound Annual Growth Rate in Shares Outstanding</strong> shows how quickly a company has been growing its number of Shares Outstanding.  This is measured as a Compounded Annual Growth Rate (CAGR) over the past 3 years.</p>",
            field: "data.ratios__DilWASPercentCAGR_3Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Share Dilution",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "CAGR 5y",
            description:
              "<p>The <strong>The 5 Year Compound Annual Growth Rate in Shares Outstanding</strong> shows how quickly a company has been growing its number of Shares Outstanding.  This is measured as a Compounded Annual Growth Rate (CAGR) over the past 5 years.</p>",
            field: "data.ratios__DilWASPercentCAGR_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Equity Dilution Funding",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p><strong>Equity Dilution Funding</strong> represents the total sum of cash a company has raised through the issuance of equity (eg. common stock, preferred stock and so on) during the last 12 months. If the number is negative, this could mean that the company is buying back shares.</p>",
            field: "ratios._DilutionFunding_TTM",
          },
          {
            name: "Equity Dilution Funding",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p><strong>Equity Dilution Funding</strong> represents the total sum of cash a company has raised through the issuance of equity (eg. common stock, preferred stock and so on) during the last financial year. If the number is negative, this could mean that the company is buying back shares.</p>",
            field: "ratios._DilutionFunding_RA",
          },
        ],
        categories: [],
      },
      {
        name: "Research & Development Ratios",
        description:
          "<p><em>Research and Development</em> costs are useful for seeing how much a company is investing into its future prospects. Stockopedia tracks growth in Research and Development spending over the last 12 month period, as well as R&D divided by assets and R&D divided by sales.</p>",
        ratios: [
          {
            name: "Research & Development to Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Research & Development to Sales</strong> ratio is a measure to compare the effectiveness of R&D expenditures between companies in the same industry.  It is calculated as R&D expenditure divided by Total Sales.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__Research2Sales_TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Research & Development to Assets",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Research & Development to Assets</strong> ratio is a measure to compare the effectiveness of R&D expenditures between companies in the same industry.  It is calculated as R&D expenditure divided by Total Assets.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__Research2Assets_TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Research & Development Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Research & Development Growth</strong> ratio is a measure of how quickly a company has grown its R&D expenditures.  It is calculated as the year on year change in R&D expenditure.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__RDChgPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "ProfileSummary",
    description: "<p></p>",
    ratios: [],
    categories: [
      {
        name: "CompanyInfo",
        description: "<p></p>",
        ratios: [
          {
            name: "Business Summary",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description: "<p></p>",
            field: "profile.description",
          },
          {
            name: "Directors",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description: "<p></p>",
            field: "profile.directors",
          },
          {
            name: "No. of Employees",
            dataSource: DataSource.BATCH,
            dataType: "int",
            description: "<p></p>",
            field: "profile.employee_count",
          },
          {
            name: "No. of Shareholders",
            dataSource: DataSource.BATCH,
            dataType: "int",
            description: "<p></p>",
            field: "profile.shareholder_count",
          },
        ],
        categories: [],
      },
      {
        name: "StatementsInfo",
        description: "<p></p>",
        ratios: [
          {
            name: "Incorporated",
            dataSource: DataSource.BATCH,
            dataType: "datetime",
            description: "<p></p>",
            field: "profile.incorporated_date",
          },
          {
            name: "Public Since",
            dataSource: DataSource.BATCH,
            dataType: "datetime",
            description: "<p></p>",
            field: "profile.float_date",
          },
          {
            name: "Free Float",
            dataSource: DataSource.BATCH,
            dataType: "int",
            description: "<p></p>",
            field: "profile.general_information._FreeFloat",
          },
        ],
        categories: [],
      },
      {
        name: "ListingInfo",
        description: "<p></p>",
        ratios: [
          {
            name: "Asset Category",
            dataSource: DataSource.GENERAL_INFORMATION,
            dataType: "string",
            description:
              "<p>This field tells us what asset category (eg. common stock or preference share) the security falls into.</p>",
            field: "term.asset_category",
          },
        ],
        categories: [],
      },
      {
        name: "OtherInfo",
        description: "<p></p>",
        ratios: [
          {
            name: "Address",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description: "<p></p>",
            field: "profile.address",
          },
          {
            name: "Web",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description: "<p></p>",
            field: "profile.web",
          },
          {
            name: "Phone",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description: "<p></p>",
            field: "profile.phone",
          },
          {
            name: "Contact",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description: "<p></p>",
            field: "profile.contact",
          },
          {
            name: "Auditors",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description: "<p></p>",
            field: "profile.auditors",
          },
          {
            name: "Email",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description: "<p></p>",
            field: "profile.email",
          },
          {
            name: "City",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description: "<p></p>",
            field: "profile.city",
          },
          {
            name: "Country",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description: "<p></p>",
            field: "profile.country",
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "Price Quotes",
    description:
      "<p>The <em>Price Quotes</em> category contains technical information to do with the intraday share price of a stock, including its bid-offer spread, volume and daily changes. Also included in this category is data relating to opening and closing prices, as well as the dates, times and size of trades.</p>",
    ratios: [],
    categories: [
      {
        name: "Intraday Prices",
        description:
          "<p>The <em>Intraday Prices</em> section of the quote includes the current market around the stock. Fields included are the last, bid, ask, mid and volume of the stock.</p>",
        ratios: [
          {
            name: "Last Price",
            dataSource: DataSource.QUOTES,
            dataType: "double",
            description:
              "<p>The <strong>Last Traded Price</strong> represents the main quoted price for the security in question.   It is usually the price of the last trade or the previous close price if the market has yet to open.</p>",
            field: "data.quotes_price",
          },
          {
            name: "Bid Price",
            dataSource: DataSource.QUOTES,
            dataType: "string",
            description:
              "<p>The <strong>Bid Price</strong> is the highest price which prospective buyers are willing to pay in the market.   It can be considered the highest price at which you can currently sell your shares in the market. It is quoted in the same units as the quoted price.</p>",
            field: "data.quotes_bid",
          },
          {
            name: "Ask Price",
            dataSource: DataSource.QUOTES,
            dataType: "string",
            description:
              "<p>The <strong>Ask Price</strong> is the price which sellers are asking buyers to pay to buy their shares in the market.   It can be considered the lowest price at which you can currently buy shares in the market. It is quoted in the same units as the quoted price.</p>",
            field: "data.quotes_ask",
          },
          {
            name: "Mid Price",
            dataSource: DataSource.QUOTES,
            dataType: "string",
            description:
              "<p>The <strong>Mid Price</strong> is the exact mid-point between the quoted Bid Price and Ask Price for a security.  It can differ considerably from the Last Price as the last trade may have been higher (at the ask) or lower (at the bid).</p>",
            field: "data.quotes_MID_PRICE",
          },
          {
            name: "Volume",
            dataSource: DataSource.QUOTES,
            dataType: "int",
            description:
              "<p>The <strong>Volume</strong> is the total number of shares that have changed hands cumulatively during the current trading day.  This is generally an intraday figure unless the market has closed.  It can be compared against average three month volumes to identify volume spikes.</p>",
            field: "data.quotes.volume",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Dividend Rate",
            dataSource: DataSource.QUOTES,
            dataType: "string",
            description:
              "<p>The latest reported dividend to be paid per share to shareholders.</p>",
            field: "quotes.DIVIDEND",
          },
          {
            name: "Yield",
            dataSource: DataSource.QUOTES,
            dataType: "string",
            description:
              "<p>This represents the latest reported dividend as a percentage of the latest share price.</p>",
            field: "quotes.YIELD",
          },
        ],
        categories: [],
      },
      {
        name: "Price Change",
        description:
          "<p>This sub-section has the nominal price change for a security since the previous day's close both in currency and percentage terms.</p>",
        ratios: [
          {
            name: "Price Change",
            dataSource: DataSource.QUOTES,
            dataType: "string",
            description:
              "<p>This is the nominal <strong>Price Change</strong> for this security since the previous day’s close price.  It is quoted in the same units as the quoted price.</p>",
            field: "data.quotes_change",
          },
          {
            name: "Percent Change",
            dataSource: DataSource.QUOTES,
            dataType: "double",
            description:
              "<p>The <strong>Percent Change</strong> measures the absolute percentage price change of the security’s price since the previous day’s close.  It is quoted as a percentage of the previous days’ close.</p>",
            field: "data.quotes_percent_change",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
      {
        name: "Trade Timing",
        description:
          "<p><em>Trade time</em> looks at the date and time at which the last trade of a stock on its stock exchange was reported.</p>",
        ratios: [
          {
            name: "Trade Date",
            dataSource: DataSource.QUOTES,
            dataType: "datetime",
            description:
              "<p>This is the <strong>Trade Date</strong> indicating the calendar date of the last trade.  This is stored in UTC but will be published according to your current timezone.</p>",
            field: "data.quotes_date",
          },
          {
            name: "Trade Time",
            dataSource: DataSource.QUOTES,
            dataType: "time",
            description:
              "<p>This is the <strong>Trade Time</strong> indicating the time of the last trade.  This is stored in UTC but will be published according to your current timezone.</p>",
            field: "data.quotes_time",
          },
        ],
        categories: [],
      },
      {
        name: "Open High Low Close",
        description:
          "<p>The following fields display a stock's daily opening and closing price, as well as its intra-day high and low. These provide measures of the range of trading through the day.</p>",
        ratios: [
          {
            name: "Open Price",
            dataSource: DataSource.QUOTES,
            dataType: "string",
            description:
              "<p>The <strong>Open Price</strong> is the price at which the security first traded at the open of the day’s trading on its stock exchange.  For some companies this may have been after an auction period.</p>",
            field: "data.quotes_open",
          },
          {
            name: "Day's High",
            dataSource: DataSource.QUOTES,
            dataType: "string",
            description:
              "<p>The <strong>Day’s High Price</strong> is the highest price at which the security has traded during the current trading day.</p>",
            field: "data.quotes_high",
          },
          {
            name: "Day's Low",
            dataSource: DataSource.QUOTES,
            dataType: "string",
            description:
              "<p>The <strong>Day’s Low Price</strong> is the lowest price at which the security has traded during the current trading day.</p>",
            field: "data.quotes_low",
          },
          {
            name: "Close Price",
            dataSource: DataSource.QUOTES,
            dataType: "string",
            description:
              "<p>The <strong>Close Price</strong> is the price at which the security closed on the previous trading day.  We update this figure after the day’s trading closes to reflect the current day’s close price.</p>",
            field: "data.quotes_close",
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "Momentum Indicators",
    description:
      "<p><em>Momentum</em> is a powerful predictive factor for future share price returns. As traders like to say, 'the trend is your friend'. The following section contains information on a variety of indicators, including: recent share price highs and lows, moving averages across periods of time, relative strength indicators and the Momentum Rank. You can also find the average volumes of shares traded & share price volatility</p>",
    ratios: [],
    categories: [
      {
        name: "Price Volatility",
        description:
          "<p>The <em>Price Volatility</em> of a stock is often used as a way of defining its upside and downside risk potential and, as such, has important implications for its valuation. Measures of volatility contained here include Daily Volatility, the Beta of the share, and its 6 Month and 1 Year Volatility.</p>",
        ratios: [
          {
            name: "Daily Volatility",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>Daily Volatility</strong> of a security is the standard deviation of its daily return time series. It is commonly used as a measure of the risk of the security. We calculate the daily volatility with up to 3 years of daily price data.</p>",
            field: "data.ratios__DailyVol",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "AutoCorrelation",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p><strong>AutoCorrelation</strong> is a mathematical measure, which can be used to assess how similar a time series is to a past version of itself. It can be used to identify whether there are repetitive patterns in a time series.</p>",
            field: "data.ratios__AutoCorrelation",
          },
          {
            name: "6 Month Volatility",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The daily volatility of a security is the standard deviation of a daily return time series. It is commonly used as a measure of the risk of the security. We calculate the daily volatility with up to 3 years of daily price data. This is adjusted to represent the standard deviation of 6 month returns.</p>",
            field: "data.ratios__6MonthVol",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "1 Year Volatility",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The daily volatility of a security is the standard deviation of a daily return time series. It is commonly used as a measure of the risk of the security. We calculate the daily volatility with up to 3 years of daily price data. This is adjusted to represent the standard deviation of annual returns.</p>",
            field: "data.ratios__1YearVol",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Beta",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p><strong>Beta</strong> is a measure of a company's common stock price volatility relative to the market. It is calculated as the slope of the 60 month regression line of the percentage price change of the stock relative to the percentage price change of the relevant index (e.g. the FTSE All Share).</p>",
            field: "data.ratios__BETA",
          },
        ],
        categories: [],
      },
      {
        name: "Momentum Rankings",
        description:
          "<p>The <em>Momentum Rankings</em> are Stockopedia's ranking system based on a blend of price momentum and earnings momentum indicators. The QM Rank (Quality + Momentum) and VM Rank (Value + Momentum) are equally weighted combinations of Stockopedia's Quality and Momentum Ranks and Value and Momentum Ranks respectively.</p>",
        ratios: [
          {
            name: "Stockopedia Momentum Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>Momentum Rank</strong> is a ranking system based on a blend of price momentum (52 week highs, relative strength) and earnings momentum (recommendation upgrades, earnings upgrades and earnings surprise) factors.  These inputs are computed for every company in the market on a daily basis, ranked, blended and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__MomentumScore_zz",
          },
          {
            name: "Stockopedia QM Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>QM Rank</strong> provides a simple but effective system for finding good, improving (quality+momentum) shares. It is an equally weighted combination of Stockopedia's QualityRank and MomentumRank.  These two basic ranks are computed for every company in the market on a daily basis, summed and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__QMScore",
          },
          {
            name: "Stockopedia VM Rank",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>VM Rank</strong> provides a simple but effective system for finding cheap, improving (value+momentum) shares. It is an equally weighted combination of Stockopedia's ValueRank and MomentumRank.  These two basic ranks are computed for every company in the market on a daily basis, summed and then re-ranked as a percentile from 0 (worst) to 100 (best).</p>",
            field: "data.ratios__VMScore_zz",
          },
        ],
        categories: [],
      },
      {
        name: "Price and Volume Information",
        description:
          "<p>This section contains <em>Price and Volume</em> data including the closing prices of shares, their three-month average volume, and their 10d / 3m volume ratio.</p>",
        ratios: [
          {
            name: "Last Close Price",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>The <strong>Close Price</strong> is the most recent closing price of the company's shares on the relevant stock exchange.</p>",
            field: "data.ratios__QuotesClose",
          },
          {
            name: "Average Volume",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            modifier: "3m",
            description:
              "<p>The <strong>3 Month Average Volume</strong> is the daily average of the cumulative trading volume during the last three months.</p>",
            field: "data.ratios__VOL3MAVG",
          },
          {
            name: "Average Volume",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            modifier: "10d",
            description:
              "<p>The <strong>10 Day Average Volume</strong> is the daily average of the cumulative trading volume during the last ten days.</p>",
            field: "data.ratios__Vol10DAvg",
          },
          {
            name: "Recent Volume Surge",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>This <strong>10 Day to 3 Month Volume Ratio</strong> is a proprietary volume metric that aims to assess the recent volume trend. It compares the average daily volume over the last 10 trading days with the average daily volume over the last 3 months and is expressed as a percentage.</p>",
            field: "data.ratios__10d_vs_3m_Vol",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
      {
        name: "Absolute and Relative Share Price Strength",
        description:
          "<p>This section contains <em>Relative Price Strength</em> measures such as its share price performance relative to its benchmark index over multiple timeframes, and its <em>Absolute Share Price Strength</em> similarly. We also provide longer term compound annual growth rates.</p>",
        ratios: [
          {
            name: "Absolute Price Change",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3y",
            description:
              "<p>The <strong>3 Year Absolute Strength</strong> measures the absolute percentage price performance of the share price over the past 3 years. It is to be contrasted with Relative Strength.  It is calculated as (Current Price minus Old Price) divided by Old Price x 100.</p>",
            field: "data.ratios__AbsStrength_3Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "No. of years Price History",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>The <strong>Price History</strong> shows the number of years of consecutive price history that we have available for this security in the Stockopedia database.</p>",
            field: "data.ratios__PriceStreak",
          },
          {
            name: "Relative Strength",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1w",
            description:
              "<p>The <strong>1 Week Relative Strength</strong> measures a stock's price change over the last week relative to the price change of a market index.  It shows the relative outperformance or underperformance of the stock in that timeframe.</p>",
            field: "data.ratios__RelStrength_1Wk",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Relative Strength",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1m",
            description:
              "<p>The <strong>1 Month Relative Strength</strong> measures a stock's price change over the last month relative to the price change of a market index.  It shows the relative outperformance or underperformance of the stock in that timeframe.</p>",
            field: "data.ratios__RelStrength_1Mo",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Relative Strength",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3m",
            description:
              "<p>The <strong>3 Month Relative Strength</strong> measures a stock's price change over the last 3 months relative to the price change of a market index.  It shows the relative outperformance or underperformance of the stock in that timeframe.</p>",
            field: "data.ratios__RelStrength_3Mo",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Relative Strength",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "6m",
            description:
              "<p>The <strong>6 Month Relative Strength</strong> measures a stock's price change over the last 6 months relative to the price change of a market index.  It shows the relative outperformance or underperformance of the stock in that timeframe.</p>",
            field: "data.ratios__RelStrength_6Mo",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Relative Strength",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1y",
            description:
              "<p>The <strong>1 Year Relative Strength</strong> measures a stock's price change over the last year relative to the price change of a market index.  It shows the relative outperformance or underperformance of the stock in that timeframe.</p>",
            field: "data.ratios__RelStrength_1Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Relative Strength",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3y",
            description:
              "<p>The <strong>3 Year Relative Strength</strong> measures a stock's price change over the last 3 years relative to the price change of a market index.  It shows the relative outperformance or underperformance of the stock in that timeframe.</p>",
            field: "data.ratios__RelStrength_3Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Relative Strength",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y",
            description:
              "<p>The <strong>5 Year Relative Strength</strong> measures a stock's price change over the last 5 years relative to the price change of a market index.  It shows the relative outperformance or underperformance of the stock in that timeframe.</p>",
            field: "data.ratios__RelStrength_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Absolute Price Change",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1w",
            description:
              "<p>The <strong>1 Week Absolute Strength</strong> measures the absolute percentage price performance of the share price over the past week. It is to be contrasted with Relative Strength.  It is calculated as (Current Price minus Old Price) divided by Old Price x 100.</p>",
            field: "ratios._AbsStrength_1Wk",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Absolute Price Change",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1m",
            description:
              "<p>The <strong>1 Month Absolute Strength</strong> measures the absolute percentage price performance of the share price over the past month. It is to be contrasted with Relative Strength.  It is calculated as (Current Price minus Old Price) divided by Old Price x 100.</p>",
            field: "data.ratios__AbsStrength_1Mo",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Absolute Price Change",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3m",
            description:
              "<p>The <strong>3 Month Absolute Strength</strong> measures the absolute percentage price performance of the share price over the past 3 months. It is to be contrasted with Relative Strength.  It is calculated as (Current Price minus Old Price) divided by Old Price x 100.</p>",
            field: "data.ratios__AbsStrength_3Mo",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Absolute Price Change",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "6m",
            description:
              "<p>The <strong>6 Month Absolute Strength</strong> measures the absolute percentage price performance of the share price over the past 6 months. It is to be contrasted with Relative Strength.  It is calculated as (Current Price minus Old Price) divided by Old Price x 100.</p>",
            field: "data.ratios__AbsStrength_6Mo",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Absolute Price Change",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1y",
            description:
              "<p>The <strong>1 Year Absolute Strength</strong> measures the absolute percentage price performance of the share price over the past year. It is to be contrasted with Relative Strength.  It is calculated as (Current Price minus Old Price) divided by Old Price x 100.</p>",
            field: "ratios._AbsStrength_1Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Absolute Price Change",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y",
            description:
              "<p>The <strong>5 Year Absolute Strength</strong> measures the absolute percentage price performance of the share price over the past 5 years. It is to be contrasted with Relative Strength.  It is calculated as (Current Price minus Old Price) divided by Old Price x 100.</p>",
            field: "data.ratios__AbsStrength_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Price CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y",
            description:
              "<p>The <strong>5 Year Compound Annual Growth Rate</strong> measures the average / compound annualised growth of the share price over the past five years.  It is calculated as Current Price divided by Old Price to the power of a 5th, multiplied by 100.</p>",
            field: "data.ratios__PricePercentChg_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
      {
        name: "Moving Averages",
        description:
          "<p><em>Moving Averages</em> are popular indicators in technical analysis that aim to filter out the 'noise' of short-term share price fluctuations in order to better see longer-term directions of trend & reversal. As well as the 200-day moving average, Stockopedia also looks at Price vs. 50-day MA and 50-day MA / 200-day MA.</p>",
        ratios: [
          {
            name: "200 day Moving Average",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "200d",
            description:
              "<p>The <strong>200 Day Moving Average</strong> is a long term moving average that helps determine the overall health of a stock.  A 200 Day moving average is calculated by taking the closing prices for the last 200 days of any security, summing them together and dividing by 200.</p>",
            field: "data.ratios__200DayMovingAverage_Local",
          },
          {
            name: "Price vs Moving Average",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "50d",
            description:
              "<p>This measures how far the last close price is from the <strong>50 Day Moving Average</strong>. This is calculated as (Close Price - 50 Day MA) / 50 Day MA * 100.</p>",
            field: "data.ratios__PriceVs50DayMAPercent",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Price vs Moving Average",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "130d",
            description:
              "<p>This measures how far the last close price is from the <strong>130 Day Moving Average</strong>. This is calculated as (Close Price - 130 Day MA) / 130 Day MA * 100.</p>",
            field: "data.ratios__PriceVs130DayMAPercent",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Price vs Moving Average",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "200d",
            description:
              "<p>This measures how far the last close price is from the <strong>200 Day Moving Average</strong>. This is calculated as (Close Price - 200 Day MA) / 200 Day MA * 100.</p>",
            field: "data.ratios__PriceVs200DayMAPercent",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "50d vs 200d Moving Average Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>This measures the security's <strong>50 Day Moving Average</strong> price divided by the <strong>200 Day Moving Average</strong> price.</p>",
            field: "data.ratios__50DV200DMA",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
      {
        name: "Share Price Highs & Lows",
        description:
          "<p>The <em>Highs & Lows</em> of a company's shares over varying time frames can be important in determining higher probability entry and exit points. In addition to the 52-week highs and lows, Stockopedia lists the current share price vs. its 52-week highs, 52-week lows, and 10-year highs.</p>",
        ratios: [
          {
            name: "Price vs 52w High",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>Price vs. 52 Week High</strong> indicator compares the current price to the highest price at which the stock has traded at in the last 52 weeks (12 months).  It is the Current Price, less the 52 Week High, divided by the 52 Week High.</p>",
            field: "data.ratios__PriceVsHighPercent",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Price vs 52w Low",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>Price vs. 52 Week Low</strong> indicator compares the current price to the lowest price at which the stock has traded at in the last 52 weeks (12 months).  It is the Current Price, less the 52 Week Low, divided by the 52 Week Low.</p>",
            field: "data.ratios__PriceVsLowPercent",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Price vs 10y High",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>Price vs. 10 Year High</strong> indicator compares the current price to the highest price at which the stock has traded at in the last 10 years.</p>",
            field: "data.ratios__PriceVs10YHighPercent",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "52w High Price",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>52 Week High</strong> is the highest price that the stock has traded at over a 12 month period. It is calculated by taking the maximum of all prices within the trading year, factoring in intra-day movements. This field is the same currency and units as the Quote price for this instrument.</p>",
            field: "data.ratios__52WHigh_Local",
          },
          {
            name: "52w Low Price",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>52 Week Low</strong> is the lowest price that the stock has traded at over a 12 month period. It is calculated by taking the minimum of all prices within the trading year, factoring in intra-day movements.  This field is the same currency and units as the Quote price for this instrument.</p>",
            field: "data.ratios__52WLow_Local",
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "Miscellaneous Metrics",
    description:
      "<p>The Miscellaneous section contains information on how many of Stockopedia's <em>stock screens</em> a company qualifies for. The screens are sorted by a variety of factors, including value, quality and momentum.</p>",
    ratios: [],
    categories: [
      {
        name: "Table Display",
        description: "<p></p>",
        ratios: [],
        categories: [],
      },
    ],
  },
  {
    name: "Growth Ratios",
    description:
      "<p>Understanding the historic & forecast <em>growth</em> of a company has important implications for pricing its shares, as higher growth often attracts a higher valuation multiple. At Stockopedia, we track the growth of different aspects of a business, including its sales, earnings, cashflow and assets to give investors a better idea of the company's prospects.</p>",
    ratios: [],
    categories: [
      {
        name: "Earnings Growth Measures",
        description:
          "<p><em>Earnings Growth</em> is an important measure for investors assessing company valuation. Higher earnings growth often leads to higher valuations. Common measures that Stockopedia list include trailing 12 month earnings per share growth (TTM EPS Growth), quarter on quarter earnings per share growth and the EPS compound annual growth rate (EPS CAGR). Other measures contained here include the cyclically-adjusted P/E 10y CAGR, net profit 5y CAGR%, EPS growth streak and operating profit growth.</p>",
        ratios: [
          {
            name: "Earnings per Share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p><strong>Earnings per Share Growth</strong> is used to determine the rate at which a company is growing its profitability.  It is measured as a percentage change over a given period.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and earnings are diluted and normalised.</p>',
            field: "data.ratios__DilNEPSChgPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Earnings per Share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p><strong>Earnings per Share Growth</strong> is used to determine the rate at which a company is growing its profitability.  It is measured as a percentage change over a given period.  This is measured on a historical basis and earnings are diluted and normalised.</p>",
            field: "data.ratios__DilNEPSChgPercentRA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Earnings per Share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Forecast 1y",
            description:
              "<p><strong>Earnings per Share Growth</strong> is used to determine the rate at which a company is growing its profitability.  It is measured as a percentage change over a given period.  This is measured on a 1 year forecast basis.</p>",
            field: "data.ratios__DilNEPSChgPercentFY1",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Earnings per Share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Forecast 2y",
            description:
              "<p><strong>Earnings per Share Growth</strong> is used to determine the rate at which a company is growing its profitability.  It is measured as a percentage change over a given period.  This is measured on a 2 year forecast basis.</p>",
            field: "data.ratios__DilNEPSChgPercentFY2",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Earnings per Share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling 1y",
            description:
              "<p><strong>Earnings per Share Growth</strong> is used to determine the rate at which a company is growing its profitability.  It is measured as a percentage change over a given period.  This is measured on a 1 year rolling basis and earnings are diluted and normalised.</p>",
            field: "data.ratios__DilNEPSChgPercentRo1",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Earnings per Share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling 2y",
            description:
              "<p><strong>Earnings per Share Growth</strong> is used to determine the rate at which a company is growing its profitability.  It is measured as a percentage change over a given period.  This is measured on a 2 year rolling basis and earnings are diluted and normalised.</p>",
            field: "data.ratios__DilNEPSChgPercentRo2",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Earnings per Share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1y Ago",
            description:
              "<p><strong>Earnings per Share Growth</strong> is used to determine the rate at which a company is growing its profitability.  It is measured as a percentage change over a given period.  This is measured on a 1 year historical basis and earnings are diluted and normalised.</p>",
            field: "data.ratios__DilNEPSChgPercentRPY",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Earnings per Share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "2y Ago",
            description:
              "<p><strong>Earnings per Share Growth</strong> is used to determine the rate at which a company is growing its profitability.  It is measured as a percentage change over a given period.  This is measured on a 2 year historical basis and earnings are diluted and normalised.</p>",
            field: "data.ratios__DilNEPSChgPercentRPY2",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Interim Earnings per Share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Q on Q",
            description:
              "<p><strong>EPS Growth Quarter on Quarter</strong> measures the growth rate of a company over the past quarter versus the previous quarter.  This is the percentage change in earnings per share for the last quarter (or interim period if the company reports every 6 months), versus the previous quarter.  This is measured on a historical basis and earnings are diluted and normalised.</p>",
            field: "data.ratios__DilNEPSChgPercentRQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Interim Earnings per Share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Q on PY Q",
            description:
              "<p><strong>EPS Growth Quarter on Prior Year Quarter</strong> measures the growth rate of a company over the past quarter vs the same quarter in the prior year.  This is the percentage change in earnings per share for the last quarter (or interim period if the company reports every 6 months), versus the same quarter in the prior year.  This is measured on a historical basis and earnings are diluted and normalised.</p>",
            field: "data.ratios__DilNEPSChgPercentQonPYQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Earnings per share CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3y",
            description:
              "<p>This figure represents the <strong>3 Year Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, of the company's diluted earnings per share (EPS).  It helps in determining whether companies have been consistent in growing their earnings over the long run.</p>",
            field: "data.ratios__DilNEPSPercentCAGR_3Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Earnings per share CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y",
            description:
              "<p>This figure represents the <strong>5 Year Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, of the company's diluted earnings per share (EPS).  It helps in determining whether companies have been consistent in growing their earnings over the long run.</p>",
            field: "data.ratios__DilNEPSPercentCAGR_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Earnings per share CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "10y",
            description:
              "<p>This figure represents the <strong>10 Year Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, of the company's diluted earnings per share (EPS).  It helps in determining whether companies have been consistent in growing their earnings over the long run.</p>",
            field: "ratios._DilNEPS%CAGR_10Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Consecutive years EPS Growth",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>The <strong>EPS Growth Streak</strong> measures the consistency of the company’s earnings growth.  It answers the question, for how many out of the last 5 consecutive years has the company grown EPS?</p>",
            field: "data.ratios__DilNEPSGrowthStreak",
          },
          {
            name: "Operating Profit Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p><strong>Operating Profit Growth</strong> shows the percentage increase in operating income over the last year.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__OpIncChgPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Operating Profit Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p><strong>Operating Profit Growth</strong> shows the percentage increase in operating income over the last year.  This is measured on a historical basis.</p>",
            field: "data.ratios__OpIncChgPercentRA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Net Profit 5y CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y",
            description:
              "<p><strong>Net Profit 5 Year Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, is the 5 year compounded growth rate in a company’s net profits.  It helps in determining whether companies have been consistent in growing their earnings over the long run.</p>",
            field: "data.ratios__NetIncPercentCAGR_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "CAPE 10y CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "10y",
            description:
              "<p>This figure represents the <strong>Graham EPS 10 Year Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, of the company's diluted earnings per share (EPS).  It helps in determining whether companies have been consistent in growing their earnings over the long run.</p>",
            field: "data.ratios__DilNEPSPercentCAGR_10Yr_Graham",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
      {
        name: "Cashflow Growth Measures",
        description:
          "<p>The <em>Cashflow Growth</em> section has data on the compound annual growth rates of free cash flow and operating cash flow over a period of five years (FCF 5y CAGR and OCF 5y CAGR).</p>",
        ratios: [
          {
            name: "Free Cashflow 5y CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>5 Year Free Cash Flow Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, measures how quickly a company has been growing its Free Cash Flow over the past 5 years.</p>",
            field: "data.ratios__FCFPercentCAGR_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Operating Cashflow 5y CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>5 Year Operating Cash Flow Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, measures how quickly a company has been growing its Operating Cash Flow over the past 5 years.</p>",
            field: "data.ratios__CashOpActPercentCAGR_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
      {
        name: "Sales Growth Measures",
        description:
          "<p><em>Sales Growth</em> is an important measure for investors as higher Sales Growth can lead to higher company valuations. Stockopedia includes the trailing 12m sales growth figure, the Sales 3y CAGR, the average level of annual sales over three years and the annual sales growth streak.</p>",
        ratios: [
          {
            name: "No. of years consecutive sales growth",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>The <strong>Sales Growth Streak</strong> shows how many consecutive years the company has managed to grow its Sales over.  i.e. Sales Streak = 3 shows that the company has grown its sales for the last 3 consecutive fiscal years.</p>",
            field: "data.ratios__SalesStreak",
          },
          {
            name: "Sales Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p><strong>Sales Growth</strong> measures how quickly a company has been growing its sales.  It is measured as the percentage change in sales over a given time period.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__TotRevChgPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Sales Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Q on Q",
            description:
              "<p><strong>Sales Growth Quarter on Quarter</strong> measures how quickly a company has been growing its sales.  This is measured as the growth against the previous quarter.</p>",
            field: "data.ratios__TotRevChgPercentRQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Sales Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Q on PYQ",
            description:
              "<p><strong>Sales Growth Quarter on Prior Year Quarter</strong> measures how quickly a company has been growing.  This is measured as the growth against the same quarter in the prior year.</p>",
            field: "data.ratios__TotRevChgPercentQonPYQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Sales Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling 1y",
            description:
              "<p><strong>Sales Growth</strong> measures how quickly a company has been growing its sales.  It is measured as the percentage change in sales over a given time period.  This is measured on a 1 year rolling basis.</p>",
            field: "data.ratios__TotRevChgPercentRo1",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Sales Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling 2y",
            description:
              "<p><strong>Sales Growth</strong> measures how quickly a company has been growing its sales.  It is measured as the percentage change in sales over a given time period.  This is measured on a 2 year rolling basis.</p>",
            field: "data.ratios__TotRevChgPercentRo2",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Sales CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3y",
            description:
              "<p>The <strong>Sales 3 Year Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, measures the growth rate in sales over the longer run.</p>",
            field: "data.ratios__TotRevPercentCAGR_3Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Sales CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y",
            description:
              "<p>The <strong>Sales 5 Year Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, measures the growth rate in sales over the longer run.</p>",
            field: "data.ratios__TotRevPercentCAGR_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Average Sales 3y",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3y",
            description:
              "<p>The <strong>3 Year Average Sales</strong> tries to smooth out a company’s sales over multiple years to recognise that Sales fluctuate from year to year.  it is calculated as the average sales over the last 3 years.</p>",
            field: "data.ratios__TotRev_Avg_3Yr",
            unit: RatioUnit.MILLION,
          },
        ],
        categories: [],
      },
      {
        name: "Asset Growth Measures",
        description:
          "<p><em>Asset Growth Measures</em> show how quickly a company has been growing assets and book value. This section also shows the compound annual growth rate of a group's cash and capital expenditure (Cash CAGR and Capex CAGR).</p>",
        ratios: [
          {
            name: "Book Value Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Book Value Growth Rate</strong> shows how quickly a company has been growing its Book Value.  It is measured as the percentage change in Book Value over a given time frame. This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__BVPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Book Value Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "CAGR 3y",
            description:
              "<p>The <strong>Book Value 3 Year Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, shows how quickly a company has been growing its Book Value.  It is measured as the percentage change in Book Value over a given time frame. This is measured as a Compounded Annual Growth Rate (CAGR) over the past 3 years.</p>",
            field: "data.ratios__BVPercentCAGR_3Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Book Value Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "CAGR 5y",
            description:
              "<p>The <strong>Book Value 5 Year Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, shows how quickly a company has been growing its Book Value.  It is measured as the percentage change in Book Value over a given time frame. This is measured as a Compounded Annual Growth Rate (CAGR) over the past 5 years.</p>",
            field: "data.ratios__BVPercentCAGR_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Book Value Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "CAGR Max",
            description:
              "<p>The <strong>Book Value Long Run Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, shows how quickly a company has been growing its Book Value.  It is measured as the percentage change in Book Value over a given time frame. This is measured as a Compounded Annual Growth Rate (CAGR) over as long a period as possible.</p>",
            field: "data.ratios__BVPercentCAGR_MAX",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Book Value per share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "CAGR 5y",
            description:
              "<p>The <strong>Book Value Per Share Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, shows how quickly a company has been growing its Book Value Per Share.  It is measured as the percentage change in Book Value Per Share over a given time frame. This is measured as a Compounded Annual Growth Rate (CAGR) over the past 5 years.</p>",
            field: "data.ratios__BVPSPercentCAGR_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Capital Expenditure CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3y",
            description:
              "<p>The <strong>Capital Expenditure 3 Year Compound Growth Rate</strong>, or <strong>CAGR</strong>, measures how quickly a company has been increasing its Capital Expenditure.  It is measured as the percentage change in Book Value Per Share over a given time frame.  This is measured as a Compounded Annual Growth Rate (CAGR) over the past 3 years.</p>",
            field: "data.ratios__CapExPercentCAGR_3Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Capital Expenditure CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y",
            description:
              "<p>The <strong>Capital Expenditure 3 Year Compound Growth Rate</strong>, or <strong>CAGR</strong>, measures how quickly a company has been increasing its Capital Expenditure.  It is measured as the percentage change in Book Value Per Share over a given time frame.  This is measured as a Compounded Annual Growth Rate (CAGR) over the past 5 years.</p>",
            field: "data.ratios__CapExPercentCAGR_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Cash & Short Term Investments CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y",
            description:
              "<p>The <strong>Cash 5 Year Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, shows how quickly a company has been growing its cash pile.  This is measured as a Compounded Annual Growth Rate (CAGR) over the past 5 years.</p>",
            field: "data.ratios__CashSTInvPercentCAGR_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Asset Growth %",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Asset Growth Rate</strong> shows how quickly a company has been growing its Assets.  It is calculated as a percentage change in Assets over a given period.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "data.ratios__TOTAPercentTTM",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
      {
        name: "Guru Growth Ratios",
        description:
          '<p>The <em>Expected Return / Sustainable Growth Rate</em> estimates the return that an investor might expect on his investment. Stockopedia calculates it using the "Buffetology" approach: multiply the average 10 year rate of return on equity by the average retention ratio to get the implied sustainable growth rate; use this rate to calculate the predicted book value per share in year 10; multiply this by the average return on equity to get the 10 year earnings per share; multiply this by the average P/E ratio (plus any dividends) to get an expected return based on the current price.</p>',
        ratios: [
          {
            name: "Expected Return",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Sustainable Growth",
            description:
              "<p>The <strong>Expected Return (Sustainable Growth)</strong> estimates the return that an investor might expect on an investment at a given point in time.  This version uses the Sustainable Growth rate in the calculation.</p>",
            field: "data.ratios__ExpectedReturn_SG",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Expected Return",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Historic Growth",
            description:
              "<p>The <strong>Expected Return (Historical Growth)</strong> estimates the return that an investor might expect on an investment at a given point in time.  This version uses the Historical Growth rate in the calculation.</p>",
            field: "data.ratios__ExpectedReturn_HE",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "GraphicalHistoryFields",
    description: "<p></p>",
    ratios: [
      {
        name: "P/E (Year High)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "1y Ago",
        description:
          "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  Throughout a given year, the PE ratio will fluctuate within a given range as the price changes and/or the company publishes new earnings figures. This is the highest PE value for the company 1 year ago.</p>",
        field: "ratios._PENormal_High_RPY",
      },
      {
        name: "Yield (Year Low)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "4y Ago",
        description:
          "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  Throughout a given year, the dividend yield will fluctuate within a given range as the price changes and/or the company pays higher, or lower, dividends. This is the lowest dividend yield for the company 4 years ago.</p>",
        field: "ratios._DPSYield_Low_RPY4",
      },
      {
        name: "Yield (Year High)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "1y Ago",
        description:
          "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  Throughout a given year, the dividend yield will fluctuate within a given range as the price changes and/or the company pays higher, or lower, dividends. This is the highest dividend yield for the company 1 year ago.</p>",
        field: "ratios._DPSYield_High_RPY",
      },
      {
        name: "Yield (Year High)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "2y Ago",
        description:
          "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  Throughout a given year, the dividend yield will fluctuate within a given range as the price changes and/or the company pays higher, or lower, dividends. This is the highest dividend yield for the company 2 years ago.</p>",
        field: "ratios._DPSYield_High_RPY2",
      },
      {
        name: "Yield (Year High)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "3y Ago",
        description:
          "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  Throughout a given year, the dividend yield will fluctuate within a given range as the price changes and/or the company pays higher, or lower, dividends. This is the highest dividend yield for the company 3 years ago.</p>",
        field: "ratios._DPSYield_High_RPY3",
      },
      {
        name: "Yield (Year High)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "4y Ago",
        description:
          "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price. Throughout a given year, the dividend yield will fluctuate within a given range as the price changes and/or the company pays higher, or lower, dividends. This is the highest dividend yield for the company 4 years ago.</p>",
        field: "ratios._DPSYield_High_RPY4",
      },
      {
        name: "Yield (Year High)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "Last Year",
        description:
          "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  Throughout a given year, the dividend yield will fluctuate within a given range as the price changes and/or the company pays higher, or lower, dividends. This is the highest dividend yield for the company last year.</p>",
        field: "ratios._DPSYield_High_RA",
      },
      {
        name: "Yield (Year High)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "TTM",
        description:
          "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  Throughout a given year, the dividend yield will fluctuate within a given range as the price changes and/or the company pays higher, or lower, dividends. This is the highest dividend yield for the company during the last 12 months.</p>",
        field: "ratios._DPSYield_High_TTM",
      },
      {
        name: "Yield (Year Low)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "1y Ago",
        description:
          "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  Throughout a given year, the dividend yield will fluctuate within a given range as the price changes and/or the company pays higher, or lower, dividends. This is the lowest dividend yield for the company 1 year ago.</p>",
        field: "ratios._DPSYield_Low_RPY",
      },
      {
        name: "Yield (Year Low)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "2y Ago",
        description:
          "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  Throughout a given year, the dividend yield will fluctuate within a given range as the price changes and/or the company pays higher, or lower, dividends. This is the lowest dividend yield for the company 2 years ago.</p>",
        field: "ratios._DPSYield_Low_RPY2",
      },
      {
        name: "Yield (Year Low)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "3y Ago",
        description:
          "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  Throughout a given year, the dividend yield will fluctuate within a given range as the price changes and/or the company pays higher, or lower, dividends. This is the lowest dividend yield for the company 3 years ago.</p>",
        field: "ratios._DPSYield_Low_RPY3",
      },
      {
        name: "Yield (Year Low)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "Last Year",
        description:
          "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  This is the lowest dividend yield for the company last year ago.</p>",
        field: "ratios._DPSYield_Low_RA",
      },
      {
        name: "Yield (Year Low)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "TTM",
        description:
          "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  Throughout a given year, the dividend yield will fluctuate within a given range as the price changes and/or the company pays higher, or lower, dividends. This is the lowest dividend yield for the company during the last 12 months.</p>",
        field: "ratios._DPSYield_Low_TTM",
      },
      {
        name: "P/E (Year High)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "2y Ago",
        description:
          "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  Throughout a given year, the PE ratio will fluctuate within a given range as the price changes and/or the company publishes new earnings figures. This is the highest PE value for the company 2 years ago.</p>",
        field: "ratios._PENormal_High_RPY2",
      },
      {
        name: "P/E (Year High)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "3y Ago",
        description:
          "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  Throughout a given year, the PE ratio will fluctuate within a given range as the price changes and/or the company publishes new earnings figures. This is the highest PE value for the company 3 years ago.</p>",
        field: "ratios._PENormal_High_RPY3",
      },
      {
        name: "P/E (Year High)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "4y Ago",
        description:
          "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  Throughout a given year, the PE ratio will fluctuate within a given range as the price changes and/or the company publishes new earnings figures. This is the highest PE value for the company 4 years ago.</p>",
        field: "ratios._PENormal_High_RPY4",
      },
      {
        name: "P/E (Year High)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "Last Year",
        description:
          "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share. Throughout a given year, the PE ratio will fluctuate within a given range as the price changes and/or the company publishes new earnings figures.  This is the highest PE value for the company last year.</p>",
        field: "ratios._PENormal_High_RA",
      },
      {
        name: "P/E (Year High)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "TTM",
        description:
          "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  Throughout a given year, the PE ratio will fluctuate within a given range as the price changes and/or the company publishes new earnings figures. This is the PE for the company when the PE was at its highest point during the last 12 months.</p>",
        field: "ratios._PENormal_High_TTM",
      },
      {
        name: "P/E (Year Low)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "1y Ago",
        description:
          "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  Throughout a given year, the PE ratio will fluctuate within a given range as the price changes and/or the company publishes new earnings figures. This is the lowest PE value for the company 1 year ago.</p>",
        field: "ratios._PENormal_Low_RPY",
      },
      {
        name: "P/E (Year Low)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "2y Ago",
        description:
          "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  Throughout a given year, the PE ratio will fluctuate within a given range as the price changes and/or the company publishes new earnings figures. This is the lowest PE value for the company 2 years ago.</p>",
        field: "ratios._PENormal_Low_RPY2",
      },
      {
        name: "P/E (Year Low)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "3y Ago",
        description:
          "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  Throughout a given year, the PE ratio will fluctuate within a given range as the price changes and/or the company publishes new earnings figures. This is the lowest PE value for the company 3 years ago.</p>",
        field: "ratios._PENormal_Low_RPY3",
      },
      {
        name: "P/E (Year Low)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "4y Ago",
        description:
          "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  Throughout a given year, the PE ratio will fluctuate within a given range as the price changes and/or the company publishes new earnings figures. This is the lowest PE value for the company 4 years ago.</p>",
        field: "ratios._PENormal_Low_RPY4",
      },
      {
        name: "P/E (Year Low)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "Last Year",
        description:
          "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  Throughout a given year, the PE ratio will fluctuate within a given range as the price changes and/or the company publishes new earnings figures. This is the lowest PE value for the company last year.</p>",
        field: "ratios._PENormal_Low_RA",
      },
      {
        name: "P/E (Year Low)",
        dataSource: DataSource.BATCH,
        dataType: "double",
        modifier: "TTM",
        description:
          "<p>The <strong>Price to Earnings Ratio</strong>, or <strong>PE Ratio</strong>, is the primary valuation ratio used by most equity investors. It is the price per share divided by earnings per share.  Throughout a given year, the PE ratio will fluctuate within a given range as the price changes and/or the company publishes new earnings figures. This is the PE for the company when the PE was at its highest point during the last 12 months.</p>",
        field: "ratios._PENormal_Low_TTM",
      },
      {
        name: "Forecasts Available",
        dataSource: DataSource.BATCH,
        dataType: "int",
        description:
          "<p>This is the number of future years for which estimates data is available.</p>",
        field: "ratios._ForecastsAvailable",
      },
    ],
    categories: [],
  },
  {
    name: "Financial Statements",
    description:
      "<p><em>Financial Statements</em> are a key part of company accounts and important information is often uncovered by calculating liquidity, solvency and profitability ratios from their figures. At Stockopedia, we publish a variety of such ratios by using data from company Balance Sheets, Income Statements & Cashflow Statements.</p>",
    ratios: [],
    categories: [
      {
        name: "Income Statement",
        description:
          "<p>Stockopedia publishes <em>Income Statements</em> for global stocks. Metrics available in the screener include sales, EBITDA, EBITDA margin, operating profit, net profit and earnings per share lines of the statement. Other figures looked at include sales per share, retained EPS, the company's EPS streak (how many profitable periods in the past five years) and 10y EPS increases.</p>",
        ratios: [
          {
            name: "Normalised Earnings per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Normalised Earnings per Share</strong>, or <strong>NEPS</strong> figure indicates the earnings per-share a business would have generated if all stock options and other sources of dilution that were currently exercisable are taken into account. This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis and earnings are diluted and normalised.</p>',
            field: "ratios._DilNEPS_TTM",
          },
          {
            name: "Normalised Earnings per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Prior TTM",
            description:
              "<p>The <strong>Normalised Earnings per Share</strong>, or <strong>NEPS</strong>, figure indicates the earnings per-share a business would have generated if all stock options and other sources of dilution that were currently exercisable are taken into account. This is measured on a prior TTM basis and earnings are diluted and normalised.</p>",
            field: "ratios._DilNEPS_PTTM",
          },
          {
            name: "Normalised Earnings per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Ago",
            description:
              "<p>The <strong>Normalised Earnings per Share</strong>, or <strong>NEPS</strong>, figure indicates the earnings per-share a business would have generated if all stock options and other sources of dilution that were currently exercisable are taken into account. This is a historical value from 5 years ago.</p>",
            field: "ratios._DilNEPS_RPY5",
          },
          {
            name: "Normalised Earnings per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "6y Avg",
            description:
              "<p>The <strong>Normalised Earnings per Share</strong>, or <strong>NEPS</strong>, figure indicates the earnings per-share a business would have generated if all stock options and other sources of dilution that were currently exercisable are taken into account. This is a historical value from 6 years ago.</p>",
            field: "ratios._DilNEPS_Avg_6Yr",
          },
          {
            name: "Basic EPS Incl. Extraordinaries",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>The <strong>Earnings per Share</strong>, or <strong>EPS</strong>, figure is ‘As Reported’.  This means it does not factor in Thomson Reuters’ normalisation methodology for extraordinary items.</p>",
            field: "ratios._BasicEPSIncl_TTM",
          },
          {
            name: "No. of years consecutive positive earnings per share",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>The <strong>EPS Streak</strong> measures how many times out of the last 5 consecutive years the company reported profits.</p>",
            field: "ratios._DilNEPSStreak",
          },
          {
            name: "No. of years of EPS Increases in last decade",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>This is the number of times that <strong>Earnings per Share</strong>, or <strong>EPS</strong>, has increased over the last ten financial years.</p>",
            field: "ratios._EPSIncrease",
          },
          {
            name: "Net Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p><strong>Net Profit</strong> is the residual income of a firm after adding total revenue and gains and subtracting all expenses and losses for the reporting period.</p>",
            field: "ratios._NetInc_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Net Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM GBP",
            description:
              "<p><strong>Net Profit</strong> is the profit reported net of all taxes, interest and expenses.  Often referred to as the 'bottom line' and used to calculate per share earnings.</p>",
            field: "ratios._NetIncGBP_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Net Profit",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p><strong>Net Profit</strong> is the profit reported net of all taxes, interest and expenses.  Often referred to as the 'bottom line' and used to calculate per share earnings.  The currency of this field is dynamic and will match the value set in your site settings.</p>",
            field: "ratios._NetIncCCY_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Net Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM USD",
            description:
              "<p><strong>Net Profit</strong> is the profit reported net of all taxes, interest and expenses.  Often referred to as the 'bottom line' and used to calculate per share earnings.</p>",
            field: "ratios._NetIncUSD_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Net Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM EUR",
            description:
              "<p><strong>Net Profit</strong> is the profit reported net of all taxes, interest and expenses.  Often referred to as the 'bottom line' and used to calculate per share earnings.</p>",
            field: "ratios._NetIncEUR_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Net Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM JPY",
            description:
              "<p><strong>Net Profit</strong> is the profit reported net of all taxes, interest and expenses.  Often referred to as the 'bottom line' and used to calculate per share earnings.</p>",
            field: "ratios._NetIncJPY_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Net Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM SGD",
            description:
              "<p><strong>Net Profit</strong> is the profit reported net of all taxes, interest and expenses.  Often referred to as the 'bottom line' and used to calculate per share earnings.</p>",
            field: "ratios._NetIncSGD_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Net Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM INR",
            description:
              "<p><strong>Net Profit</strong> is the profit reported net of all taxes, interest and expenses.  Often referred to as the 'bottom line' and used to calculate per share earnings.</p>",
            field: "ratios._NetIncINR_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Net Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM AUD",
            description:
              "<p><strong>Net Profit</strong> is the profit reported net of all taxes, interest and expenses.  Often referred to as the 'bottom line' and used to calculate per share earnings.</p>",
            field: "ratios._NetIncAUD_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Net Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM CAD",
            description:
              "<p><strong>Net Profit</strong> is the profit reported net of all taxes, interest and expenses.  Often referred to as the 'bottom line' and used to calculate per share earnings.</p>",
            field: "ratios._NetIncCAD_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Net Profit Before Extraordinaries",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p><strong>Net Profit Before Extraordinaries</strong> is the residual income of a firm after taking Total Revenue and subtracting all Ordinary Expenses for the reporting period.  This means that one-off items are not included in the figure.</p>",
            field: "ratios._NetIncBExtra_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Sales Per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p><strong>Sales per Share</strong> is the ratio of a company's annual revenue to its average number of shares outstanding for a given year. A higher ratio is seen as indicating an active company that has successfully used its resources to produce sales.</p>",
            field: "ratios._TotRevPS_TTM",
          },
          {
            name: "Total Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p><strong>Sales</strong>, or <strong>Revenue</strong>, tells you the dollar value of goods and services a company sells. This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "ratios._TotRev_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Total Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Prior TTM",
            description:
              "<p><strong>Sales</strong>, or <strong>Revenue</strong>, tells you the dollar amount of goods and services a company sells. This is measured on a prior TTM basis.</p>",
            field: "ratios._TotRev_PTTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Total Sales",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p><strong>Sales</strong>, or <strong>Revenue</strong>, tells you the dollar amount of goods and services a company sells. This is measured on a historical basis.</p>",
            field: "ratios._TotRev_RA",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Operating Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p><strong>Operating Profit</strong> is a measure of a company\'s earning power from ongoing operations, calculated as the difference between operating revenues and operating expenses.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "ratios._OpInc_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Operating Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1y Ago",
            description:
              "<p><strong>Operating Profit</strong> is a measure of a company's earning power from ongoing operations, calculated as the difference between operating revenues and operating expenses.  This is measured on a 1 year historical basis.</p>",
            field: "ratios._OpInc_RPY",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Operating Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "2y Ago",
            description:
              "<p><strong>Operating Profit</strong> is a measure of a company's earning power from ongoing operations, calculated as the difference between operating revenues and operating expenses.  This is measured on a 2 year historical basis.</p>",
            field: "ratios._OpInc_RPY2",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Operating Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3y Ago",
            description:
              "<p><strong>Operating Profit</strong> is a measure of a company's earning power from ongoing operations, calculated as the difference between operating revenues and operating expenses.  This is measured on a 3 year historical basis.</p>",
            field: "ratios._OpInc_RPY3",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Operating Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "4y Ago",
            description:
              "<p><strong>Operating Profit</strong> is a measure of a company's earning power from ongoing operations, calculated as the difference between operating revenues and operating expenses.  This is measured on a 4 year historical basis.</p>",
            field: "ratios._OpInc_RPY4",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Operating Profit",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Ago",
            description:
              "<p><strong>Operating Profit</strong> is a measure of a company's earning power from ongoing operations, calculated as the difference between operating revenues and operating expenses.  This is measured on a 5 year historical basis.</p>",
            field: "ratios._OpInc_RPY5",
            unit: RatioUnit.MILLION,
          },
          {
            name: "EBITDA",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p><strong>EBITDA</strong> comprises earnings before interest, tax and depreciation.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "ratios._EBITDA_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "EBITDA Margin",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p><strong>EBITDA Margin</strong> is used in determining how profitable a company or business is with regard to operations. It is calculated as EBITDA (Earnings before interest, tax and depreciation) divided by Revenue.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "ratios._EBITDAMgn%TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "EBITDA Margin",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Avg",
            description:
              "<p><strong>EBITDA Margin</strong> is used in determining how profitable a company or business is with regard to operations. It is calculated as EBITDA (Earnings before interest, tax and depreciation) divided by Revenue.  This is an average of the past 5 years.</p>",
            field: "ratios._EBITDAMgn%Avg_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Retained Earnings Per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p><strong>Retained Earnings Per Share</strong> refers to the portion of net income which is retained by the company rather than distributed to its owners as dividends. This is calculated by dividing Retained Earnings by the total number of Shares Outstanding.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "ratios._RetEarnPS_TTM",
          },
        ],
        categories: [],
      },
      {
        name: "Balance Sheet",
        description:
          "<p>Stockopedia publishes * Balance Sheets* for global stocks. The following <em>Balance Sheet</em> figures are available in the screener: cash and net cash per share, cash and short-term investments per share, working capital, net current asset value and long-term debt.</p>",
        ratios: [
          {
            name: "Cash & Short Term Investments",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>Cash and Short Term Investments</strong> is calculated by taking all the cash and short term investments of the company and dividing that number by the total shares outstanding.</p>",
            field: "ratios._CashSTInv_RQ",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Cash and Short Term investment Per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>Cash per Share</strong> is calculated by taking all a company’s cash and dividing that number by the total shares outstanding.</p>",
            field: "ratios._CashSTInvPS_RQ",
          },
          {
            name: "Net Cash Per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>Net Cash per Share</strong> is calculated by taking all a company’s cash, less all current liabilities and dividing that number by the total shares outstanding.</p>",
            field: "ratios._NetCashSTInvPS_RQ",
          },
          {
            name: "Net Debt",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>Net Debt</strong> is the sum of all Short Term Debt, Notes Payable, Long Term debt and Preferred Equity minus the total cash and equivalents and short term investments for the most recent reporting period.</p>",
            field: "ratios._NetDebt_RQ",
          },
          {
            name: "Net Current Asset Value",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>NCAV</strong> is the company’s current assets minus its total liabilities. This gives an additional margin of safety versus book value - on this valuation measure, one is essentially paying nothing for all the fixed assets (buildings, machinery, etc), or any goodwill items that may exist.</p>",
            field: "ratios._NCAV_RQ",
          },
          {
            name: "Working Capital",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>Working Capital</strong> is calculated as Current Assets minus Current Liabilities. It is a measure of the Operating Liquidity available to a business.</p>",
            field: "ratios._WC_RQ",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Long Term Debt",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>Long Term Debt</strong> represents debt with maturities beyond one year. Long Term Debt may consist of long-term bank borrowings, bonds, convertible bonds, etc.</p>",
            field: "ratios._TotLTD_RQ",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Net Fixed Assets",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "Net Fixed Assets - or or property, plant and equipment after depreciation-  is defined as per the Greenblatt definition as Total Assets - Total Current Assets - Total Intangible assets/goodwill",
            field: "series._NFA",
            unit: RatioUnit.MILLION,
          },
        ],
        categories: [],
      },
      {
        name: "Cash Flow Statement",
        description:
          "<p>Stockopedia lists <em>Cash Flow Statements</em> for global stocks. Metrics available in the screener include free cash flow per share, operating cash flow per share and capex per share. The site also looks at TTM free cash flow, TTM operating cash flow and the operating cash flow / earnings per share ratio.</p>",
        ratios: [
          {
            name: "Free Cashflow",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1y Ago",
            description:
              "<p><strong>Free Cash Flow</strong> is calculated from the Statement of Cash Flows as Cash From Operations minus Capital Expenditures.  This is measured on a 1 year historical basis.</p>",
            field: "ratios._FCF_RPY",
          },
          {
            name: "Free Cashflow",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "2y Ago",
            description:
              "<p><strong>Free Cash Flow</strong> is calculated from the Statement of Cash Flows as Cash From Operations minus Capital Expenditures.  This is measured on a 2 year historical basis.</p>",
            field: "ratios._FCF_RPY2",
          },
          {
            name: "Free Cashflow per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p><strong>Free Cash Flow Per Share</strong> is calculated from the Statement of Cash Flows as Cash From Operations minus Capital Expenditures, divided by the Diluted Number of Ordinary Shares Outstanding.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "ratios._FCFPS_TTM",
          },
          {
            name: "Free Cashflow",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p><strong>Free Cash Flow</strong> is calculated from the Statement of Cash Flows as Cash From Operations minus Capital Expenditures.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "ratios._FCF_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Free Cashflow",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3y Ago",
            description:
              "<p><strong>Free Cash Flow</strong> is calculated from the Statement of Cash Flows as Cash From Operations minus Capital Expenditures.  This is measured on a 3 year historical basis.</p>",
            field: "ratios._FCF_RPY3",
          },
          {
            name: "Operating Cash Flow Per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p><strong>Operating Cash Flow Per Share</strong> refers to the amount of cash a company generates from the revenues it brings in, excluding costs associated with long-term capital investment. It is similar to operating profit but excluding non-cash items and accruals.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis. using Diluted Shares Outstanding.</p>',
            field: "ratios._CashOpActPS_TTM",
          },
          {
            name: "Operating Cashflow Per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM GBP",
            description:
              '<p><strong>Operating Cash Flow Per Share</strong> refers to the amount of cash a company generates from the revenues it brings in, excluding costs associated with long-term capital investment. It is similar to operating profit but excluding non-cash items and accruals.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis. using Diluted Shares Outstanding.</p>',
            field: "ratios._CashOpActPSGBP_TTM",
          },
          {
            name: "Operating Cashflow",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p><strong>Operating Cash Flow Per Share</strong> refers to the amount of cash a company generates from the revenues it brings in, excluding costs associated with long-term capital investment. It is similar to operating profit but excluding non-cash items and accruals.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "ratios._CashOpAct_TTM",
            unit: RatioUnit.MILLION,
          },
          {
            name: "Operating Cash Flow to EPS",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Operating Cash Flow to Earnings Per Share Ratio</strong>, or <strong>OCF/EPS Ratio</strong>, compares operating cash flow per share (i.e. operating cash flow divided by the number of shares used to calculate EPS) with normalised EPS.  It is a measure of cash conversion, i.e. the proportion of profits that are converted to cash flow.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis. using Diluted Shares Outstanding.</p>',
            field: "ratios._CashOpActPS2EPS_TTM",
          },
          {
            name: "Capital Expenditure Per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p><strong>Capital Expenditure Per Share</strong> is the amount of Capital Expenditure Per Share which the company incurs in order to maintain its operating assets. It is calculated as the Capital Expenditure from the Statement of Cash Flows divided by the Average Shares Outstanding for the same period.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis. using Diluted Shares Outstanding.</p>',
            field: "ratios._CapExPS_TTM",
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "Dividend, Yield & Income Ratios",
    description:
      "<p><em>Dividends</em> are important to those seeking passive income from their investments, but income investing does not stop at identifying high yields. Here you will find information about how well a company's dividend is covered, its payout ratio, how fast payments to shareholders have grown in the past and what dividends are forecast for the future. Looking at a company's dividend track record can also provide more general insights into the stability and profitability of a company. Stockopedia provides a range of financial metrics around Dividend Yield, Dividend Safety & Dividend Growth.</p>",
    ratios: [],
    categories: [
      {
        name: "Dividend Yield",
        description:
          "<p><em>Dividend Yield</em> measures provide ways to assess the level of the payout of profits to shareholders. Stockopedia includes the dividend yield, dividend per share, increases and decreases to the DPS, annual dividend history (whether or not a company has paid a dividend in the last five years) and the annual dividend streak (consecutive years of dividend payments).</p>",
        ratios: [
          {
            name: "Dividend Yield",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              '<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  This is measured on a <a href="/knowledgebase/articles/77922-how-do-you-calculate-ttm-trailing-twelve-month-r" target="_blank">TTM</a> basis.</p>',
            field: "ratios._DPSYield%TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend Yield",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling",
            description:
              "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  This is measured on a rolling basis.</p>",
            field: "ratios._DPSYield%Ro",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend Yield",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling 1y",
            description:
              "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  This is measured on a 1 year rolling basis.</p>",
            field: "ratios._DPSYield%Ro1",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend Yield",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling 2y",
            description:
              "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  This is measured on a 2 year rolling basis.</p>",
            field: "ratios._DPSYield%Ro2",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend Yield",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Avg",
            description:
              "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  This is measured as an average of the past 5 years' historical values.</p>",
            field: "ratios._DPSYield%Avg_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend Yield",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Avg (Weiss)",
            description:
              "<p>The <strong>Dividend Yield</strong> shows how much a company pays out in dividends each year relative to its share price. In the absence of any capital gains, the dividend yield is the return on investment for a stock.  It is calculated as the Dividend per Share divided by the Share Price.  The Weiss variation is measured as an average of the past 5 years' historical values, adjusted for the average market capitalisation in each of those years.</p>",
            field: "ratios._DPSYield_5YrAvgHigh",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p><strong>Dividend per Share</strong> is the amount amount of dividend payment that a shareholder receives for each share held. It can be calculated by taking the total amount of dividends paid and dividing it by the total shares outstanding.  This uses the TTM dividend as the dividend figure.</p>",
            field: "ratios._DPSCom_TTM",
          },
          {
            name: "Dividend per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling 1y",
            description:
              "<p><strong>Dividend per Share</strong> is the amount amount of dividend payment that a shareholder receives for each share held. It can be calculated by taking the total amount of dividends paid and dividing it by the total shares outstanding.  This uses the 1 year rolling dividend as the dividend figure.</p>",
            field: "ratios._DPSCom_Ro1",
          },
          {
            name: "Dividend per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling 2y",
            description:
              "<p><strong>Dividend per Share</strong> is the amount amount of dividend payment that a shareholder receives for each share held. It can be calculated by taking the total amount of dividends paid and dividing it by the total shares outstanding.  This uses the 2 year rolling dividend as the dividend figure.</p>",
            field: "ratios._DPSCom_Ro2",
          },
          {
            name: "Dividend per Share",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y Avg",
            description:
              "<p><strong>Dividend per Share</strong> is the amount amount of dividend payment that a shareholder receives for each share held. It can be calculated by taking the total amount of dividends paid and dividing it by the total shares outstanding.  This takes the average of the previous 5 year’s of DPS figures.</p>",
            field: "ratios._DPSCom_Avg_5Yr",
          },
          {
            name: "Annual Dividend History",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>The <strong>Annual Dividend History</strong> shows whether or not the company paid a dividend in the last 5 years.</p>",
            field: "ratios._DivHistory",
          },
          {
            name: "Annual Dividend Streak",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>The <strong>Annual Dividend Streak</strong> shows the number of consecutive years the company has paid a dividend over the last 10 years.</p>",
            field: "ratios._DivStreak",
          },
          {
            name: "10y DPS Increase Count",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>The <strong>10 Year DPS Increase Count</strong> shows the number of times that the Dividend per Share has been increased during the last ten financial years.</p>",
            field: "ratios._DPSIncrease",
          },
          {
            name: "10y DPS Decrease Count",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>The <strong>10 Year DPS Decrease Count</strong> shows the number of times that the Dividend per Share has been decreased during the last ten financial years.</p>",
            field: "ratios._DPSDecrease",
          },
        ],
        categories: [],
      },
      {
        name: "Dividend Growth Measures",
        description:
          "<p><em>Dividend Growth</em> measures allow an assessment of the level of dividend growth over various timeframes. At Stockopedia we provide year on year dividend per share growth, 3y, 5y and 10y dividend per share compound annual growth rate and annual dividend growth streak.</p>",
        ratios: [
          {
            name: "Dividend per share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>The <strong>Dividend per Share Growth</strong> measures the year on year growth of dividend payments by the company. It is calculated as the year on year change in Dividends per Share.  This is measured on a trailing twelve month basis.</p>",
            field: "ratios._DPSChg%TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend per share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Forecast 1y",
            description:
              "<p>The <strong>Dividend per Share Growth</strong> measures the year on year growth of dividend payments by the company. It is calculated as the year on year change in Dividends per Share.  This is measured using 1 year forecast data.</p>",
            field: "ratios._DPSChg%FY1",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend per share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p>The <strong>Dividend per Share Growth</strong> measures the year on year growth of dividend payments by the company. It is calculated as the year on year change in Dividends per Share.  This is measured using the most recent full year financial statements.</p>",
            field: "ratios._DPSChg%RA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend per share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1y Ago",
            description:
              "<p>The <strong>Dividend per Share Growth</strong> measures the year on year growth of dividend payments by the company. It is calculated as the year on year change in Dividends per Share.  This is measured using full year financial statements from 1 financial year ago.</p>",
            field: "ratios._DPSChg%RPY",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend per share Growth",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "2y Ago",
            description:
              "<p>The <strong>Dividend per Share Growth</strong> measures the year on year growth of dividend payments by the company. It is calculated as the year on year change in Dividends per Share.  This is measured using full year financial statements from 2 financial years ago.</p>",
            field: "ratios._DPSChg%RPY2",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend per share CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3y",
            description:
              "<p>The <strong>Dividend per Share Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, measures the rate of growth in Dividends per Share.  It is calculated as the Compound Annual Growth Rate in Dividends Per Share over a given time period.  This version is calculated using the last 3 years worth of data..</p>",
            field: "ratios._DPSCom%CAGR_3Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend per share CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "5y",
            description:
              "<p>The <strong>Dividend per Share Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, measures the rate of growth in Dividends per Share.  It is calculated as the Compound Annual Growth Rate in Dividends Per Share over a given time period.  This version is calculated using the last 5 years worth of data..</p>",
            field: "ratios._DPSCom%CAGR_5Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Dividend per share CAGR",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "10y",
            description:
              "<p>The <strong>Dividend per Share Compound Annual Growth Rate</strong>, or <strong>CAGR</strong>, measures the rate of growth in Dividends per Share.  It is calculated as the Compound Annual Growth Rate in Dividends Per Share over a given time period.  This version is calculated using the last 10 years worth of data..</p>",
            field: "ratios._DPSCom%CAGR_10Yr",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Annual Dividend Growth Streak",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>The <strong>Annual Dividend Growth Streak</strong> is used to measure how consistently a company has increased its Dividend per Share over the past 10 years.  It is calculated as the number of years in which a company has increased its Dividend per Share.</p>",
            field: "ratios._DPSGrowthStreak",
          },
        ],
        categories: [],
      },
      {
        name: "Dividend Safety Ratios",
        description:
          "<p><em>Dividend Safety</em> focuses on the sustainability of a company's payments to its shareholders. The main measures at Stockopedia are dividend cover (EPS / DPS) and the payout ratio, which is the inverse of the dividend cover ratio (dividends paid as a percentage of net income).</p>",
        ratios: [
          {
            name: "Dividend Cover",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling",
            description:
              "<p><strong>Dividend Cover</strong> is a popular measure of dividend safety.  It is calculated as earnings per share divided by the dividend per share. It provides a quick fix on how many times the dividend is ‘covered’ by earnings.   This is the current ‘rolling’ version which balances the last annual figures with the forecasts for the current year.</p>",
            field: "ratios._DivCover_Ro",
          },
          {
            name: "Dividend Cover",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p><strong>Dividend Cover</strong> is a popular measure of dividend safety.  It is calculated as earnings per share divided by the dividend per share. It provides a quick fix on how many times the dividend is ‘covered’ by earnings.  This is a trailing twelve month figure.</p>",
            field: "ratios._DivCover_TTM",
          },
          {
            name: "Dividend Cover",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Rolling 1y",
            description:
              "<p><strong>Dividend Cover</strong> is a popular measure of dividend safety.  It is calculated as earnings per share divided by the dividend per share. It provides a quick fix on how many times the dividend are projected to be ‘covered’ by earnings.   This is the 1 year ‘rolling forecast’ version which blends this year’s forecasts with next year’s forecasts.</p>",
            field: "ratios._DivCover_Ro1",
          },
          {
            name: "Dividend Payout Ratio",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>The <strong>Dividend Payout Ratio</strong> is a popular measure of dividend safety.  It measures the amount of earnings paid out in dividends to shareholders and is the inverse of the Dividend Cover Ratio. It is calculated as dividends per share divided by earnings per share. It is quoted as a percentage.</p>",
            field: "ratios._PayRatio%TTM",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "Company Profile and Classification Data",
    description:
      "<p>The <em>Company Profile</em> section includes information on identifying characteristics such as the company's name, its stock market ticker, which sectors and industry groups it operates in, who the directors are etc. Stockopedia also provides a range of data about how and where the company trades.</p>",
    ratios: [],
    categories: [
      {
        name: "Basics",
        description: "<p></p>",
        ratios: [
          {
            name: "Security Name",
            dataSource: DataSource.GENERAL_INFORMATION,
            dataType: "string",
            description:
              "<p>This is the <strong>Name</strong> of the security in question.  It is likely to be a company name, ETF, bond or index name.   Please note that several securities may share the same name if there are more than one class of share.</p>",
            field: "term.name",
          },
          {
            name: "Ticker Symbol",
            dataSource: DataSource.GENERAL_INFORMATION,
            dataType: "string",
            description:
              "<p>This is the <strong>Ticker Symbol</strong> of the security, an easily memorable identifier used to trade the security on the stock exchange.  It is usually, though not always, between 2 to 4 characters long and either alphabetical (AAPL) or numeric (888).</p>",
            field: "term.ticker",
          },
        ],
        categories: [],
      },
      {
        name: "Sector and Factor Classifications",
        description:
          "<p>Stockopedia groups companies not just by industry, sector, country, region and size but also by its own proprietary factor and volatility classifications. The StockRanks Styles label a company according to how it stacks up using the Quality, Value and Momentum Ranks, while the Risk Ratings sort markets into quintiles according to a measure of normalised three-year volatility.</p>",
        ratios: [
          {
            name: "Economic Sector",
            dataSource: DataSource.SCREENER,
            dataType: "string",
            description:
              "<p>This is the <strong>Economic Sector</strong> according to the Thomson Reuters Business Classification Schema (TRBC).  There are 10 economic sectors in the schema which classify stocks across broad economic segments such as Technology and Financials.</p>",
            field: "data.ratios__TRBCEconSectCode",
          },
          {
            name: "Industry Group",
            dataSource: DataSource.SCREENER,
            dataType: "string",
            description:
              "<p>This is the <strong>Industry Group</strong> according to the Thomson Reuters Business Classification Schema (TRBC).  There are 54 industry groups in the schema which are each unique to a single Economic Sector.</p>",
            field: "data.ratios__TRBCIndGrpCode",
          },
          {
            name: "Industry",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>This is the <strong>Industry</strong> according to the Thomson Reuters Business Classification Schema (TRBC).</p>",
            field: "data.ratios__TRBCIndGrpCode",
          },
          {
            name: "Economic Activity",
            dataSource: DataSource.BATCH,
            dataType: "string",
            description:
              "<p>This is the <strong>Economic Activity</strong> according to the Thomson Reuters Business Classification Schema (TRBC).</p>",
            field: "ratios._TRBCActCode",
          },
          {
            name: "Risk Rating",
            dataSource: DataSource.SCREENER,
            dataType: "string",
            description:
              "<p>The <strong>Risk Rating</strong> is Stockopedia’s classification of the normalised 3 year market volatility of the company’s share price.   We have designed the Risk Rating to be both a useful predictive measure of future volatility, but also a useful predictive factor for accessing the Low Volatility premium.</p><p>The five classifications (from least to most volatile) are <strong>Conservative</strong>, <strong>Balanced</strong>, <strong>Adventurous</strong>,  <strong>Speculative</strong> and <strong>Highly Speculative</strong>.   At any time 10% of the market will be classified as Conservative, 15% Balanced, 20% Adventurous, 25% Speculative and 30% Highly Speculative.  We use proprietary measures of volatility, which account for the fact that some stocks have shorter histories and/or are traded less frequently than others.</p>",
            field: "data.ratios__RiskRating",
          },
          {
            name: "Size Group",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>The <strong>Size Group</strong> is a classification of a stock according to its relative <strong>Market Capitalisation</strong>,  based on common heuristic cut offs for what constitutes a large or a small company. Any company with a Market Capitalisation of less than GBP 50 million is classified as a <strong>Micro Cap</strong>, less than GBP 350 million is a <strong>Small Cap</strong>, less than GBP 2.5 billion is a <strong>Mid Cap</strong> and all larger companies are <strong>Large Cap</strong>.</p>",
            field: "data.ratios__MktCapGroup_gb",
          },
          {
            name: "StockRank Style",
            dataSource: DataSource.SCREENER,
            dataType: "string",
            description:
              '<p>The <strong>StockRank Styles</strong> are an intuitive classification system based on a stock’s exposure to the Quality, Value and Momentum Ranks.  They allow an investor, at a glance, to understand the investibility of a stock and whether it suits their investing style.</p><p>There are four <strong>Winning Styles</strong> (<a href="http://www.stockopedia.com/content/super-stocks-why-multi-factor-stock-selection-offers-higher-returns-at-lower-risk-122399/">Super Stocks</a>, <a href="http://www.stockopedia.com/content/high-flyers-how-to-beat-the-market-in-expensive-and-highly-priced-shares-109584/">High Flyers</a>, <a href="http://www.stockopedia.com/content/contrarian-stocks-how-going-against-the-crowd-can-put-you-ahead-110736/">Contrarian</a> and <a href="http://www.stockopedia.com/content/turnarounds-how-to-find-value-shares-that-are-bouncing-back-119147/">Turnaround</a>) and four <strong>Losing Styles</strong> (<a href="http://www.stockopedia.com/content/sucker-stocks-why-do-we-love-to-own-the-worst-prospects-in-the-market-112389/">Sucker Stock</a>, <a href="http://www.stockopedia.com/content/momentum-traps-how-to-avoid-the-siren-song-of-overhyped-stocks-109797/">Momentum Trap</a>, <a href="http://www.stockopedia.com/content/value-traps-how-to-avoid-bargain-stocks-that-might-never-recover-110319/">Value Trap</a> and <a href="http://www.stockopedia.com/content/falling-stars-how-to-handle-glamour-shares-that-fall-from-grace-111255/">Falling Star</a>).  Some stocks do not fit any style and these are classified as <strong>Neutral</strong>.</p>',
            field: "data.ratios__StockRankStyle_zz",
          },
          {
            name: "Country",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>This is the country (eg. UK or US) that a stock trades in. For example, if a stock trades on the London Stock Exchange, then the stock's country would be the UK.</p>",
            field: "region.country",
          },
          {
            name: "Region",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>This is the region (eg. Europe) that a stock trades in. For example, if a stock trades on the London Stock Exchange or the Oslo Stock Exchange, then the stock's region would be Europe.</p>",
            field: "region.region",
          },
          {
            name: "StockRank Style",
            dataSource: DataSource.SCREENER,
            dataType: "string",
            modifier: "Previous Day",
            description:
              '<p>This is the <strong>StockRank Style</strong> for the <em>previous trading day</em>. The <strong>StockRank Styles</strong> are an intuitive classification system based on a stock’s exposure to the Quality, Value and Momentum Ranks.  They allow an investor, at a glance, to understand the investibility of a stock and whether it suits their investing style.</p><p>There are four <strong>Winning Styles</strong> (<a href="http://www.stockopedia.com/content/super-stocks-why-multi-factor-stock-selection-offers-higher-returns-at-lower-risk-122399/">Super Stocks</a>, <a href="http://www.stockopedia.com/content/high-flyers-how-to-beat-the-market-in-expensive-and-highly-priced-shares-109584/">High Flyers</a>, <a href="http://www.stockopedia.com/content/contrarian-stocks-how-going-against-the-crowd-can-put-you-ahead-110736/">Contrarian</a> and <a href="http://www.stockopedia.com/content/turnarounds-how-to-find-value-shares-that-are-bouncing-back-119147/">Turnaround</a>) and four <strong>Losing Styles</strong> (<a href="http://www.stockopedia.com/content/sucker-stocks-why-do-we-love-to-own-the-worst-prospects-in-the-market-112389/">Sucker Stock</a>, <a href="http://www.stockopedia.com/content/momentum-traps-how-to-avoid-the-siren-song-of-overhyped-stocks-109797/">Momentum Trap</a>, <a href="http://www.stockopedia.com/content/value-traps-how-to-avoid-bargain-stocks-that-might-never-recover-110319/">Value Trap</a> and <a href="http://www.stockopedia.com/content/falling-stars-how-to-handle-glamour-shares-that-fall-from-grace-111255/">Falling Star</a>).  Some stocks do not fit any style and these are classified as <strong>Neutral</strong>.</p>',
            field: "data.ratios__StockRankStylePrev_zz",
          },
        ],
        categories: [],
      },
      {
        name: "Dates",
        description: "<p></p>",
        ratios: [
          {
            name: "Last Annual Result Date",
            dataSource: DataSource.BATCH,
            dataType: "datetime",
            description:
              "<p>This displays the date of the latest available <strong>Annual Results</strong> for the company in question.  It can be used as a table column in editable tables at Stockopedia.</p>",
            field: "data.ratios__LatestAvailableAnnual",
          },
          {
            name: "Last Interim Result Date",
            dataSource: DataSource.BATCH,
            dataType: "datetime",
            description:
              "<p>This is the date of the latest available <strong>Interim or Quarterly Result</strong> for the company in question.  This can either be an interim (half year) or quarterly report depending on how frequently the company reports.   It can be used as a table column in editable tables at Stockopedia.</p>",
            field: "data.ratios__LatestAvailableInterim",
          },
          {
            name: "Stock Split Date",
            dataSource: DataSource.BATCH,
            dataType: "datetime",
            modifier: "Last",
            description:
              "<p>The <strong>Last Stock Split Date</strong> is the most recent date a company executed a stock split. A stock split is a corporate action whereby a company divides its existing shares into multiple shares.</p>",
            field: "ratios._LastSplitDate",
          },
          {
            name: "Reverse Split Date",
            dataSource: DataSource.BATCH,
            dataType: "datetime",
            modifier: "Last",
            description:
              "<p>The <strong>Last Reverse Split Date</strong> is the most recent date a company executed a reverse stock split. A reverse stock split, also known as a stock consolidation, is a corporate action whereby a company consolidates a larger number of shares into a smaller number.</p>",
            field: "ratios._LastConsolidationDate",
          },
          {
            name: "IPO Date",
            dataSource: DataSource.BATCH,
            dataType: "datetime",
            description:
              "<p>The <strong>IPO Date</strong>, or <strong>Initial Pubic Offering Date</strong> is the date a given firm's shares start to trade on a public stock exchange for the first time.</p>",
            field: "ratios._IPODate",
          },
          {
            name: "Incorporation Date",
            dataSource: DataSource.BATCH,
            dataType: "datetime",
            description:
              "<p>The <strong>Incorporation Date</strong> is the date a company was formed as an indepndent corporate entity or company.</p>",
            field: "ratios._IncorporationDate",
          },
          {
            name: "Statements Announcement Date",
            dataSource: DataSource.STATEMENTS,
            dataType: "datetime",
            modifier: "Last",
            description:
              "<p>The <strong>Last Annual Statements Date</strong> is the date a company last produced a set of annual financial statements (ie. an interim balance sheet, income statement and cashflow statement).</p>",
            field: "ratios._LastAnnualStatementsAnnouncement",
          },
          {
            name: "Statements Announcement Date",
            dataSource: DataSource.STATEMENTS,
            dataType: "datetime",
            modifier: "Last",
            description:
              "<p>The <strong>Last Interim Statements Date</strong> is the date a company last produced a set of interim financial statements (ie. an interim balance sheet, income statement and cashflow statement).</p>",
            field: "ratios._LastInterimStatementsAnnouncement",
          },
          {
            name: "Statements Announcement Date",
            dataSource: DataSource.STATEMENTS,
            dataType: "datetime",
            modifier: "Next",
            description:
              "<p>The <strong>Next Interim Statements Date</strong> is the date a company is scheduled to produce a set of annual financial statements (ie. an interim balance sheet, income statement and cashflow statement).</p>",
            field: "ratios._NextInterimStatementsAnnouncement",
          },
          {
            name: "Statements Announcement Date",
            dataSource: DataSource.STATEMENTS,
            dataType: "datetime",
            modifier: "Next",
            description:
              "<p>The <strong>Next Annual Statements Date</strong> is the date a company is scheduled to produce a set of annual financial statements (ie. an interim balance sheet, income statement and cashflow statement).</p>",
            field: "ratios._NextAnnualStatementsAnnouncement",
          },
          {
            name: "Ex-Dividend Date",
            dataSource: DataSource.BATCH,
            dataType: "datetime",
            modifier: "Last",
            description:
              "<p>The <strong>Last Ex-Dividend Date</strong> is the most recent date a company went ex-dividend. Investors who hold the share on the last trading day before the ex-dividend date are entitled to the dividend. Investors who buy the share on or after the ex-date are not entitled to the dividend. As such, the ex-date is the first date the stock trades without the dividend (ie. excluding the dividend).</p>",
            field: "ratios._LastDividendExDate",
          },
          {
            name: "Dividend Pay Date",
            dataSource: DataSource.BATCH,
            dataType: "datetime",
            modifier: "Last",
            description:
              "<p>The <strong>Last Dividend Pay Date</strong> is the last date a company paid a dividend to shareholders.</p>",
            field: "ratios._LastDividendPayDate",
          },
          {
            name: "Dividend Pay Date",
            dataSource: DataSource.BATCH,
            dataType: "datetime",
            modifier: "Next",
            description:
              "<p>The <strong>Next Dividend Pay Date</strong> is the date a company is scheduled to pay a dividend to shareholders.</p>",
            field: "ratios._NextDividendPayDate",
          },
          {
            name: "Ex-Dividend Date",
            dataSource: DataSource.BATCH,
            dataType: "datetime",
            modifier: "Next",
            description:
              "<p>The <strong>Next Ex-Dividend Date</strong> is the date a company is scheduled to go ex-dividend. Investors who hold the share on the last trading day before the ex-dividend date are entitled to the dividend. Investors who buy the share on or after the ex-date are not entitled to the dividend. As such, the ex-date is the first date the stock trades without the dividend (ie. excluding the dividend).</p>",
            field: "ratios._NextDividendExDate",
          },
        ],
        categories: [],
      },
      {
        name: "Investability",
        description: "<p></p>",
        ratios: [
          {
            name: "ISA Eligiblity",
            dataSource: DataSource.BATCH,
            dataType: "int",
            description:
              "<p>If a share is <strong>ISA Eligible</strong> then it is eligible to be held in the UK tax free 'Individual Savings Account' wrapper - known as an ISA.  This is only relevant for UK investors.  It is a boolean field and represented as a tick or cross in tables.</p>",
            field: "data.ratios__IsIsable",
          },
        ],
        categories: [],
      },
      {
        name: "Exchange & Index Listing Information",
        description:
          "<p>Stockopedia includes information on where a company's shares are listed, including its index membership, primary listing, exchange and exchange segment.</p>",
        ratios: [
          {
            name: "Index Memberships",
            dataSource: DataSource.SCREENER,
            dataType: "string",
            description:
              "<p>This displays the major <strong>Index Memberships</strong> for a given security.  For example for UK shares it will display whether the security is a part of the FTSE 100, FTSE 350 or FTSE All Share Indices.</p>",
            field: "data.ratios__Indices",
          },
          {
            name: "Primary Listing",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              '<p>The <strong>Primary Listing</strong> field displays whether a security is primary listed on its stock exchange.  There are many “Dual Listed “companies on exchanges around the world who have their Primary Listing elsewhere.  The Value of "Is Primary Listing?” is set to either 1 or 0 where 1 indicates the security is a primary listing.  This is a boolean field and is rendered as a tick or cross in tables.</p>',
            field: "data.ratios__IsPrimaryListing",
          },
          {
            name: "UK Exchange Segment",
            dataSource: DataSource.SCREENER,
            dataType: "string",
            description:
              "<p>This is the <strong>Exchange Segment</strong> for the primary trading market for the security.</p>",
            field: "data.ratios__Exchange",
          },
          {
            name: "Exchange Code",
            dataSource: DataSource.SCREENER,
            dataType: "string",
            description:
              "<p>This is the <strong>Exchange Code</strong> for the primary trading market for the security.   It is displayed as a 3 digit alphabetical mnemonic.  e.g. for the London Stock Exchange, the Exchange Code is ‘LSE’.</p>",
            field: "data.ratios__ExchangeCode",
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "BondInfo",
    description: "<p></p>",
    ratios: [],
    categories: [
      {
        name: "BondCouponInfo",
        description: "<p></p>",
        ratios: [
          {
            name: "Coupon Class",
            dataSource: DataSource.INSTRUMENTS,
            dataType: "string",
            description:
              "<p>This field contains a three letter code which indicates whether a bond pays coupons at a fixed, variable or floating rate.</p><p><em>FIX</em> indicates that the bond is a fixed coupon bond. As such, the bond pays interest at regular intervals and at fixed rates until the bond matures. For example, if the coupon rate is 5.75% and the bond pays two coupons per year, investors will simply recieve two coupon payments per year, where each payment equal to 2.875% (ie. 5.75% / 2) of the bond's par value. If the par value is £100, investors will receive coupon payments of £2.875 twice per year until the bond matures.</p><p><em>FLT</em> indicates that the bond is a floating-rate bond. Interest is typically equal to a money market reference rate (eg. the LIBOR rate) plus a quoted spread (ie. a fixed coupon rate). The quoted spread is typically fixed, but the market reference rate can change over time. As a result, float-rate bonds may pay interest rates that vary over time.</p><p><em>VAR</em> indicates that the bond pays interest at a variable rate. Coupon rates may change over time. For example, step-up bonds fall into this category. Step-up bonds pay coupons where future coupon payments are higher than previous payments.</p><p><em>DSC</em> indicates that the bond is a zero-coupon bond, which does not pay interest and trades at a discount to the bond's par value.</p>",
            field: "basic.CouponClass",
          },
          {
            name: "Coupon Class Description",
            dataSource: DataSource.INSTRUMENTS,
            dataType: "string",
            description:
              "<p>This field indicates whether a bond pays coupons at a fixed, variable or floating rate.</p><p>Fixed Coupon bonds pay interest at regular intervals and at fixed rates until the bond matures. For example, if the coupon rate is 5.75% and the bond pays two coupons per year, investors will simply recieve two coupon payments per year, where each payment equal to 2.875% (ie. 5.75% / 2) of the bond's par value. If the par value is £100, investors will receive coupon payments of £2.875 twice per year until the bond matures.</p><p>Floating-rate bonds pay interest that is typically equal to a money market reference rate (eg. the LIBOR rate) plus a quoted spread (ie. a fixed coupon rate). The quoted spread is typically fixed, but the market reference rate can change over time. As a result, float-rate bonds may pay interest rates that vary over time.</p><p>Variable Coupon bonds pay interest at rates that change over time. For example, step-up bonds fall into this category. Step-up bonds pay coupons where future coupon payments are higher than previous payments.</p><p>Zero Coupon bonds do not pay interest.</p>",
            field: "basic.CouponClassDescription",
          },
          {
            name: "Coupon Currency",
            dataSource: DataSource.INSTRUMENTS,
            dataType: "string",
            description:
              "<p>The currency in which coupon payments are made.</p>",
            field: "basic.CouponCurrency",
          },
          {
            name: "Coupon Payments per Annum",
            dataSource: DataSource.INSTRUMENTS,
            dataType: "int",
            description:
              "<p>The number of coupon payments bond holders recieve per year.</p>",
            field: "basic.CouponFrequency",
          },
          {
            name: "Coupon Rate",
            dataSource: DataSource.INSTRUMENTS,
            dataType: "double",
            description:
              "<p>This is the interest payment that bond holders recieve, expressed as a percentage of the bond's par value. For example, if the coupon rate is 5.75% and the bond pays two coupons per year, investors will simply recieve two coupon payments per year, where each payment equal to 2.875% (ie. 5.75% / 2) of the bond's par value. If the par value is £100, investors will receive coupon payments of £2.875 twice per year until the bond matures.</p>",
            field: "basic.CouponRate",
          },
          {
            name: "Coupon Type Description",
            dataSource: DataSource.INSTRUMENTS,
            dataType: "string",
            description:
              "<p>This field explains what type of coupon each bond pays. For example:</p><p><em>Zero Coupon</em>: Zero Coupon bonds do not pay interest.</p><p><em>Plain Vanilla Fixed Coupon</em>: Fixed Coupon bonds pay interest at regular intervals and at fixed rates until the bond matures. For example, if the coupon rate is 5.75% and the bond pays two coupons per year, investors will simply recieve two coupon payments per year, where each payment equal to 2.875% (ie. 5.75% / 2) of the bond's par value. If the par value is £100, investors will receive coupon payments of £2.875 twice per year until the bond matures.</p><p><em>Step Up / Step Down</em>: Step-up (step-down) bonds pay coupons where future coupon payments are higher (lower) than previous payments.</p><p><em>Floating</em>: Floating-rate bonds pay interest that is typically equal to a money market reference rate (eg. the LIBOR rate) plus a quoted spread (ie. a fixed coupon rate). The quoted spread is typically fixed, but the market reference rate can change over time. As a result, float-rate bonds may pay interest rates that vary over time.</p><p><em>Fixed then Floating</em>: Fixed then Floating bonds pay a fixed coupon for a given period, then pay interest at a variable rate until maturity.</p><p><em>Zero Then Floating</em>: The bond will pay no coupon for a given period, and then pay interest at a variable rate until maturity.</p>",
            field: "basic.CouponTypeDescription",
          },
          {
            name: "Coupon Frequency Description",
            dataSource: DataSource.INSTRUMENTS,
            dataType: "string",
            description:
              "<p>The number of coupon payments bond holders recieve per year.</p>",
            field: "basic.CouponFrequencyDescription",
          },
          {
            name: "Coupon Type",
            dataSource: DataSource.INSTRUMENTS,
            dataType: "string",
            description:
              "<p>This is a four letter code that represents the type of coupon a bond pays. The first two letters of each code indicates whether the coupon rate is fixed, variable or floating. FX indicates that coupon rates are fixed. VR indicates that coupon rates are variable. FR denotes that the bond pays interest at a floating rate. For example, coupon payments may move up or down, usually in line with a money market referene rate (eg. the LIBOR rate).</p>",
            field: "basic.CouponType",
          },
          {
            name: "Coupon Description",
            dataSource: DataSource.INSTRUMENTS,
            dataType: "string",
            description: "<p></p>",
            field: "basic.Description",
          },
          {
            name: "Maturity Date",
            dataSource: DataSource.INSTRUMENTS,
            dataType: "datetime",
            description:
              "<p>The date when the principal amount of a bond is repaid to the investor and coupon payments stop.</p>",
            field: "basic.MaturityDate",
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "Analyst Forecasts",
    description:
      "<p><em>Analyst Forecasts</em> can give a quick impression of where the market thinks a stock is heading. As professional stock analysts often have access to chief executives at meetings and can be some of the first to process new information, it helps to keep track of these changes. Stockopedia publishes financial metrics relating to the latest consensus analyst recommendations, earnings forecasts, upgrades/downgrades and earnings surprises.</p>",
    ratios: [
      {
        name: "Forecast Earnings per Share",
        dataSource: DataSource.SCREENER,
        dataType: "double",
        modifier: "Forecast 1y",
        description:
          "<p>This is the first year fiscal forecast of earnings per share for the company.</p>",
        field: "ratios._FY1EPS_CML",
      },
      {
        name: "Forecast Earnings per Share",
        dataSource: DataSource.SCREENER,
        dataType: "double",
        modifier: "Forecast 2y",
        description:
          "<p>This is the second year fiscal forecast of earnings per share for the company.</p>",
        field: "ratios._FY2EPS_CML",
      },
    ],
    categories: [
      {
        name: "Consensus Analyst Recommendations",
        description:
          "<p><em>Consensus Analyst Recommendations</em> show the average buy or sell recommendation on a stock from the analyst community. Stockopedia publishes these consensus recommendations, the number of buy, outperform, hold, underperform and sell recommendations as well as changes to those recommendations</p>",
        ratios: [
          {
            name: "Broker Consensus Recommendation",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p><strong>Broker Consensus</strong> is the average recommendation of brokers on a 1-5 scale.   Counter intuitively, 1 is scored as a Strong Buy, and 5 as a Strong sell.  3 is a Hold.</p>",
            field: "ratios._ConsRec_C",
          },
          {
            name: "Change in Consensus Recommendation",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1m",
            description:
              "<p>The <strong>Change in Broker Consensus, 1 Month</strong> is the change in the consensus recommendation over the last month.  Counter intuitively, 1 is scored as a Strong Buy, and 5 as a Strong sell.  3 is a Hold.</p>",
            field: "ratios._ConsRec_Change_1MA",
          },
          {
            name: "Change in Consensus Recommendation",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3m",
            description:
              "<p>The <strong>Change in Broker Consensus, 3 Month</strong> is the change in the consensus recommendation over the last 3 months.  Counter intuitively, 1 is scored as a Strong Buy, and 5 as a Strong sell.  3 is a Hold.</p>",
            field: "ratios._ConsRec_Change_3MA",
          },
          {
            name: "No. of Broker Buy Recommendations",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>This is the <strong>Number of Buy Recommendations</strong> by analysts covering the stocks. A Buy Recommendation generally indicates that the analyst expect that the stock should significantly outperform versus other comparable stocks.</p>",
            field: "ratios._BuyRec_C",
          },
          {
            name: "No. of Broker Outperform Recommendations",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              '<p>This is the <strong>Number of Outperform Recommendations</strong> amongst analysts who cover the stocks. An Outperform Recommendation means that a stock is expected to do slightly better than the market return. This is also known as "moderate buy", or "accumulate".</p>',
            field: "ratios._OutPRec_C",
          },
          {
            name: "No. of Broker Hold Recommendations",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              '<p>This is the <strong>Number of Hold Recommendations</strong>. A "Hold" is an analyst\'s opinion that can be interpreted as meaning that a stock should be kept by individuals who already own it, but should not be purchased by those who don\'t already. A hold recommendation is generally better than a Sell Recommendation but worse than a Buy Recommendation. It usually means that the analyst believes the stock will remain fairly stable and neither outperform or underperform the Market. Holds are also referred to as "Neutral" or "Market Perform" recommendations.</p>',
            field: "ratios._HoldRec_C",
          },
          {
            name: "No. of Broker Underperform Recommendations",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>This is the <strong>Number of Underperform Recommendations</strong>. This is an analyst recommendation meaning that the stock is expected to do slightly worse than the market. It is also known as Moderate Sell, or Weak Hold.</p>",
            field: "ratios._UndPRec_C",
          },
          {
            name: "No. of Broker Sell Recommendations",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>This is the <strong>Number of Sell Recommendations</strong> by analysts covering the stocks. A Sell Recommendation generally indicates that the analyst expect that the stock should significantly under perform versus other comparable stocks.</p>",
            field: "ratios._SellRec_C",
          },
        ],
        categories: [],
      },
      {
        name: "Earnings Upgrades",
        description:
          "<p><em>Earnings Upgrades</em> indicators show the amount that consensus analyst forecasts are being upgraded or downgraded over various timeframes. Stockopedia provides current year forecast earnings upgrades (EPS Upgrade FY1), EPS upgrade FY2, the number of upgrades and downgrades, and the EPS upgrade streak.</p>",
        ratios: [
          {
            name: "FY1 EPS Estimate Upgrade",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1m",
            description:
              "<p>The <strong>1 Month EPS Upgrade FY1</strong> shows the percentage EPS upgrade of consensus broker forecasts for FY1 over the past month.</p>",
            field: "ratios._EPSEstvs1MA_FY1",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "FY1 EPS Estimate Upgrade",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3m",
            description:
              "<p>The <strong>3 Month EPS Upgrade FY1</strong> shows the percentage EPS upgrade of consensus broker forecasts for FY1 over the past 3 months.</p>",
            field: "ratios._EPSEstvs3MA_FY1",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "FY2 EPS Estimate Upgrade",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1m",
            description:
              "<p>The <strong>1 Month EPS Upgrade FY2</strong> shows the percentage EPS upgrade of consensus broker forecasts for FY2 over the past month.</p>",
            field: "ratios._EPSEstvs1MA_FY2",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "FY2 EPS Estimate Upgrade",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3m",
            description:
              "<p>The <strong>3 Month EPS Upgrade FY1</strong> shows the percentage EPS upgrade of consensus broker forecasts for FY2 over the past 3 months.</p>",
            field: "ratios._EPSEstvs3MA_FY2",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "No. of FY2 EPS Upgrades",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            modifier: "1w",
            description:
              "<p>The <strong>Number of One Week Upgrades</strong> shows the total count of Earnings Estimate Upgrades from brokers/analysts in the last week. It should be contrasted to EPS Downgrades.</p>",
            field: "ratios._EPSUpGradings1WA_FY2",
          },
          {
            name: "No. of FY2 EPS Upgrades",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            modifier: "1m",
            description:
              "<p>The <strong>Number of One Month Upgrades</strong> shows the total count of Earnings Estimate Upgrades from brokers/analysts in the last month. It should be contrasted to EPS Downgrades.</p>",
            field: "ratios._EPSUpGradings1MA_FY2",
          },
          {
            name: "No. of FY2 EPS Downgrades",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            modifier: "1w",
            description:
              "<p>The <strong>Number of One Week Downgrades</strong> shows the total count of Earnings Estimate Downgrades from brokers/analysts in the last week. It should be contrasted to EPS Downgrades.</p>",
            field: "ratios._EPSDnGradings1WA_FY2",
          },
          {
            name: "No. of FY2 EPS Downgrades",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            modifier: "1m",
            description:
              "<p>The <strong>Number of One Month Downgrades</strong> shows the total count of Earnings Estimate Downgrades from brokers/analysts in the last month. It should be contrasted to EPS Downgrades.</p>",
            field: "ratios._EPSDnGradings1MA_FY2",
          },
          {
            name: "No. of quarters consecutive EPS upgrades",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p>The <strong>EPS Upgrade Streak</strong> shows how many consecutive quarters over the last 15 months (5 quarters) have seen estimate upgrades for the company.</p>",
            field: "ratios._EPS_Upgrade_Streak",
          },
        ],
        categories: [],
      },
      {
        name: "Earnings & Sales Surprises",
        description:
          "<p><em>Earnings & Sales Surprises</em> show the positive or negative amount that a company's reported earnings per share (or sales per share) wer above or below the forecasts from the analyst community. The scaled earnings surprise, which takes into account the range of expectations of analysts.</p>",
        ratios: [
          {
            name: "Earnings Surprise",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>EPS Surprise %, Last Interim</strong>, shows what the percentage EPS surprise was against the last interim report.  If a company releases a number higher or lower than the consensus (a combination of all the released estimates), this is known respectively as a positive or negative surprise.</p>",
            field: "ratios._EPSSurprise_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Earnings  Surprise",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p><strong>EPS Surprise %, Last Year</strong>, shows what the percentage EPS surprise was against the last annual report.  If a company releases a number higher or lower than the consensus (a combination of all the released estimates), this is known respectively as a positive or negative surprise.</p>",
            field: "ratios._EPSSurprise_RA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Sales Surprise",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>Sales Surprise %, Last Interim</strong>, shows what the percentage Sales surprise was against the last interim report.  If a company releases a number higher or lower than the consensus (a combination of all the released estimates), this is known respectively as a positive or negative surprise.</p>",
            field: "ratios._SalesSurprise_RQ",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Sales Surprise",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Last Year",
            description:
              "<p><strong>Sales Surprise %, Last Year</strong>, shows what the percentage Sales surprise was against the last annual report.  If a company releases a number higher or lower than the consensus (a combination of all the released estimates), this is known respectively as a positive or negative surprise.</p>",
            field: "ratios._SalesSurprise_RA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Scaled Earnings Surprise",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p><strong>Scaled Earnings Surprise</strong> is a means of comparing the level of earnings surprise to the amount of disagreement between various analysts (since a wide range of expectations has different implications for what is “unexpected”).</p>",
            field: "ratios._SUE_RQ",
          },
        ],
        categories: [],
      },
      {
        name: "Miscellaneous Analyst Data",
        description:
          "<p>Miscellaneous data that's descriptive of the company's analyst coverage. From the number of brokers, to the number of estimates, as well as long term price and growth forecasts.</p>",
        ratios: [
          {
            name: "No. of Broker Covering",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p><strong>The Number of Brokers</strong> is the number of analysts that are actively covering the company, i.e. that are currently providing a forecast for the next year and contributing to the next year consensus estimate.</p>",
            field: "ratios._EPSEst_NumOfEst_FY2",
          },
          {
            name: "No. of Broker Estimates",
            dataSource: DataSource.SCREENER,
            dataType: "int",
            description:
              "<p><strong>The Number of Estimates</strong> is the number of estimates that are in place for the company.</p>",
            field: "ratios._EPSR_FY1_NumOfEst",
          },
          {
            name: "Discount from Broker Price Target",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p><strong>The Percent of Price Target</strong> is the percentage above or below the broker price target. Price Targets with a non-12M horizon are excluded by Reuters from the consensus price target calculations.</p>",
            field: "ratios._PriceVsTarget%",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Price Target Change",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "1m",
            description:
              "<p>The <strong>1 Month Price Target Change</strong> is the percent change in the broker price target over the past month.</p>",
            field: "ratios._TargetPrice_Chg_1MA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Price Target Change",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            modifier: "3m",
            description:
              "<p>The <strong>1 Month Price Target Change</strong> is the percent change in the broker price target over the past 3 months.</p>",
            field: "ratios._TargetPrice_Chg_3MA",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Long Term Growth Forecast",
            dataSource: DataSource.SCREENER,
            dataType: "double",
            description:
              "<p>The <strong>Long Term Growth Forecast</strong> is the consensus long-term growth rate amongst analysts which cover the company.   It is a data point collated by Thomson Reuters to provide the annualised compound growth rate over the next 3 to 7 year period.</p>",
            field: "ratios._LTG_CM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Target vs. Price Discount",
            dataSource: DataSource.BATCH,
            dataType: "double",
            modifier: "%",
            description:
              '<p>Broker targets and recommendations as to whether clients should buy, sell or hold stocks  are widely reported in the media. Despite this, <a href="http://www.stockopedia.co.uk/content/consensus-recommendation-screen-is-it-worth-listening-to-strong-buys-from-brokers-58358/">the value of these recommendations</a> is hotly debated as broker recommendations have historically exhibited a strong upward bias. Researchers Jegadeesh, Kim, Krische and Lee found that the average analyst rating over the 1985 to 1999 period was close to a buy recommendation and sell or strong sell recommendation made up less than 5% of all recommendations. This is because most sell-side analysts work for brokerage houses which often have strong investment banking franchises, creating a  potential conflict of interest when firms act as investment bankers to the companies their analysts cover.</p><p>Nevertheless, the consensus price target can be a useful benchmark of market sentiment around a given stock - it may also be used as a contrarian signal by value investors.</p>',
            field: "ratios._TargetVsPrice%",
            unit: RatioUnit.PERCENTAGE,
          },
        ],
        categories: [],
      },
    ],
  },
  {
    name: "Aggregates",
    description: "<p></p>",
    ratios: [],
    categories: [
      {
        name: "Aggregate Fields",
        description: "<p></p>",
        ratios: [
          {
            name: "No. of Advancers",
            dataSource: DataSource.ARCHIVES,
            dataType: "int",
            description:
              "<p>This is the number of companies in a given index that have risen during the day.</p>",
            field: "aggregate.technicals.advancers",
          },
          {
            name: "No. of Unchanged",
            dataSource: DataSource.ARCHIVES,
            dataType: "int",
            description:
              "<p>This is the number of companies in a given index that have neither risen or fallen during the day.</p>",
            field: "aggregate.technicals.unchanged_ad",
          },
          {
            name: "No. of Decliners",
            dataSource: DataSource.ARCHIVES,
            dataType: "int",
            description:
              "<p>This is the number of companies in a given index that have fallen during the day.</p>",
            field: "aggregate.technicals.decliners",
          },
          {
            name: "No. of 52-Week Highs",
            dataSource: DataSource.ARCHIVES,
            dataType: "int",
            description:
              "<p>This is the number of companies in a given index that have reached the <strong>highest price</strong> they have traded at during the previous 52 weeks.</p>",
            field: "aggregate.technicals.highs",
          },
          {
            name: "No. of 52-Week Lows",
            dataSource: DataSource.ARCHIVES,
            dataType: "int",
            description:
              "<p>This is the number of companies in a given index that have dropped below the <strong>lowest price</strong> they have traded at during the previous 52 weeks.</p>",
            field: "aggregate.technicals.lows",
          },
          {
            name: "Price to Earnings Ratio",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>This is the median Price to Earnings ratio for all companies in a given stock market index.</p>",
            field: "aggregate.median.ratios._PENormal_TTM",
          },
          {
            name: "Price to Book Value",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "Latest",
            description:
              "<p>This is the median Price to Book ratio for all companies in a given stock market index.</p>",
            field: "aggregate.median.ratios._PBV_RQ",
          },
          {
            name: "Dividend Yield",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>This is the median Dividend Yield for all companies in a given stock market index.</p>",
            field: "aggregate.median.ratios._DPSYield%TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Stockopedia StockRank™",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "Avg",
            description:
              "<p>The <strong>Stockopedia StockRank™</strong> provides a simple but effective system to discover higher expected return shares. It is an equally weighted combination of the ValueRank, QualityRank and MomentumRank. The average StockRank is, of course, the average StockRank for all companies in a given sector, country or index.</p>",
            field: "aggregate.average.ratios._QVMScore_zz",
          },
          {
            name: "Stockopedia Value Rank",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "Avg",
            description:
              "<p>The <strong>Value Rank</strong> is a ranking system based on an equal weighted blend of traditional value ratios including the P/B, P/E, P/S, P/FCF, Earnings Yield and Dividend Yield. The average Value Rank is, of course, the average Value Rank for all companies in a given sector, country or index.</p>",
            field: "aggregate.average.ratios._ValueScore_zz",
          },
          {
            name: "Stockopedia Quality Rank",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "Avg",
            description:
              "<p>The <strong>Quality Rank</strong> is ranking system that aims to find the best quality listed companies.  It is based on a blend of measures that include an assessment of company franchise (profitability, cashflow, margins), company risk (bankruptcy, volatility) and fundamental trend (F-Score). The average Quality Rank is, of course, the average Quality Rank for all companies in a given sector, country or index.</p>",
            field: "aggregate.average.ratios._QualityScore_zz",
          },
          {
            name: "Stockopedia Momentum Rank",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "Avg",
            description:
              "<p>The <strong>Momentum Rank</strong> is a ranking system based on a blend of price momentum (52 week highs, relative strength) and earnings momentum (recommendation upgrades, earnings upgrades and earnings surprise) factors. The average Momentum Rank is, of course, the average Momentum Rank for all companies in a given sector, country or index.</p>",
            field: "aggregate.average.ratios._MomentumScore_zz",
          },
          {
            name: "Price to Earnings Ratio",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "Avg",
            description:
              "<p>This is the <strong>mean (ie. average) Price to Earnings</strong> ratio for all companies in a given sector or stock market index.</p>",
            field: "aggregate.average.ratios._PENormal_TTM",
          },
          {
            name: "Price to Book Value",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "Latest Avg",
            description:
              "<p>This is the <strong>mean (ie. average) Price to Book</strong> ratio for all companies in a given sector or stock market index.</p>",
            field: "aggregate.average.ratios._PBV_RQ",
          },
          {
            name: "Dividend Yield",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "Avg",
            description:
              "<p>This is the <strong>mean (ie. average) Dividend Yield</strong> for all companies in a given sector or stock market index.</p>",
            field: "aggregate.average.ratios._DPSYield%TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Sector Name",
            dataSource: DataSource.GENERAL_INFORMATION,
            dataType: "string",
            description:
              "<p>This is the <strong>Name</strong> of the sector or industry in question.</p>",
            field: "sector.name",
          },
          {
            name: "Earnings per Share Growth",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>This represents the <strong>median</strong> Earnings per Share Growth Rate, as a percentage, for the entire index or sector, over the previous 12 months.</p>",
            field: "aggregate.median.ratios._DilNEPSChg%TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Price to Earnings Ratio",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "Rolling 1y",
            description:
              '<p>This is the <strong>median Rolling Price to Earnings</strong> ratio for all companies in a given sector or stock market index for <strong>all stocks with estimates</strong>. The Rolling P/E ratio divides the price by a forecast earnings number which weights this year and next year\'s earnings forecasts depending on how far a company is through its fiscal year (see <a href="/learn/our-data/how-we-calculate-rolling-forecast-ratios-462848">here</a>).</p>',
            field: "aggregate.median.ratios._PENormal_Ro1",
          },
          {
            name: "Dividend Yield",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "Rolling 1y",
            description:
              '<p>This is the <strong>median rolling dividend yield</strong> for all companies in a given sector or stock market index for <strong>all dividend payers with estimates</strong>. The rolling yield is calculated by dividing the rolling DPS by the price. In turn, the rolling DPS weights this year and next year\'s dividend forecasts depending on how far a company is through its fiscal year (see <a href="/learn/our-data/how-we-calculate-rolling-forecast-ratios-462848">here</a>).</p>',
            field: "aggregate.median.ratios._DPSYield%Ro1",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Earnings per Share Growth",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "Rolling 1y",
            description:
              '<p>This represents the <strong>median</strong> Rolling Earnings per Share Growth Rate, as a percentage, for the entire index or sector, over the next 12 months for <strong>all stocks with estimates</strong>. The rolling forecast earnings weights this year and next year\'s earnings forecasts depending on how far a company is through its fiscal year (see <a href="/learn/our-data/how-we-calculate-rolling-forecast-ratios-462848">here</a>).</p>',
            field: "aggregate.median.ratios._DilNEPSChg%Ro1",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Capital Employed",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "TTM",
            description:
              "<p>This represents the <strong>median</strong> ROCE, as a percentage, for the entire index or sector, over the previous 12 months.</p>",
            field: "aggregate.median.ratios._ROCE%TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Return on Capital Employed",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "TTM Avg",
            description:
              "<p>This represents the <strong>average</strong> ROCE, as a percentage, for the entire index or sector, over the previous 12 months.</p>",
            field: "aggregate.average.ratios._ROCE%TTM",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Relative Strength",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "3m",
            description:
              "<p>This represents the <strong>median</strong> Relative Stength for the entire index or sector, over the previous 3 months.</p>",
            field: "aggregate.median.ratios._RelStrength_3Mo",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Relative Strength",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "3m Avg",
            description:
              "<p>This represents the <strong>average</strong> Relative Stength for the entire index or sector, over the previous 3 months.</p>",
            field: "aggregate.average.ratios._RelStrength_3Mo",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Relative Strength",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "6m",
            description:
              "<p>This represents the <strong>median</strong> Relative Stength for the entire index or sector, over the previous 6 months.</p>",
            field: "aggregate.median.ratios._RelStrength_6Mo",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "Relative Strength",
            dataSource: DataSource.ARCHIVES,
            dataType: "double",
            modifier: "6m Avg",
            description:
              "<p>This represents the <strong>average</strong> Relative Stength for the entire index or sector, over the previous 6 months.</p>",
            field: "aggregate.average.ratios._RelStrength_6Mo",
            unit: RatioUnit.PERCENTAGE,
          },
          {
            name: "No. Neither High nor Low",
            dataSource: DataSource.ARCHIVES,
            dataType: "int",
            description:
              "<p>This is the number of companies in a given index are neither at a high or low price.</p>",
            field: "aggregate.technicals.unchanged_hl",
          },
          {
            name: "No. Above 200dma",
            dataSource: DataSource.ARCHIVES,
            dataType: "int",
            description:
              "<p>This is the number of companies in a given index whose price is above their 200 day moving average.</p>",
            field: "aggregate.technicals.above",
          },
          {
            name: "No. Below 200dma",
            dataSource: DataSource.ARCHIVES,
            dataType: "int",
            description:
              "<p>This is the number of companies in a given index whose price is below their 200 day moving average.</p>",
            field: "aggregate.technicals.below",
          },
          {
            name: "No. At 200dma",
            dataSource: DataSource.ARCHIVES,
            dataType: "int",
            description:
              "<p>This is the number of companies in a given index whose price is at their 200 day moving average.</p>",
            field: "aggregate.technicals.unchanged_ma",
          },
        ],
        categories: [],
      },
    ],
  },
];
