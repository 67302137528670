import { EntityListManger } from "@stockopedia/cms-ef";
import { Loading } from "@stockopedia/cms-ui";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { enumKeys } from "shared/utils";
import { urls } from "urls";

import { TemplateStatus } from "../services/model";

export const ListView = () => {
  const history = useHistory();
  return (
    <EntityListManger
      columns={[
        {
          key: "title",
          title: "Title",
          projection: (value) => value.title,
        },
        {
          key: "numArticles",
          title: "Number of Articles",
          projection: (value) => value.numArticles,
        },
        {
          key: "blueprint",
          title: "Blueprint",
          projection: (value) => (
            <Link
              to={`${urls.cybertorial.authors.root.url}/${value.blueprint!.id}`}
            >
              {value.blueprint.name}
            </Link>
          ),
        },
        {
          key: "updatedAt",
          title: "Updated At",
          projection: (value) => value.updatedAt,
        },
      ]}
      onError={() => {}}
      name="templates"
      loadingView={() => <Loading />}
      link={(item) => `${urls.cybertorial.templates.root.url}/${item.id}`}
      onCreate={() => history.push(`${urls.cybertorial.templates.create.url}`)}
      filters={[
        {
          field: "title",
          type: "search",
        },
        {
          field: "status",
          type: "select",
          options: enumKeys(TemplateStatus),
        },
        {
          field: "authorLastName",
          type: "search",
        },
        {
          field: "authorFirstName",
          type: "search",
        },
      ]}
      sort={{
        fields: ["title", "updatedAt"],
        default: {
          field: "updatedAt",
          direction: "desc",
        },
      }}
    />
  );
};
