import queryString from 'query-string'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Padding } from '../../padding'
import { usePaginationContext } from './context'

interface ItemTextProps {
  active?: boolean
}

const ItemText = styled.span<ItemTextProps>`
  color: ${(props) =>
    props.active ? 'var(--color-primary)' : 'var(--color-dark-text)'};
`

const Item = ({
  children,
  path,
  search,
  page,
  isCurrent
}: {
  children: React.ReactNode
  search: object
  path: string
  isCurrent: boolean
  page: number
}) => {
  return (
    <Padding size='sm' right>
      <Link
        to={{
          pathname: path,
          search: queryString.stringify({ ...search, page: page })
        }}
      >
        <ItemText active={isCurrent}>{children}</ItemText>
      </Link>
    </Padding>
  )
}

const arrayBetween = (start: number, end: number) => {
  const result: number[] = []

  for (var i = start; i <= end; i++) {
    result.push(i)
  }

  return result
}

const calcAround = (page: number, last: number): number[] => {
  if (page > 3 || page < last - 3) {
    return arrayBetween(page - 3, page + 3)
  } else if (page >= last - 3) {
    return arrayBetween(last - 6, last)
  }
  return arrayBetween(page, page + 6)
}

const Container = styled.div`
  display: inline-block;
  width: 260px;
  display: flex;
  padding-top: var(--padding-standard);
  padding-bottom: var(--padding-standard);
`

export const Pagination = () => {
  const { path, search, last, page } = usePaginationContext()

  const around = calcAround(page, last)
    .filter((x) => x >= 0 && x <= last)
    .slice(0, last)
  const showFirst = !around.some((x) => x + 1 === 1)
  const showLast = !around.some((x) => x + 1 === last)
  const hasNext = page + 1 < last
  const hasPrevious = page - 1 >= 0

  return (
    <Container>
      <Padding size='sm' all={false} right>
        <Link
          to={{
            pathname: path,
            search: queryString.stringify({
              ...search,
              page: hasPrevious ? page - 1 : 0
            })
          }}
          className='text-xs px-2 font-medium cursor-pointer text-gray-600 hover:text-gray-800'
        >
          <ItemText>Prev</ItemText>
        </Link>
      </Padding>
      {showFirst && (
        <Item page={0} isCurrent={page === 0} path={path} search={search}>
          {1}
        </Item>
      )}
      {showFirst && (
        <Padding size='sm' all={false} right>
          <span>...</span>
        </Padding>
      )}
      {around.map((item) => (
        <Item
          key={item}
          page={item}
          isCurrent={item === page}
          path={path}
          search={search}
        >
          {item + 1}
        </Item>
      ))}
      {showLast && (
        <Padding size='sm' all={false} right>
          <span>...</span>
        </Padding>
      )}
      {showLast && (
        <Item
          page={last + 1}
          isCurrent={last === page}
          path={path}
          search={search}
        >
          {last + 1}
        </Item>
      )}
      <Padding size='sm' all={false} right>
        <Link
          to={{
            pathname: path,
            search: queryString.stringify({
              ...search,
              page: hasNext ? page + 1 : page
            })
          }}
          className='text-xs font-medium cursor-pointer text-gray-600 hover:text-gray-800'
        >
          <ItemText>Next</ItemText>
        </Link>
      </Padding>
    </Container>
  )
}
