import { Direction } from "./types";
type StandardEnum<T> = {
  [id: string]: T | string;
  [nu: number]: string;
};

export function formatDate(date: Date) {
  if (!(date instanceof Date)) {
    return date;
  }
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const strMinutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + strMinutes + " " + ampm;
  return (
    date.getMonth() +
    1 +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear() +
    "  " +
    strTime
  );
}

export const enumKeys = (subject: StandardEnum<unknown>) =>
  Object.keys(subject).filter((k) => !(parseInt(k, 10) >= 0));

type SortDirection = "DESC" | "ASC";
interface SortResult {
  by: string;
  direction: SortDirection;
}
export const makeSortParams = (sort: {
  field: string;
  direction: Direction;
}): SortResult | null => {
  if (sort?.field && sort?.direction) {
    return {
      by: sort.field,
      direction: sort.direction?.toUpperCase() as SortDirection,
    };
  }
  return null;
};

export const gqlResult = async <T>(
  fn: () => Promise<{ data: T; errors?: any }>,
): Promise<{ data?: T; errors?: Error[] }> => {
  try {
    const result = await fn();
    if (result.errors) {
      return {
        errors: result.errors,
      };
    }

    return result;
  } catch (e) {
    return {
      errors: (e as any)?.errors ?? [e],
    };
  }
};

export const formatNumber = (val: number) => {
  return val
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
