import React from "react";
import { SelectionTypeContext } from "../hooks";
import { AssetManagerContext } from "../hooks/use-asset-manager";
import {
  Asset,
  IAssetManagerService,
  AssetSelectionType,
  AssetEditMode,
} from "../models";
import { createAssetManagerState } from "../state";
import { compareAssets } from "../utils/compare-assets";
import { AssetBrowser } from "./asset-browser";
import { Paper } from "./lib/paper";

interface Options {
  title?: string;
  assetManagerService: IAssetManagerService;
  selectionType?: AssetSelectionType;
  clickToEdit?: boolean;
  showSelected?: boolean;
}

interface Props {
  name?: string;
  value: Array<Asset | string>;
  onChange: (assets: string[]) => void;
  options: Options;
}

const AssetManager_: React.FC<Props> = ({
  value,
  onChange,
  options: {
    title,
    assetManagerService,
    selectionType = AssetSelectionType.Multiple,
    clickToEdit = false,
    showSelected = true,
  },
}) => (
  <AssetManagerContext.Provider
    value={createAssetManagerState(assetManagerService, {
      selectedAssets: value,
      showingSelected: showSelected,
      editMode: clickToEdit ? AssetEditMode.Viewing : AssetEditMode.Editing,
    })}
  >
    <SelectionTypeContext.Provider value={selectionType}>
      <Paper>
        <AssetBrowser title={title} onSelectAssets={onChange} />
      </Paper>
    </SelectionTypeContext.Provider>
  </AssetManagerContext.Provider>
);

export const AssetManager = React.memo(AssetManager_, (prev, next) =>
  prev.value.every((val, index) => compareAssets(val, next.value[index])),
);
