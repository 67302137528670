import {
  Asset,
  IAssetManagerService,
  TagGroup,
  TagValue,
} from "@stockopedia/cms-am";
import { StockopediaRestClient } from "clients/stockopedia-rest";

export class AssetManagerService implements IAssetManagerService {
  constructor(private httpClient: StockopediaRestClient, baseUrl: string) {}

  async searchAssets(term: string): Promise<Asset[]> {
    if (term.trim().length === 0) {
      return [];
    }

    const { data } = await this.httpClient.get<Asset[]>(
      "/assets/search/" + encodeURI(term),
    );
    return data;
  }

  async loadTagGroups(): Promise<TagGroup[]> {
    const { data } = await this.httpClient.get<TagGroup[]>(`/tags/groups`);
    return data;
  }

  async loadTagValues(group: string): Promise<TagValue[]> {
    const { data } = await this.httpClient.get(`/tags/values/${group}`);
    return data;
  }

  async loadAssetTagValues(asset: Asset): Promise<TagValue[]> {
    const { data } = await this.httpClient.get(`/assets/tags/${asset.id}`);
    return data;
  }

  async loadAllTagValues(): Promise<TagValue[]> {
    const { data } = await this.httpClient.get(`/tags/values`);
    return data;
  }

  async addTagGroup(group: string): Promise<void> {
    await this.httpClient.post(`/tags/group`, { group });
  }

  async addTagValue(group: string, value: string): Promise<void> {
    await this.httpClient.post(`/tags/value`, { group, value });
  }

  async getAssetsByTags(tags: TagValue[]): Promise<Asset[]> {
    const tagsQuery = tags
      .reduce(
        (items, tag) => [...items, `tags[]=${tag.group}:${tag.value}`],
        [] as string[],
      )
      .join("&");

    const { data } = await this.httpClient.get(`/assets/by?${tagsQuery}`);
    return data;
  }

  async uploadFile(
    file: File,
    onProgress: (progress: number) => void,
    onComplete: (asset: Asset) => void,
    onError: (e: Error) => void,
  ) {
    const fd = new FormData();
    fd.append("file", file, file.name);

    try {
      let { data } = await this.httpClient.post(`/assets/upload`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );

          onProgress(progress);
        },
      });

      onComplete(data);
    } catch (e) {
      onError(e as any);
    }
  }

  async tagAsset(asset: Asset, tags: TagValue[]) {
    await this.httpClient.post(`/assets/tag`, {
      assetId: asset.id,
      tags,
    });
  }

  async updateAssetName(asset: Asset, newName: string) {
    await this.httpClient.post(`/assets/rename`, {
      assetId: asset.id,
      newName,
    });
  }
}
