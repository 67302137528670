import {
  EntityContextProvider,
  EntityServiceProvider,
} from "@stockopedia/cms-ef";
import { Container } from "framework";
import { Module } from "framework/types";
import { urls } from "urls";

import { ArticleService } from "./services/articles.service";
import { CreateArticleView, EditArticleView } from "./views/crud.view";
import { ListArticles } from "./views/list.view";

export const ARTICLES_MODULE_TOKEN = "articles";

export const articlesModule = (c: Container): Module => ({
  id: ARTICLES_MODULE_TOKEN,
  rootComponent: ({ children }) => (
    <EntityServiceProvider service={c.resolve(ArticleService)}>
      <EntityContextProvider basePath={urls.cybertorial.BASE_PATH.url}>
        {children}
      </EntityContextProvider>
    </EntityServiceProvider>
  ),
  pages: {
    [urls.cybertorial.articles.create.url]: CreateArticleView,
    [urls.cybertorial.articles.edit.url]: EditArticleView,
    [urls.cybertorial.articles.root.url]: ListArticles,
  },
});
