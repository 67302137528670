import React, { useEffect, useState } from "react";
import { useAssetManager } from "../hooks";
import {
  SearchResultItem,
  SearchResultValue,
} from "./lib/auto-complete-search";
import { TagsSelector } from "./lib/tags-selector";
import { searchTags } from "../utils";
import { NewTagCreator } from "./new-tag-creator";

const CREATE_NEW_TAG_ITEM_GROUP_NAME = "Create new";

export const UploadTagsSelector: React.FC = () => {
  const allTagValues = useAssetManager((s) => s.allTagValues);
  const loadAllTagValuesStatus = useAssetManager(
    (s) => s.loadAllTagValuesStatus,
  );
  const loadAllTagValues = useAssetManager((s) => s.loadAllTagValues);
  const selectTag = useAssetManager((s) => s.selectTag);
  const deselectTag = useAssetManager((s) => s.deselectTag);
  const selectedTags = useAssetManager((s) => s.selectedTags);
  const deselectTags = useAssetManager((s) => s.deselectTags);

  const [isCreatingTag, setCreatingTag] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (loadAllTagValuesStatus === null) {
      loadAllTagValues();
    }
  }, [loadAllTagValuesStatus]);

  const handleSearch = async (term: string): Promise<SearchResultValue[]> => {
    setSearchTerm(term);
    const results = searchTags(term, allTagValues!);

    if (!!results.find((result) => result.item.value === term)) {
      return results;
    }

    return [
      ...results,
      {
        index: results.length,
        item: {
          value: `Create tag '${term}'`,
          group: CREATE_NEW_TAG_ITEM_GROUP_NAME,
        },
      },
    ];
  };

  const handleSelectTag = (tag: SearchResultItem) => {
    if (tag.group === CREATE_NEW_TAG_ITEM_GROUP_NAME) {
      return setCreatingTag(true);
    }

    selectTag(tag);
  };

  if (isCreatingTag) {
    return (
      <NewTagCreator
        tagName={searchTerm}
        onCreated={() => setCreatingTag(false)}
      />
    );
  }

  return (
    <TagsSelector
      loadTagValuesStatus={loadAllTagValuesStatus}
      handleSearch={handleSearch}
      selectedTags={selectedTags}
      onSelectTag={handleSelectTag}
      onDeselectTag={deselectTag}
      onClearAll={deselectTags}
    />
  );
};
