import { IMapper, autoMapper } from "@stockopedia/typed-mapper";
import { StockopediaClient } from "clients/stockopedia";
import { gqlResult } from "shared/utils";

import { BatchRegion, ControlPlaneData } from "../state/types";

interface DashboardResult {
  throughputs: {
    maxArticlesPerDay: number;
    publishingSpreadHours: number;
    region: BatchRegion;
  }[];
  adminSettings: {
    daysBetweenArticles: number;
  };
}

const DASHBOARD_QUERY = `
query GetDashboardData {
  throughputs {
    maxArticlesPerDay
    publishingSpreadHours
    region
  }
  adminSettings {
    daysBetweenArticles
  }
}
`;

const UPDATE_THROUGHPUT_RATES = `
  mutation UpdateThroughoutRates($dailyLimit: Int!, $publishingWindow: Float!, $region: BatchRegion!) {
    upsertThroughput(maxArticlesPerDay: $dailyLimit, publishingSpreadHours: $publishingWindow, region: $region) {
      maxArticlesPerDay
      publishingSpreadHours
      region
    }
  }
`;

const UPDATE_SETTINGS = `
  mutation UpsertAdminSettingsUpsertAdminSettings($daysBetweenArticles: Int!) {
    upsertAdminSettings(daysBetweenArticles: $daysBetweenArticles) {
      daysBetweenArticles
    }
  }
`;

export class CybertorialControlPlaneService {
  private readonly mapper: IMapper<DashboardResult, ControlPlaneData>;

  constructor(private readonly apiClient: StockopediaClient) {
    this.mapper = autoMapper
      .createMap<DashboardResult, ControlPlaneData>(ControlPlaneData)
      .forMember("throughputs", (opt) =>
        opt.mapFrom((x) =>
          x.throughputs.map((x) => ({
            dailyArticles: x.maxArticlesPerDay,
            publishingWindow: x.publishingSpreadHours,
            region: x.region,
          })),
        ),
      )
      .forMember("adminSettings", (opt) => opt.mapFrom((x) => x.adminSettings));
  }

  async getDashboardData(): Promise<ControlPlaneData> {
    const { data, errors } = await gqlResult<DashboardResult>(() =>
      this.apiClient.query<DashboardResult>({
        query: DASHBOARD_QUERY,
      }),
    );

    if (errors?.length) {
      throw new Error("Unable to fetch dasboard data");
    }

    return this.mapper.map(data!);
  }

  async updateThroughput(
    dailyLimit: number,
    publishingWindow: number,
    region: BatchRegion,
  ): Promise<void> {
    const { errors } = await gqlResult<void>(() =>
      this.apiClient.query<void>({
        query: UPDATE_THROUGHPUT_RATES,
        variables: {
          publishingWindow,
          dailyLimit,
          region,
        },
      }),
    );

    if (errors?.length) {
      throw new Error("Unable to fetch dasboard data");
    }
  }

  async updateSettings(daysBetweenArticles: number): Promise<void> {
    const { errors } = await gqlResult<void>(() =>
      this.apiClient.query<void>({
        query: UPDATE_SETTINGS,
        variables: {
          daysBetweenArticles,
        },
      }),
    );

    if (errors?.length) {
      throw new Error("Unable to fetch dasboard data");
    }
  }
}
