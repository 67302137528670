import { StockopediaClient } from "clients/stockopedia";
import { Container } from "framework";

import { AuthorsService } from "../authors";
import { articleMapper } from "../mappers";
import { TemplateService } from "../templates";
import { ARTICLES_MODULE_TOKEN, articlesModule } from "./module";
import { ArticleService } from "./services/articles.service";

declare module "framework/container" {
  interface Container {
    registerArticlesModule(): Container;
  }
}

Container.prototype.registerArticlesModule = function () {
  this.registerFactorySingleton(
    ArticleService,
    (c) =>
      new ArticleService(
        c.resolve(StockopediaClient),
        c.resolve(AuthorsService),
        c.resolve(TemplateService),
        articleMapper,
      ),
  ).registerModule(ARTICLES_MODULE_TOKEN, articlesModule);

  return this;
};
