import React from 'react'
import {
  faCaretDown,
  faCaretUp,
  faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Column } from '../column'
import styled from 'styled-components'
import { Padding } from '../padding'

interface Item {
  [key: string]: any
}

interface Column<T> {
  title: string
  key: keyof T
  projection: (values: any) => JSX.Element | string
}

interface Row {
  [key: string]: JSX.Element | string
}

interface TableProps<T> {
  columns: Column<T>[]
  sort?: {
    active?: string
    columns: string[]
    direction: 'asc' | 'desc'
  }
  onSort?: (column: string, direction: 'asc' | 'desc') => void
  rows: Row[]
  link: (item: Item) => string
}

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Lato !important;

  & thead tr th {
    background-color: rgb(243, 243, 244);
    height: 36px;
    padding: 0px 25px;
    font-size: 13px;
  }

  & thead tr th p {
    width: fit-content;
    position: relative;
    padding-right: 14px;
  }

  & tr td {
    font-size: 1.3rem;
    padding: 0px 25px;
    height: 63px;
  }

  & tr {
    border-bottom: 1px solid rgb(241, 241, 242);
  }

  & tr:last-child {
    border-bottom: none;
  }

  & tr:hover {
    background: rgb(247, 248, 248);
  }
`

const ExpandButton = styled(FontAwesomeIcon)`
  background: transparent;
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--color-faded-text);

  &:hover {
    color: var(--color-primary-dark);
  }
`

const CarretButton = styled(FontAwesomeIcon)`
  cursor: pointer;
`

const IconTh = styled.th`
  width: 30px;
`

const invertDirection = (direction: 'asc' | 'desc') => {
  switch (direction) {
    case 'asc':
      return 'desc'
    default:
      return 'asc'
  }
}

export function Table<T>({
  sort,
  onSort = () => {},
  columns,
  rows,
  link
}: TableProps<T>) {
  if (!columns || !rows || !rows.length) {
    return (
      <Padding left right bottom>
        No data to show
      </Padding>
    )
  }
  return (
    <StyledTable>
      <thead>
        <tr>
          {columns.map((x) => {
            const handler = sort?.columns.find((c) => c === x.key)
              ? () => onSort(x.key as string, invertDirection(sort?.direction))
              : () => {}
            return (
              <th key={x.key as string} onClick={handler}>
                <p>
                  {x.title}{' '}
                  {sort?.active === x.key && (
                    <CarretButton
                      icon={
                        sort?.direction === 'desc' ? faCaretDown : faCaretUp
                      }
                      onClick={() => {}}
                    />
                  )}
                </p>
              </th>
            )
          })}
          <IconTh />
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => {
          return (
            <tr key={index}>
              {columns.map((column, colIndex) => {
                return (
                  <td key={colIndex}>
                    <p>{column.projection(row)}</p>
                  </td>
                )
              })}
              <td>
                <a href={link(row)}>
                  <ExpandButton icon={faExternalLinkAlt} />
                </a>
              </td>
            </tr>
          )
        })}
      </tbody>
    </StyledTable>
  )
}
