import { StockopediaClient } from "clients/stockopedia";
import { Container } from "framework";

import {
  CYBERTORIAL_CONTROL_PLANE_MODULE,
  cybertorialControlPlaneModule,
} from "./module";
import { CybertorialControlPlaneService } from "./services/control-plane.service";

declare module "framework/container" {
  interface Container {
    registerCybertorialControlPlane(): Container;
  }
}

Container.prototype.registerCybertorialControlPlane = function () {
  this.registerFactorySingleton(
    CybertorialControlPlaneService,
    (c) => new CybertorialControlPlaneService(c.resolve(StockopediaClient)),
  ).registerModule(
    CYBERTORIAL_CONTROL_PLANE_MODULE,
    cybertorialControlPlaneModule,
  );

  return this;
};
