import { resolveDependency } from "framework/hooks";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { RatiosClient } from "./client";
import { Category, Ratio, Category as RatioCategory } from "./types";

interface Context {
  ratios?: RatioCategory[];
  showPicker: boolean;
  search?: string;
  searchedRatios?: Ratio[];
  selected?: Ratio;
  actions: {
    setSearch: (val: string) => void;
    showModal: (val: boolean) => void;
    onSelected: (val: Ratio) => void;
    setSelected: (val?: Ratio) => void;
  };
}

const RatiosEditorContext = createContext<Context>({} as Context);

export const useRatioPickerContext = () => useContext(RatiosEditorContext);

interface ProviderProps {
  onSelected: (val: Ratio) => void;
  ratios?: RatioCategory[];
  children: React.ReactNode;
}

interface FetcherProps {
  children: (ratios?: RatioCategory[]) => JSX.Element;
  filter?: (ratio: Ratio) => boolean;
}

export const RatiosFetcher = ({
  children,
  filter = () => true,
}: FetcherProps) => {
  const [ratios, setRatios] = useState<RatioCategory[]>();

  useEffect(() => {
    if (!ratios?.length) {
      const client = resolveDependency(RatiosClient);
      client.getRatios().then((res) => {
        const mapCategory = (category: Category): Category => ({
          ...category,
          ratios: category.ratios?.filter(filter),
          categories: category.categories?.flatMap(mapCategory),
        });
        setRatios(res.map(mapCategory));
      });
    }
  }, [filter, ratios?.length]);

  return children(ratios);
};

export const RatioPickerContext = ({
  children,
  onSelected,
  ratios,
}: ProviderProps) => {
  const [search, setSearch] = useState<string>();
  const [searchedRatios, setSearchedRatios] = useState<Ratio[]>();
  const [showRatios, setShowRatios] = useState(false);
  const [selected, setSelected] = useState<Ratio>();

  useEffect(() => {
    if (search?.length) {
      const res = ratios
        ?.flatMap(function f(x) {
          let res: Ratio[] = [];
          if (x.categories) {
            res = res.concat(x.categories.flatMap(f));
          }
          if (x.ratios) {
            res = res.concat(x.ratios);
          }

          return res;
        })
        .filter(
          (x) =>
            x.name.toLowerCase().includes(search.toLowerCase()) ||
            x.field.toLowerCase().includes(search.toLowerCase()),
        );
      setSearchedRatios(res);
    } else {
      setSearchedRatios(undefined);
    }
  }, [search, ratios]);

  const state = useMemo(
    () => ({
      showPicker: showRatios,
      search,
      actions: {
        setSearch,
        showModal: setShowRatios,
        onSelected,
        setSelected,
      },
      ratios,
      searchedRatios,
      selected,
    }),
    [
      ratios,
      showRatios,
      setShowRatios,
      search,
      setSearch,
      searchedRatios,
      onSelected,
      selected,
    ],
  );

  return (
    <RatiosEditorContext.Provider value={state}>
      {children}
    </RatiosEditorContext.Provider>
  );
};
