import { Button, Loading } from "@stockopedia/cms-ui";
import React from "react";
import styled from "styled-components";
import { useSelectionType } from "../hooks";

import { LoadingState, AssetSelectionType } from "../models";
import { useAssetManager } from "../hooks";

import { AssetTile } from "./asset-tile";
import { FileDropper } from "./file-dropper";
import { ErrorText } from "./lib/error";
import { Spacer } from "./lib/spacer";

const Container = styled.div`
  padding: calc(var(--padding-xs) * 5);
`;

const InnerContainer = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Fill = styled.div`
  flex-grow: 1;
`;

const ToolsContainer = styled.div`
  display: flex;
`;

const ShowHideSelected = styled.a`
  align-self: flex-end;
`;

interface Props {
  onSelectAssets: (assets: string[]) => void;
}

export const FileView: React.FC<Props> = ({ onSelectAssets }) => {
  const assets = useAssetManager((s) => s.browserAssets);
  const loadAssetsStatus = useAssetManager((s) => s.loadAssetsStatus);
  const selectedAssets = useAssetManager((s) => s.selectedAssets);
  const showingSelected = useAssetManager((s) => s.showingSelected);
  const setShowingSelected = useAssetManager((s) => s.setShowingSelected);

  const selectionType = useSelectionType();

  const handleChooseClick = () =>
    onSelectAssets(
      selectedAssets.map((asset) =>
        typeof asset === "string" ? asset : asset.url,
      ),
    );

  return (
    <Container>
      <InnerContainer>
        {loadAssetsStatus === LoadingState.Loading && <Loading />}

        {loadAssetsStatus === LoadingState.Error && (
          <ErrorText>There was an issue loading the assets</ErrorText>
        )}

        {loadAssetsStatus === LoadingState.Success && (
          <React.Fragment>
            <Grid>
              {assets.map((asset) => (
                <AssetTile key={asset.id} asset={asset} />
              ))}
            </Grid>
          </React.Fragment>
        )}
      </InnerContainer>

      <Spacer />

      <ToolsContainer>
        {selectedAssets.length > 0 && (
          <ShowHideSelected
            onClick={() => setShowingSelected(!showingSelected)}
          >
            {showingSelected ? "Hide selected" : "Show selected"}
          </ShowHideSelected>
        )}

        <Fill />

        <FileDropper />

        <Spacer />

        {selectionType !== AssetSelectionType.None && (
          <Button
            color="primary"
            disabled={selectedAssets.length === 0}
            onClick={handleChooseClick}
          >
            Choose{" "}
            {selectionType === AssetSelectionType.Multiple &&
            selectedAssets.length
              ? `(${selectedAssets.length} Selected)`
              : ""}
          </Button>
        )}
      </ToolsContainer>
    </Container>
  );
};
