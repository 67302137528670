import React from "react";
import styled from "styled-components";

interface Props {
  progress: number;
}

const Bar = styled.div`
  background-color: #32a852;
  padding: 2px;
`;

const ProgressText = styled.span`
  color: white;
`;

export const ProgressBar: React.FC<Props> = ({ progress }) => (
  <Bar style={{ width: `${progress}%` }}>
    <ProgressText>{progress}%</ProgressText>
  </Bar>
);
