import { AssetManager, AssetSelectionType } from "@stockopedia/cms-am";
import { container } from "framework";
import React from "react";
import { AssetManagerService } from "./services/assetManagerService";

export const Assets: React.FC = () => (
  <AssetManager
    value={[]}
    onChange={() => {}}
    options={{
      title: "Asset Manager",
      assetManagerService: container.resolve(AssetManagerService),
      selectionType: AssetSelectionType.Multiple,
    }}
  />
);
