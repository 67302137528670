import { InputText } from '@buffetjs/core'
import React from 'react'

interface Props {
  value: string
  name: string
}

export const ReadonlyTextInput = ({ value, name }: Props) => {
  return <InputText name={name} disabled type='text' value={value} />
}

export const ConfigurableReadonlyTextInput = (props: Partial<Props>) => (
  <ReadonlyTextInput {...(props as any)} />
)
