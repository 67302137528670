import React, { useEffect } from "react";
import styled from "styled-components";
import { Text } from "@buffetjs/core";
import { Remove } from "@buffetjs/icons";

import { Paper } from "./lib/paper";
import { useAssetManager } from "../hooks";
import { createThumbnailClass } from "./lib/thumbnail";
import { TagsSelector } from "./lib/tags-selector";
import { searchTags } from "../utils";

const Overlay = styled.div`
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const Container = styled.div`
  margin: 20px;
  min-height: calc(100% - 40px);
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Thumbnail = createThumbnailClass("100%", "200px");

export const AssetTagsManager: React.FC = () => {
  const asset = useAssetManager((s) => s.edittingAsset);
  const editAsset = useAssetManager((s) => s.editAsset);

  const assetTagValues = useAssetManager((s) => s.assetTagValues);
  const loadAssetTagValuesStatus = useAssetManager(
    (s) => s.loadAssetTagValuesStatus,
  );
  const loadAssetTagValues = useAssetManager((s) => s.loadAssetTagValues);

  const allTagValues = useAssetManager((s) => s.allTagValues);
  const loadAllTagValuesStatus = useAssetManager(
    (s) => s.loadAllTagValuesStatus,
  );
  const loadAllTagValues = useAssetManager((s) => s.loadAllTagValues);

  const selectAssetTag = useAssetManager((s) => s.selectAssetTag);
  const deselectAssetTag = useAssetManager((s) => s.deselectAssetTag);
  const clearAllAssetTags = useAssetManager((s) => s.clearAllAssetTags);

  useEffect(() => {
    if (loadAssetTagValuesStatus === null) {
      loadAssetTagValues();
    }

    if (loadAllTagValuesStatus === null) {
      loadAllTagValues();
    }
  }, [loadAssetTagValuesStatus, loadAllTagValuesStatus]);

  const handleSearch = async (term: string) => searchTags(term, allTagValues!);
  return (
    <Overlay>
      <Container>
        <Paper style={{ height: "100%" }}>
          <TopContainer>
            <Text fontSize="lg" fontWeight="bold">
              Manage Tags
            </Text>

            <a onClick={() => editAsset(null)}>
              <Remove />
            </a>
          </TopContainer>

          <Thumbnail style={{ backgroundImage: `url(${asset?.url})` }} />

          <TagsSelector
            handleSearch={handleSearch}
            loadTagValuesStatus={loadAssetTagValuesStatus}
            selectedTags={assetTagValues!}
            onSelectTag={selectAssetTag}
            onDeselectTag={deselectAssetTag}
            onClearAll={clearAllAssetTags}
          />
        </Paper>
      </Container>
    </Overlay>
  );
};
