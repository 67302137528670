import { EntityListManger } from "@stockopedia/cms-ef";
import { Loading } from "@stockopedia/cms-ui";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { enumKeys } from "shared/utils";
import { urls } from "urls";

import { BlueprintStatus } from "../services/model";

export const ListBlueprints = () => {
  const history = useHistory();
  return (
    <EntityListManger
      columns={[
        {
          key: "name",
          title: "Name",
          projection: (value) => value.name,
        },
        {
          key: "updatedAt",
          title: "Updated At",
          projection: (value) => value.updatedAt,
        },
        {
          key: "description",
          title: "Description",
          projection: (value) => value.description,
        },
        {
          key: "theme",
          title: "Theme",
          projection: (value) => (
            <Link to={`${urls.cybertorial.themes.root.url}/${value.theme.id}`}>
              {value.theme.title}
            </Link>
          ),
        },
        {
          key: "numTemplates",
          title: "Number of Templates",
          projection: (value) => value.numTemplates,
        },
        {
          key: "autoPublish",
          title: "Auto Publish",
          projection: (value) => value.autoPublish.toString(),
        },
      ]}
      onError={() => {}}
      name="blueprints"
      loadingView={() => <Loading />}
      link={(item) => `${urls.cybertorial.blueprints.root.url}/${item.id}`}
      onCreate={() => history.push(`${urls.cybertorial.blueprints.create.url}`)}
      filters={[
        {
          field: "name",
          type: "search",
        },
        {
          field: "autoPublish",
          type: "select",
          operator: "equals",
          options: ["true", "false"],
        },
        {
          field: "status",
          operator: "equals",
          type: "select",
          options: enumKeys(BlueprintStatus),
        },
      ]}
      sort={{
        fields: ["name", "updatedAt"],
        default: {
          field: "updatedAt",
          direction: "desc",
        },
      }}
    />
  );
};
