import "modules/cybertorial/control-plane/container";

import { Container } from "framework";
import { AssetManagerService } from "./services/assetManagerService";
import { env } from "env";
import { StockopediaRestClient } from "clients/stockopedia-rest";

declare module "framework/container" {
  interface Container {
    registerAssetManager(): Container;
  }
}

Container.prototype.registerAssetManager = function () {
  this.registerFactorySingleton(
    AssetManagerService,
    (c) =>
      new AssetManagerService(
        c.resolve(StockopediaRestClient),
        env.assets_host,
      ),
  );

  return this;
};
