import CodeIcon from "@material-ui/icons/Code";
import { Modal } from "@stockopedia/cms-ui";
import {
  Editor,
  FullscreenButton,
  generateSearchDecorator,
  useToolbarContext,
} from "@stockopedia/rte";
import {
  RatioPickerContext,
  RatiosFetcher,
  useRatioPickerContext,
} from "shared/components/ratio-picker/context";
import { RatiosDisplay } from "shared/components/ratio-picker/picker";
import styled from "styled-components";

const Container = styled.div`
  border: 1px solid #e3e9f3;
  border-radius: 2px;
  padding: 20px;

  &:focus {
    border-color: #78caff;
  }
  & svg {
    font-size: 2rem;
  }
`;
const Highlight = styled.span`
  background: yellow;
`;

const InsertRatioButtonWrapper = ({ children }) => {
  const { actions: editorActions, state } = useToolbarContext();
  return (
    <RatiosFetcher>
      {(ratios) => {
        return (
          <RatioPickerContext
            onSelected={(ratio) =>
              editorActions.insertData(
                "RATIO",
                state,
                {},
                `{{ratio ${ratio.field}}}`,
              )
            }
            ratios={ratios}
          >
            {children}
          </RatioPickerContext>
        );
      }}
    </RatiosFetcher>
  );
};
const InsertRatioButton = () => {
  const { actions, showPicker, ratios } = useRatioPickerContext();
  return (
    <>
      <CodeIcon onClick={() => actions.showModal(true)} />
      {showPicker && (
        <Modal onHide={() => actions.showModal(false)}>
          <RatiosDisplay categories={ratios} />
        </Modal>
      )}
    </>
  );
};

export const RteEditor = ({ value, onChange }) => {
  return (
    <Container>
      <Editor
        value={value}
        onChange={onChange}
        toolbar={() => {
          return (
            <>
              <FullscreenButton />
              <InsertRatioButtonWrapper>
                <InsertRatioButton />
              </InsertRatioButtonWrapper>
            </>
          );
        }}
        decorators={[generateSearchDecorator(/{([^}]+)}/g, Highlight)]}
      />
    </Container>
  );
};
