import { Button } from "@stockopedia/cms-ui";
import { Text } from "@buffetjs/core";
import React, { useState } from "react";
import styled from "styled-components";
import { LoadingState } from "..";
import { useAssetManager } from "../hooks";
import { Paper } from "./lib/paper";
import { ProgressBar } from "./lib/progress";
import { Spacer } from "./lib/spacer";
import { UploadPreview } from "./lib/upload-preview";
import { ErrorText } from "./lib/error";
import { UploadTagsSelector } from "./upload-tags-selector";

const BottomWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const UploadManager: React.FC = () => {
  const uploadAsset = useAssetManager((s) => s.uploadAsset);
  const uploadStatus = useAssetManager((s) => s.uploadStatus);
  const clearUploadData = useAssetManager((s) => s.clearUploadData);
  const selectedFileForUpload = useAssetManager((s) => s.selectedFileForUpload);
  const setFileForUpload = useAssetManager((s) => s.setFileForUpload);
  const selectedTags = useAssetManager((s) => s.selectedTags);

  const [progress, setProgress] = useState(0);

  const handleCancel = () => {
    setFileForUpload(null);
  };

  const handleClose = () => {
    clearUploadData();
  };

  const handleUpload = () => {
    uploadAsset(setProgress);
  };

  if (!selectedFileForUpload) {
    return null;
  }

  return (
    <React.Fragment>
      <Paper>
        <UploadPreview file={selectedFileForUpload} />
      </Paper>

      <Spacer />

      <Paper>
        {uploadStatus === null && <UploadTagsSelector />}

        {uploadStatus === LoadingState.Loading && (
          <ProgressBar progress={progress} />
        )}

        {uploadStatus === LoadingState.Error && (
          <ErrorText>There was an unexpected error</ErrorText>
        )}

        {uploadStatus === LoadingState.Success && <Text>Success</Text>}
      </Paper>

      <Spacer />

      {uploadStatus !== LoadingState.Success && (
        <BottomWrapper>
          <Button
            color="secondary"
            onClick={handleCancel}
            disabled={uploadStatus === LoadingState.Loading}
          >
            Cancel
          </Button>

          <Spacer />

          <Button
            color="primary"
            onClick={handleUpload}
            disabled={
              uploadStatus === LoadingState.Loading || selectedTags.length === 0
            }
          >
            Upload
          </Button>
        </BottomWrapper>
      )}

      {uploadStatus === LoadingState.Success && (
        <BottomWrapper>
          <Button color="primary" onClick={handleClose}>
            Close
          </Button>
        </BottomWrapper>
      )}
    </React.Fragment>
  );
};
