import Add from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { useMemo } from "react";
import { Category } from "shared/components/ratio-picker/types";
import styled from "styled-components";

import { Rule } from "../rule";
import { SecuritiesTable } from "../securities/view";
import { Button } from "../shared/button";
import { RuleState } from "../types";
import { ScreenBuilderProvider, useScreenBuilderContext } from "./context";

export interface Props {
  ratios: Category[];
  value: string;
  onChange: (query: string) => void;
}

const makeRules = (ast: string): RuleState[] => {
  try {
    return JSON.parse(ast);
  } catch (e) {
    return [];
  }
};

export const ScreenRuleBuilder = ({
  ratios: categories,
  value,
  onChange,
}: Props) => {
  const handleChange = (rules: RuleState[]) => {
    if (JSON.stringify(rules) !== value) {
      onChange(JSON.stringify(rules));
    }
  };

  const cachedValue = useMemo(() => {
    return { rules: makeRules(value) };
  }, [value]);

  return (
    <ScreenBuilderProvider
      value={cachedValue}
      ratios={categories}
      onChange={handleChange}
    >
      <>
        <View />
        <SecuritiesTable ast={cachedValue?.rules ?? []} />
      </>
    </ScreenBuilderProvider>
  );
};

const View = () => {
  const { rules, actions } = useScreenBuilderContext();

  const handleAdd = () => {
    actions.addRule();
  };

  return (
    <StyledContainer>
      <StyledUl>
        {rules.map((rule, index) => {
          return (
            <li key={index}>
              <Rule
                value={rule}
                onChange={(ruleState) => actions.updateRule(index, ruleState)}
              />
              <DeleteButton index={index} />
            </li>
          );
        })}
      </StyledUl>
      <PlusButton type="button" onClick={handleAdd}>
        <Add />
      </PlusButton>
    </StyledContainer>
  );
};

const DeleteButton = ({ index }) => {
  const { actions } = useScreenBuilderContext();

  return (
    <StyledDeleteButton
      type="button"
      onClick={() => {
        actions.deleteRule(index);
      }}
    >
      <DeleteIcon />
    </StyledDeleteButton>
  );
};

const StyledUl = styled.ul`
  margin-bottom: var(--padding-sm);

  & > li {
    margin-bottom: var(--padding-sm);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PlusButton = styled(Button)`
  align-self: center;
  border-radius: 100%;
`;

const StyledDeleteButton = styled(PlusButton)`
  background: var(--color-danger);
  align-self: flex-start;
  color: #fff;
`;
