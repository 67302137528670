import { EditorState, Modifier, SelectionState, convertToRaw } from 'draft-js'
import {
  Block,
  HANDLED,
  NOT_HANDLED,
  StringToTypeMap,
  addNewBlockAt,
  beforeInput,
  createEditorState,
  getCurrentBlock
} from 'medium-draft'
import {
  blockToHTML,
  entityToHTML,
  setRenderOptions,
  styleToHTML
} from 'medium-draft/lib/exporter'
import mediumDraftImporter from 'medium-draft/lib/importer'
import React from 'react'

const newBlockToHTML = (block) => {
  if (block.type === Block.ATOMIC) {
    if (block.text === 'E') {
      return {
        start: '<figure class="md-block-atomic md-block-atomic-embed">',
        end: '</figure>'
      }
    } else if (block.text === '-') {
      return (
        <p className='md-block-atomic md-block-atomic-break'>
          <hr />
        </p>
      )
    }
  }
  return blockToHTML(block)
}

export const exporter = (state: EditorState) => {
  const fn = setRenderOptions({
    styleToHTML,
    blockToHTML: newBlockToHTML,
    entityToHTML
  })
  return fn(state.getCurrentContent())
}

export const importer = (html: string): EditorState => {
  return createEditorState(convertToRaw(mediumDraftImporter(html)))
}

export type OnChange = (state: EditorState) => void

const newTypeMap = StringToTypeMap
newTypeMap['2.'] = Block.OL

const DQUOTE_START = '“'
const DQUOTE_END = '”'
const SQUOTE_START = '‘'
const SQUOTE_END = '’'

export const handleDroppedFiles = (callback: OnChange, state: EditorState) => (
  selection: SelectionState,
  files: { type: string }[]
) => {
  const file = files[0]
  if (file.type.indexOf('image/') === 0) {
    // eslint-disable-next-line no-undef
    const src = URL.createObjectURL(file)
    callback(
      addNewBlockAt(state, selection.getAnchorKey(), Block.IMAGE, {
        src
      })
    )
    return HANDLED
  }
  return NOT_HANDLED
}

export type InsertDataFn = (
  type: string,
  editorState: EditorState,
  data: any,
  text: string
) => void

export const insertData = (callback: OnChange) => (
  type: string,
  editorState: EditorState,
  data: any,
  text: string
) => {
  const contentState = editorState.getCurrentContent()
  const selection = editorState.getSelection()
  // create new content with text
  const newContent = Modifier.insertText(contentState, selection, text)
  // create new link entity
  const newContentWithEntity = newContent.createEntity(type, 'MUTABLE', data)
  const entityKey = newContentWithEntity.getLastCreatedEntityKey()
  // create new selection with the inserted text
  const anchorOffset = selection.getAnchorOffset()
  const newSelection = new SelectionState({
    anchorKey: selection.getAnchorKey(),
    anchorOffset,
    focusKey: selection.getAnchorKey(),
    focusOffset: anchorOffset + text.length
  })
  // and aply link entity to the inserted text
  const newContentWithLink = Modifier.applyEntity(
    newContentWithEntity,
    newSelection,
    entityKey
  )
  // create new state with link text
  const withLinkText = EditorState.push(
    editorState,
    newContentWithLink,
    'insert-characters'
  )
  // now lets add cursor right after the inserted link
  const withProperCursor = EditorState.forceSelection(
    withLinkText,
    newContent.getSelectionAfter()
  )
  // update the editor with all changes

  callback(withProperCursor)
}

export const handleBeforeInput = (callback: OnChange) => (
  editorState: EditorState,
  str: string
) => {
  if (str === '"' || str === "'") {
    const currentBlock = getCurrentBlock(editorState)
    const selectionState = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const text = currentBlock.getText()
    const len = text.length
    if (selectionState.getAnchorOffset() === 0) {
      callback(
        EditorState.push(
          editorState,
          Modifier.insertText(
            contentState,
            selectionState,
            str === '"' ? DQUOTE_START : SQUOTE_START
          ),
          'change-inline-style'
        )
      )
      return HANDLED
    } else if (len > 0) {
      const lastChar = text[len - 1]
      if (lastChar !== ' ') {
        callback(
          EditorState.push(
            editorState,
            Modifier.insertText(
              contentState,
              selectionState,
              str === '"' ? DQUOTE_END : SQUOTE_END
            ),
            'change-inline-style'
          )
        )
      } else {
        callback(
          EditorState.push(
            editorState,
            Modifier.insertText(
              contentState,
              selectionState,
              str === '"' ? DQUOTE_START : SQUOTE_START
            ),
            'change-inline-style'
          )
        )
      }
      return HANDLED
    }
  }
  return beforeInput(editorState, str, callback, newTypeMap)
}
