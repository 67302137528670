import { createContext, useContext } from "react";
import { StateSelector, UseStore } from "zustand";

import { ControlPlaneState } from "../state";

export const ControlPlaneContext =
  createContext<UseStore<ControlPlaneState> | null>(null);

export const useControlPlane = <U>(
  selector: StateSelector<ControlPlaneState, U>,
) => useContext(ControlPlaneContext)!(selector);
