import { InputText } from '@buffetjs/core'
import React from 'react'

interface Props {
  value?: string
  name: string
  icon?: React.ReactNode
  onChange: (v: string) => void
  onFocus?: () => void
  onBlur?: (e: any) => void
  onClick?: () => void
  type?: string
  placeholder?: string
  autoComplete?: 'on' | 'off'
}

export const TextInput = ({
  value,
  onChange,
  onFocus,
  onClick,
  onBlur,
  icon,
  type,
  placeholder,
  name,
  autoComplete
}: Props) => {
  return (
    <InputText
      autoComplete={autoComplete}
      name={name}
      onChange={({ target: { value } }) => {
        console.log(value)
        onChange(value)
      }}
      placeholder={placeholder}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      type={type ?? 'text'}
      icon={icon}
      value={value}
    />
  )
}
