import {
  EntityContextProvider,
  EntityServiceProvider,
} from "@stockopedia/cms-ef";
import { Container } from "framework";
import { Module } from "framework/types";
import { urls } from "urls";

import { ThemeService } from "./services/themes.service";
import { CreateView, EditView } from "./views/crud.view";
import { ListView } from "./views/list.view";

export const THEMES_MODULE_TOKEN = "themes";

export const themesModule = (c: Container): Module => ({
  id: THEMES_MODULE_TOKEN,
  rootComponent: ({ children }) => (
    <EntityServiceProvider service={c.resolve(ThemeService)}>
      <EntityContextProvider basePath={urls.cybertorial.BASE_PATH.url}>
        {children}
      </EntityContextProvider>
    </EntityServiceProvider>
  ),
  pages: {
    [urls.cybertorial.themes.create.url]: CreateView,
    [urls.cybertorial.themes.edit.url]: EditView,
    [urls.cybertorial.themes.root.url]: ListView,
  },
});
