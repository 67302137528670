import "@fortawesome/fontawesome-free/css/all.min.css";
import "modules/auth/container";
import "clients/container";
import "shared/components/ratio-picker/container";
import "modules/container";

import { Loading } from "@stockopedia/cms-ui";
import { ModuleLoader } from "framework/components";
import { container } from "framework/container";
import { Module } from "framework/types";
import { ADMIN_MODULE_TOKEN, adminModule } from "modules/admin";
import { AUTH_MODULE_TOKEN } from "modules/auth";
import { INITIAL_URL_STORAGE_KEY } from "modules/auth/contants";
import { AuthContext, useAuth } from "modules/auth/hooks/use-auth";
import { AuthState } from "modules/auth/state";
import {
  CYBERTORIAL_MODULE_TOKEN,
  cybertorialModule,
} from "modules/cybertorial";
import React, { Suspense } from "react";
import { useEffect } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { LoadingState } from "shared/types";
import { urls } from "urls";

const Features = React.lazy(() => import("./modules/features"));

container
  .registerAuth()
  .registerModule(ADMIN_MODULE_TOKEN, adminModule)
  .registerModule(CYBERTORIAL_MODULE_TOKEN, cybertorialModule)
  .registerStockopediaClient()
  .registerRatios()
  .registerFeatures()
  .registerAssetManager();

function Root() {
  const userState = useAuth((s) => s.user);
  const userLoadingState = useAuth((s) => s.loadingState);
  const location = useLocation();

  useEffect(() => {
    if (!sessionStorage.getItem(INITIAL_URL_STORAGE_KEY)) {
      sessionStorage.setItem(INITIAL_URL_STORAGE_KEY, location.pathname);
    }
  }, [location]);

  if (userLoadingState === LoadingState.waiting) {
    return <Loading />;
  }

  if (!userState) {
    return <Redirect to={urls.auth.login.url} />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <Features />
    </Suspense>
  );
}

function App() {
  return (
    <AuthContext.Provider
      value={container.resolve<Module<AuthState>>(AUTH_MODULE_TOKEN).store!}
    >
      <ToastProvider>
        <BrowserRouter>
          <ModuleLoader modules={[AUTH_MODULE_TOKEN]}>
            <Switch>
              <Route path={urls.root.url} component={Root} />
            </Switch>
          </ModuleLoader>
        </BrowserRouter>
      </ToastProvider>
    </AuthContext.Provider>
  );
}

export default App;
