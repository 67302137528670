import { EditorState } from 'draft-js'
import React, { createContext, useContext } from 'react'
import styled from 'styled-components'

import { InsertDataFn } from '../utils'

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 8px;
  padding: 10px;
  background: var(--color-background);
  border-radius: 2px;

  & > svg {
    cursor: pointer;
    font-size: 1.8rem;
    margin-left: 10px;
  }
`

interface Actions {
  toggleFullscreen: () => void
  insertData: InsertDataFn
}

interface Props {
  render?: () => React.ReactNode
}

export const Toolbar = ({ render }: Props) => {
  return <InnerContainer>{render && render()}</InnerContainer>
}

interface Context {
  actions: Actions
  state: EditorState
}

export const ToolbarContext = createContext<Context>(undefined as any)

export const useToolbarContext = () => useContext(ToolbarContext)
