import { env } from "env";
import { Container } from "framework";

import { AUTH_MODULE_TOKEN } from "./index";
import { authModule } from "./module";
import { UserServiceFactory } from "./services/user/factory";
import { UserService } from "./services/user/service";

declare module "framework/container" {
  interface Container {
    registerAuth(): Container;
  }
}

const config = {
  authority: env.auth_authority,
  client_id: "growthCms",
  redirect_uri: env.auth_redirect_uri,
  response_type: "code",
  scope: "openid profile offline_access role",
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  post_logout_redirect_uri: env.auth_post_logout_redirect_uri,
  silent_redirect_uri: env.auth_silent_redirect_uri,
};

Container.prototype.registerAuth = function () {
  this.registerFactory(UserService, (_) =>
    new UserServiceFactory(config).make(
      env.node_env === "development" && !env.auth_enabled,
    ),
  );

  this.registerModule(AUTH_MODULE_TOKEN, authModule);

  return this;
};
