import { Padding, Row } from "@stockopedia/cms-ui";

import { Button } from "../shared/button";
import { AdvancedType } from "../types";
import { useScreenRuleContext } from "./";

export const advancedButtonOptions = [
  {
    display: "Median",
    value: AdvancedType.Median,
  },
  {
    display: "Average",
    value: AdvancedType.Average,
  },
  {
    display: "Rank",
    value: AdvancedType.Rank,
  },
  {
    display: "Comparison",
    value: AdvancedType.Comparison,
  },
];

export const AdvancedTypePicker = () => {
  const { actions, advancedType } = useScreenRuleContext();

  return (
    <Row className="items-center">
      {advancedButtonOptions.map((b) => {
        return (
          <Padding key={b.value} className="auto-width" right size="sm">
            <Button
              active={b.value === advancedType}
              onClick={() => actions.setAdvancedType(b.value)}
            >
              {b.display}
            </Button>
          </Padding>
        );
      })}
    </Row>
  );
};
