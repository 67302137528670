import { Button as BuffetButton } from '@buffetjs/core'
import React from 'react'

type ColorType = 'primary' | 'warning' | 'secondary'

interface Props {
  disabled?: boolean
  label?: string
  type?: 'submit' | 'button'
  color: ColorType
  onClick?: () => void
  children?: React.ReactNode
  icon?: React.ReactElement
}

const colorTypeMapper = (color: ColorType): string => {
  switch (color) {
    case 'primary':
      return 'success'
    case 'secondary':
      return 'cancel'
    case 'warning':
      return 'delete'
  }
}

export const Button = ({ children, ...props }: Props) => (
  <BuffetButton {...props} color={colorTypeMapper(props.color)}>
    {children}
  </BuffetButton>
)
