import { UserManager } from "oidc-client";

import { NoopUserService } from "./noop";
import { UserService } from "./service";

interface Config {
  authority: string;
  client_id: string;
  redirect_uri: string;
  response_type: string;
  scope: string;
  automaticSilentRenew: boolean;
  post_logout_redirect_uri: string;
  silent_redirect_uri: string;
}
export class UserServiceFactory {
  constructor(private readonly config: Config) {}

  make(development: boolean) {
    if (development) {
      return new NoopUserService();
    }
    return new UserService(new UserManager(this.config));
  }
}
